import * as React from "react";
import Svg, { Path } from "react-native-svg";
const BuildingIcon = (props) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill={props?.fill ? props?.fill : "none"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.71683 3.80433C9.71683 3.9785 9.57016 4.12516 9.396 4.12516H8.36016C6.38016 4.12516 4.76683 5.7385 4.76683 7.7185V16.1793C4.76683 16.3535 4.62016 16.5002 4.446 16.5002H3.80433C2.7135 16.5002 1.8335 15.6202 1.8335 14.5293V3.80433C1.8335 2.7135 2.7135 1.8335 3.80433 1.8335H7.746C8.83683 1.8335 9.71683 2.7135 9.71683 3.80433ZM20.1668 3.80433V14.5293C20.1668 15.6202 19.2868 16.5002 18.196 16.5002H17.6185C17.4443 16.5002 17.2977 16.3535 17.2977 16.1793V7.7185C17.2977 5.7385 15.6843 4.12516 13.7043 4.12516H12.6043C12.4302 4.12516 12.2835 3.9785 12.2835 3.80433C12.2835 2.7135 13.1635 1.8335 14.2543 1.8335H18.196C19.2868 1.8335 20.1668 2.7135 20.1668 3.80433Z"
      fill={props.isActive ? "#6724E2" : props?.fill ? props?.fill : "black"}
      stroke={props?.stroke ? props?.stroke : "none"}
    />
    <Path
      d="M13.7041 5.5H8.35994C7.1316 5.5 6.1416 6.49 6.1416 7.71833V17.9483C6.1416 19.1767 7.1316 20.1667 8.35994 20.1667H9.8541C10.1108 20.1667 10.3124 19.965 10.3124 19.7083V17.4167C10.3124 17.0408 10.6241 16.7292 10.9999 16.7292C11.3758 16.7292 11.6874 17.0408 11.6874 17.4167V19.7083C11.6874 19.965 11.8891 20.1667 12.1458 20.1667H13.7133C14.9324 20.1667 15.9224 19.1767 15.9224 17.9575V7.71833C15.9224 6.49 14.9324 5.5 13.7041 5.5ZM12.8333 13.5208H9.1666C8.79077 13.5208 8.4791 13.2092 8.4791 12.8333C8.4791 12.4575 8.79077 12.1458 9.1666 12.1458H12.8333C13.2091 12.1458 13.5208 12.4575 13.5208 12.8333C13.5208 13.2092 13.2091 13.5208 12.8333 13.5208ZM12.8333 10.7708H9.1666C8.79077 10.7708 8.4791 10.4592 8.4791 10.0833C8.4791 9.7075 8.79077 9.39583 9.1666 9.39583H12.8333C13.2091 9.39583 13.5208 9.7075 13.5208 10.0833C13.5208 10.4592 13.2091 10.7708 12.8333 10.7708Z"
      fill={props.isActive ? "#6724E2" : props?.fill ? props?.fill : "black"}
      stroke={props?.stroke ? props?.stroke : "none"}
    />
  </Svg>
);
export default BuildingIcon;
