import * as React from "react";
import Svg, { Path } from "react-native-svg";
const RulerPenIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.60246 4.5H12.3525M8.60246 13.5H11.6025M8.60246 10.4625L12.3525 10.5M8.60246 7.5H10.8525M6.32996 5.25H1.89746M16.1025 14.25V3.75C16.1025 2.25 15.3525 1.5 13.8525 1.5H10.8525C9.35246 1.5 8.60246 2.25 8.60246 3.75V14.25C8.60246 15.75 9.35246 16.5 10.8525 16.5H13.8525C15.3525 16.5 16.1025 15.75 16.1025 14.25ZM4.11746 1.5C2.89496 1.5 1.89746 2.4975 1.89746 3.7125V13.4325C1.89746 13.77 2.03996 14.28 2.21246 14.5725L2.82746 15.5925C3.53246 16.77 4.69496 16.77 5.39996 15.5925L6.01496 14.5725C6.18746 14.28 6.32996 13.77 6.32996 13.4325V3.7125C6.32996 2.4975 5.33246 1.5 4.11746 1.5Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);
export default RulerPenIcon;
