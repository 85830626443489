import Svg, { Path } from "react-native-svg";
const WaIcon = (props) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <Path
      fill={props.fill || "#000"}
      d="M20.148 10.46A9.17 9.17 0 0 0 9.442 1.961c-3.832.632-6.903 3.74-7.499 7.571a9.183 9.183 0 0 0 1.11 6.05l-.816 3.035a.913.913 0 0 0 1.127 1.118l2.988-.825a9.125 9.125 0 0 0 4.639 1.256c5.17 0 9.46-4.556 9.157-9.708Zm-4.675 3.95a2.09 2.09 0 0 1-1.063 1.008c-.275.12-.578.175-.898.175-.468 0-.972-.11-1.495-.34a8.397 8.397 0 0 1-1.576-.907 13.244 13.244 0 0 1-1.504-1.283c-.476-.477-.898-.981-1.283-1.504a8.063 8.063 0 0 1-.898-1.567c-.22-.523-.33-1.027-.33-1.504 0-.311.055-.614.165-.889.11-.284.284-.54.531-.77.294-.293.615-.43.954-.43.128 0 .256.027.376.082a.76.76 0 0 1 .311.257L9.827 8.24c.082.12.146.22.183.32.046.102.064.193.064.285 0 .11-.037.22-.1.33-.065.11-.147.22-.257.33l-.349.367a.235.235 0 0 0-.073.183c0 .037.01.073.018.11a.633.633 0 0 1 .037.092c.082.155.23.348.43.586.43.505.899.974 1.403 1.403.239.201.44.339.596.421.028.01.055.028.083.037a.252.252 0 0 0 .119.018.25.25 0 0 0 .192-.082l.349-.348c.119-.12.229-.202.33-.257.11-.064.21-.1.33-.1.091 0 .183.017.284.063.1.046.21.101.32.184l1.522 1.081c.12.083.202.184.257.294.046.119.073.229.073.357-.055.156-.091.33-.165.495Z"
    />
  </Svg>
);
export default WaIcon;
