import { Text, View } from "react-native";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import achievements from "./achievement.json";

export function CompanyProfileAchievement({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <View style={styles("header")}>
        <Text style={styles("txTitle")}>Pencapaian Fammi</Text>
        <Text style={styles("txDesc")}>
          Dipercaya Oleh Berbagai Stakeholder Untuk Kolaborasi Mempersiapkan
          Generasi
        </Text>
      </View>
      <View style={styles("content")}>
        {achievements.map((item) => (
          <AchievementCard key={item.id} data={item} styles={styles} />
        ))}
      </View>
    </View>
  );
}

function AchievementCard({ data, styles }) {
  return (
    <View style={styles("card")}>
      <View style={styles("cardContent")}>
        <Text style={styles("txTotal")}>{data.total}</Text>
        <Text style={styles("txLabel")}>{data.label}</Text>
      </View>
      <View style={[styles("colorsBox"), { backgroundColor: data.color }]} />
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    header: { gap: 16 },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 26,
      lineHeight: 34,
      color: "#0A0316",
      textAlign: "center",
    },
    content: {
      flexDirection: "row",
      gap: 20,
    },
    card: {
      width: 285,
      height: 152,
      borderRadius: 20,
      backgroundColor: "#fff",
    },
    cardContent: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 8,
      padding: 24,
    },
    txTotal: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 44,
      lineHeight: 50,
      color: "#6724E2",
    },
    txLabel: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#0A0316",
    },
    colorsBox: {
      width: "100%",
      height: 20,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
  },
  {
    container: { gap: 32 },
    header: { gap: 12 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 18,
      lineHeight: 34,
    },
    content: {
      flexDirection: "column",
      gap: 24,
    },
    card: { width: "100%" },
    txLabel: { fontSize: 20 },
  }
);
