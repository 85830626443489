import { StyleSheet } from "react-native";

const CreateResponsiveStyle = (webStyle, mobileStyle) => {
  const web = StyleSheet.create(webStyle);
  const mobile = StyleSheet.create(mobileStyle);

  return (layout) => (style) => {
    if (layout.width < 500 && mobile.hasOwnProperty(style)) {
      return StyleSheet.compose(web[style], mobile[style]);
    }
    return web[style];
  };
};

export default CreateResponsiveStyle;
