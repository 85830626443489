import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import MessagesCircleIcon from "../assets/icons/MessagesCircleIcon";
import Profile2UserCircleIcon from "../assets/icons/Profile2UserCircleIcon";
import VerifyCircleIcon from "../assets/icons/VerifyCircleIcon";

const KOTAInformation = ({ icon, label, desc }) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {icon}
      <View style={{ marginLeft: 8 }}>
        <Text style={styles.topText}>{label}</Text>
        <Text style={styles.descText}>{desc}</Text>
      </View>
    </View>
  );
};

const KOTACard = () => {
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <Text style={styles.topText}>KOTA ~ Komunitas Terampil & Ahli</Text>
        <Pressable>
          <Text style={{ ...styles.topText, color: "#6724E2" }}>Gabung</Text>
        </Pressable>
      </View>
      <View style={styles.bottom}>
        <KOTAInformation
          icon={<Profile2UserCircleIcon />}
          label="1.204 Warga"
          desc="Tergabung"
        />
        <KOTAInformation
          icon={<MessagesCircleIcon />}
          label="42 Gratis"
          desc="Layanan"
        />
        <KOTAInformation
          icon={<VerifyCircleIcon />}
          label="65 Ahli"
          desc="Standby"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginHorizontal: 16,
    marginBottom: 24,
  },
  top: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea",
  },
  topText: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
  },
  bottom: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 16,
  },
  descText: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 11,
  },
});

export default KOTACard;
