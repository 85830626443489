import moment from "moment";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

function HeaderContent(props) {
  const { quizAnswer } = props;
  const [identity, setIdentity] = useState();
  useEffect(() => {
    if (!quizAnswer) {
      return;
    }

    let filteredData = {};
    quizAnswer?.map((q) => {
      if (q.indexLabel && q.indexLabel !== null && q.indexLabel !== "") {
        filteredData[q.indexLabel] = q.chosenAnswer;
      }
    });

    setIdentity(filteredData);
    // console.log(filteredData);
  }, [quizAnswer]);
  return (
    <View style={styles.header}>
      <View style={styles.headerItem}>
        <View style={{ marginRight: 32, gap: 8, flexDirection: "column" }}>
          <Text style={styles.txHeaderName}>Nama</Text>
          <Text style={styles.txHeaderTile}>{identity?.nama}</Text>
        </View>
        <View style={{ marginRight: 32, gap: 8, flexDirection: "column" }}>
          <Text style={styles.txHeaderName}>Jenis Kelamin</Text>
          <Text style={styles.txHeaderTile}>{identity?.gender}</Text>
        </View>
        <View style={{ gap: 8, flexDirection: "column" }}>
          <Text style={styles.txHeaderName}>Tanggal Lahir</Text>
          <Text style={styles.txHeaderTile}>{identity?.tanggal_lahir}</Text>
        </View>
      </View>
      <View style={styles.headerItem}>
        <View style={{ marginRight: 32, gap: 8, flexDirection: "column" }}>
          <Text style={styles.txHeaderName}>Tanggal Tes</Text>
          <Text style={styles.txHeaderTile}>
            {moment(quizAnswer?.[0].created).format("DD MMMM YYYY")}
          </Text>
        </View>
        {/* <View style={{ marginRight: 32 }}>
          <Text style={styles.txHeaderName}>Sekolah</Text>
          <Text
            style={{ ...styles.txHeaderTile, maxWidth: 240, width: "100%" }}
            numberOfLines={1}
          >
            Fammi Corner TK Muslimat NU Dewi Mashithoh 4 Kalipare Malang
          </Text>
        </View> */}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 48,
    paddingVertical: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#959895",
    borderBottomWidth: 1,
    borderStyle: "solid",
  },
  txHeaderName: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "400",
	color: '#959895'
  },
  txHeaderTile: {
    fontFamily: "Archivo-Bold",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "600",
  },
  headerItem: {
    flexDirection: "row",
  },
});

export default HeaderContent;
