import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const Profile2UserCircleIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={12} cy={12} r={12} fill="black" />
    <Path
      d="M9.99997 5.3335C8.25331 5.3335 6.83331 6.7535 6.83331 8.50016C6.83331 10.2135 8.17331 11.6002 9.91997 11.6602C9.97331 11.6535 10.0266 11.6535 10.0666 11.6602H10.1133C10.9329 11.6328 11.7097 11.2878 12.2795 10.6981C12.8493 10.1084 13.1674 9.32019 13.1666 8.50016C13.1666 6.7535 11.7466 5.3335 9.99997 5.3335ZM13.3866 13.4328C11.5266 12.1928 8.49331 12.1928 6.61997 13.4328C5.77331 13.9995 5.30664 14.7662 5.30664 15.5862C5.30664 16.4062 5.77331 17.1662 6.61331 17.7262C7.54664 18.3528 8.77331 18.6662 9.99997 18.6662C11.2266 18.6662 12.4533 18.3528 13.3866 17.7262C14.2266 17.1595 14.6933 16.3995 14.6933 15.5728C14.6866 14.7528 14.2266 13.9928 13.3866 13.4328ZM17.3266 8.89216C17.4333 10.1855 16.5133 11.3188 15.24 11.4722H15.2066C15.1666 11.4722 15.1266 11.4722 15.0933 11.4855C14.4466 11.5188 13.8533 11.3122 13.4066 10.9322C14.0933 10.3188 14.4866 9.39883 14.4066 8.39883C14.3609 7.87883 14.1843 7.37887 13.8933 6.9455C14.2426 6.77555 14.6281 6.69314 15.0163 6.70538C15.4046 6.71761 15.7841 6.82413 16.122 7.01573C16.46 7.20733 16.7462 7.47828 16.9561 7.80518C17.1659 8.13209 17.2931 8.50514 17.3266 8.89216Z"
      fill="white"
    />
    <Path
      d="M18.6585 15.0599C18.6052 15.7066 18.1919 16.2666 17.4985 16.6466C16.8319 17.0133 15.9919 17.1866 15.1585 17.1666C15.6385 16.7333 15.9185 16.1933 15.9719 15.6199C16.0385 14.7933 15.6452 13.9999 14.8585 13.3666C14.4119 13.0133 13.8919 12.7333 13.3252 12.5266C14.7985 12.0999 16.6519 12.3866 17.7919 13.3066C18.4052 13.7999 18.7185 14.4199 18.6585 15.0599Z"
      fill="white"
    />
  </Svg>
);
export default Profile2UserCircleIcon;
