import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import PlayIcon from "../assets/icons/PlayIcon";

function CardVideo() {
  return (
    <View style={styles.parentCard}>
      <Image
        source={{ uri: "https://via.placeholder.com/325x230" }}
        style={styles.cardImage}
      />
      <View style={styles.sectionButton}>
        <PlayIcon />
        <View style={styles.timeSection}>
          <Text style={styles.txTime}>2 Menit</Text>
        </View>
      </View>
      <View style={styles.main}>
        <Text style={styles.textMain}>Tips Supaya Lebih Dekat dengan Remaja dan Bahas Tema Sensitif</Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentCard: {
    width: 310,
    borderRadius: 10,
    backgroundColor: "#fff",
    marginRight: 14,
  },
  sectionButton: {
    width: "100%",
    height: 137,
    marginTop: -137,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 50,
    paddingBottom: 12,
    borderRadius: 10,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    paddingLeft: 12,
  },
  cardImage: {
    width: "100%",
    borderRadius: 10,
    height: 137,
  },
  timeSection: {
    width: "100%",
    justifyContent: "flex-start",
  },
  txTime: {
    color: "#FFFFFF",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
  },
  main: {
    padding: 12,
  },
  textMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 18,
  },
});
export default CardVideo;
