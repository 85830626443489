import axios from "axios";
import {API_URL} from "@env";

export const sendOtp = (phoneNumber) => {
    return axios.post(API_URL + `/sendotp-kota`, {
        "phone": phoneNumber
    });
}

export const checkOtp = (phoneNumber, otp) => {
    return axios.post(API_URL + `/checkotp-kota`, {
        "phone": phoneNumber,
        "otpCode": otp 
    });
}

export const getAdditionalMember = (token) => {
    return axios.get(API_URL + `/members/${token}`);
}

export const updateToken = (token, otp) => {
    return axios.post(API_URL + `/update-token`, {
        otpCode: otp, 
        member_id: token
    });
}