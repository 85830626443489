import { StyleSheet, Text, TextInput, TouchableOpacity } from "react-native";
import { CheckCircleIcon, RadioIcon } from "../../assets/icons";
import SurveyQuestionCard from "./SurveyQuestionCard";
import ModalMenuDropdown from "../ModalMenuDropdown";

const SurveyDetailCard = ({ data, answer, handleAnswer }) => {
  const { orderNo, title, quizType, detail } = data;
  const isSingleChoice = quizType === "single_choice";
  const detailOptions = JSON.parse(detail)?.options;

  return (
    <SurveyQuestionCard number={orderNo} type={quizType} question={title}>
      {quizType === "free_text" || quizType === "short_text" ? (
        <TextInput
          multiline={quizType === "free_text" ? true : false}
          placeholder="Ketik jawaban di sini"
          style={[styles.textInput, styles.optionTx, {height: quizType === "free_text" ? 100 : 48}]}
          placeholderTextColor="#999"
          value={answer[data.rowId]}
          onChangeText={(val) => {
            if (val === "") {
              let newAnswer = answer;
              delete newAnswer[data.rowId];
              return handleAnswer({ ...newAnswer });
            }
            handleAnswer({
              ...answer,
              [data.rowId]: val,
            });
          }}
        />
      ) : quizType === "data_source" ? (
        <ModalMenuDropdown
          options={detailOptions}
          isSearch
          value={answer?.[data.rowId]}
          onChange={(value) => {
            handleAnswer({ ...answer, [data.rowId]: value });
          }}
        />
      ) : (
        Array.isArray(detailOptions) &&
        JSON.parse(detail)?.options?.map((opt, id) => (
          <TouchableOpacity
            key={id}
            style={styles.option}
            onPress={() => {
              let value;
              if (isSingleChoice) {
                value = opt;
              } else if (Array.isArray(answer?.[data.rowId])) {
                const multiple = answer?.[data.rowId];
                const idx = multiple.findIndex((i) => i === opt);

                if (idx > -1) {
                  const f = multiple.filter((i) => i !== opt);
                  value = f;
                } else {
                  value = [...multiple, opt];
                }
              } else {
                value = [opt];
              }

              if (value.length === 0) {
                let newAnswer = answer;
                delete newAnswer[data.rowId];
                return handleAnswer({ ...newAnswer });
              }

              handleAnswer({ ...answer, [data.rowId]: value });
            }}
          >
            {isSingleChoice ? (
              <RadioIcon
                isSelected={opt === answer?.[data.rowId]}
                style={{ flexShrink: 0 }}
              />
            ) : (
              <CheckCircleIcon
                isSelected={
                  Array.isArray(answer?.[data.rowId]) &&
                  answer?.[data.rowId].includes(opt)
                }
                style={{ flexShrink: 0 }}
              />
            )}
            <Text style={[styles.optionTx, { maxWidth: "90%" }]}>{opt}</Text>
          </TouchableOpacity>
        ))
      )}
    </SurveyQuestionCard>
  );
};

const styles = StyleSheet.create({
  option: {
    width: "100%",
    minHeight: 46,
    borderWidth: 1,
    borderRadius: 10,
    padding: 12,
    position: "relative",
    borderColor: "#EAEAEA",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  optionTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 14,
    zIndex: 1,
  },
  textInput: {
    height: 100,
    padding: 12,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#eaeaea",
    textAlignVertical: "top",
  },
});

export default SurveyDetailCard;
