import React, { useState, Fragment, useEffect } from "react";
import {
  Text,
  TouchableHighlight,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { StyleSheet, View } from "react-native";
import { Like1Icon } from "../assets/icons";
import moment from "moment";
import { getComments, setFeedback } from "../services/programs";
import { useNavigation } from "@react-navigation/native";

function ListComment(props) {
  const { data, onOpenComment, openComment, memberId, setIsSnackbar, setMsg } =
    props;
  const [like, setLike] = useState(data?.totalView > 0);
  const [totalLike, setTotalLike] = useState(data?.totalLike);
  const [threads, setThreads] = useState([]);
  const navigation = useNavigation();
  useEffect(() => {
    if (!memberId) {
      return;
    }

    if (openComment) {
      return;
    }

    getComments(data?.additionalContentId, data?.questionId, memberId).then(
      (res) => {
        setThreads(res?.data);
      }
    );
  }, [memberId, data?.questionId, openComment]);
  const ListThread = ({ countMore, thread }) => (
    <Fragment>
      <View style={styles.parentThread}>
        <Text style={styles.txContent}>{thread?.question}</Text>
        <Text style={{ ...styles.txSpan, marginLeft: 0, marginTop: 8 }}>
          {moment(thread?.created).format("DD MMM YYYY, HH:mm")}
        </Text>
      </View>
      <View style={styles.threadDivider}>
        {countMore && (
          <Pressable
            onPress={() => {
              navigation.navigate("DetailDiscussions", {
                additionalContentId: data?.additionalContentId,
                id: data?.questionId,
                data,
                alreadyLike: like,
              });
            }}
          >
            <Text style={{ ...styles.txSpan, marginLeft: 0 }}>
              Lihat {countMore - 2} komentar lainnya
            </Text>
          </Pressable>
        )}
      </View>
    </Fragment>
  );
  return (
    <View style={styles.parentList}>
      <View
        style={{
          ...styles.flexRow,
          justifyContent: "space-between",
          marginBottom: 8,
          alignItems: "flex-start",
        }}
      >
        <Text style={{...styles.txContent, maxWidth:"95%"}}>{data?.question}</Text>
        <TouchableOpacity
          style={{ ...styles.flexRow }}
          onPress={() => {
            if (like) {
              setTotalLike(totalLike - 1);
            } else {
              setTotalLike(totalLike + 1);
            }
            setLike(!like);
            setFeedback(data?.questionId, data?.memberId);
            if (!like) {
              setIsSnackbar(true);
              setMsg("Kamu berhasil memberikan terima kasih");
            }
          }}
        >
          <Like1Icon
            customColor={like ? "#000" : "#fff"}
            customStrokeColor={like ? "#000" : "#999"}
          />
        </TouchableOpacity>
      </View>

      <Text style={styles.txSpan}>
        {moment(data?.created).format("DD MMM YYYY, HH:mm")} • {totalLike}{" "}
        Terima Kasih
      </Text>
      <View style={{ marginTop: 10 }}>
        {threads?.length > 0 &&
          threads
            ?.slice(0, 2)
            .map((thread, index) => (
              <ListThread
                thread={thread}
                countMore={
                  index + 1 === 2 && threads.length > 2 && threads.length
                }
              />
            ))}
        <Pressable onPress={() => onOpenComment(data)} style={styles.input}>
          <Text style={{...styles.textInput, color: "#999999"}}>Balas komentar...</Text>
        </Pressable>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    backgroundColor: "#fff",
    paddingVertical: 16,
    paddingHorizontal: 12,
    borderRadius: 10,
    marginBottom: 10,
  },
  txContent: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 20,
  },
  txSpan: {
    color: "#999",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 12,
  },
  txLike: {
    color: "#000",
    marginLeft: 4,
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 12,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: "#F6F6F6",
    paddingTop: 14,
    paddingRight: 12,
    paddingBottom: 14,
    paddingLeft: 12,
  },
  textInput: {
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
  },
  parentThread: {
    backgroundColor: "#F6F6F6",
    borderRadius: 10,
    padding: 12,
  },
  threadDivider: {
    marginLeft: 12,
    padding: 12,
    paddingBottom: 0,
    borderLeftColor: "#f6f6f6",
    borderStyle: "solid",
    borderLeftWidth: 2,
  },
});
export default ListComment;
