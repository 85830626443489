import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ChevronRight } from "../../assets/icons";

export const ScreeningUserDiffResultCard = ({ data, onWatchAllUser }) => {
  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <View style={[styles.header, styles.flexRowBetween]}>
          <Text style={styles.txTitle}>Nama</Text>
          <Text style={styles.txTitle}>Perbedaan Aspek</Text>
        </View>
        <View style={styles.list}>
          {data.map((d, id) => {
            return (
              <View key={id} style={styles.flexRowBetween}>
                <Text
                  numberOfLines={1}
                  style={[styles.txDataLabel, { maxWidth: "60%" }]}
                >
                  {d.memberName}
                </Text>
                <Text style={[styles.txDataLabel, { color: "#999" }]}>
                  {parseInt(d.memberPoin ?? 0)} Aspek
                </Text>
              </View>
            );
          })}
        </View>
      </View>

      <TouchableOpacity style={styles.button} onPress={onWatchAllUser}>
        <Text style={styles.txButton}>Lihat Semua Pengguna</Text>
        <ChevronRight />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 10,
    width: "100%"
  },
  flexRowBetween: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10
  },
  header: {
    paddingVertical: 14,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea"
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12
  },
  list: {
    paddingVertical: 14,
    paddingHorizontal: 12,
    flexDirection: "column",
    gap: 16
  },
  txDataLabel: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 12
  },
  txButton: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#6724E2"
  }
});
