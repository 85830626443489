import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ChevronRight } from "../../assets/icons";

export const ScreeningAspectDiffCard = ({ data, onWatchAllAspect }) => {

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        {data.map((d, id) => {
          const isLast = d === data[data.length - 1];
          return <AspectDiffCard key={id} {...d} hideBorder={isLast} />;
        })}
      </View>

      <TouchableOpacity style={styles.button} onPress={onWatchAllAspect}>
        <Text style={styles.txButton}>Lihat Semua Aspek</Text>
        <ChevronRight />
      </TouchableOpacity>
    </View>
  );
};

export function AspectDiffCard({ question, memberPoin, isDetail, hideBorder }) {
  return (
    <View
      style={[
        styles.data,
        isDetail && styles.isDetail,
        !hideBorder && styles.border
      ]}
    >
      <Text style={styles.txDataLabel}>{question}</Text>
      <View style={styles.value}>
        <Text style={styles.txDataValue}>{parseInt(memberPoin ?? 0)} Pengguna</Text>
        <Text style={styles.txDataDesc}>berbeda aspek dengan petugas</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 10,
    width: "100%"
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    flexDirection: "column"
  },
  data: {
    paddingVertical: 14,
    paddingHorizontal: 12
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea"
  },
  isDetail: {
    backgroundColor: "#fff",
    borderRadius: 10
  },
  txDataLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13
  },
  value: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 6
  },
  txDataValue: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    color: "#4EB826"
  },
  txDataDesc: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#999"
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 12
  },
  txButton: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#6724E2"
  }
});
