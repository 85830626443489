import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ArrowCircleRightIcon = ({ color, bgOpacity, ...props }) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      opacity={bgOpacity ?? 0.4}
      d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
      fill={color ?? "#FDC52E"}
    />
    <Path
      d="M21.4804 32.5603C21.1004 32.5603 20.7204 32.4203 20.4204 32.1203C19.8404 31.5403 19.8404 30.5803 20.4204 30.0003L26.4204 24.0003L20.4204 18.0003C19.8404 17.4203 19.8404 16.4603 20.4204 15.8803C21.0004 15.3003 21.9604 15.3003 22.5404 15.8803L29.6004 22.9403C30.1804 23.5203 30.1804 24.4803 29.6004 25.0603L22.5404 32.1203C22.2404 32.4203 21.8604 32.5603 21.4804 32.5603Z"
      fill={color ?? "#FDC52E"}
    />
  </Svg>
);
export default ArrowCircleRightIcon;
