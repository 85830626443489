import { StyleSheet, View, Text } from "react-native";
import { ArchiveTick, TickCircleIcon } from "../assets/icons";

function StepOrder({mode}) {
  const DUMMY_LIST = mode === "package" ? 
  [
    "Saat ini kamu baru saja membeli kuota konsultasi",
    "Pembelian kuota tidak otomatis pesan konsultasi",
    "Pembelian kuota tidak otomatis pesan konsultasi",
    "Atau klik tombol di bawah ini untuk cara cepat"
  ]
  :
  [
    "Pastikan kamu tidak lupa jadwal konsultasi",
    "Fammi akan mengirimkan notifikasi pengingat",
    "Cek agenda di beranda untuk memulai konsultasi",
  ];
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <ArchiveTick />
        <Text style={styles.title}>Bagaimana tahap selanjutnya?</Text>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.list}>
          {DUMMY_LIST?.map((s, id) => (
            <View key={id} style={styles.service}>
              <TickCircleIcon />
              <Text style={styles.text}>{s}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    marginRight: 16,
    width: "100%",
    borderRadius: 10,
  },
  header: {
    backgroundColor: "#FDC52E",
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  contentContainer: {
    padding: 12,
    paddingTop: 0
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 15,
    marginLeft: 8,
  },
  text: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 13,
  },

  list: {
    flexDirection: "column",
    rowGap: 10,
    marginTop: 12,
  },
  service: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 8,
  },
});

export default StepOrder;
