import React from "react";
import { Pressable, Text, View, StyleSheet } from "react-native";

function FeatureButton(props) {
  const { icon, topLabel, bottomLabel, onPress } = props;
  return (
    <Pressable onPress={onPress}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {icon && icon()}
        <View>
          <Text style={styles.buttonLabel}>{topLabel}</Text>
          <Text style={{ ...styles.buttonLabel, fontWeight: 700 }}>
            {bottomLabel}
          </Text>
        </View>
      </View>
    </Pressable>
  );
}
const styles = StyleSheet.create({
  buttonLabel: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 11,
    marginLeft: 6,
  },
});
export default FeatureButton;
