import { Fragment, useEffect, useState } from "react";
import {
  AppBar,
  BottomNavigation,
  EmptyState,
  ListChat,
  ModalFeedback,
  PopupFactory,
  Snackbar,
} from "../components";
import { ScrollView, StyleSheet, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAgendas } from "../services/agenda";
import moment from "moment";
import { initializeSdk } from "../services/chat";
import { ChatIcon } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import { usePopup } from "../components/PopupFactory";
import { useTranslation } from "react-i18next";

function Chat2({route}) {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [successFeedback, setSuccesssFeedback] = useState(false);
  const [openConsultation, setOpenConsultation] = useState(false);
  const [userMode, setUserMode] = useState({});
  const [consType, setConsType] = useState({});
  const [chat, setChat] = useState([]);
  const [chosenChat, setChosenChat] = useState({});
  const [newUnreadChat, setNewUnreadChat] = useState([]);
  const [con, setCon] = useState(undefined);
  const { toggle, visible } = usePopup();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();

  const messageListener = (res) => {
    if (res.msgType === "receiveMessage") {
      if (newUnreadChat.indexOf(res?.fromUserId) > -1) {
        return;
      }
      setNewUnreadChat((prevState) => [
        ...prevState, 
        res?.fromUserId
      ]);
    }
  };

  useEffect(() => {
    async function getUserMode() {
      let modes = {
        memberId: await AsyncStorage.getItem("token"),
        mode: await AsyncStorage.getItem("792f3e7")
      };

      if (modes.mode === "speaker") {
        const speakerId = await AsyncStorage.getItem("792f3e1");
        modes['speakerId'] = speakerId;
      }

      setUserMode(modes);
      return modes;
    }

    getUserMode();
  }, []);

  useEffect(() => {
    if (!userMode?.memberId) {
      return;
    }
    // const theId = userMode?.mode === "speaker" ? userMode?.speakerId : userMode?.memberId;
    const theId = userMode?.memberId;

    initializeSdk(messageListener, theId)
    .then((res) => {
      setCon(res);
    });
  }, [route.params?.foo, userMode]);

  useEffect(() => {
    if (!userMode?.mode) {
      return;
    }

    getAgendas(userMode.mode, userMode.memberId)
    .then(async (res) => {
      const resp = res?.data;
      const clientChat = [];
      let speakerId = "";
      if (userMode.mode === "speaker") {
        speakerId = await AsyncStorage.getItem("792f3e1");
      }
      resp.map((c) => {
        const consultationTime = moment(c.consultationDate+' '+c.consultationHour.split("-")[0]);
        const consultationEnd = moment(c.consultationDate+' '+c.consultationHour.split("-")[1]);
        clientChat.push({
          title: c.summary,
          name: c.memberId === userMode.memberId ? c.speakerName : c.memberName,
          time: consultationTime.format("DD MMM"),
          timeUnformatted: consultationEnd,
          isRead: true,
          isLike: true,
          mode: userMode.mode,
          // source: userMode.memberId, // userMode.mode === "speaker" ? speakerId : c.memberId,
          target: c.speakerId, // userMode.mode === "member" ? c.speakerId : c.memberId,
          photo: c.memberId === userMode.memberId ? c.speakerImage : c.memberPhoto,
          isDone: consultationEnd < moment(),
          consultationReady: consultationTime <= moment(),
          consId: c.consId,
          feedbackId: c.feedbackId,
          consultationDate: c.consultationDate,
          consultationHour: c.consultationHour,
          speakerId: c.speakerId,
          memberId: c.memberId,
          loggedInMemberId: userMode.memberId,
          speakerMemberId: c.speakerMemberId,
          anonymous: JSON.parse(c?.profile).anonymous,
        });
      });

      setChat(clientChat);
      setLoading(false);
    });
  }, [userMode]);

  return (
    <Fragment>
      <AppBar title="Chat" isBack={true} />
      {
          chat?.length == 0 &&
          !loading &&
          <View style={{
            flexDirection:"row",
            alignItems: "center",
            padding: 16,
            backgroundColor: "#EEEAFF",
            height: "100%",
            minHeight: "100%"
          }}>
            <EmptyState
              Icon={ChatIcon}
              title="Kamu Belum Punya Chat"
              desc="Chat akan muncul jika kamu sudah pernah melakukan pembelian"
            />
          </View>
        }
        {
          loading &&
          <View style={{
            flexDirection:"row",
            alignItems: "center",
            padding: 16,
            backgroundColor: "#EEEAFF",
            height: "100%",
            minHeight: "100%",
            flex: 1
          }}>
            <EmptyState
              Icon={ChatIcon}
              title={t("chat:loading")}
              desc=""
            />
          </View>
        }
      {
        !loading &&
        chat?.length > 0 &&
        <ScrollView style={styles.container} >
        {chat?.map((item, index) => (
          <ListChat
            setConsType={setConsType}
            setOpenConsultation={setOpenConsultation}
            toggle={toggle}
            con={con}
            newUnreadChat={newUnreadChat}
            setNewUnreadChat={setNewUnreadChat}
            data={item}
            mode={item.mode}
            key={index}
            isLast={index + 1 === chat?.length}
            setOpenFeedback={setOpenFeedback}
            setChosenChat={setChosenChat}
          />
        ))}
        </ScrollView>
      }
      <Snackbar
        visible={successFeedback}
        hideDialog={() => {
          setSuccesssFeedback(false);
        }}
        title="Penilaian berhasil dikirim"
      />
      <ModalFeedback
        visible={openFeedback}
        data={chosenChat}
        hideDialog={() => {
          setOpenFeedback(false);
        }}
        setSuccess={setSuccesssFeedback}
      />
      {
        <PopupFactory
          type={"not-started"}
          titleMsg={consType.titleMsg}
          msg={consType.msg}
          visible={visible}
          onCancel={() => {
            toggle();
            navigation.navigate("Consultation", {
              consId: consType.consId,
              jump: "Chat"
            });
          }}
          onSubmit={() => {
            toggle();
          }}
        />
      }
      <BottomNavigation activeIndex={1} />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
    paddingBottom: 10
  },
});
export default Chat2;
