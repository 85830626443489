import Svg, { Rect, Path } from "react-native-svg";
const ForumIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
    <Rect width={30} height={30} fill="#6724E2" rx={10} />
    <Path
      fill="#fff"
      d="M18.75 7.5h-7.5a3.743 3.743 0 0 0-3.75 3.735v5.235a3.743 3.743 0 0 0 3.75 3.735h1.125c.203 0 .473.135.6.3l1.125 1.492c.495.66 1.305.66 1.8 0l1.125-1.492a.76.76 0 0 1 .6-.3h1.125c2.07 0 3.75-1.672 3.75-3.735v-5.235A3.743 3.743 0 0 0 18.75 7.5ZM12 15a.747.747 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75-.33.75-.75.75Zm3 0a.747.747 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75-.33.75-.75.75Zm3 0a.747.747 0 0 1-.75-.75c0-.412.337-.75.75-.75s.75.338.75.75-.33.75-.75.75Z"
    />
  </Svg>
);
export default ForumIcon;
