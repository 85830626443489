import Svg, { Path } from "react-native-svg";
const DocumentIcon = () => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M14.667 1.833H7.333c-3.208 0-4.583 1.833-4.583 4.583v9.167c0 2.75 1.375 4.583 4.583 4.583h7.334c3.208 0 4.583-1.833 4.583-4.583V6.416c0-2.75-1.375-4.583-4.583-4.583Zm-7.334 9.396H11c.376 0 .688.312.688.687a.692.692 0 0 1-.688.688H7.333a.692.692 0 0 1-.687-.688c0-.376.311-.687.687-.687Zm7.334 5.041H7.333a.693.693 0 0 1-.687-.687c0-.376.311-.688.687-.688h7.334c.376 0 .687.312.687.688a.693.693 0 0 1-.687.687Zm2.291-7.791h-1.833a2.52 2.52 0 0 1-2.52-2.521V4.125c0-.376.31-.688.687-.688.376 0 .687.312.687.688v1.833c0 .633.514 1.146 1.146 1.146h1.833c.376 0 .688.312.688.687a.692.692 0 0 1-.688.688Z"
    />
  </Svg>
);
export default DocumentIcon;
