import Svg, { Circle, Path } from "react-native-svg";
const MedalIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <Circle cx={32} cy={32} r={30.5} stroke="#FDC52E" strokeWidth={3} />
    <Path
      fill="#FDC52E"
      d="M43.085 27.25c0 2.295-.681 4.401-1.853 6.158-1.71 2.534-4.417 4.323-7.568 4.782a9.55 9.55 0 0 1-1.663.143 9.55 9.55 0 0 1-1.662-.143c-3.151-.459-5.858-2.248-7.569-4.782a11.032 11.032 0 0 1-1.852-6.159 11.075 11.075 0 0 1 11.083-11.083 11.075 11.075 0 0 1 11.084 11.083Z"
      opacity={0.4}
    />
    <Path
      fill="#FDC52E"
      d="m46.645 42.245-2.613.617c-.586.143-1.045.586-1.172 1.172l-.554 2.328c-.3 1.266-1.916 1.646-2.755.649L32 38.334l-7.553 8.693c-.839.997-2.454.617-2.755-.65l-.554-2.327a1.577 1.577 0 0 0-1.172-1.172l-2.612-.617c-1.203-.285-1.63-1.79-.76-2.66l6.175-6.175c1.71 2.533 4.417 4.322 7.568 4.782A9.55 9.55 0 0 0 32 38.35a9.55 9.55 0 0 0 1.662-.142c3.151-.46 5.859-2.249 7.569-4.782l6.175 6.175c.87.855.443 2.359-.76 2.644ZM32.92 22.469l.934 1.868c.127.254.46.507.76.555l1.694.285c1.077.174 1.33.965.555 1.741l-1.315 1.314c-.221.222-.348.65-.269.966l.38 1.631c.301 1.282-.38 1.79-1.52 1.108l-1.583-.934c-.285-.174-.76-.174-1.045 0l-1.583.934c-1.14.665-1.821.174-1.52-1.108l.38-1.63c.063-.302-.048-.745-.27-.967l-1.314-1.314c-.776-.776-.522-1.552.554-1.741l1.695-.285c.285-.048.617-.301.744-.555l.934-1.868c.46-1.013 1.282-1.013 1.79 0Z"
    />
  </Svg>
);
export default MedalIcon;
