import React from "react";
import Svg, { Path } from "react-native-svg";
const Pen = () => (
  <Svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#fff"
      d="M10.5 11h-9a.378.378 0 0 1-.375-.375c0-.205.17-.375.375-.375h9c.205 0 .375.17.375.375s-.17.375-.375.375Zm-.99-9.26c-.97-.97-1.92-.994-2.915 0l-.605.606c-.05.05-.07.13-.05.2A4.065 4.065 0 0 0 8.705 5.31a.2.2 0 0 0 .205-.05l.6-.605c.495-.49.735-.965.735-1.445.005-.495-.235-.975-.735-1.47ZM7.805 5.766a4.398 4.398 0 0 1-.74-.43 3.019 3.019 0 0 1-.28-.215.61.61 0 0 1-.085-.075c-.165-.14-.35-.32-.515-.52-.015-.01-.04-.045-.075-.09-.05-.06-.135-.16-.21-.275a2.745 2.745 0 0 1-.195-.295 6.46 6.46 0 0 1-.25-.478c-.075-.166-.292-.215-.42-.086L2.17 6.165a.602.602 0 0 0-.14.275l-.27 1.915c-.05.34.045.66.255.875.18.175.43.27.7.27.06 0 .12-.005.18-.015l1.92-.27a.557.557 0 0 0 .275-.14l2.861-2.86c.13-.13.08-.353-.088-.425l-.058-.025Z"
    />
  </Svg>
);
export default Pen;
