import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { AppBar, EmptyState, ListOrder } from "../components";
import { getTransactions } from "../services/transactions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { EmptyShoppingCartIcon, ShoppingCartIcon } from "../assets/icons";

function Order() {
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();
  const navigation = useNavigation();
  useEffect(() => {
    async function getToken(){
      return await AsyncStorage.getItem("token");
    };
    getToken()
    .then((res) => {
      getTransactions(res)
      .then((res) => {
        const data = res?.data;
        const theOrders = [];
        data?.map((d) => {
          if (!d.note || d.note === "") {
            return;
          }
          const note = JSON.parse(d?.note);
          theOrders.push({
            id: d?.transactionId,
            type: note?.additionalContentId ? "Paket" : "Konsultasi",
            title: note?.speakerName ? note?.speakerName : (note?.additionalName ? note?.additionalName : "Paket"),
            price: `Rp ${parseFloat(d?.total)}`,
            status: d?.status,
            timeLimit: d?.created
          })
        });
        setOrders(theOrders);
      })
    });
  }, []);

  return (
    <Fragment>
      <AppBar title={t("detail-order:order")} isBack />
      {
          orders?.length === 0 &&
          <ScrollView contentContainerStyle={styles.container}>
          <View style={{
            flexDirection:"row",
            alignItems: "center",
            padding: 16,
            backgroundColor: "#EEEAFF",
            height: "100%",
            minHeight: "100%",
          }}>
            <EmptyState
              Icon={EmptyShoppingCartIcon}
              title={t("order:empty-title")}
              desc={t("order:empty-desc")}
            />
          </View>
          </ScrollView>
        }
        {
          orders?.length > 0 &&
      <ScrollView style={styles.container}>
        {orders?.map((item, index) => (
          <TouchableOpacity onPress={() => {
            navigation.navigate("DetailOrder", {
              transactionId: item?.id
            });
          }}>
            <ListOrder key={item?.id} data={item} t={t}/>
          </TouchableOpacity>
        ))}
      </ScrollView>
        }
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    flex: 1,
    padding: 16,
  },
});
export default Order;
