import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
const SearchIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <G clipPath="url(#a)">
      <Path
        stroke="#999"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.5 16.5 15 15m-6.375.75a7.124 7.124 0 1 0 0-14.248 7.124 7.124 0 0 0 0 14.248Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SearchIcon;
