import axios from "axios";
import {API_URL} from "@env";

export const getSchedules = (speakerId) => {
    return axios.get(API_URL + `/speaker-schedule/${speakerId}`);
}

export const checkSchedule = (speakerId, bookingTime) => {
    return axios.get(API_URL + `/check-booking/${speakerId}/${bookingTime}`);
}

export const bookSchedule = (payload) => {
    return axios.post(API_URL + `/schedule/initialize-book`, payload);
}