import React from "react";
import { APP_SPECIAL_PACKAGE } from "@env";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  PersonalConsultationIcon,
  PsikologResultTestIcon,
  QnaGroupIcon,
  ReadModulMaterialIcon,
} from "../assets/icons";
import SectionContent from "./SectionContent";
import { useNavigation } from "@react-navigation/native";

function MenuCard() {
  const navigation = useNavigation();

  return (
    <SectionContent
      title="Atas Masalah dengan Solusi berikut"
      hideAllButton={true}
      style={{ gap: 4 }}
    >
      {/* <Text style={styles.titleTx}></Text> */}
      <View style={{ gap: 14 }}>
        <View style={styles.menus}>
          <MenuItem
            label="Konsultasi Pribadi"
            Icon={PersonalConsultationIcon}
            onClick={() => {
              navigation.navigate("ChooseExpert", {type: "expert", status: "general"});
            }}
          />
          <MenuItem 
            label="Tanya Jawab di Grup" 
            Icon={QnaGroupIcon} 
            onClick={() => {
              navigation.navigate("Kota");
            }}
          />
        </View>
        <View style={styles.menus}>
          <MenuItem 
            label="Baca Modul Materi" 
            Icon={ReadModulMaterialIcon} 
            onClick={() => {
              navigation.navigate("EbookList", {additionalContentId: APP_SPECIAL_PACKAGE});
            }}
          />
          <MenuItem
            label="Lihat Hasil Tes Psikologi"
            Icon={PsikologResultTestIcon}
            onClick={() => {
              navigation.navigate("PsikologiTestPage", {resultOnly: "true"});
            }}
          />
        </View>
      </View>
    </SectionContent>
  );
}

function MenuItem({ Icon, label, onClick }) {
  return (
    <TouchableOpacity style={styles.menu} onPress={onClick}>
      <Icon />
      <Text style={styles.menuTx}>{label}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  titleTx: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
  },
  menus: {
    flexDirection: "row",
    alignItems: "center",
    gap: 14,
  },
  menu: {
    backgroundColor: "#fff",
    borderRadius: 20,
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    flexGrow: 1,
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  menuTx: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    maxWidth: 75,
  },
});

export default MenuCard;
