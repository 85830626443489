import React, { useEffect, useState } from "react";
import moment from "moment";
import { Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  Calendar2Icon,
  Eye2Icon,
  MessageEditIcon,
  MessageQuestionIcon
} from "../assets/icons";
import { getSurveysProgramById } from "../services/survey";
import { useNavigation } from "@react-navigation/native";
import ModalScreeningOption from "./ModalScreeningOptions";
import { checkRespondentEligible } from "../services/screening";

const AgendaProgramScreeningCard = (props) => {
  const isAnswered = false;
  const {hideDate, data, additionalContentId, memberId} = props;
  const [detail, setDetail] = useState({});
  const [isCoverError, setIsCoverError] = useState(false);
  const [quizId, setQuizId] = useState(undefined);
  const [screeningOptionModal, setScreeningOptionModal] = useState(false);
  const [role, setRole] = useState("");
  const navigation = useNavigation();
  moment().locale("id");

  useEffect(() => {
    // get role
    checkRespondentEligible(memberId, additionalContentId)
    .then((res) => {
      if (res.data[0]?.roleName) {
        setRole(res.data[0].roleName);
      }

      if (data?.agendaType === "screening") {
        setQuizId(data?.screeningId);
        getSurveysProgramById(data?.screeningId, memberId)
        .then((res) => {
          setDetail(res?.data[0]);
        });
      }
  
      if (data?.agendaType === "survey") {
        setQuizId(data?.quizId);
        getSurveysProgramById(data?.quizId, memberId)
        .then((res) => {
          setDetail(res?.data[0]);
        });
      }  
    });

  }, [data]);

  useEffect(() => {
    fetch(`https://fammi.ly/uploads/quiz/images/${detail?.cover}`)
    .then((res) => {
      if (res.status === 404) {
        return setIsCoverError(true);
      }

      return setIsCoverError(false);
    })
  }, [detail]);

  return (
    <Pressable 
    onPress={() => {
      console.log(role);
      if (role === "Petugas" && data?.agendaType === "screening") {
        setScreeningOptionModal(true);
        return;
      }

      if (detail?.isAnswered === 1) {
        return navigation.navigate(data?.agendaType === "survey" ? "SurveyResult" : "ScreeningResult", {
          additionalContentId: additionalContentId,
          quizId: quizId,
          isScreening: data?.agendaType === "survey" ? "0" : "1",
          fromAgenda: 1
        });
      }

      navigation.navigate("SurveyDetail", {
        additionalContentId: additionalContentId,
        quizId: quizId,
        isScreening: data?.agendaType === "survey" ? "0" : "1",
        fromAgenda: 1
      });
    }}>
      {
        !hideDate &&
        <View style={styles.flexRow}>
          <View style={styles.dot} />
          <Text style={styles.dateTx}>{moment(data?.scheduledDate).format("dddd, DD MMM YYYY")}</Text>
        </View>
      }
      <View style={styles.container}>
        {
          detail?.cover &&
          !isCoverError &&
          <Image
            onError={() => {
              setIsCoverError(true);
            }}
            alt="cover"
            style={{...styles.image, height: 61 }}
            source={{
              uri: `https://fammi.ly/uploads/quiz/images/${detail?.cover}`
            }}
          />
        }
        <View style={styles.card}>
          <View style={styles.header}>
            <Text style={styles.semiboldTx}>{detail?.title}</Text>
            <View style={[styles.flexRow, { gap: 12 }]}>
              <View style={[styles.flexRow, { gap: 6 }]}>
                <View style={styles.icon}>
                  <MessageQuestionIcon width={14} height={14} fill="#000" />
                </View>
                <Text style={[styles.mediumTx, { color: "#999" }]}>
                  {detail?.totalPertanyaan} Pertanyaan
                </Text>
              </View>

              <View style={[styles.flexRow, { gap: 6 }]}>
                <View style={styles.icon}>
                  <Calendar2Icon width={14} height={14} fill="#000" />
                </View>
                <Text style={[styles.mediumTx, { color: "#999" }]}>
                  {moment(data?.scheduledDate).format("dddd, DD MMM YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.footer}>
            <TouchableOpacity
              style={[
                styles.button,
                detail?.isAnswered === 1
                  ? {
                      borderWidth: 1,
                      borderColor: "#eaeaea"
                    }
                  : { backgroundColor: "#EEEAFF" }
              ]}
              onPress={() => {
                if (role === "Petugas" && data?.agendaType === "screening") {
                  setScreeningOptionModal(true);
                  return;
                }
          
                if (detail?.isAnswered === 1) {
                  return navigation.navigate(data?.agendaType === "survey" ? "SurveyResult" : "ScreeningResult", {
                    additionalContentId: additionalContentId,
                    quizId: quizId,
                    isScreening: data?.agendaType === "survey" ? "0" : "1",
                    fromAgenda: 1
                  });
                }
          
                navigation.navigate("SurveyDetail", {
                  additionalContentId: additionalContentId,
                  quizId: quizId,
                  isScreening: data?.agendaType === "survey" ? "0" : "1",
                  fromAgenda: 1
                });
              }}
            >
              {detail?.isAnswered === 1 ? <Eye2Icon /> : <MessageEditIcon />}
              <Text
                style={[
                  styles.mediumTx,
                  { color: detail?.isAnswered === 1 ? "#000" : "#6724E2" }
                ]}
              >
                {detail?.isAnswered === 1 ? `Lihat Hasil ${data?.agendaType == "survey" ? "Survey" : "Screening"}` : `Isi ${data?.agendaType == "survey" ? "Survey" : "Screening"}`}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <ModalScreeningOption
        visible={screeningOptionModal}
        hideDialog={() => setScreeningOptionModal(false)}
        onFillScreening={() => {
          navigation.navigate("Screening", { quizId, additionalContentId });
          setScreeningOptionModal(false);
        }}
        onGoToReport={() => {
          navigation.navigate("ScreeningReport", {
            quizId,
            additionalContentId,
          });
          setScreeningOptionModal(false);
        }}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10
  },
  dot: {
    width: 6,
    height: 15,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: "#6724E2"
  },
  dateTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14
  },
  container: {
    margin: 12,
    marginBottom: 0
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10
  },
  header: {
    gap: 10,
    padding: 12,
    paddingTop: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#EAEAEA"
  },
  image: {
    borderRadius: 10,
    width: "100%",
    height: 60
  },
  icon: {
    padding: 5,
    borderRadius: 100,
    width: 24,
    height: 24,
    backgroundColor: "#F3F3F3"
  },
  mediumTx: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12
  },
  semiboldTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13
  },
  footer: {
    padding: 12,
    flexDirection: "row",
    gap: 12
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    flexGrow: 1,
    padding: 6,
    gap: 4
  }
});

export default AgendaProgramScreeningCard;
