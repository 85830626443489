import React, { useEffect, useState } from "react";
import {
  AppBar,
  BoxDiscussion,
  ListComment,
  ModalComment,
  Snackbar,
} from "../components";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { ArchiveTickIcon } from "../assets/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import md5 from "md5";
import {
  createDiscussion,
  getComments,
  getListDiscussions,
  sendNotif,
  setFeedback,
  setMark,
  subscribeTopic,
} from "../services/programs";

function DetailDiscussions(props) {
  const { route } = props;
  const { data, id, additionalContentId, alreadyLike } = route?.params;
  const [openComment, setOpenComment] = useState(false);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [msg, setMsg] = useState("");
  const [isArchive, setIsArchive] = useState(data?.totalRate > 0);
  const [question, setQuestion] = useState("");
  const [isOnSend, setIsOnSend] = useState(false);
  const [listComment, setListComment] = useState([]);
  const [member, setMember] = useState(undefined);
  const [selectedComment, setSelectedComment] = useState(null);

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMember(memberId);
      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    if (!member) {
      return;
    }

    if (openComment) {
      return;
    }

    // getListDiscussions(id).then((res) => {
    //   setListComment(res?.data);
    // });
    getComments(additionalContentId, id, member).then((res) => {
      setListComment(res?.data);
    });
  }, [member, id, openComment]);
  const onSubscribeTopic = () => {
    subscribeTopic(member, additionalContentId);
    sendNotif(additionalContentId, id + "comment");
  };

  const onSendComment = async () => {
    setIsOnSend(true);
    if (question.length === 0) {
      setMsg("Harap isi komentar kamu");
      setIsSnackbar(true);
      setIsOnSend(false);
      return;
    }
    const payload = {
      title: "",
      categories: data?.categories,
      tags: data?.tags,
      question: question,
      memberId: member,
      questionId: md5(new Date().getTime()),
      additionalContentId: additionalContentId,
      parentId: selectedComment?.questionId ?? id,
    };
    await createDiscussion(payload)
      .then((res) => {
        if (res?.data || res?.data === "true" || res?.data === true) {
          setMsg("Komentar kamu berhasil ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
          onSubscribeTopic();
        } else {
          setMsg("Komentar kamu gagal ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
        }
      })
      .catch((err) => {
        setMsg("Komentar kamu gagal ditambahkan");
        setOpenComment(false);
        setIsSnackbar(true);
        setIsOnSend(false);
        setQuestion("");
      });
  };

  const onOpenComment = (data) => {
    setSelectedComment(data);
    setOpenComment(true);
  };
  return (
    <React.Fragment>
      <AppBar
        title="Diskusi"
        isBack
        rightSection={
          <Pressable
            onPress={() => {
              setMark(id, member);
              if (!isArchive) {
                setIsArchive(true);
                setMsg("Diskusi berhasil ditandai");
                setIsSnackbar(true);
              } else {
                setIsArchive(false);
                setMsg("Diskusi berhasil dihapus");
                setIsSnackbar(true);
              }
            }}
            style={{
              ...styles.archiveButton,
              backgroundColor: !isArchive ? "#fff" : "#DCF4CA",
            }}
          >
            <ArchiveTickIcon customColor={isArchive && "#4EB826"} />
          </Pressable>
        }
      />
      <ScrollView style={styles.container}>
        <BoxDiscussion
          data={data}
          setOpenComment={setOpenComment}
          alreadyLike={alreadyLike}
          onGiveSupport={() => {
            setFeedback(id, member);
            setMsg("Kamu berhasil memberikan dukungan");
            setIsSnackbar(true);
          }}
        />
        <View style={{ padding: 16 }}>
          {listComment.map((item, index) => (
            <ListComment
              key={item?.questionId}
              data={item}
              onOpenComment={onOpenComment}
              openComment={openComment}
			  memberId={member}
			  setIsSnackbar={setIsSnackbar}
			  setMsg={setMsg}
            />
          ))}
        </View>
      </ScrollView>
      <ModalComment
        setValue={setQuestion}
        isOnSend={isOnSend}
        value={question}
        visible={openComment}
        onSendComment={onSendComment}
        onClose={() => {
          setOpenComment(false);
		  setQuestion("");
		  setSelectedComment(null)
        }}
      />
      <Snackbar
        visible={isSnackbar}
        hideDialog={() => {
          setIsSnackbar(false);
        }}
        title={msg}
      />
    </React.Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    flex: 1,
  },
  archiveButton: {
    width: 36,
    height: 36,
    borderWidth: 1,
    borderColor: "#eaeaea",
    borderStyle: "solid",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 36 / 2,
  },
});

export default DetailDiscussions;
