export const officerResult = [
  {
    label: "Siap Masuk SD",
    value: 80
  },
  {
    label: "Ragu-ragu",
    value: 32
  },
  {
    label: "Belum Siap",
    value: 48
  }
];
export const userResult = [
  {
    label: "Siap Masuk SD",
    value: 8
  },
  {
    label: "Ragu-ragu",
    value: 20
  },
  {
    label: "Belum Siap",
    value: 12
  }
];

export const userDifferent = [
  {
    label: "Anggih Nur Hidayat",
    value: 24
  },
  {
    label: "Intan S",
    value: 20
  },
  {
    label: "Nadya Kurniawati",
    value: 18
  },
  {
    label: "Indra F",
    value: 14
  },
  {
    label: "Muhammad Budi Asep Gunawarman Darmaji Kutanegoro",
    value: 18
  }
];

export const aspectDifferent = [
  {
    label:
      "Apakah ayah bunda sudah mempraktekkan di rumah dalam Memberikan Apresiasi?",
    value: 42
  },
  {
    label:
      "Nullam vulputate consequat arcu vel consectetur. Vivamus augue quam, sagittis eu nulla et?",
    value: 18
  },
  {
    label:
      "Nullam vulputate consequat arcu vel consectetur. Vivamus augue quam, sagittis eu nulla et?",
    value: 8
  }
];

export const userRespondent = [
  "Siti Aisyah",
  "Budi Hartono",
  "Putri Ratnasari",
  "Didi Suprapto",
  "Wahyu Setiawan",
  "Sri Wulandari",
  "Joko Susanto",
  "Ratna Dewi",
  "Arief Suryanto",
  "Dewi Lestari",
  "Agus Santoso",
  "Rini Utami",
  "Hendro Prasetyo",
  "Dewi Susanti",
  "Hadi Kusumo",
  "Ratna Sari",
  "Joko Santosa",
  "Sri Wahyuni",
  "Agus Setiawan",
  "Rini Indriani",
  "Sugeng Riyanto",
  "Sri Rahayu",
  "Supriyanto",
  "Ani Cahyani",
  "Dwi Santoso",
  "Nurul Hidayah",
  "Sugeng Santoso",
  "Siti Rahayu",
  "Bambang Suryanto",
  "Sri Utami",
  "Sugeng Hartono",
  "Rini Susanti",
  "Joko Purnomo",
  "Siti Fatimah",
  "Agus Widodo",
  "Sri Winarti",
  "Budi Santoso",
  "Dewi Astuti",
  "Eko Prasetyo",
  "Sri Muliyani",
  "Hadi Wibowo",
  "Ratna Sari",
  "Sugeng Santosa",
  "Sri Wahyuni",
  "Joko Santoso",
  "Siti Wulandari",
  "Bambang Setiawan",
  "Rini Susanti",
  "Didi Cahyani",
  "Ratna Sari"
];

export const getUserDiffAspect = () => {
  const dataArray = [];
  for (const nama of userRespondent) {
    const data = {
      name: nama,
      total: Math.floor(Math.random() * (30 - 5 + 1)) + 5
    };
    dataArray.push(data);
  }

  return dataArray.sort((a, b) => b.total - a.total);
};

export const getDiffAscpect = () => {
  const dataArray = [];

  for (let i = 0; i < 50; i++) {
    const data = {
      label:
        "Apakah ayah bunda sudah mempraktekkan di rumah dalam Memberikan Apresiasi?",
      value: Math.floor(Math.random() * 50) + 1
    };
    dataArray.push(data);
  }

  return dataArray.sort((a, b) => b.value - a.value);
};
