import Svg, { Path } from "react-native-svg";
const HomeIcon = ({ isActive }) => (
  <Svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill={isActive ? "#6724E2" : "#000"}
      d="M18.87 6.753 13.59 3.06c-1.44-1.008-3.648-.953-5.032.12L3.965 6.761c-.917.715-1.64 2.182-1.64 3.337v6.325a4.245 4.245 0 0 0 4.234 4.244h9.882a4.237 4.237 0 0 0 4.235-4.235v-6.215c0-1.237-.798-2.759-1.806-3.465Zm-6.682 10.248a.693.693 0 0 1-.688.688.692.692 0 0 1-.687-.688v-2.75c0-.375.311-.687.687-.687.376 0 .688.312.688.688v2.75Z"
    />
  </Svg>
);
export default HomeIcon;
