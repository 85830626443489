import { Text, View } from "react-native-web";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { ChevronRightIcon } from "../../assets/icons";

const FAQS = [
  "Mengapa saya harus menggunakan Fammi?",
  "Apa itu Fammi?",
  "Apa itu program Anti-Bullying?",
  "Berapa biaya yang perlu dikeluarkan?",
  "Program apa saja yang tersedia di Fammi?",
];

export function ProgramAntiBullyingFAQ({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>Frequently Asked Questions</Text>
      <View style={styles("content")}>
        {FAQS.map((item, idx) => (
          <FAQCard key={idx} label={item} styles={styles} />
        ))}
      </View>
    </View>
  );
}

function FAQCard({ label, styles }) {
  return (
    <View style={styles("card")}>
      <Text style={styles("txLabelCard")}>{label}</Text>
      <View style={styles("rightIcon")}>
        <ChevronRightIcon color="#6323DA" width={24} height={24} />
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    content: {
      flexDirection: "column",
      gap: 24,
    },
    card: {
      width: "100%",
      borderRadius: 40,
      paddingVertical: 12,
      paddingHorizontal: 24,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#fff",
    },
    txLabelCard: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 26,
      color: "#170F49",
      textAlign: "left",
    },
    rightIcon: {
      width: 46,
      height: 46,
      borderRadius: 50,
      padding: 11,
      backgroundColor: "#6724E21A",
    },
  },
  {
    container: { gap: 32 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    content: {},
  }
);
