import React from "react";
import { Image, StyleSheet, Text, Pressable, View } from "react-native";
import { HeartIcon, PeoplesIcon, VerifyIcon } from "../assets/icons";

function InformationExpert(props) {
  const { data, t, onOpenSipp } = props;

  return (
    <View style={styles.parentList}>
      {
        (data?.sipp || data?.str ) &&
        <View style={styles.topSection}>
          <Pressable onPress={onOpenSipp} style={styles.labelSIPP}>
            <VerifyIcon isSmall fill="#fff" />
            <Text style={styles.textLabel}>SIPP</Text>
          </Pressable>
        </View>
      }
      <Image
        source={{
          uri: `https://fammi.ly/uploads/speakers/images/${data?.photo}`,
        }}
        style={styles.avatar}
      />
      <Text style={styles.textName}>{data?.name}</Text>
      <Text style={styles.textSpan}>{data?.description}</Text>
      <View style={{ ...styles.flexOne, marginTop: 16 }}>
        <View
          style={{
            ...styles.flexOne,
            marginRight: 10,
            backgroundColor: "#FFFFFF",
            borderWidth: 1,
            borderColor: "#EAEAEA",
            borderStyle: "solid",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            paddingTop: 8,
            paddingRight: 12,
            paddingBottom: 8,
            paddingLeft: 12,
          }}
        >
          <HeartIcon />
          <Text style={{ ...styles.textMain, marginLeft: 12 }}>
            {Math.ceil((data?.rate * 100) / 5)}% {t?.("common:satisfied")}
          </Text>
        </View>
        <View
          style={{
            ...styles.flexOne,
            backgroundColor: "#FFFFFF",
            borderWidth: 1,
            borderColor: "#EAEAEA",
            borderStyle: "solid",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            paddingTop: 8,
            paddingRight: 12,
            paddingBottom: 8,
            paddingLeft: 12,
          }}
        >
          <PeoplesIcon />
          <Text style={{ ...styles.textMain, marginLeft: 12 }}>
            {data?.helped} {t?.("common:helped")}
          </Text>
        </View>
      </View>
      <Text style={{ ...styles.textMain, marginTop: 16, textAlign: "center" }}>
        {data?.speciality}
      </Text>
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 16,
    flexDirection: "column",
    alignItems: "center",
    padding: 12,
  },
  topSection: {
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  avatar: {
    borderRadius: 60 / 2,
    marginBottom: 16,
    width: 60,
    height: 60,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },
  textName: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    color: "#000000",
    marginBottom: 4,
  },
  textSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
    textAlign: "center",
  },
  labelStatus: {
    backgroundColor: "#4EB826",
    borderRadius: 24 / 2,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 6,
    paddingRight: 6,
    paddingBottom: 6,
    paddingLeft: 6,
  },
  indicator: {
    backgroundColor: "#ECF8E6",
    height: 6,
    width: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    marginRight: 8,
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
	marginLeft: 4,
    color: "#ECF8E6",
  },

  textMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
  },
  labelSIPP: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
    paddingVertical: 3,
    backgroundColor: "#4BB6FF",
    borderRadius: 9,
  },
});

export default InformationExpert;
