import axios from "axios";
import {API_URL} from "@env";

export const sendFeedback = (payload) => {
    return axios.post(API_URL + '/service-feedback', payload)
}

export const getFeedback = (speakerId) => {
    return axios.get(API_URL + `/service-feedback/${speakerId}`)
}

export const getFeedbackSummary = (speakerId) => {
    return axios.get(API_URL + `/service-feedback-summary/${speakerId}`)
}

export const checkFeedback = (speakerId, memberId) => {
    return axios.get(API_URL + `/check-consultation/${speakerId}/${memberId}`)
}