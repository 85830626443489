import React from "react";
import Svg, { Path } from "react-native-svg";
const SubscribeIcon = () => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="m19.48 16.93-1.513.358a.9.9 0 0 0-.679.678l-.32 1.348c-.175.733-1.11.962-1.596.385l-2.74-3.154a.456.456 0 0 1 .229-.742 7.784 7.784 0 0 0 4.152-2.594.457.457 0 0 1 .679-.046l2.035 2.035c.696.697.449 1.567-.248 1.732Zm-17.005 0 1.512.358a.9.9 0 0 1 .679.678l.32 1.348c.175.733 1.11.962 1.596.385l2.74-3.154c.22-.256.101-.66-.229-.742a7.784 7.784 0 0 1-4.152-2.594.458.458 0 0 0-.679-.046l-2.035 2.035c-.696.697-.449 1.567.248 1.732ZM11 1.833A6.412 6.412 0 0 0 4.583 8.25c0 1.329.394 2.548 1.073 3.566a6.402 6.402 0 0 0 4.381 2.768c.312.055.633.082.963.082.33 0 .65-.027.962-.082a6.403 6.403 0 0 0 4.382-2.768 6.387 6.387 0 0 0 1.073-3.566A6.412 6.412 0 0 0 11 1.833Zm2.805 6.215-.76.76a.65.65 0 0 0-.157.56l.22.944c.174.743-.22 1.036-.88.642l-.916-.541a.642.642 0 0 0-.605 0l-.917.54c-.66.386-1.054.102-.88-.64l.22-.945c.037-.174-.028-.43-.156-.56l-.78-.76c-.448-.45-.302-.898.322-1l.98-.164a.668.668 0 0 0 .431-.321l.541-1.082c.294-.586.77-.586 1.064 0l.54 1.082a.664.664 0 0 0 .44.32l.981.166c.614.1.761.55.312.999Z"
    />
  </Svg>
);
export default SubscribeIcon;
