import { Image, Text, TouchableOpacity, View } from "react-native";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import options from "./options.json";

export function CompanyProfileCollaboration({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>
        Tertarik Bekerjasama Dengan Fammi? Pilih yang Sesuai!
      </Text>
      <View style={styles("content")}>
        {options.map((item) => (
          <CardOption key={item.id} data={item} styles={styles} />
        ))}
      </View>
    </View>
  );
}

function CardOption({ data, styles }) {
  return (
    <View style={styles("card")}>
      <Image
        source={require(`../../../assets/images/${data.image}`)}
        style={styles("cardImage")}
        width="100%"
      />
      <View>
        <View style={styles("cardHeader")}>
          <Text style={styles("txCardTitle")}>{data.title}</Text>
          <Text style={styles("txCardDesc")}>{data.desc}</Text>
        </View>
        <View>
          {data.features.map((item, idx) => (
            <View key={idx} style={styles("cardFeature")}>
              <Text style={styles("txCardFeature")}>•</Text>
              <Text style={styles("txCardFeature")}>{item}</Text>
            </View>
          ))}
        </View>
      </View>
      <TouchableOpacity style={styles("button")}>
        <Text style={styles("txButton")}>Hubungi Fammi</Text>
      </TouchableOpacity>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    content: {
      flexDirection: "row",
      gap: 20,
    },
    card: {
      width: 285,
      height: 465,
      padding: 16,
      gap: 24,
      alignItems: "flex-start",
      backgroundColor: "#fff",
      borderRadius: 20,
    },
    cardImage: {
      width: "100%",
      height: 175,
      borderRadius: 10,
    },
    cardHeader: { marginBottom: 16 },
    txCardTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 26,
      color: "#260C55",
    },
    txCardDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 20,
      color: "#0A0316",
    },
    cardFeature: {
      flexDirection: "row",
      gap: 4,
    },
    txCardFeature: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 20,
      color: "#959895",
    },
    button: {
      backgroundColor: "#FDC52E",
      paddingVertical: 12,
      paddingHorizontal: 22,
      borderRadius: 24,
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 24,
      color: "#000",
    },
    companyIcon: {
      width: 188,
      height: 42,
      position: "absolute",
      right: 10,
      bottom: 6,
    },
  },
  {
    container: { gap: 32 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    content: {
      flexDirection: "column",
      gap: 24,
    },
    card: {
      width: "100%",
      height: 581,
      padding: 16,
      gap: 32,
      borderRadius: 24,
    },
    cardImage: {
      width: "100%",
      height: 214,
      borderRadius: 12,
    },
    cardHeader: { marginBottom: 12 },
    txCardTitle: {
      fontSize: 24,
      lineHeight: 32,
    },
    txCardDesc: {
      fontSize: 18,
      lineHeight: 29,
    },
    cardFeature: {
      flexDirection: "row",
      gap: 4,
    },
    txCardFeature: {
      fontSize: 18,
      lineHeight: 29,
    },
    txButton: {
      fontSize: 18,
      lineHeight: 29,
    },
    companyIcon: {
      right: 16,
      bottom: 12,
    },
  }
);
