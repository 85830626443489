import React, { Fragment, useEffect, useState } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import Modal from "react-native-modal";
import {
  ChevronDown,
  ChevronRight,
  CloseCircleIcon,
  SearchBoldIcon,
  SearchIcon,
} from "../assets/icons";
import { TextInput } from "react-native";
import EmptyState from "./EmptyState";
import { getOptions } from "../services/survey";

function ModalMenuDropdown(props) {
  const { onChange, value, options, isSearch, placeholder } = props;
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [additionalOptions, setAdditionalOptions] = useState([]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (!Array.isArray(options) && options.indexOf(".json") > -1) {
      setLoadingOptions(true);
      setTimeout(() => {
        getOptions(options.replace(".json", ""))
        .then((res) => {
          setTimeout(() => {
            setAdditionalOptions(res?.data.options);
            setLoadingOptions(false);
          }, 1000);
        });
      }, 500);
    }
  }, [options, visible]);

  useEffect(() => {
    if (search?.length === 0) {
      return setFilteredOptions(additionalOptions);
    }
    const filtered = additionalOptions.filter((p) =>
      search?.length > 0 ? p.toLowerCase().includes(search.toLowerCase()) : true
    );
    setFilteredOptions(filtered);
  }, [search, additionalOptions]);

  return (
    <Fragment>
      <Pressable
        style={{
          borderRadius: 10,
          borderWidth: 1,
          borderColor: "#eaeaea",
          borderStyle: "solid",
          backgroundColor: "#FFF",
          flexDirection: "row",
          alignItems: "center",
          padding: 12,
          justifyContent: "space-between",
        }}
        onPress={() => {
          setVisible(true);
        }}
      >
        <Text
          style={{
            color: value ? "#000" : "#999",
            fontFamily: "Archivo-Regular",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {value ?? placeholder ?? "Pilih"}
        </Text>
        <ChevronDown />
      </Pressable>
      <Modal
        isVisible={visible}
        onBackdropPress={() => {
          setFilteredOptions([]);
          setAdditionalOptions([]);
          setVisible(false);
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
			minHeight: 300,
          }}
        >
          <View
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 12,
              paddingRight: 12,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                color: "#000",
                fontFamily: "Archivo-Bold",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: 14,
              }}
            >
              Pilih Jawaban
            </Text>
            <Pressable
              onPress={() => {
                setFilteredOptions([]);
                setAdditionalOptions([]);
                setVisible(false);
              }}
            >
              <CloseCircleIcon />
            </Pressable>
          </View>
          {isSearch && (
            <View
              style={{
                padding: 12,
                paddingTop: 0,
                borderBottomWidth: 1,
                borderBottomColor: "#EAEAEA",
                borderStyle: "solid",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: "#eaeaea",
                  borderStyle: "solid",
                  backgroundColor: "#FFF",
                  paddingVertical: 8,
                  paddingHorizontal: 12,
                }}
              >
                <SearchIcon />
                <TextInput
                  value={search}
                  onChangeText={(text) => {
                    setSearch(text);
                  }}
                  style={{
                    width: "75%",
                    marginLeft: 10,
                    fontFamily: "Archivo-Regular",
                    fontSize: 12,
                    fontStyle: "normal",
                    // fontWeight: 500,
                  }}
                  placeholder="Cari Jawaban"
                />
              </View>
            </View>
          )}
          {
            loadingOptions &&
            <EmptyState
                  Icon={SearchBoldIcon}
                  title="Mohon Menunggu"
                  desc="Sedang memuat pilihan jawaban"
                />
          }
          {
            !loadingOptions &&
            <ScrollView style={{maxHeight: 300, padding: 16}}>
              {filteredOptions?.length > 0 ? (
                filteredOptions.map((p, i) => {
                  return (
                    <Pressable
                      onPress={() => {
                        onChange(p);
                        setFilteredOptions([]);
                        setAdditionalOptions([]);
                        setVisible(false);
                      }}
                    >
                      <View
                        style={{
                          paddingTop: 16,
                          paddingBottom: 16,
                          paddingLeft: 12,
                          paddingRight: 12,
                          borderBottomWidth:
                            i === filteredOptions.length - 1 ? 0 : 1,
                          borderBottomColor:
                            i === filteredOptions.length - 1 ? "#fff" : "#EAEAEA",
                          borderStyle: "solid",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: i === filteredOptions.length - 1 ? 20: 0
                        }}
                      >
                        <Text
                          style={{
                            color: "#000",
                            fontFamily: "Archivo-Regular",
                            fontSize: 12,
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: 14,
                          }}
                        >
                          {p}
                        </Text>
                        <ChevronRight />
                      </View>
                    </Pressable>
                  );
                })
              ) : (
                <EmptyState
                  Icon={SearchBoldIcon}
                  title="Jawaban Tidak Ditemukan"
                  desc="Gunakan kata kunci yang lain untuk mencari jawaban"
                />
              )}
              </ScrollView>
          }
        </View>
      </Modal>
    </Fragment>
  );
}

export default ModalMenuDropdown;
