import { useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { ScrollView } from "react-native-web";
import CreateResponsiveStyle from "../Utils/createResponsiveStyle";
import { WhatsappIcon } from "../assets/icons";
import {
  CompanyProfileAchievement,
  CompanyProfileAwards,
  CompanyProfileBanner,
  CompanyProfileCollaboration,
  CompanyProfileFooter,
  CompanyProfileHero,
  CompanyProfileHows,
  CompanyProfileMission,
  CompanyProfilePricing,
  CompanyProfileProgramPilihan,
  CompanyProfilePsikologiTes,
  CompanyProfileSponsor,
  CompanyProfileTestimoni,
  CompanyProfileTopbar,
} from "../components";

function CompanyProfile() {
  const [isScroll, setIsScroll] = useState(true);
  const layout = useWindowDimensions();
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);
  return (
    <ScrollView
      style={{ overflow: isScroll ? "auto" : "hidden" }}
      contentContainerStyle={{
        alignItems: "center",
        backgroundColor: "#F1F2F9",
        paddingHorizontal: isMobile ? 16 : 120,
        overflow: isScroll ? "auto" : "hidden",
        width: "100%",
        height: "100vh",
      }}
    >
      <View style={styles("container")}>
        <CompanyProfileTopbar
          layout={layout}
          setIsScroll={setIsScroll}
          isCompanyProfile
        />
        <View style={{ gap: 84 }}>
          <CompanyProfileHero layout={layout} />
          <CompanyProfileMission layout={layout} />
          <CompanyProfileHows layout={layout} />
          <CompanyProfileProgramPilihan layout={layout} />
          <CompanyProfilePsikologiTes layout={layout} />
          <CompanyProfileTestimoni layout={layout} />
          <CompanyProfilePricing layout={layout} />
          <CompanyProfileBanner layout={layout} />
          <CompanyProfileCollaboration layout={layout} />
          <CompanyProfileAchievement layout={layout} />
          <CompanyProfileAwards layout={layout} />
        </View>
        <View style={{ gap: 84 }}>
          <CompanyProfileSponsor layout={layout} />
          <CompanyProfileFooter layout={layout} />
        </View>
      </View>
      <TouchableOpacity style={styles("button")}>
        <WhatsappIcon />
        {!isMobile && <Text style={styles("txButton")}>Hubungi Fammi!</Text>}
      </TouchableOpacity>
    </ScrollView>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      maxWidth: 1200,
      paddingTop: 24,
      overflow: "hidden",
    },
    button: {
      backgroundColor: "#6724E2",
      paddingVertical: 12,
      paddingHorizontal: 24,
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
      borderRadius: 40,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 20,
      position: "sticky",
      bottom: 20,
      left: "83%",
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      color: "#fff",
    },
  },
  {
    container: {
      width: "100%",
    },
  }
);

export default CompanyProfile;
