import * as React from "react";
import Svg, { Path } from "react-native-svg";
const PresentationChartIcon = (props) => (
  <Svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M26.1663 3.25391H2.83301C2.35467 3.25391 1.95801 2.85724 1.95801 2.37891C1.95801 1.90057 2.35467 1.50391 2.83301 1.50391H26.1663C26.6447 1.50391 27.0413 1.90057 27.0413 2.37891C27.0413 2.85724 26.6447 3.25391 26.1663 3.25391Z"
      fill="#FDC52E"
    />
    <Path
      d="M19.9486 26.1089C19.797 26.4122 19.482 26.5872 19.167 26.5872C19.0386 26.5872 18.8986 26.5522 18.782 26.4939L14.5003 24.3589L10.2186 26.4939C10.102 26.5522 9.96197 26.5872 9.83363 26.5872C9.51863 26.5872 9.20363 26.4122 9.05197 26.1089C8.8303 25.6656 9.0053 25.1406 9.44863 24.9306L13.6253 22.8422V19.8789H15.3753V22.8422L19.552 24.9306C19.9953 25.1406 20.1703 25.6656 19.9486 26.1089Z"
      fill="#FDC52E"
    />
    <Path
      d="M4 2.37891V16.4956C4 18.7122 5.16667 19.8789 7.38333 19.8789H21.6167C23.8333 19.8789 25 18.7122 25 16.4956V2.37891H4ZM20.31 10.0556L16.635 13.1239C16.2967 13.4039 15.865 13.5206 15.4567 13.4506C15.0367 13.3806 14.675 13.1239 14.4533 12.7506L13.2283 10.7089L9.81 13.5556C9.64667 13.6956 9.44833 13.7539 9.25 13.7539C9.005 13.7539 8.74833 13.6489 8.57333 13.4389C8.25833 13.0656 8.31667 12.5172 8.69 12.2022L12.365 9.13391C12.7033 8.85391 13.135 8.73724 13.5433 8.80724C13.9633 8.87724 14.325 9.13391 14.5467 9.50724L15.7717 11.5489L19.19 8.70224C19.5633 8.38724 20.1117 8.44557 20.4267 8.81891C20.73 9.19224 20.6833 9.74057 20.31 10.0556Z"
      fill="#FDC52E"
    />
  </Svg>
);
export default PresentationChartIcon;
