import { StyleSheet, Text, View } from "react-native";
import { CheckIcon, Profile2UserIcon } from "../../assets/icons";

export const ScreeningStatisticCard = ({packageData, memberResult, quota}) => {
  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <View style={styles.icon}>
          <Profile2UserIcon width={18} height={18} fill="#6724E2" />
        </View>
        <View>
          <Text style={styles.txValue}>{memberResult.length}/{quota}</Text>
          <Text style={styles.txLabel}>Sudah Mengisi</Text>
        </View>
      </View>

      <View style={styles.card}>
        <View style={styles.icon}>
          <CheckIcon width={18} height={18} fill="#6724E2" />
        </View>
        <View>
          <Text style={styles.txValue}>{((memberResult.length / quota) * 100).toFixed(2)} %</Text>
          <Text style={styles.txLabel}>Pencapaian</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    width: "100%"
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 24,
    flexDirection: "column",
    alignItems: "center",
    gap: 12,
    flex: 1
  },
  icon: {
    width: 40,
    height: 40,
    padding: 11,
    backgroundColor: "#DFD8FF",
    borderRadius: 100
  },
  txValue: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center"
  },
  txLabel: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#999",
    marginTop: 2,
    textAlign: "center"
  }
});
