import Svg, { Path } from "react-native-svg";
const BigHeartCircle = ({ customColor }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <Path
      fill={customColor ?? "red"}
      d="M16 2.667C8.64 2.667 2.666 8.64 2.666 16S8.64 29.333 16 29.333c7.36 0 13.333-5.973 13.333-13.333S23.36 2.666 16 2.666Zm.44 20c-.24.08-.654.08-.894 0-2.08-.707-6.746-3.68-6.746-8.72 0-2.227 1.786-4.027 4-4.027a3.97 3.97 0 0 1 3.2 1.613 3.989 3.989 0 0 1 3.2-1.613c2.213 0 4 1.8 4 4.027 0 5.04-4.667 8.013-6.76 8.72Z"
    />
  </Svg>
);
export default BigHeartCircle;
