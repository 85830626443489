import { API_URL } from "@env";
import axios from "axios";

export const addRespondent = (payload) => {
  return axios.post(API_URL + "/respondent", payload);
};

export const getListRespondent = (additionalContentId, quizId, token) => {
  return axios.get(API_URL + `/respondent/${additionalContentId}/${quizId}/${token}`);
};

export const getQuizReport = (quizId, additionalContentId) => {
  return axios.get(
    API_URL + `/quiz_report/member/${quizId}/${additionalContentId}`
  );
};
export const getQuizReportByRespondence = (quizId, additionalContentId) => {
  return axios.get(API_URL + `/quiz_report/respondence/${quizId}/${additionalContentId}`);
};

export const submitScreening = (quizId, additionalContentId, respondent_id, postedAnswer) => {
  return axios.post(
    API_URL +
      `/answer/${quizId}/respondent/${additionalContentId}/${respondent_id}`,
      postedAnswer
  );
};

export const checkRespondentEligible = (member_id, package_id) => {
  return axios.get(API_URL + `/get_role_by_package/${member_id}/${package_id}`);
}

export const getQuota = (additionalContentId, quizId) => {
  return axios.get(API_URL + `/quiz_quota/${quizId}/${additionalContentId}`);
}

export const getAspectComparison = (quizId, additionalContentId) => {
  return axios.get(
    API_URL + `/aspect_comparison/${quizId}/${additionalContentId}`
  );
};

export const checkCode = (additionalContentId, quizId) => {
  return axios.get(API_URL + `/check_passcode/${quizId}/${additionalContentId}`);
}

export const confirmCode = (additionalContentId, quizId, payload) => {
  return axios.post(
    API_URL + `/quiz_passcode/${quizId}/${additionalContentId}`,
    payload
  );
}