import Svg, { Path } from "react-native-svg";
const EditIcon = ({ fill, ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      fill={fill ?? "#000"}
      d="M12.143 1.5H5.857C3.127 1.5 1.5 3.127 1.5 5.857v6.278c0 2.738 1.627 4.365 4.357 4.365h6.278c2.73 0 4.357-1.627 4.357-4.357V5.857c.008-2.73-1.62-4.357-4.35-4.357Zm-3.93 11.633c-.218.217-.63.427-.93.472l-1.846.262c-.067.008-.135.015-.202.015a1.11 1.11 0 0 1-.795-.307c-.248-.247-.353-.607-.293-1.005l.263-1.845a1.84 1.84 0 0 1 .473-.93L8.226 6.45c.12.336.27.66.45.968.075.127.158.247.226.337.082.128.18.247.24.315.037.053.067.09.082.105.188.225.405.435.593.592a.688.688 0 0 0 .097.09c.113.09.225.18.323.248.12.09.24.172.367.24.15.09.315.172.48.255.172.075.33.143.487.195l-3.36 3.338Zm4.815-4.815-.69.697a.233.233 0 0 1-.165.067c-.023 0-.053 0-.068-.007a4.651 4.651 0 0 1-3.173-3.172.223.223 0 0 1 .06-.225l.698-.698c1.14-1.14 2.228-1.118 3.345 0 .57.57.848 1.117.848 1.688-.008.54-.285 1.08-.855 1.65Z"
    />
  </Svg>
);
export default EditIcon;
