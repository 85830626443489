import Svg, { Path } from "react-native-svg";
const ProfileIcon = ({ isActive, ...props }) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <Path
      fill={isActive ? "#6724E2" : "#000"}
      d="M11.5 1.833a4.358 4.358 0 0 0-4.354 4.354c0 2.356 1.843 4.263 4.244 4.345a.737.737 0 0 1 .202 0h.064a4.343 4.343 0 0 0 4.198-4.345A4.358 4.358 0 0 0 11.5 1.833Zm4.657 11.137c-2.558-1.705-6.728-1.705-9.304 0-1.164.779-1.806 1.833-1.806 2.96 0 1.128.642 2.173 1.797 2.943 1.283.862 2.97 1.292 4.656 1.292 1.687 0 3.373-.43 4.657-1.292 1.155-.78 1.796-1.824 1.796-2.96-.009-1.128-.641-2.173-1.796-2.943Z"
    />
  </Svg>
);
export default ProfileIcon;
