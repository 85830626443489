import * as React from "react";
import Svg, { Path } from "react-native-svg";
const PasswordCheckIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M18 4H15.75V2.75C15.75 2.34 15.41 2 15 2C14.59 2 14.25 2.34 14.25 2.75V21.25C14.25 21.66 14.59 22 15 22C15.41 22 15.75 21.66 15.75 21.25V20H18C20.21 20 22 18.21 22 16V8C22 5.79 20.21 4 18 4ZM6.5 4C4.29 4 2.5 5.79 2.5 8V16C2.5 18.21 4.29 20 6.5 20H11.5C12.05 20 12.5 19.55 12.5 19V5C12.5 4.45 12.05 4 11.5 4H6.5ZM6.67 12.38C6.62 12.5 6.55 12.61 6.46 12.71C6.36 12.8 6.25 12.87 6.13 12.92C6.01 12.97 5.88 13 5.75 13C5.62 13 5.49 12.97 5.37 12.92C5.25 12.87 5.14 12.8 5.04 12.71C4.95 12.61 4.88 12.5 4.82 12.38C4.77183 12.2592 4.74804 12.13 4.75 12C4.75 11.74 4.86 11.48 5.04 11.29L5.19 11.17C5.25 11.13 5.31 11.1 5.37 11.08C5.43 11.05 5.49 11.03 5.55 11.02C5.89 10.95 6.23 11.06 6.46 11.29C6.64 11.48 6.75 11.74 6.75 12C6.75 12.13 6.72 12.26 6.67 12.38ZM10.17 12.38C10.12 12.5 10.05 12.61 9.96 12.71C9.86 12.8 9.75 12.87 9.63 12.92C9.51 12.97 9.38 13 9.25 13C9.12 13 8.99 12.97 8.87 12.92C8.75 12.87 8.64 12.8 8.54 12.71C8.40008 12.5701 8.30529 12.3915 8.26786 12.1972C8.23043 12.0029 8.25207 11.8019 8.33 11.62C8.38 11.49 8.45 11.39 8.54 11.29C8.91 10.92 9.58 10.92 9.96 11.29C10.14 11.48 10.25 11.74 10.25 12C10.25 12.13 10.22 12.26 10.17 12.38Z"
      fill="#6724E2"
    />
  </Svg>
);
export default PasswordCheckIcon;
