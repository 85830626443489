import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { PenBold } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import EmptyFeedback from "../assets/icons/EmptyFeedback";
import { getFeedback } from "../services/feedback";

function FeedbackExpert(props) {
  const navigation = useNavigation()
  const {speaker, t} = props;
  const [feedback, setFeedback] = useState([]);
  useEffect(() => {
    if (!speaker) {
      return;
    }

    getFeedback(speaker.speakerId)
    .then((res) => {
      const resp = res.data;
      const filtered = resp?.filter((i) => i.descriptions);
      setFeedback(filtered);
    });
  }, [speaker]);
  return (
    <View style={styles.parentList}>
      {
        feedback?.length == 0 &&
        <View style={{
          padding: 24,
          alignItems: "center"
        }}>
          <EmptyFeedback/>
          <Text style={{
            marginTop: 24,
            fontWeight: "700",
            fontFamily: "Archivo-Regular",
            fontSize: 14
          }}>
            {t("detail_expert:reviews_card.empty.title")}
          </Text>
          <Text style={{
            fontFamily: "Archivo-Regular",
            fontSize: 14,
            marginTop: 12,
            textAlign: "center"
          }}>
            {t("detail_expert:reviews_card.empty.description")}
          </Text>
        </View> 
      }
      {
        feedback?.length > 0 &&
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            paddingTop: 16,
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <View style={styles.flexOne}>
            <PenBold />
            <Text style={styles.txTitle}>{feedback?.length} {t("detail_expert:reviews_card.title")}</Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("SpeakerFeedback", {
                speaker, feedback
              })
            }}
          >
            <Text style={styles.textAction}>{t("detail_expert:reviews_card.see-all")}</Text>
          </TouchableOpacity>
        </View>
      }
      {
        feedback?.length > 0 &&
        <View style={{ marginTop: 16 }}>
          {feedback.map((item, index) => {
            if (!item?.descriptions) {
              return;
            }
            return <TouchableOpacity
              key={index}
              style={[
                styles.listFeedback,
                index + 1 !== feedback.length && {
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  borderBottomColor: "#eaeaea",
                },
              ]}
            >
              <Text style={styles.txMain}>
                {
                  item?.descriptions
                }
              </Text>
            </TouchableOpacity>
          })}
        </View>
      }
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    marginBottom: 16,
    paddingBottom: 10,
  },

  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },
  listFeedback: {
    backgroundColor: "#fff",

    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 12,
    paddingRight: 12,
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },

  txMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#000",
  },
  textAction: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
  },
});

export default FeedbackExpert;
