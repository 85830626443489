import * as React from "react";
import Svg, { Path } from "react-native-svg";
const InfoIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M16.1702 8.05457L15.1577 6.86957C14.9702 6.64457 14.8127 6.22457 14.8127 5.92457V4.64957C14.8127 3.85457 14.1602 3.20207 13.3652 3.20207H12.0902C11.7902 3.20207 11.3627 3.04457 11.1377 2.85707L9.95266 1.84457C9.43516 1.40207 8.58766 1.40207 8.07016 1.84457L6.87016 2.85707C6.64516 3.04457 6.22516 3.20207 5.92516 3.20207H4.62766C3.83266 3.20207 3.18016 3.85457 3.18016 4.64957V5.92457C3.18016 6.21707 3.03016 6.63707 2.84266 6.86207L1.83016 8.05457C1.39516 8.57957 1.39516 9.41957 1.83016 9.92957L2.84266 11.1221C3.03016 11.3396 3.18016 11.7671 3.18016 12.0596V13.3421C3.18016 14.1371 3.83266 14.7896 4.62766 14.7896H5.93266C6.22516 14.7896 6.65266 14.9471 6.87766 15.1346L8.06266 16.1471C8.58016 16.5896 9.42766 16.5896 9.94516 16.1471L11.1302 15.1346C11.3552 14.9471 11.7752 14.7896 12.0752 14.7896H13.3502C14.1452 14.7896 14.7977 14.1371 14.7977 13.3421V12.0671C14.7977 11.7671 14.9552 11.3471 15.1427 11.1221L16.1552 9.93707C16.6127 9.42707 16.6127 8.57957 16.1702 8.05457ZM8.43766 6.09707C8.43766 5.78957 8.69266 5.53457 9.00016 5.53457C9.30766 5.53457 9.56266 5.78957 9.56266 6.09707V9.71957C9.56266 10.0271 9.30766 10.2821 9.00016 10.2821C8.69266 10.2821 8.43766 10.0271 8.43766 9.71957V6.09707ZM9.00016 12.6521C8.58766 12.6521 8.25016 12.3146 8.25016 11.9021C8.25016 11.4896 8.58016 11.1521 9.00016 11.1521C9.41266 11.1521 9.75016 11.4896 9.75016 11.9021C9.75016 12.3146 9.42016 12.6521 9.00016 12.6521Z"
      fill="#292D32"
    />
  </Svg>
);
export default InfoIcon;
