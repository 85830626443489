import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import md5 from "md5";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import Modal from "react-native-modal";
import { ChevronDown, SendIcon } from "../assets/icons";
import { AppBar, PrimaryButton, Snackbar } from "../components";
import { createDiscussion, sendNotif } from "../services/programs";

function NewDiscuss({ route }) {
  const { params } = route;
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [isOnSend, setIsOnSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [visible, setVisible] = useState(false);
  const [pilihan, setPilihan] = useState([]);
  const [form, setForm] = useState({
    title: "",
    categories: "",
    tags: "",
    question: "",
  });

  const targetKonsultasi = t("program-detail:about-lists")?.split(",");
  const problemCategories = t("program-detail:problem-lists")?.split(",");

  const handleChangeForm = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  useEffect(() => {
    if (params.hideCategory) {
      setForm((val) => ({
        ...val,
        categories: targetKonsultasi[targetKonsultasi.length - 1],
        tags: problemCategories[problemCategories.length - 1],
      }));
    }
  }, [params?.hideCategory]);

  const onSubmit = async () => {
    if (!form.title || !form.question) {
      setIsOnSend(false);
      setIsError(true);
      return;
    }

    if (!form.categories) {
      setForm((val) => ({
        ...val,
        categories: "-",
      }));
    }

    if (!form.tags) {
      setForm((val) => ({
        ...val,
        tags: "-",
      }));
    }

    setIsOnSend(true);
    const questionId = md5(new Date().getTime());
    const payload = {
      ...form,
      memberId: await AsyncStorage.getItem("token"),
      questionId: questionId,
      additionalContentId: params?.additionalContentId,
    };
    if (params?.parentId) {
      payload.parentId = `${params?.parentId}`;
    }
    await createDiscussion(payload)
      .then((res) => {
        if (res?.data === true) {
          setIsSuccess(true);
          sendNotif(params?.additionalContentId, questionId);

          if (params?.mode === "ruang-kota") {
            return navigation.navigate("Kota", {
              refresh: new Date().getTime(),
            });
          }

          setTimeout(() => {
            navigation.navigate("ProgramDetail", {
              ...params,
              refresh: new Date().getTime(),
            });
          }, 1000);
        } else {
          setIsOnSend(false);
          setIsError(true);
        }
      })
      .catch((err) => {
        setIsOnSend(false);
        setIsError(true);
      });
  };
  return (
    <Fragment>
      <AppBar
        title={
          params?.parentId
            ? "Komentar Diskusi"
            : t("program-detail:add-discussion")
        }
        isBack
        rightSection={
          <PrimaryButton
            loading={isOnSend}
            onPress={onSubmit}
            icon={<SendIcon customColor="#fff" small />}
            style={{
              paddingTop: 10,
              paddingRight: 10,
              paddingBottom: 10,
              paddingLeft: 10,
            }}
            title={t("program:send")}
          />
        }
      />
      <View style={styles.container}>
        <TextInput
          value={form.title}
          onChangeText={(text) => {
            handleChangeForm("title", text);
          }}
          style={styles.inputTitle}
          placeholder={t("program-detail:discussion-title")}
          placeholderTextColor={"#C4C4C4"}
        />
        {!params?.hideCategory && (
          <View style={{ ...styles.flexBetween, padding: 0 }}>
            <Pressable
              onPress={() => {
                setVisible(true);
                setPilihan(targetKonsultasi);
              }}
              style={{ ...styles.selectBox, padding: 16 }}
            >
              <Text
                style={{
                  ...styles.txBox,
                  color: form.categories.length > 0 ? "#000000" : "#C4C4C4",
                }}
              >
                {form.categories.length > 0
                  ? form.categories
                  : t("program-detail:about-whom")}
              </Text>
              <ChevronDown />
            </Pressable>
            <View style={styles.divider} />
            <Pressable
              onPress={() => {
                setVisible(true);
                setPilihan(problemCategories);
              }}
              style={{ ...styles.selectBox, padding: 16 }}
            >
              <Text
                style={{
                  ...styles.txBox,
                  color: form.tags.length > 0 ? "#000000" : "#C4C4C4",
                }}
              >
                {form.tags.length > 0
                  ? form.tags
                  : t("program-detail:about-what")}
              </Text>
              <ChevronDown />
            </Pressable>
          </View>
        )}
        <TextInput
          style={styles.mainInput}
          multiline={true}
          numberOfLines={5}
          value={form.question}
          onChangeText={(text) => {
            handleChangeForm("question", text);
          }}
          placeholder={t("program-detail:write-question")}
        />
      </View>
      <Snackbar
        visible={isError}
        hideDialog={() => {
          setIsError(false);
        }}
        title={t("program-detail:please-complete")}
      />
      <Snackbar
        visible={isSuccess}
        hideDialog={() => {
          setIsSuccess(false);
        }}
        title={t("program-detail:discussion-success")}
      />
      <Modal
        isVisible={visible}
        onBackdropPress={() => {
          setVisible(false);
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
          }}
        >
          <View
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <Text>
              {pilihan.length > 0 && pilihan[0] === t("program-detail:my-self")
                ? t("popup-consultation-forms:about:title")
                : t("popup-consultation-forms:problems:title")}
            </Text>
          </View>
          {pilihan.map((p, i) => {
            return (
              <Pressable
                onPress={() => {
                  if (
                    pilihan.length > 0 &&
                    pilihan[0] === t("program-detail:my-self")
                  ) {
                    handleChangeForm("categories", p);
                    setVisible(false);
                  } else {
                    handleChangeForm("tags", p);
                    setVisible(false);
                  }
                }}
              >
                <View
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    paddingLeft: 12,
                    paddingRight: 12,
                    borderBottom:
                      i === pilihan.length - 1 ? "none" : "solid 1px #EAEAEA",
                  }}
                >
                  <Text>{p}</Text>
                </View>
              </Pressable>
            );
          })}
        </View>
      </Modal>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
    height: "100%",
  },
  inputTitle: {
    width: "100%",
    backgroundColor: "#fff",
    width: "100%",
    borderBottomColor: "#eaeaea",
    borderBottomWidth: 1,
    borderStyle: "solid",
    padding: 16,
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 12,
  },
  mainInput: {
    backgroundColor: "#fff",
    padding: 16,
    fontFamily: "Archivo-Regular",
    height: "100%",
    width: "100%",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    textAlignVertical: "top",

    lineHeight: 12,
  },
  flexBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderBottomColor: "#eaeaea",
    borderBottomWidth: 1,
    borderStyle: "solid",
  },
  divider: {
    backgroundColor: "#EAEAEA",
    width: 1,
    height: 22,
  },
  selectBox: {
    width: "47%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  txBox: {
    color: "#999",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 12,
  },
});

export default NewDiscuss;
