import {
  Image,
  Linking,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import {
  InstagramCircleIcon,
  LinkedinCircleIcon,
  TiktokCircleIcon,
  YoutubeCircleIcon,
} from "../../assets/icons";

export function CompanyProfileFooter({ layout }) {
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  const renderSosmed = (
    <View
      style={[
        styles("sosmed"),
        isMobile && { flexDirection: "column", gap: 12 },
      ]}
    >
      <View style={styles("sosmed")}>
        <PressableIcon
          isMobile={isMobile}
          Icon={YoutubeCircleIcon}
          url="https://www.youtube.com/@fammiofficial"
        />
        <PressableIcon
          isMobile={isMobile}
          Icon={InstagramCircleIcon}
          url="https://www.instagram.com/fammiofficial/"
        />
        <PressableIcon
          isMobile={isMobile}
          Icon={TiktokCircleIcon}
          url="https://www.tiktok.com/@fammiofficial"
        />
        <PressableIcon
          isMobile={isMobile}
          Icon={LinkedinCircleIcon}
          url="https://www.linkedin.com/company/fammiindonesia/"
        />
      </View>
      <Text style={styles("txContent")}>@fammimenemani</Text>
    </View>
  );

  const renderCopyRight = (
    <Text style={styles("txContent")}>
      Copyright © 2023 PT. Fammi Indonesia Edutech
    </Text>
  );
  return (
    <View style={styles("footer")}>
      <View style={styles("content")}>
        <Image
          style={styles("logo")}
          resizeMode="contain"
          source={require("../../assets/fammi-logo-white.svg")}
        />
        <Text style={styles("txContent")}>
          Jl. Ir. H. Juanda No.299, Dago, Kecamatan Coblong, Kota Bandung, Jawa
          Barat 40135
        </Text>
        {!isMobile && renderCopyRight}
      </View>
      {renderSosmed}
      {isMobile && renderCopyRight}
      <Svg width="100%" height="100%" style={styles("overlay")}>
        <Defs>
          <LinearGradient id={`gradFooter`} x1="00%" x2="100%" y1="0%" y2="0%">
            <Stop offset={0} stopColor="#402777" />
            <Stop offset={1} stopColor="#2B1A50" />
          </LinearGradient>
        </Defs>
        <Rect width="100%" height="100%" fill={`url(#gradFooter)`} />
      </Svg>
    </View>
  );
}

const PressableIcon = ({ url, Icon, isMobile }) => {
  const sosmedIconProps = isMobile
    ? { width: 32, height: 32 }
    : { width: 48, height: 48 };
  return (
    <Pressable onPress={() => Linking.openURL(url)}>
      <Icon {...sosmedIconProps} />
    </Pressable>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    footer: {
      padding: 48,
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      position: "relative",
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      zIndex: -1,
    },
    content: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: 48,
      maxWidth: 375,
    },
    logo: {
      width: 128,
      height: 48,
    },
    sosmed: {
      flexDirection: "row",
      alignItems: "center",
      gap: 20,
    },
    txContent: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 22,
      color: "#FDFDFD",
    },
  },
  {
    footer: {
      padding: 16,
      flexDirection: "column",
      alignItems: "center",
      gap: 32,
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      zIndex: -1,
    },
    content: {
      flexDirection: "column",
      alignItems: "center",
      gap: 12,
      maxWidth: 315,
    },
    logo: {
      width: 112,
      height: 42,
    },
    sosmed: {
      gap: 14,
    },
    txContent: {
      textAlign: "center",
    },
  }
);
