import { Fragment, useState } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { AppBar, InputJournal, PrimaryButton } from "../components";
import { useNavigation } from "@react-navigation/native";
import { inputList, setJournalForm } from "../services/journals";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function WriteJournal(props) {
  const { route } = props;
  const type = route.params.speakerType ?? { type: null };
  const [ journal, setJournal ] = useState({});
  const navigation = useNavigation();
  const { t } = useTranslation();
  const INPUTLIST = inputList(t);
  return (
    <Fragment>
      <AppBar title="Tulis Jurnal" isBack />
      <ScrollView style={styles.container}>
        {INPUTLIST[type].map((item, index) => (
          <InputJournal item={item} key={index} setJournal={setJournal} journal={journal}/>
        ))}

        <PrimaryButton 
          title={t("profile-form:button")}
          onPress={() => {

            setJournalForm({
              form:journal,
              speakerId: route.params.speaker.speaker_id,
              consId:  route.params.consId,
              speakerType: route.params.speakerType
            }).then((res) => {
              navigation.navigate("Journal", {
                "journal": journal, 
                "inputList": INPUTLIST[type],
                "jumpCounter": 2
              })
            })
          }}
        />
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 7,
    paddingRight: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});
export default WriteJournal;
