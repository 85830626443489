import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Calendar } from "../assets/icons";
// import {default as DatePickerNative} from 'react-native-date-picker'
import moment from "moment/moment";
import 'moment/locale/id';
import DatePickerComponent from "./DatePicker/DatePickerComponent";
// import DatePickerComponentWeb from "./DatePicker/DatePickerComponentWeb";

function ConsultationSchedule({data, chosenHourSchedule, setChosenHourSchedule, setChosenDateSchedule, setSnackBarMsg, setOpenSnackBar, t, loading}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState();
  const [hoursSchedule, setHoursSchedule] = useState(undefined);
  const [scheduledDayData, setScheduleDayData] = useState([]);
  const [nearestDateData, setNearestDateData] = useState(undefined);
  const [displayDate, setDisplayDate] = useState(true);
  
  useEffect(() => {
    if (!date) {
      return;
    }

    if (moment(date).isBefore(moment()) && moment().diff(date, "days") > 0) {
      setSnackBarMsg("Pemilihan tanggal tidak sesuai.")
      setOpenSnackBar(true);
      setTimeout(() => {
        setSnackBarMsg(t("choose-expert:choose-schedule"))
      }, 3000);
      return;
    }
    
    const selectedDayName =  moment(date).locale("id").format("dddd"); // moment(date).lang("ID-id").format("dddd");
    const filteredSchedule = scheduledDayData?.filter((s) => s.dayName === selectedDayName);
    if (filteredSchedule?.length > 0) {
      setHoursSchedule(filteredSchedule?.[0]?.hourList);
      setChosenDateSchedule(date);
    } else {
      setHoursSchedule(undefined);
      setChosenDateSchedule(undefined);
    }
  }, [date, scheduledDayData]);

  useEffect(() => {
    const scheduleData = data?.[0];
    const exceptionalDate = scheduleData?.exceptionalDate?.split(", ");
    if (exceptionalDate?.indexOf(moment(date).format("DD-MM-YYYY")) > -1) {
      return;
    }
    let nearestDate = [];

    const scheduledDay = [];
    scheduleData?.startTime.split("::").map((s) => {
      const hours = s.split(", ");
      scheduledDay.push({
        dayName: hours[0],
        hourList: hours.slice(1)
      });

      let addedDate = moment().day(hours[0]);

      if (moment().day(hours[0]).diff(moment(), "days") < 0) {
        addedDate = moment().day(hours[0]).add(7, "days");
      }
      nearestDate.push(new Date(addedDate).getTime());
    });

    nearestDate = nearestDate.sort();
    setNearestDateData(nearestDate[0]);
    setScheduleDayData(scheduledDay);
    setDate(new Date(nearestDate[0]));
  }, [data?.[0]]);

  return (
    <View style={styles.parentList}>
      <View style={styles.flexOne}>
        <Calendar />
        <Text style={styles.txTitle}>{t("detail_expert:schedule_card.title")}</Text>
      </View>
      <View
        style={{
          ...styles.flexOne,
          marginTop: 16,
          paddingBottom: 16,
          borderBottomWidth: 1,
          borderBottomColor: "#EAEAEA",
          borderStyle: "solid",
        }}
      >
        {
          nearestDateData &&
          moment(moment.utc(nearestDateData).local().format("YYYY-MM-DD")).diff(moment().local().format("YYYY-MM-DD"), "days") !== 0 &&
          moment(moment.utc(date).local().format("YYYY-MM-DD")).diff(moment(nearestDateData).local().format("YYYY-MM-DD"), "days") === 0 &&
          !loading &&
          <TouchableOpacity
            style={{
              ...styles.btnOn,
              backgroundColor: moment(date).local().format('DD MM YYYY') === moment.utc(nearestDateData).local().format('DD MM YYYY') ? "#6724E2" : "#FFF",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: moment(date).local().format('DD MM YYYY') === moment.utc(nearestDateData).local().format('DD MM YYYY') ? "#FFF" : "#6724E2"
            }}
            onPress={() => {
              setDisplayDate(false);
              setDate(new Date(nearestDateData));
            }}
          >
            <Text style={{ ...styles.txBtnOn, 
              color: moment(date).local().format('DD MM YYYY') === moment.utc(nearestDateData).local().format('DD MM YYYY') ? "#FFF" : "#6724E2"
            }}>
            {t("detail_expert:closest")} {moment.unix(nearestDateData/1000).format("DD MMM YYYY")}
            </Text>
          </TouchableOpacity>
        }
        {
          date &&
          displayDate &&
          moment(date).local().format('DD MM YYYY') !== moment().local().format('DD MM YYYY') &&
          moment(date).local().format("YYYY-MM-DD") !== moment.utc(nearestDateData).local().format("YYYY-MM-DD") &&
          !loading &&
          data?.length > 0 &&
          <TouchableOpacity style={styles.btnOn}>
            <Text style={styles.txBtnOn}>
              Jadwal {moment(date).format('DD MMM YYYY')}
            </Text>
          </TouchableOpacity>
        }
        {
          nearestDateData &&
          // moment(moment.utc(nearestDateData).local().format("YYYY-MM-DD")).diff(moment().local().format("YYYY-MM-DD"), "days") === 0 &&
          moment(moment.utc(date).local().format("YYYY-MM-DD")).diff(moment().local().format("YYYY-MM-DD"), "days") === 0 &&
          !loading &&
          <TouchableOpacity 
            style={{
              ...styles.btnOn,
              backgroundColor: moment(date).local().format('DD MM YYYY') === moment().local().format('DD MM YYYY') ? "#6724E2" : "#FFF",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: moment(date).local().format('DD MM YYYY') === moment().local().format('DD MM YYYY') ? "#FFF" : "#6724E2",
            }}
            onPress={() => {
              setDate(new Date())
            }}
          >
            <Text style={{
              ...styles.txBtnOn,
              color: moment(date).local().format('DD MM YYYY') === moment().local().format('DD MM YYYY') ? "#FFF" : "#6724E2"
            }}>{t("detail_expert:schedule_card.filter_today")}</Text>
          </TouchableOpacity>
        }
        {
          !loading &&
          <TouchableOpacity
            style={{
              ...styles.btnOn,
              backgroundColor: "#fff",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#6724E2",
            }}
            onPress={() => {
              setShowCalendar(true);
            }}
          >
            <Text style={{ ...styles.txBtnOn, color: "#6724E2" }}>
            {t("detail_expert:schedule_card.filter_custom")}
            </Text>
          </TouchableOpacity>
        }
      </View>
      {
          showCalendar &&
          <View>
            {
              (Platform.OS === "android" || Platform.OS === "ios") &&
              <DatePickerComponent open={showCalendar} setOpen={setShowCalendar} date={date} setDate={setDate} setDisplayDate={setDisplayDate}/>
            }
            {
              Platform.OS === "web" &&
              <input type="date" onChange={(e) => {setDate(e.target.value);setDisplayDate(true);}} style={{padding: 8, paddingLeft: 12, paddingRight: 12, textAlign: "center", justifyContent: "center", borderRadius: 10, backgroundColor: "#FFF", color: "#6724E2", borderWidth: 1, borderColor: "#6724E2", width: 100}}/>
            }
          </View>
      }
      {
        !hoursSchedule &&
        <Text style={{
          backgroundColor: "#EAEAEA",
          color: "#999999",
          fontFamily: "Archivo-Regular",
          padding: 12,
          borderRadius: 10
        }}>
          {
            loading &&
            t("choose-expert:loading")
          }
          {
            !loading &&
            data.length > 0 &&
            `${t("choose-expert:unavailable")} ${moment(date).format("DD MMM YYYY")}`
          }
          {
            !loading &&
            data.length === 0 &&
            `${t("choose-expert:totally-unavailable")}`
          }
        </Text>
      }
      {
        hoursSchedule?.length > 0 &&
        <View style={{ ...styles.flexOne, paddingTop: 12, flexWrap: "wrap" }}>
          {
            hoursSchedule.map((h, index) => {
              return <TouchableOpacity
                key={index}
                style={{
                  ...styles.btnOn,
                  backgroundColor: h === chosenHourSchedule ? "#6724E2" : "#fff",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#eaeaea",
                  marginTop: 10,
                }}
                onPress={() => {
                  setChosenHourSchedule(h);
                }}
              >
                <Text style={{ ...styles.txBtnOn, color: h === chosenHourSchedule ? "#FFF" : "#000" }}>
                  {h}
                </Text>
              </TouchableOpacity>
            })
          }
        </View>
      }
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 16,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 12,
    paddingRight: 12,
  },

  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
  btnOn: {
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    paddingTop: 8,
    paddingRight: 12,
    paddingBottom: 8,
    paddingLeft: 12,
    marginRight: 12,
  },
  txBtnOn: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});

export default ConsultationSchedule;
