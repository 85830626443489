import React from "react";
import { Image, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export const Footer = ({ layout }) => {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <View style={styles("content")}>
        <Image
          source={require("../../assets/images/fammi-logo.png")}
          alt="fammi-logo"
          style={styles("footerImg")}
        />
        <Text style={styles("txFooter")}>
          Jl. Ir. H. Djuanda No. 299, Kota Bandung, Indonesia
        </Text>
      </View>
      <View style={styles("content")}>
        <View style={[styles("content"), styles("socialMedia")]}>
          <Image
            source={require("../../assets/images/tiktok.png")}
            alt="tiktok"
            style={styles("footerImg")}
          />
          <Image
            source={require("../../assets/images/instagram.png")}
            alt="instagram"
            style={styles("footerImg")}
          />
          <Image
            source={require("../../assets/images/linkedin.png")}
            alt="linkedin"
            style={styles("footerImg")}
          />
        </View>
        <Text style={styles("txFooter")}>@fammiofficial</Text>
      </View>
    </View>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      backgroundColor: "#F3F3F3",
      borderTopLeftRadius: 40,
      borderTopRightRadius: 40,
      paddingVertical: 30,
      paddingHorizontal: 40,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    content: { flexDirection: "row", alignItems: "center", gap: 14 },
    footerImg: {
      width: 30,
      height: 30,
    },
    txFooter: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 14,
      lineHeight: 15,
      textAlign: "center",
    },
  },
  {
    container: {
      flexDirection: "column",
      justifyContent: "center",
      paddingVertical: 20,
      paddingHorizontal: 20,
      gap: 20,
    },
    content: { flexDirection: "column", gap: 10 },
    socialMedia: { flexDirection: "row" },
    txFooter: {
      fontSize: 12,
      lineHeight: 13,
    },
  }
);
