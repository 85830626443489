import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

const SectionContent = ({
  title,
  style,
  hideAllButton,
  handleAllButton,
  children,
}) => {
  return (
    <View style={[styles.container, { ...style }]}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
        {!hideAllButton && (
          <TouchableOpacity onPress={handleAllButton}>
            <Text style={styles.button}>Semua</Text>
          </TouchableOpacity>
        )}
      </View>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
  },
  button: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
  },
});

export default SectionContent;
