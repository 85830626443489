import * as React from "react";
import Svg, { Path } from "react-native-svg";

const EmojiHappyIcon = (props) => {
  const fill = (props && props.fill) ? props.fill : (props && props.color) ? props.color : "#6724E2";

  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.135C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.135C14.865 16.5 16.4925 14.8725 16.4925 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM6.375 4.785C7.1475 4.785 7.785 5.415 7.785 6.195C7.785 6.975 7.155 7.605 6.375 7.605C5.595 7.605 4.965 6.96 4.965 6.1875C4.965 5.415 5.6025 4.785 6.375 4.785ZM9 14.31C6.9825 14.31 5.34 12.6675 5.34 10.65C5.34 10.125 5.7675 9.69 6.2925 9.69H11.6925C12.2175 9.69 12.645 10.1175 12.645 10.65C12.66 12.6675 11.0175 14.31 9 14.31ZM11.625 7.59C10.8525 7.59 10.215 6.96 10.215 6.18C10.215 5.4 10.845 4.77 11.625 4.77C12.405 4.77 13.035 5.4 13.035 6.18C13.035 6.96 12.3975 7.59 11.625 7.59Z"
        fill={fill}
      />
    </Svg>  
  )
};
export default EmojiHappyIcon;
