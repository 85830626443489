import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const TickSquareCircleIcon = (props) => (
  <Svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={22} cy={22} r={22} fill="#DCF4CA" />
    <Path
      d="M26.19 11.623H17.81C14.17 11.623 12 13.793 12 17.433V25.803C12 29.453 14.17 31.623 17.81 31.623H26.18C29.82 31.623 31.99 29.453 31.99 25.813V17.433C32 13.793 29.83 11.623 26.19 11.623ZM26.78 19.323L21.11 24.993C20.97 25.133 20.78 25.213 20.58 25.213C20.38 25.213 20.19 25.133 20.05 24.993L17.22 22.163C16.93 21.873 16.93 21.393 17.22 21.103C17.51 20.813 17.99 20.813 18.28 21.103L20.58 23.403L25.72 18.263C26.01 17.973 26.49 17.973 26.78 18.263C27.07 18.553 27.07 19.023 26.78 19.323Z"
      fill="#4EB826"
    />
  </Svg>
);
export default TickSquareCircleIcon;
