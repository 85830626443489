import { PermissionsAndroid, Platform } from "react-native"
import messaging from '@react-native-firebase/messaging';

export const getPermission = () => {
    if (Platform.OS === "android") {
        PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATION);
    }
}

export const getDeviceToken = () => {
    return messaging().getToken();
}