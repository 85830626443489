import Svg, { Circle, Path } from "react-native-svg"
const StatsIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <Circle cx={14} cy={14} r={14} fill="#FDFDFD" />
    <Path
      fill="#834FE3"
      d="M9.562 15.67H7.337c-.917 0-1.667.75-1.667 1.667v4.166c0 .459.375.834.833.834h3.059a.836.836 0 0 0 .833-.834v-5a.836.836 0 0 0-.833-.833ZM15.11 12.334h-2.225c-.916 0-1.666.75-1.666 1.667v7.5c0 .458.375.833.833.833h3.892a.836.836 0 0 0 .833-.833V14c0-.917-.742-1.667-1.667-1.667ZM20.67 18.17h-2.225a.836.836 0 0 0-.834.833v2.5c0 .459.375.834.834.834h3.058a.836.836 0 0 0 .833-.834v-1.666c0-.917-.75-1.667-1.666-1.667ZM16.51 8.043c.258-.259.358-.567.275-.834-.084-.266-.342-.458-.709-.516l-.8-.134c-.033 0-.108-.058-.124-.091l-.442-.884c-.334-.675-1.092-.675-1.425 0l-.442.884c-.008.033-.083.091-.117.091l-.8.134c-.366.058-.616.25-.708.516-.083.267.017.575.275.834l.617.625c.033.025.058.125.05.158l-.175.767c-.133.575.083.833.225.933.141.1.45.233.958-.067l.75-.441c.034-.025.142-.025.175 0l.742.441c.233.142.425.184.575.184.175 0 .3-.067.375-.117.142-.1.358-.358.225-.933l-.175-.767c-.009-.042.016-.133.05-.158l.625-.625Z"
    />
  </Svg>
)
export default StatsIcon
