import { Image, Text, View } from "react-native";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import awards from "./awards.json";

export function CompanyProfileAwards({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      {awards.map((item) => (
        <View key={item.id} style={styles("card")}>
          <Image
            style={styles("image")}
            source={require(`../../../assets/images/awards/${item.id}.png`)}
          />
          <Text style={styles("txLabel")}>{item.label}</Text>
        </View>
      ))}
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      maxWidth: 1200,
      flexDirection: "row",
      rowGap: 16,
      justifyContent: "space-between",
    },
    card: {
      flexDirection: "column",
      alignItems: "center",
      gap: 10,
    },
    image: {
      width: 100,
      height: 100,
    },
    txLabel: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 22,
      color: "#0A0316",
      textAlign: "center",
      maxWidth: 110,
    },
  },
  {
    container: {
      maxWidth: 350,
      flexWrap: "wrap",
      columnGap: 92,
    },
  }
);
