import { useNavigation } from "@react-navigation/native";
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
} from "react-native";
import { EditIcon, GraphIcon, PersonalCardIcon, Profile2UserIcon } from "../assets/icons";
import {
  AppBar,
  EmptyState,
  ScreeningAspectDiffCard,
  ScreeningRespondentCard,
  ScreeningStatisticCard,
  ScreeningUserDiffResultCard,
} from "../components";
import { userDifferent } from "../components/ScreeningReport/dummyScreeningReport";
import {
  getAspectComparison,
  getQuizReport,
  getQuizReportByRespondence,
  getQuota,
} from "../services/screening";
import { useEffect, useState } from "react";
import { getProgramId } from "../services/programs";

export const screeningType = {
  respondent: "respondent",
  userAspect: "userAspect",
  diffAspect: "diffAspect",
};

const ScreeningReport = ({ route }) => {
  const navigation = useNavigation();
  const { params } = route;
  const { additionalContentId, quizId } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [packageData, setPackageData] = useState(true);
  const [memberResult, setMemberResult] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [aspectDifferent, setAspectDifferent] = useState([]);
  const [quota, setQuota] = useState(0);

  async function getMemberResult() {
    const [res, userRes, resAspect, packageRes, quotaRes] = await Promise.all([
      getQuizReport(quizId, additionalContentId),
      getQuizReportByRespondence(quizId, additionalContentId),
      getAspectComparison(quizId, additionalContentId),
      getProgramId(additionalContentId),
      getQuota(additionalContentId, quizId)
    ]);

    setUserResult(userRes.data);
    setMemberResult(res.data);
    setAspectDifferent(resAspect.data);
    setPackageData(packageRes.data);
    setQuota(quotaRes.data.quota ?? 1);
    setIsLoading(false);
  }

  useEffect(() => {
    getMemberResult();
  }, []);

  const handleWatchRespondent = (title, data) => {
    navigation.navigate("ScreeningReportDetail", {
      kind: screeningType.respondent,
      title: `Responden ${title}`,
      data,
    });
  };

  return (
    <>
      <AppBar title="Laporan Screening" isBack />
      {isLoading && (
        // <View
        //   style={{
        //     flex: 1,
        //     alignItems: "center",
        //     justifyContent: "center",
        //     backgroundColor: "#EEEAFF",
        //   }}
        // >
        <ScrollView contentContainerStyle={styles.container}>
          {/* <ActivityIndicator color="#6724E2" /> */}
          <EmptyState
            Icon={EditIcon}
            title={"Laporan Screening Belum Tersedia"}
            desc={
              "Laporan screening akan muncul setelah responden mengisi form screening"
            }
          />
        {/* </View> */}
        </ScrollView>
      )}
      {!isLoading && (
        <View style={{ flex: 1 }}>
          <ScrollView
            style={styles.container}
            contentContainerStyle={styles.contentContainer}
          >
            <SectionCard Icon={GraphIcon} title="Statistik">
              <ScreeningStatisticCard packageData={packageData} quota={quota} memberResult={[...memberResult, ...userResult]} />
            </SectionCard>
            {
              userResult?.length > 0 &&
              <SectionCard
                Icon={PersonalCardIcon}
                title="Hasil Screening Petugas"
              >
                <ScreeningRespondentCard
                  data={userResult}
                  keyGroup="recId"
                  onWatchRespondent={() =>
                    handleWatchRespondent("Petugas", userResult)
                  }
                />
              </SectionCard>
            }
            {
              memberResult?.length > 0 &&
              <SectionCard
                Icon={PersonalCardIcon}
                title="Hasil Screening Pengguna"
              >
                <ScreeningRespondentCard
                  data={memberResult}
                  keyGroup="recId"
                  onWatchRespondent={() =>
                    handleWatchRespondent("Pengguna", memberResult)
                  }
                />
              </SectionCard>
            }
            {
              aspectDifferent.length > 0 && 
              <SectionCard Icon={Profile2UserIcon} title="Pengguna Beda Hasil">
                <ScreeningUserDiffResultCard
                  data={aspectDifferent}
                  onWatchAllUser={() => {
                    navigation.navigate("ScreeningReportDetail", {
            data: aspectDifferent,
                      kind: screeningType.userAspect,
                      title: "Pengguna Beda Aspek",
                    });
                  }}
                />
              </SectionCard>
            }
            {aspectDifferent.length > 0 && (
              <SectionCard Icon={Profile2UserIcon} title="Aspek yang Berbeda">
                <ScreeningAspectDiffCard
                  data={aspectDifferent}
                  onWatchAllAspect={() => {
                    navigation.navigate("ScreeningReportDetail", {
					  data: aspectDifferent, 	
                      kind: screeningType.diffAspect,
                      title: "Aspek yang Berbeda",
                    });
                  }}
                />
              </SectionCard>
            )}
            <View style={{ height: 1 }} />
          </ScrollView>
        </View>
      )}
    </>
  );
};

function SectionCard({ Icon, title, children }) {
  return (
    <View style={styles.section}>
      <View style={[styles.flexRow, { gap: 6 }]}>
        <Icon width={18} height={18} fill="#292D32" />
        <Text style={styles.txSection}>{title}</Text>
      </View>
      {children}
    </View>
  );
}

export default ScreeningReport;

const styles = StyleSheet.create({
  // container: {
  //   backgroundColor: "#EEEAFF",
  //   padding: 16
  // },
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#EEEAFF"
  },
  contentContainer: {
    gap: 24,
  },
  section: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 12,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  txSection: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
  },
});
