import Svg, { Circle, Path } from "react-native-svg";
const ChatCircle = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <Circle cx={12} cy={12} r={12} fill="#EAEAEA" />
    <Path
      fill="#000"
      d="M12.694 8.5H8.961c-.152 0-.297.006-.437.023-1.57.135-2.357 1.062-2.357 2.771v2.333c0 2.334.933 2.795 2.794 2.795h.233c.129 0 .298.087.374.186l.7.934c.309.414.81.414 1.12 0l.7-.934a.47.47 0 0 1 .373-.186h.233c1.71 0 2.637-.782 2.771-2.357.018-.14.024-.286.024-.438v-2.333c0-1.86-.934-2.794-2.795-2.794Zm-3.902 4.667a.581.581 0 0 1-.583-.584c0-.32.262-.583.583-.583.32 0 .583.262.583.583 0 .321-.262.584-.583.584Zm2.036 0a.581.581 0 0 1-.583-.584c0-.32.262-.583.583-.583.32 0 .583.262.583.583 0 .321-.256.584-.583.584Zm2.042 0a.581.581 0 0 1-.584-.584c0-.32.263-.583.584-.583.32 0 .583.262.583.583 0 .321-.263.584-.583.584Z"
    />
    <Path
      fill="#000"
      d="M17.822 8.96v2.334c0 1.167-.361 1.96-1.085 2.397-.175.105-.379-.035-.379-.239l.006-2.158c0-2.333-1.336-3.67-3.67-3.67l-3.552.007c-.204 0-.344-.205-.239-.38.437-.723 1.23-1.085 2.392-1.085h3.733c1.86 0 2.794.934 2.794 2.795Z"
    />
  </Svg>
);
export default ChatCircle;
