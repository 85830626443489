import React, { useEffect } from "react";
import { ScrollView, Text } from "react-native";
import { Dimensions, View } from "react-native";

function PDFReader(props) {
  const { resource, onLoad } = props;
  useEffect(() => {
    if (resource) {
      setTimeout(() => {
        onLoad();
      }, 1000);
    }
  }, [resource]);
  return (
    <ScrollView style={{ maxWidth: 400, margin: "auto" }}>
      <iframe
        src={`https://docs.google.com/gview?url=${resource}&embedded=true`}
        width={`${Dimensions.get("window").width}`}
        height={`${Dimensions.get("window").height}`}
        allow="autoplay"
        style={{ maxWidth: 400, margin: "auto" }}
      ></iframe>
      <View
        style={{
          width: 40,
          height: 40,
          position: "absolute",
          right: 11,
          top: 13,
          backgroundColor: "#FFF",
          opacity: 0,
        }}
      >
        <Text>&nbsp;</Text>
      </View>
    </ScrollView>
  );
}

export default PDFReader;
