import axios from "axios";
import {API_URL} from "@env";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import md5 from "md5";

export const setJournals = async (payload) => {
    return axios.post(API_URL + `/journals`, {
        jouId: md5(new Date().getTime()),
        memberId: await AsyncStorage.getItem("token"),
        speakerId: await AsyncStorage.getItem("792f3e7") === "speaker" ? await AsyncStorage.getItem("792f3e1") : "",
        details: JSON.stringify({msgId: payload.msgId, message: payload.message}),
        consId: payload?.consId
    });
}

export const setJournalForm = async (payload) => {
    return axios.post(API_URL + `/journals`, {
        jouId: md5(new Date().getTime()),
        memberId: await AsyncStorage.getItem("token"),
        speakerId: payload.speakerId,
        details: JSON.stringify(payload.form),
        consId: payload?.consId,
        speakerType: payload.speakerType
    });
}

export const getJournals = async (memberId, type) => {
    return axios.get(API_URL + `/journals/${memberId}/${type}`);
}

export const inputList = (t) => {
    return {
        expert: [
          {
            id: 1,
            label: t("write-journal:what-symptoms"),
            placeholder: t("write-journal:write-detail-symptoms"),
          },
          {
            id: 2,
            label: t("write-journal:what-possibility-diagnosed"),
            placeholder: t("write-journal:write-diagnosis"),
          },
          {
            id: 3,
            label: t("write-journal:give-advice"),
            placeholder: t("write-journal:write-advice"),
          },
        ],
        figure: [
          {
            id: 1,
            label: t("write-journal:what-problem"),
            placeholder: t("write-journal:write-problem")
          },
          {
            id: 2,
            label: t("write-journal:how-feeling"),
            placeholder: t("write-journal:write-feeling"),
          },
          {
            id: 3,
            label: t("write-journal:advice-based"),
            placeholder: t("write-journal:give-your-advice"),
          },
        ],
        partner: [
          {
            id: 1,
            label: t("write-journal:what-problem"),
            placeholder: t("write-journal:write-problem"),
          },
          {
            id: 2,
            label: t("write-journal:how-feeling"),
            placeholder: t("write-journal:write-feeling"),
          },
          {
            id: 3,
            label: t("write-journal:supports"),
            placeholder: t("write-journal:write-support"),
          },
        ],
      };
}