import React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import Modal from "react-native-modal";
import { ChatIcon, SendIcon } from "../assets/icons";
import { Text } from "react-native";
import PrimaryButton from "./PrimaryButton";

function ModalComment(props) {
  const { visible, onClose, onSendComment, value, setValue, isOnSend } = props;
  return (
    <Modal isVisible={visible} onBackdropPress={onClose}>
      <View>
        <View style={styles.headers}>
          <View style={styles.flexRow}>
            <ChatIcon customColor="#fff" />
            <Text style={styles.txTitle}>Beri Komentar</Text>
          </View>
          <View style={{width: '22%'}}>
            <PrimaryButton
              disabled={value === "" || isOnSend}
              onPress={onSendComment}
              style={{ marginBottom: 0, alignSelf:'center' }}
              icon={<SendIcon customColor="#fff" small />}
              title="Kirim"
            />
          </View>
        </View>
        <View style={styles.parentContent}>
          <TextInput
            style={styles.input}
            multiline={true}
            numberOfLines={8}
            placeholder="Tulis komentar kamu di sini ..."
            value={value}
            onChangeText={(text) => setValue(text)}
          />
        </View>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  headers: {
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 9,
    backgroundColor: "#2B0572",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  txTitle: {
    color: "#FFF",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 12,
    marginLeft: 6,
  },
  parentContent: {
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 16,
    paddingHorizontal: 12,
  },
  input: {
    width: "100%",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
	textAlignVertical: "top",
    lineHeight: 12,
  },
});

export default ModalComment;
