import React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const MessengerFillIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M4.275 13.8V16.5L6.75 15.075C7.425 15.3 8.175 15.375 9 15.375C13.125 15.375 16.5 12.3 16.5 8.4C16.5 4.575 13.125 1.5 9 1.5C4.875 1.5 1.5 4.575 1.5 8.475C1.5 10.65 2.55 12.525 4.275 13.8Z"
      fill={props && props.fill ? props.fill : "#6724E2"}
    />
    <Circle cx={9.00013} cy={8.18177} r={1.63636} fill="white" />
  </Svg>
);

export default MessengerFillIcon;
