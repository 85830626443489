import React from "react";
import { Image, StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Pen, UserIcon } from "../assets/icons";

function ProfileInformation(props) {
  const { profile, t } = props;
  const name = profile?.name?.split(" ");
  let initial = "";
  if (name?.length === 1) {
    initial = name?.[0]?.[0] + name?.[0]?.[1];
  } else {
    initial = name?.[0]?.[0] + name?.[1]?.[0];
  }
  const background = {
    "pengguna": "#999",
    "expert": "#4EB826",
    "partner": "#000",
    "figure": "#6724E2"
  };
  const profileType = {
    "pengguna": "Pengguna",
    "expert": "Ahli",
    "partner": "Sahabat",
    "figure": "Tokoh"
  };
  const chosenBackground =  background[profile.speakerType ? profile?.speakerType?.toLowerCase() : "pengguna"];
  return (
    <View style={styles.parentCard}>
      {/* <Image
        source={{ uri: "https://via.placeholder.com/60" }}
        height={60}
        width={60}
        style={styles.avatar}
      /> */}
      <View style={{
        width: 60, 
        height: 60, 
        borderWidth: 0, 
        backgroundColor: "#EAEAEA",
        borderRadius: 30,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Text style={{
          fontSize: 30,
        }}> 
          {
            initial
          }
        </Text>
      </View>
      {/* <View style={styles.parentPen}>
        <TouchableOpacity style={styles.buttonPen}>
          <Pen />
        </TouchableOpacity>
      </View> */}
      <Text style={styles.textName}>
        {profile?.name}
      </Text>
      <Text style={styles.textPhone}>
        {
          profile?.phoneNumber
        }
      </Text>
      <View style={{...styles.viewLabel, backgroundColor: chosenBackground}}>
        <View style={{width: 20, height: 20}}>
          <UserIcon />
        </View>
        <Text style={{...styles.textLabel}}>
          {
            profile.speakerType ? t("speaker-type:"+profile.speakerType.toLowerCase()) : t("speaker-type:user")
          }
        </Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentCard: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    height: 60,
    width: 60,
    borderRadius: 60 / 2,
  },
  buttonPen: {
    backgroundColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
  },
  parentPen: {
    width: 60,
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: -16,
  },
  textName: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    textAlign: "center",
    color: "#000000",
    marginTop: 16,
  },
  textPhone: {
    marginTop: 4,
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  viewLabel: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#999999",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    paddingTop: 8,
    paddingRight: 12,
    paddingBottom: 8,
    paddingLeft: 12,
    marginTop: 12,
  },
  textLabel: {
    marginLeft: 6,
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    textTransform: "capitalize"
  },
});

export default ProfileInformation;
