import React, { useState } from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import { Calendar, ChatIcon, Heart2Icon, Message1Icon } from "../assets/icons";
import moment from "moment";

function BoxDiscussion(props) {
  const { setOpenComment, onGiveSupport, data, alreadyLike } = props;
  const [like, setLike] = useState(alreadyLike);
  const [totalLike, setTotalLike] = useState(data?.totalLike);
  return (
    <View style={StyleSheet.parentBox}>
      <View style={styles.topSection}>
        <Text style={styles.txTitle}>{data?.title}</Text>
        <Text style={styles.txContent}>{data?.question}</Text>
        <View style={styles.flexRow}>
          <View style={{ ...styles.flexRow, marginRight: 12 }}>
            <Message1Icon />
            <Text style={styles.txSpan}>{data?.totalResponses} Obrolan</Text>
          </View>
          <View style={{ ...styles.flexRow, marginRight: 12 }}>
            <Heart2Icon />
            <Text style={styles.txSpan}>{totalLike} Dukungan</Text>
          </View>
          <View style={{ ...styles.flexRow, marginRight: 12 }}>
            <Calendar customColor="#999" />
            <Text style={styles.txSpan}>
              {moment(data?.created).format("DD MMM YYYY, HH:mm")}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.bottomSection}>
        <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
          <Pressable onPress={() => {
            if (!like) {
              setTotalLike(parseInt(totalLike) * 1 + 1);
            } else {
              setTotalLike(parseInt(totalLike) * 1 - 1);
            }
            onGiveSupport();
            setLike(!like);
          }} style={styles.btnAction}>
            <Heart2Icon customColor={like ? "red" : "#000"} />
            <Text style={styles.txBtn}>Beri Dukungan</Text>
          </Pressable>
          <Pressable
            onPress={() => {
              setOpenComment(true);
            }}
            style={styles.btnAction}
          >
            <ChatIcon isActive />
            <Text style={{...styles.txBtn, color: "#999999"}}>Beri Komentar</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  parentBox: {
    backgroundColor: "#FFF",
  },
  topSection: {
    padding: 16,
    borderBottomColor: "#EAEAEA",
    borderBottomWidth: 1,
    borderStyle: "solid",
    backgroundColor: "#FFF",
  },
  bottomSection: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    padding: 16,
    backgroundColor: "#FFF",
  },
  txTitle: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 20,
    marginBottom: 10,
  },
  txContent: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 20,
    marginBottom: 10,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  txSpan: {
    color: "#999",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 12,
    marginLeft: 6,
  },
  btnAction: {
    width: "47.5%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 9,
    borderWidth: 1,
    borderColor: "#eaeaea",
    borderStyle: "solid",
    borderRadius: 10,
  },
  txBtn: {
    color: "#000",
    marginLeft: 6,
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 12,
  },
});

export default BoxDiscussion;
