import Svg, { Circle, Path } from "react-native-svg";
const ClockCircle = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
    <Circle cx={15} cy={15} r={15} fill="#EAEAEA" />
    <Path
      fill="#000"
      d="M15 7.5c-4.133 0-7.5 3.367-7.5 7.5s3.367 7.5 7.5 7.5 7.5-3.367 7.5-7.5-3.367-7.5-7.5-7.5Zm3.262 10.177a.56.56 0 0 1-.772.195l-2.325-1.387c-.577-.345-1.005-1.102-1.005-1.77V11.64c0-.308.255-.563.563-.563.307 0 .562.255.562.563v3.075c0 .27.225.668.457.803l2.325 1.387c.27.157.36.502.195.772Z"
    />
  </Svg>
);
export default ClockCircle;
