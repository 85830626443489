import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { MessengerFillIcon } from "../assets/icons";
import EmptyState from "./EmptyState";

function ModalQuotaOver(props) {
  const { visible, onClose, onContinue } = props;

  return (
    <Modal isVisible={visible} onBackdropPress={onClose}>
      <View style={styles.parentBox}>
        <EmptyState
          Icon={MessengerFillIcon}
          title="Kuota Konsultasi Gratis Habis"
          desc="Kamu masih bisa melakukan konsultasi dengan melakukan pembayaran mandiri"
        />
        <View style={styles.btnBox}>
          <TouchableOpacity
            onPress={onClose}
            style={[
              styles.button,
              {
                backgroundColor: "none",
                borderWidth: 1,
                borderColor: "#eaeaea",
              },
            ]}
          >
            <Text style={[styles.txBtn, { color: "#000" }]}>Batal</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onContinue} style={styles.button}>
            <Text style={styles.txBtn}>Lanjut Konsultasi</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingTop: 24,
    paddingBottom: 16,
  },
  btnBox: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 12,
    marginTop: 24,
  },
  button: {
    height: 36,
    backgroundColor: "#6724E2",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  txBtn: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFF",
  },
});

export default ModalQuotaOver;
