import * as React from "react";
import Svg, { Path } from "react-native-svg";
const Paper2Icon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.50706 14.9682C9.71311 15.0162 9.73199 15.286 9.53129 15.3529L8.34629 15.7429C5.36879 16.7029 3.80129 15.9004 2.83379 12.9229L1.87379 9.96043C0.913791 6.98293 1.70879 5.40793 4.68629 4.44793L4.82529 4.4019C5.22815 4.26848 5.62608 4.67471 5.52042 5.08573C5.50215 5.15681 5.48413 5.22921 5.46629 5.30293L4.73129 8.44543C3.90629 11.9779 5.11379 13.9279 8.64629 14.7679L9.50706 14.9682Z"
      fill="#292D32"
    />
    <Path
      d="M12.8776 2.40738L11.6251 2.11488C9.12013 1.52238 7.62763 2.00988 6.75013 3.82488C6.52513 4.28238 6.34513 4.83738 6.19513 5.47488L5.46013 8.61738C4.72513 11.7524 5.69263 13.2974 8.82014 14.0399L10.0801 14.3399C10.5151 14.4449 10.9201 14.5124 11.2951 14.5424C13.6351 14.7674 14.8801 13.6724 15.5101 10.9649L16.2451 7.82988C16.9801 4.69488 16.0201 3.14238 12.8776 2.40738ZM11.4676 9.99738C11.4001 10.2524 11.1751 10.4174 10.9201 10.4174C10.8751 10.4174 10.8301 10.4099 10.7776 10.4024L8.59513 9.84738C8.29513 9.77238 8.11513 9.46488 8.19013 9.16488C8.26513 8.86488 8.57263 8.68488 8.87263 8.75988L11.0551 9.31488C11.3626 9.38988 11.5426 9.69738 11.4676 9.99738ZM13.6651 7.46238C13.5976 7.71738 13.3726 7.88238 13.1176 7.88238C13.0726 7.88238 13.0276 7.87488 12.9751 7.86738L9.33763 6.94488C9.03763 6.86988 8.85763 6.56238 8.93263 6.26238C9.00763 5.96238 9.31513 5.78238 9.61513 5.85738L13.2526 6.77988C13.5601 6.84738 13.7401 7.15488 13.6651 7.46238Z"
      fill="#292D32"
    />
  </Svg>
);
export default Paper2Icon;
