import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import {
  EditIcon,
  MessageQuestionIcon,
  TickSquareIcon,
  WaIcon
} from "../../assets/icons";
import { TipsList } from "../PopupFactory/TipsContent";

const ScreeningTutorialPopup = ({ visible, onCancel, onStart }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  return (
    <Modal isVisible={visible} onBackdropPress={onCancel}>
      <View style={styles.container}>
        <View style={styles.top}>
          <View style={styles.tipsLabel}>
            <Text style={styles.txTips}>Cara Membandingkan Screening</Text>
          </View>
          <Text style={styles.txTitle}>
            Baca Informasi Berikut untuk Menghubungkan Hasil Screening
          </Text>
        </View>

        <View style={styles.lists}>
          <TipsList
            Icon={MessageQuestionIcon}
            title={"Apa itu membandingkan hasil screening?"}
            desc={
              "Hasil screening yg sudah kamu isi akan dibandingkan dengan hasil screening pengguna lain yg juga mengisi screening yg sama"
            }
          />
          <TipsList
            Icon={WaIcon}
            title={"Gunakan Nomor WhatsApp"}
            desc={
              "Kamu perlu nomor WhatsApp pengguna lain untuk membandingkan hasil screening"
            }
          />
          <TipsList
            Icon={EditIcon}
            title={"Cari Pengguna dengan Nomor WhatsApp"}
            desc={
              "Klik tombol Mulai di bawah ini untuk mencari pengguna dengan nomor WhatsApp"
            }
          />
        </View>
        <View style={styles.bottom}>
          <View style={styles.dontShow}>
            <TouchableOpacity
              onPress={async () => {
                setDontShowAgain(!dontShowAgain);
                await AsyncStorage.setItem("dontshow", "true");
              }}
            >
              <TickSquareIcon color={dontShowAgain ? "#6724E2" : "#999"} />
            </TouchableOpacity>
            <Text style={styles.txTipsDesc}>{"Jangan tampilkan lagi"}</Text>
          </View>
          <View style={styles.buttons}>
            <TouchableOpacity
              style={[styles.button, styles.cancelBtn]}
              onPress={onCancel}
            >
              <Text style={styles.txButton}>Batal</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.button, styles.startBtn]}
              onPress={onStart}
            >
              <Text style={[styles.txButton, { color: "#fff" }]}>Mulai</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 10
  },
  top: {
    padding: 16,
    alignItems: "center",
    rowGap: 12
  },
  tipsLabel: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 20,
    backgroundColor: "#4EB826"
  },
  txTips: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#fff"
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 15,
    textAlign: "center",
    maxWidth: 250
  },
  lists: {
    paddingVertical: 16,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderBottomColor: "#eaeaea",
    borderTopColor: "#eaeaea",
    rowGap: 24
  },
  tips: {
    flexDirection: "row",
    alignItems: "flex-start",
    columnGap: 12
  },
  tipsIcon: {
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: "#E2DCFC",
    padding: 8
  },
  txTipsTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#6724E2",
    marginBottom: 4
  },
  txTipsDesc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    maxWidth: 260
  },
  bottom: {
    gap: 12,
    padding: 12
  },
  dontShow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12
  },
  button: {
    flex: 1,
    padding: 12,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  txButton: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12
  },
  cancelBtn: {
    borderWidth: 1,
    borderColor: "#eaeaea",
    backgroundColor: "#fff"
  },
  startBtn: {
    backgroundColor: "#6724E2"
  }
});

export default ScreeningTutorialPopup;
