import React, { Fragment, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import ListIntelligences from "./ListIntelligences";
import ListDetail from "./ListDetail";
import TreeIcon from "../../../assets/icons/report-test/TreeIcon";
import MusicIcon from "../../../assets/icons/report-test/MusicIcon";
import StarIcon from "../../../assets/icons/report-test/StarIcon";
import MessageIcon from "../../../assets/icons/report-test/MessageIcon";
import MathIcon from "../../../assets/icons/report-test/MathIcon";
import GlobalSearchIcon from "../../../assets/icons/report-test/GlobeIconSearchIcon";
import PeoplesIcon from "../../../assets/icons/report-test/PeoplesIcon";
import ProfileIcon from "../../../assets/icons/report-test/ProfileIcon";

function MultipleIntelligences(props) {
  const {descriptions} = props;
  const [filteredList, setFilteredList] = useState([]);

  const lists = [
    {
      id: 1,
      key: "kecerdasan naturalis",
      title: "Naturalis",
      description:
        descriptions['kecerdasan naturalis'],
      icon: <TreeIcon />,
      color: "#3E951D",
      secondColor: "#DCF4CA",
      thirdColor: "#F1FBEA",
      image: require("../../../assets/images/naturalis-bg.png"),
    },
    {
      id: 2,
      key: "kecerdasan musikal",
      title: "Musikal",
      icon: <MusicIcon />,
      description: descriptions['kecerdasan musikal'],
      color: "#834FE3",
      secondColor: "#ECE4FB",
      thirdColor: "#F5F0FF",
      image: require("../../../assets/images/musikal-bg.png"),
    },
    {
      id: 3,
      key: "kecerdasan kinestetik",
      title: "Kinestetik",
      description: descriptions['kecerdasan kinestetik'],
      icon: <StarIcon />,
      color: "#305C92",
      secondColor: "#D1DFF0",
      thirdColor: "#ECF2F9",
      image: require("../../../assets/images/kinestetik-bg.png"),
    },
    {
      id: 4,
      key: "kecerdasan verbal",
      title: "Verbal",
      description: descriptions['kecerdasan verbal'],
      icon: <MessageIcon />,
      color: "#034932",
      secondColor: "#A6D9C8",
      thirdColor: "#CFFCED",
      image: require("../../../assets/images/verbal-bg.png"),
    },
    {
      id: 5,
      key: "kecerdasan logika",
      title: "Logika - Matematika",
      description: descriptions['kecerdasan logika'],
      icon: <MathIcon />,
      color: "#FD681C",
      secondColor: "#FEDECD",
      thirdColor: "#FFEEE5",
      image: require("../../../assets/images/logika-bg.png"),
    },
    {
      id: 6,
      key: "kecerdasan spasial",
      title: "Spasial",
      description: descriptions['kecerdasan spasial'],
      icon: <GlobalSearchIcon />,
      color: "#AE1919",
      secondColor: "#F9D2D2",
      thirdColor: "#FCE9E9",
      image: require("../../../assets/images/spasial-bg.png"),
    },
    {
      id: 7,
      key: "kecerdasan interpersonal",
      title: "Interpersonal",
      description: descriptions['kecerdasan interpersonal'],
      icon: <PeoplesIcon />,
      color: "#00314D",
      secondColor: "#B2E3FF",
      thirdColor: "#CCECFF",
      image: require("../../../assets/images/interpersonal-bg.png"),
    },
    {
      id: 8,
      key: "kecerdasan intrapersonal",
      title: "Intrapersonal",
      description: descriptions['kecerdasan intrapersonal'],
      icon: <ProfileIcon />,
      color: "#665600",
      secondColor: "#FFEB80",
      thirdColor: "#FFF7CC",
      image: require("../../../assets/images/intrapersonal-bg.png"),
    },
  ];

  useEffect(() => {
    if (!descriptions) {
      return;
    }

    const filteredDescriptions = lists?.filter((d) => {
      return (Object.keys(descriptions).indexOf(d.key) > -1)
    });

    setFilteredList(filteredDescriptions);
  }, [descriptions]);

  return (
    <Fragment>
      <View style={styles.parentRow}>
        {
          filteredList.length > 0 &&
          filteredList.map((item, index) => {
            return <ListIntelligences item={item} key={index} index={index}/>
          })
        }
      </View>
      <View
        style={{
          marginTop: 32,
        }}
      >
        {filteredList.map((item, index) => (
          <ListDetail
            item={{...item, title: `Kecerdasan ${item?.title}`}}
            key={index + 1}
          />
        ))}
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  parentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 24,
    borderBottomColor: "#D9DADC",
    borderBottomWidth: 1,
    borderStyle: "solid",
  },
});
export default MultipleIntelligences;
