import React from "react";
import { Image, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export const Download = ({ layout }) => {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("title")}>Download Fammi App Now!</Text>
      <Text style={styles("subTitle")}>
        Let’s join our journey to make a lasting impact for generations
      </Text>
      <View style={styles("store")}>
        <Image
          source={require("../../assets/images/play-store.png")}
          alt="play-store"
          style={styles("playStore")}
        />
        <Image
          source={require("../../assets/images/app-store.png")}
          alt="app-store"
          style={styles("appStore")}
        />
      </View>
    </View>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      borderRadius: 40,
      paddingVertical: 50,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FDC52E",
    },
    title: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: 40,
      lineHeight: 50,
      marginBottom: 10,
      textAlign: "center",
    },
    subTitle: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 28,
      marginBottom: 30,
      textAlign: "center",
    },
    store: {
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    },
    playStore: { width: 169, height: 50 },
    appStore: { width: 175, height: 50 },
  },
  {
    container: {
      paddingVertical: 100,
      paddingHorizontal: 20,
    },
    title: {
      fontSize: 20,
      lineHeight: 40,
    },
    subTitle: {
      fontSize: 16,
      lineHeight: 24,
      maxWidth: 312,
    },
    store: { gap: 10 },
    playStore: { width: 135, height: 40 },
    appStore: { width: 140, height: 40 },
  }
);
