import * as React from "react";
import Svg, { Path } from "react-native-svg";

const QuoteDownIcon = (props) => (
  <Svg
    width={48}
    height={49}
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M31.8203 25.2409H41.2003C41.0403 34.5809 39.2003 36.1209 33.4603 39.5209C32.8003 39.9209 32.5803 40.7609 32.9803 41.4409C33.3803 42.1009 34.2203 42.3209 34.9003 41.9209C41.6603 37.9209 44.0203 35.4809 44.0203 23.8409V13.0609C44.0203 9.64086 41.2403 6.88086 37.8403 6.88086H31.8403C28.3203 6.88086 25.6603 9.54086 25.6603 13.0609V19.0609C25.6403 22.5809 28.3003 25.2409 31.8203 25.2409Z"
      fill="#FDC52E"
    />
    <Path
      d="M10.18 25.2409H19.56C19.4 34.5809 17.56 36.1209 11.82 39.5209C11.16 39.9209 10.94 40.7609 11.34 41.4409C11.74 42.1009 12.58 42.3209 13.26 41.9209C20.02 37.9209 22.38 35.4809 22.38 23.8409V13.0609C22.38 9.64086 19.6 6.88086 16.2 6.88086H10.2C6.66 6.88086 4 9.54086 4 13.0609V19.0609C4 22.5809 6.66 25.2409 10.18 25.2409Z"
      fill="#FDC52E"
    />
  </Svg>
);
export default QuoteDownIcon;
