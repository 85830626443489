import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { PlayCircleIcon } from "../../assets/icons";

export function ProgramAntiBullyingDetailVideo({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <View style={styles("header")}>
        <Text style={styles("txTitle")}>
          Mengapa Sekolah Anda Memerlukan Program Ini?
        </Text>
        <Text style={styles("txDesc")}>
          Tonton Videonya untuk Informasi Detail Tentang Program Ini
        </Text>
      </View>
      <View style={styles("content")}>
        <ImageBackground
          source={require("../../assets/images/anti-bullying/video-thumbnail.png")}
          style={styles("image")}
          imageStyle={styles("imageStyle")}
        >
          <TouchableOpacity>
            <PlayCircleIcon />
          </TouchableOpacity>
        </ImageBackground>
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    header: { gap: 16 },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 28,
      lineHeight: 34,
      color: "#0A0316",
      textAlign: "center",
    },
    content: {
      width: "100%",
      height: 552,
      borderRadius: 40,
    },
    image: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageStyle: {
      width: 1200,
      height: 552,
      borderRadius: 40,
    },
  },
  {
    container: { gap: 32 },
    header: { gap: 12 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 18,
      lineHeight: 34,
    },
    content: {},
  }
);
