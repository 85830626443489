import Svg, { Path } from "react-native-svg";
const ChevronLeftIcon = (props) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.74 18.9158C13.5665 18.9158 13.393 18.8519 13.2561 18.715L7.30302 12.7619C6.33519 11.7941 6.33519 10.2054 7.30302 9.23757L13.2561 3.28452C13.5208 3.01974 13.9591 3.01974 14.2239 3.28452C14.4887 3.54931 14.4887 3.98757 14.2239 4.25235L8.27084 10.2054C7.83258 10.6437 7.83258 11.3558 8.27084 11.7941L14.2239 17.7471C14.4887 18.0119 14.4887 18.4502 14.2239 18.715C14.0869 18.8428 13.9135 18.9158 13.74 18.9158Z"
      fill="#6323DA"
    />
  </Svg>
);
export default ChevronLeftIcon;
