import { Fragment, useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import { AppBar, SurveyResultCard } from "../components";
import { getSurveyStatistic } from "../services/survey";
import { BackHandler } from "react-native";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

const SurveyResultPage = ({ route }) => {
  const { params } = route;
  const navigation = useNavigation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [memberId, setMemberId] = useState(undefined);

  const handleBackButtonClick = async () => {
    if (params?.back) {
      navigation.navigate(params?.back);
      return true;
    }

    if (params?.fromAgenda === 1) {
      navigation.navigate("AgendaProgram", {
        additionalContentId: params?.additionalContentId,
        isScreening: params?.isScreening === "1" ? "1": "0",
        refresh: new Date().getTime(),
        memberId: memberId
      });  
      return true;
    }

    navigation.navigate("Survey", {
      additionalContentId: params?.additionalContentId,
      isScreening: params?.isScreening === "1" ? "1": "0",
    });

    return true;
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      handleBackButtonClick,
    );

    return () => backHandler.remove();
  }, [memberId]);

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMemberId(memberId);
      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    getSurveyStatistic(params?.quizId)
      .then((res) => {
        const resData = res.data
          .sort((a, b) => a.orderNo - b.orderNo)
          .map((item) => {
            const answerGrouped = item.answerGrouped.split("::");
            const totalPerAnswer = item.totalPerAnsweredGrouped.split(",");
            return {
              ...item,
              answerGrouped,
              options: JSON.parse(item.detail).options?.map((opt) => ({
                label: opt,
                total: answerGrouped.includes(opt)
                  ? Number(
                      totalPerAnswer[answerGrouped.findIndex((a) => a === opt)]
                    )
                  : 0,
              })),
            };
          });

        setData(resData);
      })
      .finally(() => setIsLoading(false));
  }, [params]);

  return (
    <Fragment>
      <AppBar title="Hasil Survey" isBack jump={ params?.fromAgenda === 1 ? "AgendaProgram" : params?.back ? params?.back : "Survey"} params={{additionalContentId: params?.additionalContentId, memberId: memberId, isScreening: params?.isScreening === "1" ? "1": "0", refresh: new Date().getTime()}}  />
      <View
        style={{
          flex: 1,
          marginTop: 1,
          height: "100%",
          backgroundColor: "#fff",
        }}
      >
        {isLoading ? (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator color="#6724E2" />
          </View>
        ) : (
          <ScrollView contentContainerStyle={{ gap: 16, padding: 16 }}>
            {data.map((item) => (
              <SurveyResultCard key={item.rowId} data={item} />
            ))}
          </ScrollView>
        )}
      </View>
    </Fragment>
  );
};

export default SurveyResultPage;
