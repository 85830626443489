import Svg, { Path } from "react-native-svg";
const PeoplesIcon = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#999"
      d="M6.75 1.5a3.566 3.566 0 0 0-3.563 3.563c0 1.927 1.508 3.487 3.473 3.554a.605.605 0 0 1 .165 0h.052a3.553 3.553 0 0 0 3.436-3.555A3.566 3.566 0 0 0 6.75 1.5Zm3.81 9.112c-2.093-1.395-5.505-1.395-7.613 0-.952.637-1.477 1.5-1.477 2.422 0 .923.525 1.778 1.47 2.408 1.05.705 2.43 1.057 3.81 1.057s2.76-.352 3.81-1.057c.945-.638 1.47-1.493 1.47-2.423-.007-.922-.525-1.777-1.47-2.407Zm4.432-5.109a2.684 2.684 0 0 1-2.347 2.903h-.037c-.046 0-.09 0-.128.015a2.69 2.69 0 0 1-1.898-.622 3.432 3.432 0 0 0 1.125-2.85 3.481 3.481 0 0 0-.577-1.635 2.694 2.694 0 0 1 3.862 2.19Z"
    />
    <Path
      fill="#999"
      d="M16.491 12.442c-.06.728-.525 1.358-1.305 1.785-.75.413-1.695.608-2.633.585.54-.487.855-1.095.915-1.74.075-.93-.367-1.822-1.252-2.535a6.191 6.191 0 0 0-1.725-.945c1.658-.48 3.742-.157 5.025.878.69.555 1.042 1.252.975 1.972Z"
    />
  </Svg>
);
export default PeoplesIcon;
