import Svg, { Circle, Path } from "react-native-svg";
const LikeShapesIcon = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <Circle cx={32} cy={32} r={30.5} stroke={props.color ?? "#FD8649"} strokeWidth={3} />
    <Path
      fill={props.color ?? "#FD8649"}
      d="M30.022 16.878c1.093-.934 2.882-.934 3.99 0l2.502 2.154c.475.411 1.361.744 1.995.744H41.2a3.068 3.068 0 0 1 3.056 3.056v2.691c0 .618.333 1.52.744 1.995l2.154 2.502c.934 1.093.934 2.882 0 3.99L45 36.512c-.411.475-.744 1.361-.744 1.995v2.691a3.068 3.068 0 0 1-3.056 3.056H38.51c-.618 0-1.52.333-1.995.744l-2.502 2.154c-1.093.934-2.882.934-3.99 0l-2.502-2.154c-.475-.411-1.361-.744-1.995-.744h-2.739a3.068 3.068 0 0 1-3.056-3.056v-2.707c0-.618-.332-1.504-.728-1.98l-2.137-2.517c-.919-1.092-.919-2.866 0-3.958l2.137-2.518c.396-.475.728-1.361.728-1.979v-2.707a3.068 3.068 0 0 1 3.056-3.056h2.74c.617 0 1.52-.333 1.994-.744l2.502-2.154Z"
      opacity={0.4}
    />
    <Path
      fill={props.color ?? "#FD8649"}
      d="M39.253 30.528c-.3-.427-.807-.665-1.409-.665h-3.087a.654.654 0 0 1-.523-.237.756.756 0 0 1-.158-.586l.38-2.47c.158-.729-.333-1.568-1.061-1.805-.68-.253-1.488.095-1.805.57l-3.072 4.56v-.57c0-1.108-.475-1.568-1.646-1.568h-.776c-1.172 0-1.647.46-1.647 1.568v7.568c0 1.108.475 1.568 1.647 1.568h.776c1.108 0 1.583-.428 1.63-1.441l2.328 1.789c.317.317 1.03.49 1.536.49h2.929c1.013 0 2.027-.76 2.248-1.693l1.853-5.637c.206-.507.158-1.03-.143-1.44Z"
    />
  </Svg>
);
export default LikeShapesIcon;
