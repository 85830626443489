import Svg, { Circle, Path } from "react-native-svg";
const HeartParent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <Circle cx={12} cy={12} r={12} fill={props?.circle? props?.circle : "#FFDCDC"} />
    <Path
      fill={props?.fill ? props?.fill : "red"}
      d="M14.59 6.81c-1.056 0-2 .513-2.59 1.3a3.237 3.237 0 0 0-2.59-1.3 3.251 3.251 0 0 0-3.243 3.26c0 .695.11 1.336.303 1.931.922 2.917 3.763 4.661 5.169 5.14.198.07.525.07.723 0 1.406-.479 4.247-2.223 5.168-5.14a6.229 6.229 0 0 0 .304-1.93A3.251 3.251 0 0 0 14.59 6.81Z"
    />
  </Svg>
);
export default HeartParent;
