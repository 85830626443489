import * as React from "react";
import Svg, { Path } from "react-native-svg";
const EmojiHappy2Icon = (props) => (
  <Svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      opacity={0.4}
      d="M17.1363 2H8.75629C5.11629 2 2.94629 4.17 2.94629 7.81V16.18C2.94629 19.83 5.11629 22 8.75629 22H17.1263C20.7663 22 22.9363 19.83 22.9363 16.19V7.81C22.9463 4.17 20.7763 2 17.1363 2Z"
      fill="#4EB826"
    />
    <Path
      d="M16.4464 10.13C17.4847 10.13 18.3264 9.28829 18.3264 8.25C18.3264 7.2117 17.4847 6.37 16.4464 6.37C15.4081 6.37 14.5664 7.2117 14.5664 8.25C14.5664 9.28829 15.4081 10.13 16.4464 10.13Z"
      fill="#4EB826"
    />
    <Path
      d="M9.44641 10.13C10.4847 10.13 11.3264 9.28829 11.3264 8.25C11.3264 7.2117 10.4847 6.37 9.44641 6.37C8.40811 6.37 7.56641 7.2117 7.56641 8.25C7.56641 9.28829 8.40811 10.13 9.44641 10.13Z"
      fill="#4EB826"
    />
    <Path
      d="M16.5462 12.92H9.34617C8.64617 12.92 8.07617 13.49 8.07617 14.2C8.07617 16.89 10.2662 19.08 12.9562 19.08C15.6462 19.08 17.8362 16.89 17.8362 14.2C17.8262 13.5 17.2462 12.92 16.5462 12.92Z"
      fill="#4EB826"
    />
  </Svg>
);
export default EmojiHappy2Icon;
