import * as React from "react";
import Svg, { Path } from "react-native-svg";

const MessageEditIcon = (props) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.8334 1.66602H7.16675C3.83341 1.66602 2.16675 3.33268 2.16675 6.66602V17.4993C2.16675 17.9577 2.54175 18.3327 3.00008 18.3327H13.8334C17.1667 18.3327 18.8334 16.666 18.8334 13.3327V6.66602C18.8334 3.33268 17.1667 1.66602 13.8334 1.66602Z"
      stroke="#6724E2"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M11.2581 6.53444L6.93308 10.8594C6.76641 11.0261 6.60808 11.3511 6.57475 11.5844L6.34142 13.2344C6.25808 13.8344 6.67475 14.2511 7.27475 14.1678L8.92473 13.9344C9.15806 13.9011 9.48309 13.7428 9.64975 13.5761L13.9748 9.2511C14.7164 8.50944 15.0748 7.64277 13.9748 6.54277C12.8748 5.43444 12.0081 5.78444 11.2581 6.53444Z"
      stroke="#6724E2"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.6414 7.15039C11.008 8.45872 12.033 9.49206 13.3497 9.85872"
      stroke="#6724E2"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default MessageEditIcon;
