import * as React from "react";
import Svg, { Path } from "react-native-svg";
const MessageQuestionIcon = (props) => (
  <Svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.91699 1.41797H4.08366C2.33366 1.41797 1.16699 2.58464 1.16699 4.33464V7.83463C1.16699 9.58463 2.33366 10.7513 4.08366 10.7513V11.9938C4.08366 12.4605 4.60283 12.7405 4.98783 12.478L7.58366 10.7513H9.91699C11.667 10.7513 12.8337 9.58463 12.8337 7.83463V4.33464C12.8337 2.58464 11.667 1.41797 9.91699 1.41797ZM7.00033 8.51713C6.88429 8.51713 6.77301 8.47104 6.69097 8.38899C6.60892 8.30695 6.56283 8.19567 6.56283 8.07963C6.56283 7.9636 6.60892 7.85232 6.69097 7.77028C6.77301 7.68823 6.88429 7.64213 7.00033 7.64213C7.11636 7.64213 7.22764 7.68823 7.30968 7.77028C7.39173 7.85232 7.43783 7.9636 7.43783 8.07963C7.43783 8.19567 7.39173 8.30695 7.30968 8.38899C7.22764 8.47104 7.11636 8.51713 7.00033 8.51713ZM7.73533 6.0963C7.50783 6.24797 7.43783 6.34713 7.43783 6.51047V6.63297C7.43783 6.87213 7.23949 7.07047 7.00033 7.07047C6.76116 7.07047 6.56283 6.87213 6.56283 6.63297V6.51047C6.56283 5.8338 7.05866 5.5013 7.24533 5.37297C7.46116 5.22713 7.53116 5.12797 7.53116 4.9763C7.53116 4.68463 7.29199 4.44547 7.00033 4.44547C6.70866 4.44547 6.46949 4.68463 6.46949 4.9763C6.46949 5.21547 6.27116 5.4138 6.03199 5.4138C5.79283 5.4138 5.59449 5.21547 5.59449 4.9763C5.59449 4.20047 6.22449 3.57047 7.00033 3.57047C7.77616 3.57047 8.40616 4.20047 8.40616 4.9763C8.40616 5.6413 7.91616 5.9738 7.73533 6.0963Z"
      fill={props.fill || "#000"}
    />
  </Svg>
);
export default MessageQuestionIcon;
