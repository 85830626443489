import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

const FAB = ({ icon, label, ...props }) => {
  return (
    <TouchableOpacity style={{ ...styles.fab, ...props.style }} {...props}>
      {icon}
      <Text style={styles.fabLabel}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EEEAFF",
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
});

export default FAB;
