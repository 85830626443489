import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { CloseIcon, StarFillIcon } from "../assets/icons";
import { setProgram } from "../services/programs";

function ModalAddProgram(props) {
  const {
    visible,
    code,
    setCode,
    hideDialog,
    onAddProgram,
    token,
    setLoadProgram,
    t,
  } = props;
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setCode("");
  }, []);

  const onSubmit = () => {
    setIsError(false);
    if (code === "") {
      setIsError(true);
      return;
    }
    
    setProgram(code, 13, token).then((res) => {
      if (res?.data === true) {
        hideDialog();
        setTimeout(() => {
          onAddProgram();
          setCode("");
          setLoadProgram(true);
        }, 500);
        return;
      } else {
        setIsError(true);
      }
    });
  };
  return (
    <Modal isVisible={visible} onBackdropPress={hideDialog}>
      <View style={styles.parentBox}>
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <View style={styles.flexOne}>
            <StarFillIcon width={18} height={18} color="#000" />
            <Text style={styles.txTitle}>{t("program:add-program")}</Text>
          </View>
          <TouchableOpacity onPress={hideDialog}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <TextInput
          placeholder={t("program:code")}
          style={[
            styles.inputText,
            {
              paddingTop: 10,
              paddingBottom: 10
            },
            isError && {
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#ff0000",
            },
          ]}
          placeholderTextColor="#999"
          onChangeText={(text) => {
            setCode(text);
          }}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          value={code}
        />
        <TouchableOpacity onPress={onSubmit} style={styles.button}>
          <Text style={styles.txBtn}>{t("program:send")}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
  },
  inputText: {
    width: "100%",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderRadius: 10,
    padding: 12,
    paddingBottom: 11,
    textAlign: "center",
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 12,
    maxHeight: 36
  },
  button: {
    backgroundColor: "#6724E2",
    borderRadius: 10,
    width: "100%",
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
  },
  txBtn: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFF",
    marginLeft: 6,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#000",
    marginLeft: 6,
  },
});

export default ModalAddProgram;
