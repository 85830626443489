import * as React from "react";
import Svg, { Path } from "react-native-svg";
const RightArrowIcon = (props) => (
  <Svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.207 5.68029L20.864 11.3373C21.0515 11.5248 21.1568 11.7791 21.1568 12.0443C21.1568 12.3095 21.0515 12.5638 20.864 12.7513L15.207 18.4083C15.0184 18.5904 14.7658 18.6912 14.5036 18.689C14.2414 18.6867 13.9906 18.5815 13.8052 18.3961C13.6198 18.2107 13.5146 17.9599 13.5123 17.6977C13.51 17.4355 13.6108 17.1829 13.793 16.9943L17.743 13.0443H4.5C4.23478 13.0443 3.98043 12.9389 3.79289 12.7514C3.60536 12.5639 3.5 12.3095 3.5 12.0443C3.5 11.7791 3.60536 11.5247 3.79289 11.3372C3.98043 11.1496 4.23478 11.0443 4.5 11.0443H17.743L13.793 7.09429C13.6975 7.00204 13.6213 6.8917 13.5689 6.76969C13.5165 6.64769 13.4889 6.51647 13.4877 6.38369C13.4866 6.25091 13.5119 6.11923 13.5622 5.99633C13.6125 5.87344 13.6867 5.76179 13.7806 5.66789C13.8745 5.574 13.9861 5.49975 14.109 5.44947C14.2319 5.39919 14.3636 5.37388 14.4964 5.37504C14.6292 5.37619 14.7604 5.40378 14.8824 5.45619C15.0044 5.5086 15.1148 5.58478 15.207 5.68029Z"
      fill="#FDFDFD"
    />
  </Svg>
);
export default RightArrowIcon;
