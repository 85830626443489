import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const MathIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#FF6F25"
          d="M31.037 3.83H14.976C7.999 3.83 3.84 7.99 3.84 14.966v16.042c0 6.996 4.159 11.155 11.136 11.155h16.042c6.977 0 11.136-4.159 11.136-11.135V14.966c.02-6.977-4.14-11.136-11.117-11.136Z"
          opacity={0.4}
        />
        <Path
          fill="#FF6F25"
          d="M20.129 16.883h-8.625a1.448 1.448 0 0 1-1.438-1.438c0-.786.652-1.437 1.438-1.437h8.625c.786 0 1.437.652 1.437 1.437 0 .786-.651 1.438-1.437 1.438ZM34.504 28.383h-8.625a1.448 1.448 0 0 1-1.438-1.438c0-.785.652-1.437 1.438-1.437h8.625c.786 0 1.437.652 1.437 1.437 0 .786-.651 1.438-1.437 1.438ZM34.504 35.57h-8.625a1.448 1.448 0 0 1-1.438-1.437c0-.786.652-1.438 1.438-1.438h8.625c.786 0 1.437.652 1.437 1.438 0 .786-.651 1.437-1.437 1.437ZM34.504 14.008h-2.837v-2.875c0-.786-.651-1.438-1.437-1.438s-1.438.652-1.438 1.438v2.875H25.88c-.786 0-1.438.652-1.438 1.437 0 .786.652 1.438 1.438 1.438h2.913v2.875c0 .786.652 1.437 1.438 1.437.786 0 1.437-.651 1.437-1.437v-2.875h2.837c.786 0 1.437-.652 1.437-1.438 0-.786-.651-1.437-1.437-1.437ZM17.847 30.55l3.297-3.297a1.446 1.446 0 0 0 0-2.031 1.446 1.446 0 0 0-2.032 0l-3.296 3.296-3.297-3.296a1.446 1.446 0 0 0-2.032 0 1.446 1.446 0 0 0 0 2.031l3.297 3.297-3.297 3.297a1.446 1.446 0 0 0 0 2.031c.288.288.652.422 1.016.422s.728-.134 1.016-.422l3.296-3.296 3.297 3.296c.288.288.652.422 1.016.422s.728-.134 1.016-.422a1.446 1.446 0 0 0 0-2.031l-3.297-3.297Z"
        />
      </Svg>
    </View>
  </View>
);
export default MathIcon;
