import * as React from "react";
import Svg, { Path } from "react-native-svg";
const PeopleIcon = (props) => (
  <Svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M20.9512 9.11057C20.8696 9.09891 20.7879 9.09891 20.7062 9.11057C18.8979 9.05224 17.4629 7.57057 17.4629 5.75057C17.4629 3.89557 18.9679 2.37891 20.8346 2.37891C22.6896 2.37891 24.2062 3.88391 24.2062 5.75057C24.1946 7.57057 22.7596 9.05224 20.9512 9.11057Z"
      fill="#6724E2"
    />
    <Path
      d="M24.7545 17.1954C23.4479 18.0704 21.6162 18.3971 19.9245 18.1754C20.3679 17.2187 20.6012 16.1571 20.6129 15.0371C20.6129 13.8704 20.3562 12.7621 19.8662 11.7937C21.5929 11.5604 23.4245 11.8871 24.7429 12.7621C26.5862 13.9754 26.5862 15.9704 24.7545 17.1954Z"
      fill="#6724E2"
    />
    <Path
      d="M8.01286 9.11057C8.09453 9.09891 8.1762 9.09891 8.25786 9.11057C10.0662 9.05224 11.5012 7.57057 11.5012 5.75057C11.5012 3.88391 9.9962 2.37891 8.12953 2.37891C6.27453 2.37891 4.76953 3.88391 4.76953 5.75057C4.76953 7.57057 6.20453 9.05224 8.01286 9.11057Z"
      fill="#6724E2"
    />
    <Path
      d="M8.14132 15.0373C8.14132 16.1689 8.38631 17.2423 8.82965 18.2106C7.18465 18.3856 5.46965 18.0356 4.20965 17.2073C2.36632 15.9823 2.36632 13.9873 4.20965 12.7623C5.45798 11.9223 7.21965 11.5839 8.87631 11.7706C8.39798 12.7506 8.14132 13.8589 8.14132 15.0373Z"
      fill="#6724E2"
    />
    <Path
      d="M14.6396 18.5599C14.5463 18.5483 14.4413 18.5483 14.3363 18.5599C12.1896 18.4899 10.4746 16.7283 10.4746 14.5583C10.4863 12.3416 12.2713 10.5449 14.4996 10.5449C16.7163 10.5449 18.5129 12.3416 18.5129 14.5583C18.5013 16.7283 16.7979 18.4899 14.6396 18.5599Z"
      fill="#6724E2"
    />
    <Path
      d="M10.8486 20.975C9.08693 22.1533 9.08693 24.09 10.8486 25.2567C12.8553 26.5983 16.1453 26.5983 18.1519 25.2567C19.9136 24.0783 19.9136 22.1417 18.1519 20.975C16.1569 19.6333 12.8669 19.6333 10.8486 20.975Z"
      fill="#6724E2"
    />
  </Svg>
);
export default PeopleIcon;
