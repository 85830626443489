import Svg, { Circle, Path } from "react-native-svg";
const MusicalIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <Circle cx={32} cy={32} r={30.5} stroke="#6724E2" strokeWidth={3} />
    <Path
      fill="#6724E2"
      d="M29.29 29.387v12.777c0 3.135-2.565 5.684-5.684 5.684a5.69 5.69 0 0 1-5.684-5.684c0-3.119 2.549-5.668 5.684-5.668 1.314 0 2.502.46 3.467 1.203V30.02l2.217-.633ZM46.073 24.607v14.504a5.69 5.69 0 0 1-5.684 5.684c-3.119 0-5.684-2.55-5.684-5.684 0-3.12 2.565-5.669 5.684-5.669 1.33 0 2.518.46 3.484 1.22V25.24l2.2-.634Z"
    />
    <Path
      fill="#6724E2"
      d="M46.07 21.218v3.389l-19 5.415v-6.318c0-2.327 1.41-4.132 3.642-4.734l9.152-2.502c1.852-.506 3.42-.332 4.528.523 1.124.839 1.678 2.264 1.678 4.227Z"
      opacity={0.4}
    />
  </Svg>
);
export default MusicalIcon;
