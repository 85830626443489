import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import {
  CloseCircleIcon,
  DentIcon,
  EmojiHappyIcon,
  HeartTickIcon,
  MessengerFillIcon,
} from "../assets/icons";
import Modal from "react-native-modal";

function ModalInfoQuota(props) {
  const { visible, onClose, credit } = props;

  return (
    <Modal isVisible={visible} onBackdropPress={onClose}>
      <View style={styles.parentBox}>
        <View style={styles.header}>
          <View style={styles.flexed}>
            <DentIcon customColor="#fff" />
            <Text style={styles.txInfo}>Info Kuota</Text>
          </View>
          <Pressable onPress={onClose}>
            <CloseCircleIcon />
          </Pressable>
        </View>
        <View style={{ padding: 12 }}>
          {credit.map((item, index) => (
            <View style={styles.listQuota} key={index}>
              <View style={styles.flexed}>
                <View style={styles.parentIcon}>
                  {item?.type === "11" ? (
                    <MessengerFillIcon />
                  ) : item?.type === "10" ? (
                    <EmojiHappyIcon />
                  ) : (
                    item?.type === "9" && <HeartTickIcon />
                  )}
                </View>
                <Text style={styles.txMain}>{item?.name}</Text>
              </View>
              <View style={styles.label}>
                <Text style={styles.textLabel}>{item?.total}x</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    borderRadius: 10,
    backgroundColor: "#fff",
  },
  header: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#2B0572",
    paddingTop: 12,
    paddingRight: 16,
    paddingBottom: 12,
    paddingLeft: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
  txInfo: {
    color: "#fff",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 14,
    marginLeft: 6,
  },
  parentIcon: {
    width: 30,
    height: 30,
    backgroundColor: "#EAEAEA",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30 / 2,
    marginRight: 8,
  },
  txMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 12,
  },
  label: {
    backgroundColor: "#eaeaea",
    padding: 12,
    borderRadius: 18,
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 12,
    color: "#000",
  },
  listQuota: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
    padding: 12,
    borderWidth: 1,
    borderColor: "#EAEAEA",
    borderStyle: "solid",
    borderRadius: 10,
  },
});

export default ModalInfoQuota;
