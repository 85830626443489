import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import { ChevronLeftIcon, ChevronRightIcon } from "../../assets/icons";

export function CompanyProfileCarousel({
  data,
  activeIndex,
  onNext,
  onPrevious,
  children,
  customIndex,
}) {
  const isMobile = Dimensions.get("window").width < 500;

  const renderPrevButton = (
    <TouchableOpacity
      style={[styles.sliderBtn, !data.length && { display: "none" }]}
      onPress={onPrevious}
    >
      <ChevronLeftIcon style={styles.icon} />
    </TouchableOpacity>
  );
  return (
    <View style={styles.container}>
      {children}
      <View style={styles.slider}>
        {isMobile && renderPrevButton}
        <View style={[styles.flexRow, { gap: 10 }]}>
          {data.map((item, idx) => (
            <View
              key={idx}
              style={[
                styles.indicator,
                {
                  backgroundColor:
                    activeIndex === idx || (!isMobile && idx === 1 && customIndex)
                      ? "#6323DA"
                      : "#C5B1F0",
                },
              ]}
            />
          ))}
        </View>
        <View style={[styles.flexRow, { gap: 16 }]}>
          {!isMobile && renderPrevButton}
          <TouchableOpacity
            style={[styles.sliderBtn, !data.length && { display: "none" }]}
            onPress={onNext}
          >
            <ChevronRightIcon style={styles.icon} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 16,
    maxWidth: 1440,
    overflow: "hidden",
  },
  slider: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 10,
  },
  icon: {
    width: 22,
    height: 22,
  },
  sliderBtn: {
    width: 46,
    height: 46,
    borderRadius: 40,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
});
