import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Eye2Icon = (props) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.4833 9.99896C13.4833 11.649 12.1499 12.9823 10.4999 12.9823C8.84993 12.9823 7.5166 11.649 7.5166 9.99896C7.5166 8.34896 8.84993 7.01562 10.4999 7.01562C12.1499 7.01562 13.4833 8.34896 13.4833 9.99896Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.4999 16.8913C13.4415 16.8913 16.1832 15.1579 18.0915 12.1579C18.8415 10.9829 18.8415 9.00794 18.0915 7.83294C16.1832 4.83294 13.4415 3.09961 10.4999 3.09961C7.5582 3.09961 4.81654 4.83294 2.9082 7.83294C2.1582 9.00794 2.1582 10.9829 2.9082 12.1579C4.81654 15.1579 7.5582 16.8913 10.4999 16.8913Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default Eye2Icon;
