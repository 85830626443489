import React from "react";
import { ImageBackground, Text, View, Image } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export const Mission = ({ layout }) => {
  const styles = responsiveStyles(layout);
  const image = layout.width < 500 ? "mob" : "web";
  return (
      <View style={styles("content")}>
        <Text style={styles("txMission")}>
          Fammi’s mission is to revolutionize education and mental health
          through family and school empowerment, making it accessible and
          impactful for all.
        </Text>
      </View>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      borderWidth: 1,
      borderColor: "#EAEAEA",
      borderRadius: 40,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    content: { gap: 40, paddingLeft: 40, paddingVertical: 50 },
    brands: { width: 410, height: 180 },
    txMission: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: 20,
      lineHeight: 30,
      maxWidth: 436,
    },
    image: {
      width: "100%",
      height: 440,
      maxWidth: "50%",
      borderTopRightRadius: 40,
      borderBottomRightRadius: 40,
    },
  },
  {
    container: {
      flexDirection: "column",
      gap: 60,
    },
    content: { gap: 30, paddingLeft: 20, paddingBottom: 0 },
    brands: { width: 298, height: 132 },
    txMission: {
      maxWidth: 312,
    },
    image: {
      width: "100%",
      height: 383,
      maxWidth: "100%",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 40,
      borderBottomLeftRadius: 40,
    },
  }
);
