import Svg, { Path } from "react-native-svg"
const AlertIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 18.334c4.583 0 8.333-3.75 8.333-8.334 0-4.583-3.75-8.333-8.333-8.333S2.166 5.417 2.166 10c0 4.584 3.75 8.334 8.334 8.334ZM10.5 6.667v4.167"
    />
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.495 13.333h.008"
    />
  </Svg>
)
export default AlertIcon