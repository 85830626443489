import { Image, Text, TouchableOpacity, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export function ProgramAntiBullyingBanner({ layout }) {
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  const storeImageStyle = isMobile
    ? { width: 152, height: 44 }
    : { width: 164, height: 48 };

  return (
    <View style={styles("container")}>
      <Image
        source={require(`../../assets/images/anti-bullying/banner${
          isMobile ? "-mobile" : ""
        }.png`)}
        style={styles("banner")}
      />
      <View style={styles("content")}>
        <Text style={styles("txTitle")}>
          Ayo Bergabung Bersama 200+ Sekolah Anti-Bullying yang Telah Mengikuti
          Tes ini
        </Text>
        <View style={styles("txDescWrapper")}>
          <Text style={styles("txDesc")}>
            Hanya dengan Biaya Sesuai Kemampuan Anda
          </Text>
          <Text style={styles("txDesc")}>
            Deteksi dan Atasi Bullying di Sekolah Anda Sekarang Juga!
          </Text>
        </View>
        <TouchableOpacity style={styles("button")}>
          <Text style={styles("txButton")}>Daftar Sekarang</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      borderRadius: 40,
      backgroundColor: "#FDC52E",
      flexDirection: "row",
      alignItems: "center",
      gap: 68,
      padding: 48,
    },
    banner: {
      width: 495,
      height: 364,
    },
    content: {
      flexDirection: "column",
      alignItems: "flex-start",
      maxWidth: 542,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 34,
      color: "#0A0316",
    },
    txDescWrapper: {
      marginTop: 16,
      marginBottom: 32,
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#0A0316",
    },
    button: {
      paddingVertical: 12,
      paddingHorizontal: 22,
      borderRadius: 24,
      backgroundColor: "#260C55",
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 24,
      color: "#fff",
    },
  },
  {
    container: {
      flexDirection: "column",
      alignItems: "center",
      padding: 16,
    },
    banner: {
      width: "100%",
      height: 415,
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      borderBottomLeftRadius: 250,
      borderBottomRightRadius: 250,
    },
    content: {
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "unset",
      paddingBottom: 44,
    },
    txTitle: { textAlign: "center" },
    txDescWrapper: {
      marginTop: 24,
      marginBottom: 48,
    },
    txDesc: {
      textAlign: "center",
    },
  }
);
