import * as React from "react";
import Svg, { ClipPath, Defs, G, Path, Rect } from "react-native-svg";

const BookmarkIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_320_701)">
      <Path
        d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
        stroke={props?.stroke === "none" ? "none" : "black"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.75 1.83008V9.31508C12.75 10.7926 11.6925 11.3701 10.395 10.5901L9.405 9.99758C9.18 9.86258 8.82 9.86258 8.595 9.99758L7.605 10.5901C6.3075 11.3626 5.25 10.7926 5.25 9.31508V1.83008"
        stroke={props?.stroke === "none" ? "none" : "black"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
        stroke={props?.stroke === "none" ? "none" : "black"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.75 1.83008V9.31508C12.75 10.7926 11.6925 11.3701 10.395 10.5901L9.405 9.99758C9.18 9.86258 8.82 9.86258 8.595 9.99758L7.605 10.5901C6.3075 11.3626 5.25 10.7926 5.25 9.31508V1.83008"
        stroke={props?.stroke === "none" ? "white" : "black"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_320_701">
        <Rect width={18} height={18} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default BookmarkIcon;
