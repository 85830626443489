import axios from "axios";
import { API_URL } from "@env";

export const getMaterials = (additionalContentId) => {
  return axios.get(API_URL + `/materials/${additionalContentId}`);
};

export const getMaterial = (materialId) => {
  return axios.get(API_URL + `/material/${materialId}`);
};

export const checkFeedback = (materialId, memberId) => {
  return axios.get(API_URL + `/materials-feedback/${materialId}/${memberId}`)
}

export const sendFeedbackMaterial = (payload) => {
  return axios.post(API_URL + '/materials-feedback', payload)
}