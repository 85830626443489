import Svg, { Path } from "react-native-svg";
const HeartCircle = ({ customColor, ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <Path
      fill={customColor ?? "red"}
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Zm.248 11.25a.952.952 0 0 1-.503 0c-1.17-.398-3.795-2.07-3.795-4.905A2.255 2.255 0 0 1 7.2 5.58c.735 0 1.388.353 1.8.907a2.244 2.244 0 0 1 1.8-.907 2.255 2.255 0 0 1 2.25 2.265c0 2.835-2.625 4.507-3.803 4.905Z"
    />
  </Svg>
);
export default HeartCircle;
