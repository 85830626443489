import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const FileCircleIcon = (props) => (
  <Svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={22} cy={22} r={22} fill="#D1DFF0" />
    <Path
      d="M25.9998 12.002H17.9999C14.5 12.002 13 14.0019 13 17.0019V27.0017C13 30.0017 14.5 32.0017 17.9999 32.0017H25.9998C29.4997 32.0017 30.9997 30.0017 30.9997 27.0017V17.0019C30.9997 14.0019 29.4997 12.002 25.9998 12.002ZM17.9999 22.2518H21.9998C22.4098 22.2518 22.7498 22.5918 22.7498 23.0018C22.7498 23.4118 22.4098 23.7518 21.9998 23.7518H17.9999C17.5899 23.7518 17.2499 23.4118 17.2499 23.0018C17.2499 22.5918 17.5899 22.2518 17.9999 22.2518ZM25.9998 27.7517H17.9999C17.5899 27.7517 17.2499 27.4117 17.2499 27.0017C17.2499 26.5917 17.5899 26.2517 17.9999 26.2517H25.9998C26.4098 26.2517 26.7498 26.5917 26.7498 27.0017C26.7498 27.4117 26.4098 27.7517 25.9998 27.7517ZM28.4997 19.2518H26.4998C24.9798 19.2518 23.7498 18.0219 23.7498 16.5019V14.5019C23.7498 14.0919 24.0898 13.7519 24.4998 13.7519C24.9098 13.7519 25.2498 14.0919 25.2498 14.5019V16.5019C25.2498 17.1919 25.8098 17.7519 26.4998 17.7519H28.4997C28.9097 17.7519 29.2497 18.0919 29.2497 18.5019C29.2497 18.9119 28.9097 19.2518 28.4997 19.2518Z"
      fill="#305C92"
    />
  </Svg>
);
export default FileCircleIcon;
