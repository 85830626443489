import { Fragment, useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { AppBar } from "../components";
import { useNavigation } from "@react-navigation/native";
import { getConsultation } from "../services/consultations";

function Consultation({route}) {
  const navigation = useNavigation();
  const [data, setData] = useState({});
  const {params} = route;
  useEffect(() => {
    if (!params?.consId) {
      return ;
    }
    getConsultation(params?.consId)
    .then((res) => {
      setData(res?.data[0]);
    });
  }, [params?.consId]);
  return (
    <Fragment>
      <AppBar title="Catatan Konsultasi" isBack jump={params?.jump}/>
      <ScrollView style={styles.container}>
        <View style={{
          backgroundColor: "#FFF",
          padding: 12,
          borderRadius: 10
        }}>
          <Text style={styles.subtitle}>
            Konsultasi Seputar
          </Text>
          <Text style={styles.content}>
            {
              data?.summary
            }
          </Text>
          <Text style={{...styles.subtitle, marginTop: 16}}>
            Tentang Siapa
          </Text>
          <Text style={styles.content}>
          {
            data?.profile &&
              JSON.parse(data?.profile).tentang
            }
          </Text>
          <Text style={{...styles.subtitle, marginTop: 16}}>
            Penjelasan Masalah
          </Text>
          <Text style={styles.content}>
          {
              (data?.question)
            }
          </Text>
          <Text style={{...styles.subtitle, marginTop: 16}}>
            Kategori Masalah
          </Text>
          <Text style={styles.content}>
          {
              (data?.categories)
            }
          </Text>
        </View>
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    flex: 1,
    padding: 16,
  },
  subtitle: {
    fontFamily: "Archivo-SemiBold",
    fontSize: 12
  },
  content: {
    fontFamily: "Archivo-Regular",
    fontSize: 12
  }
});
export default Consultation;
