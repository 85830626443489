import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { AddIcon, EditIcon } from "../assets/icons";
import {
  AppBar,
  EmptyState,
  FAB,
  ModalAddScreening,
  ScreeningCard,
  Snackbar
} from "../components";
import { addRespondent, getListRespondent } from "../services/screening";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Screening = (props) => {
  const { params } = props.route;
  const [data, setData] = useState([]);
  const [addScreeningModal, setAddScreeningModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [code, setCode] = useState("");
  const [refresh, setRefresh] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    async function getToken() {
      return await AsyncStorage.getItem("token");
    }
    
    getToken().then((res) => {
      setToken(res);
    });
  }, []);

  const onAddScreening = async () => {
    addRespondent({
      name: code,
      additionalContentId: params?.additionalContentId,
      quizId: params.quizId,
      memberId: token
    }).then((res) => {
      if (res.data) {
        setSnackbarMessage("Target screening berhasil ditambahkan");
        setOpenSnackBar(true);
        setRefresh(new Date().getTime());
        setCode("");
      }
    });
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    setLoading(true);
    getListRespondent(params?.additionalContentId, params.quizId, token).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [params?.additionalContentId, refresh, params?.refresh, token]);

  return (
    <React.Fragment>
      <AppBar title="Screening" isBack />
      {
        loading &&
        <ScrollView contentContainerStyle={styles.container}>
          <EmptyState
            Icon={EditIcon}
            title={"Memuat Data Responden"}
            desc={
              ""
            }
          />
        </ScrollView>
      }
      {data?.length === 0 && !loading && (
        <ScrollView contentContainerStyle={styles.container}>
          <EmptyState
            Icon={EditIcon}
            title={"Belum Ada Data Responden"}
            desc={
              "Klik tombol tambah di bawah ini untuk menambahkan data responden"
            }
          />
        </ScrollView>
      )}
      {data?.length > 0 && (
        <ScrollView
          style={{...styles.container}}
          contentContainerStyle={{ gap: 16, paddingBottom: 76 }}
        >
          {data.map((s) => (
            <ScreeningCard key={s.id} data={s} quizId={params?.quizId} />
          ))}
        </ScrollView>
      )}
      <FAB  
        icon={<AddIcon />}
        label={"Tambah Screening"}
        onPress={() => setAddScreeningModal(true)}
      />
      <ModalAddScreening
        code={code}
        setCode={setCode}
        visible={addScreeningModal}
        hideDialog={() => setAddScreeningModal(false)}
        onAdd={onAddScreening}
      />
      <Snackbar
        title={snackBarMessage}
        visible={openSnackBar}
        hideDialog={() => {
          setOpenSnackBar(false);
        }}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#EEEAFF"
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff"
  }
});

export default Screening;
