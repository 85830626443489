import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";
const DocumentTextIcon = (props) =>
  props.isCircle ? (
    <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
      <Circle cx={14} cy={14} r={14} fill="#FDFDFD" />
      <Path
        fill="#834FE3"
        d="M17 6.5h-6c-2.625 0-3.75 1.5-3.75 3.75v7.5C7.25 20 8.375 21.5 11 21.5h6c2.625 0 3.75-1.5 3.75-3.75v-7.5C20.75 8 19.625 6.5 17 6.5Zm-6 7.688h3c.307 0 .563.255.563.562a.567.567 0 0 1-.563.563h-3a.567.567 0 0 1-.563-.563c0-.307.256-.563.563-.563Zm6 4.124h-6a.567.567 0 0 1-.563-.562c0-.308.256-.563.563-.563h6c.308 0 .563.255.563.563a.567.567 0 0 1-.563.563Zm1.875-6.375h-1.5a2.061 2.061 0 0 1-2.063-2.062v-1.5c0-.307.256-.563.563-.563.308 0 .563.256.563.563v1.5c0 .518.42.938.937.938h1.5c.308 0 .563.255.563.562a.567.567 0 0 1-.563.563Z"
      />
    </Svg>
  ) : (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill={props?.fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M15.75 5.25V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V5.25C2.25 3 3.375 1.5 6 1.5H12C14.625 1.5 15.75 3 15.75 5.25Z"
        stroke={props?.fill === "black" ? "white" : "black"}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.875 3.375V4.875C10.875 5.7 11.55 6.375 12.375 6.375H13.875M6 9.75H9M6 12.75H12"
        stroke={props?.fill === "black" ? "white" : "black"}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
export default DocumentTextIcon;
