import React from "react";
import { Pressable, StyleSheet, View, Text } from "react-native";
import Fammi from "../../assets/icons/Fammi";
import DownloadIcon from "../../assets/icons/DownloadIcon";

function HeaderDownload(props) {
  const { onDownload } = props;
  return (
    <View style={styles.parent}>
      <Fammi />
      <Pressable style={styles.btnDownload} onPress={onDownload}>
        <Text style={styles.txBtn}>Unduh Dokumen</Text>
        <DownloadIcon />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  parent: {
    width: "100%",
    borderRadius: 100 / 2,
    paddingVertical: 17,
    paddingHorizontal: 48,
    backgroundColor: "#6724E2",
    maxWidth: 1200,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btnDownload: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FDC52E",
    paddingHorizontal: 22,
    paddingVertical: 12,
    borderRadius: 100 / 2,
  },
  txBtn: {
    fontFamily: "Archivo-SemiBold",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 24,
    marginRight: 12,
  },
});
export default HeaderDownload;
