export const paymentInstructions = {
    "id-lang": [
{
  "id": "gopay",
  "title": "Gopay",
  "methods": [
      {
          "id": "gopay",
          "title": "Gopay",
          "instructions": [
              "Tekan tombol Bayar di aplikasi Gojek",
              "Jika Anda sudah menginstall aplikasi Gojek, maka aplikasi akan terbuka",
              "Verifikasi rincian pembayaran dan klik Pay",
              "Masukan Security PIN dan selesaikan transaksi Anda"
          ]
      },
    ]
},
{
    "id": "shopeepay",
    "title": "ShopeePay",
    "methods": [
        {
            "id": "shopeepay",
            "title": "ShopeePay",
            "instructions": [
                "Tekan tombol Bayar di aplikasi Shopee",
                "Jika Anda sudah menginstall aplikasi Shopee, maka aplikasi akan terbuka",
                "Verifikasi rincian pembayaran dan klik Pay",
                "Masukan Security PIN dan selesaikan transaksi Anda"
            ]
        },
      ]
  },
{
  "id": "mandiri",
  "title": "Bank Mandiri",
  "methods": [
      {
          "id": "mandiri-online",
          "title": "Mandiri Online",
          "instructions": [
              "Login ke Internet Banking Mandiri (https://ibank.bankmandiri.co.id/)",
              "Pada menu utama, pilih Bayar, lalu pilih Multi Payment",
              "Pilih akun Anda di Dari Rekening, kemudian di Penyedia Jasa pilih Midtrans",
              "Masukkan Kode Pembayar an Anda dan klik Lanjutkan",
              "Konfirmasi pembayaran Anda menggunakan Mandiri Token atau aplikasi Mandiri Online"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Bayar/Beli",
              "Pilih Lainnya",
              "Pilih Multi Payment",
              "Masukkan 70012 (kode perusahaan Midtrans) lalu tekan Benar",
              "Masukkan Kode Pembayaran Anda lalu tekan Benar",
              "Pada halaman konfirmasi akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya"
          ]
      }
  ]
},
{
  "id": "bni",
  "title": "BNI",
  "methods": [
      {
          "id": "internetBanking",
          "title": "Internet Banking",
          "instructions": [
              "Ketik alamat https://ibank.bni.co.id kemudian klik Masuk",
              "Silakan masukkan User ID dan Password",
              "Klik menu Transfer kemudian pilih Tambah Rekening Favorit",
              "Masukkan nama, nomor rekening, dan email, lalu klik Lanjut",
              "Masukkan Kode Otentikasi dari token Anda dan klik Lanjut",
              "Kembali ke menu utama dan pilih Transfer lalu Transfer Antar Rekening BNI",
              "Pilih rekening yang telah Anda favoritkan sebelumnya di Rekening Tujuan lalu lanjutkan pengisian, dan tekan Lanjut",
              "Pastikan detail transaksi Anda benar, lalu masukkan Kode Otentikasi dan tekan Lanjut"
          ]
      },
      {
          "id": "mobileBanking",
          "title": "Mobile Banking",
          "instructions": [
              "Buka aplikasi BNI Mobile Banking dan login",
              "Pilih menu Transfer",
              "Pilih menu Virtual Account Billing",
              "Pilih rekening debit yang akan digunakan",
              "Pilih menu Input Baru dan masukkan 16 digit nomor Virtual Account",
              "Informasi tagihan akan muncul pada halaman validasi",
              "Jika informasi telah sesuai, masukkan Password Transaksi dan klik Lanjut",
              "Transaksi Anda akan diproses"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Menu Lainnya",
              "Pilih Transfer",
              "Pilih Rekening Tabungan",
              "Pilih Ke Rekening BNI",
              "Masukkan nomor virtual account dan pilih Tekan Jika Benar",
              "Masukkan jumlah tagihan yang akan anda bayar secara lengkap. Pembayaran dengan jumlah tidak sesuai akan otomatis ditolak",
              "Jumlah yang dibayarkan, nomor rekening dan nama Merchant akan ditampilkan. Jika informasi telah sesuai, tekan Ya",
              "Transaksi Anda sudah selesai"
          ]
      }
  ]
},
{
  "id": "bri",
  "title": "BRI",
  "methods": [
      {
          "id": "ibbri",
          "title": "IB BRI",
          "instructions": [
              "Masuk pada Internet Banking BRI",
              "Pilih menu Pembayaran & Pembelian",
              "Pilih sub menu BRIVA",
              "Masukkan Nomor BRIVA",
              "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Kirim",
              "Masukkan password dan mToken, pilih Kirim",
              "Pembayaran telah selesai. Untuk mencetak bukti transaksi, pilih Cetak"
          ]
      },
      {
          "id": "brimo",
          "title": "BRImo",
          "instructions": [
              "Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI",
              "Pilih Pembayaran, lalu pilih BRIVA",
              "Masukkan nomor BRIVA",
              "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue",
              "Masukkan Mobile Banking BRI PIN, pilih Ok",
              "Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pilih menu utama, pilih Transaksi Lain",
              "Pilih Pembayaran",
              "Pilih Lainnya",
              "Pilih BRIVA",
              "Masukkan Nomor BRIVA pelanggan dan pilih Benar",
              "Jumlah pembayaran, nomor BRIVA dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Ya",
              "Pembayaran telah selesai. Simpan bukti pembayaran Anda"
          ]
      }
  ]
},
{
  "id": "permata",
  "title": "Permata",
  "methods": [
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya",
              "Pilih Pembayaran",
              "Pilih Pembayaran Lainnya",
              "Pilih Virtual Account",
              "Masukkan 16 digit No. Virtual Account yang dituju, lalu tekan Benar",
              "Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening, & nama Merchant. Jika informasi telah sesuai tekan Benar",
              "Pilih rekening pembayaran Anda dan tekan Benar"
          ]
      }
  ]
},
{
  "id": "bca",
  "title": "BCA",
  "methods": [
      {
          "id": "klikbca",
          "title": "Klik BCA",
          "instructions": [
              "Pilih menu Transfer Dana",
              "Pilih Transfer ke BCA Virtual Account",
              "Masukkan nomor BCA Virtual Account, atau pilih Dari Daftar Transfer",
              "Jumlah yang akan ditransfer, nomor rekening dan nama merchant akan muncul di halaman konfirmasi pembayaran, jika informasi benar klik Lanjutkan",
              "Ambil BCA Token Anda dan masukkan KEYBCA Response APPLI 1 dan Klik Submit",
              "Transaksi Anda selesai"
          ]
      },
      {
          "id": "m-bca",
          "title": "m-BCA",
          "instructions": [
              "Lakukan log in pada aplikasi BCA Mobile",
              "Pilih menu m-BCA, kemudian masukkan kode akses m-BCA",
              "Pilih m-Transfer > BCA Virtual Account",
              "Pilih dari Daftar Transfer, atau masukkan Nomor Virtual Account tujuan",
              "Masukkan jumlah yang ingin dibayarkan",
              "Masukkan pin m-BCA",
              "Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran"
          ]
      },
      {
          "id": "atm",
          "title": "ATM",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya.",
              "Pilih Transfer",
              "Pilih Ke Rek BCA Virtual Account",
              "Masukkan Nomor Rekening pembayaran (11 digit) Anda lalu tekan Benar",
              "Masukkan jumlah tagihan yang akan anda bayar",
              "Pada halaman konfirmasi transfer akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya"
          ]
      }
  ]
},
{
  "id": "bankLain",
  "title": "Bank Lain",
  "methods": [
      {
          "id": "prima",
          "title": "Prima",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya",
              "Pilih Transfer",
              "Pilih Ke Rek Bank Lain",
              "Masukkan 002 (kode Bank BRI) lalu pilih Benar",
              "Masukkan Jumlah Nominal/Tagihan yang akan dibayar, pilih Benar",
              "Masukkan nomor BRIVA, pilih Benar",
              "Jumlah pembayaran, nomor virtual account, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Benar"
          ]
      },
      {
          "id": "atmBersama",
          "title": "ATM Bersama",
          "instructions": [
              "Pada menu utama, pilih Lainnya",
              "Tekan menu Transfer",
              "Pilih menu Antar Bank Online",
              "Masukkan 002 (kode Bank BRI) dan nomor BRIVA, lalu pilih Benar",
              "Masukkan Jumlah Nominal/Tagihan yang akan dibayar, pilih Benar",
              "Kosongkan nomor referensi transfer, pilih Benar",
              "Jumlah pembayaran, nomor virtual account, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Benar"
          ]
      },
      {
          "id": "alto",
          "title": "Alto",
          "instructions": [
              "Pada menu utama, pilih Transaksi Lainnya",
              "Pilih Transfer",
              "Pilih Ke Rek. Bank Lain",
              "Masukkan Jumlah Nominal/Tagihan yang akan dibayar, pilih Benar",
              "Masukkan 002 (kode Bank BRI) lalu pilih Benar",
              "Masukkan nomor BRIVA, lalu pilih Benar",
              "Jumlah pembayaran, nomor virtual account, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Benar"
          ]
      }
  ]
}], 
"en-lang": [
    {
      "id": "gopay",
      "title": "Gopay",
      "methods": [
          {
              "id": "gopay",
              "title": "Gopay",
              "instructions": [
                  "Tap Pay using GoPay.",
                  "You will be redirected to Gojek app",
                  "Verify your payment details and then click Pay",
                  "Verify your Security PIN and finish your transaction"
              ]
          },
        ]
    },
    {
    "id": "mandiri",
    "title": "Bank Mandiri",
    "methods": [
        {
            "id": "mandiri-online",
            "title": "Mandiri Online",
            "instructions": [
                "Log in to Mandiri Internet Banking (https://ibank.bankmandiri.co.id/)",
                "On the main menu, select \"Pay,\" then choose \"Multi Payment.\"",
                "Select your account under \"From Account,\" then choose \"Midtrans\" under \"Service Provider.\"",
                "Enter your Payment Code and click \"Continue.\"",
                "Confirm your payment using Mandiri Token or the Mandiri Online app."
            ]
        },
        {
            "id": "atm",
            "title": "ATM",
            "instructions": [
                "On the main menu, select \"Pay/Buy.\"",
                "Choose \"Other Services.\"",
                "Select \"Multi Payment.\"",
                "Enter 70012 (Midtrans company code) and press \"Correct.\"",
                "Enter your Payment Code and press \"Correct.\"",
                "The payment details will appear on the confirmation page. If the information is correct, press \"Yes.\"",
            ]
        }
    ]
  },
  {
    "id": "bni",
    "title": "BNI",
    "methods": [
        {
            "id": "internetBanking",
            "title": "Internet Banking",
            "instructions": [
                "Type the address https://ibank.bni.co.id and click \"Log In.\"",
"Please enter your User ID and Password.",
"Click on the \"Transfer\" menu, then select \"Add Favorite Account.\"",
"Enter the name, account number, and email, then click \"Next.\"",
"Enter the Authentication Code from your token and click \"Next.\"",
"Go back to the main menu, select \"Transfer,\" then choose \"Transfer Between BNI Accounts.\"",
"Select the account you have favorited previously as the Destination Account, then proceed with the transfer, and click \"Next.\"",
"Ensure that your transaction details are correct, then enter the Authentication Code and click \"Next.\"",
            ]
        },
        {
            "id": "mobileBanking",
            "title": "Mobile Banking",
            "instructions": [
                "Open the BNI Mobile Banking application and log in.",
"Select the \"Transfer\" menu.",
"Choose \"Virtual Account Billing.\"",
"Select the debit account to be used.",
"Choose \"New Input\" and enter the 16-digit Virtual Account number.",
"The billing information will appear on the validation page.",
"If the information is correct, enter the Transaction Password and click \"Next.\"",
"Your transaction will be processed.",
            ]
        },
        {
            "id": "atm",
            "title": "ATM",
            "instructions": [
                "On the main menu, select \"Other Services.\"",
"Choose \"Transfer.\"",
"Select \"Savings Account.\"",
"Choose \"To BNI Account.\"",
"Enter the virtual account number and select \"Press If Correct.\"",
"Enter the full payment amount. Payments with incorrect amounts will be automatically rejected.",
"The amount paid, account number, and Merchant name will be displayed. If the information is correct, press \"Yes.\"",
"Your transaction is complete.",
            ]
        }
    ]
  },
  {
    "id": "bri",
    "title": "BRI",
    "methods": [
        {
            "id": "ibbri",
            "title": "IB BRI",
            "instructions": [
                "Log in to BRI Internet Banking.",
"Select the \"Payments & Purchases\" menu.",
"Choose the \"BRIVA\" submenu.",
"Enter the BRIVA Number.",
"The payment amount, payment number, and merchant's name will appear on the payment confirmation page. If the provided information is correct, select \"Send.\"",
"Enter your password and mToken, then select \"Send.\"",
"The payment is completed. To print the transaction receipt, select \"Print.\"",
            ]
        },
        {
            "id": "brimo",
            "title": "BRImo",
            "instructions": [
                "Open the BRI Mobile Banking app and select \"BRI Mobile Banking.\"",
"Choose \"Payments,\" then select \"BRIVA.\"",
"Enter the BRIVA number.",
"The payment amount, payment number, and merchant's name will appear on the payment confirmation page. If the provided information is correct, select \"Continue.\"",
"Enter your BRI Mobile Banking PIN, then select \"Ok.\"",
"The payment is completed. Save the notification as proof of payment.",
            ]
        },
        {
            "id": "atm",
            "title": "ATM",
            "instructions": [
                "Select the main menu, then choose \"Other Transactions.\"",
"Choose \"Payment.\"",
"Select \"Others.\"",
"Choose \"BRIVA.\"",
"Enter the customer's BRIVA number and select \"Correct.\"",
"The payment amount, BRIVA number, and merchant's name will appear on the payment confirmation page. If the provided information is correct, select \"Yes.\"",
"The payment is completed. Save your payment receipt.",
            ]
        }
    ]
  },
  {
    "id": "permata",
    "title": "Permata",
    "methods": [
        {
            "id": "atm",
            "title": "ATM",
            "instructions": [
                "On the main menu, select \"Other Transactions.\"",
"Choose \"Payment.\"",
"Select \"Other Payments.\"",
"Choose \"Virtual Account.\"",
"Enter the 16-digit Virtual Account Number you wish to pay, then press \"Correct.\"",
"The payment confirmation page will display the amount paid, account number, and Merchant's name. If the information is correct, press \"Correct.\"",
"Select your payment account and press \"Correct.\"",
            ]
        }
    ]
  },
  {
    "id": "bca",
    "title": "BCA",
    "methods": [
        {
            "id": "klikbca",
            "title": "Klik BCA",
            "instructions": [
                "Select the \"Fund Transfer\" menu.",
                "Choose \"Transfer to BCA Virtual Account.\"",
                "Enter the BCA Virtual Account number, or select from the Transfer List.",
                "The transfer amount, account number, and merchant's name will appear on the payment confirmation page. If the information is correct, click \"Continue.\"",
                "Take your BCA Token and enter the KEYBCA Response APPLI 1, then click \"Submit.\"",
                "Your transaction is complete.",
            ]
        },
        {
            "id": "m-bca",
            "title": "m-BCA",
            "instructions": [
                "Log in to the BCA Mobile app.",
"Select the m-BCA menu and enter the m-BCA access code.",
"Choose m-Transfer > BCA Virtual Account.",
"Select from the Transfer List, or enter the destination Virtual Account number.",
"Enter the payment amount.",
"Enter your m-BCA PIN.",
"The payment is completed. Save the notification that appears as proof of payment.",
            ]
        },
        {
            "id": "atm",
            "title": "ATM",
            "instructions": [
                "On the main menu, select \"Other Transactions.\"",
"Choose \"Transfer.\"",
"Select \"To BCA Virtual Account.\"",
"Enter your payment account number (11 digits) and press \"Correct.\"",
"Enter the payment amount.",
"The payment confirmation page will display the details of your payment. If the information is correct, press \"Yes.\"",
            ]
        }
    ]
  },
  {
    "id": "bankLain",
    "title": "Bank Lain",
    "methods": [
        {
            "id": "prima",
            "title": "Prima",
            "instructions": [
                "On the main menu, select \"Other Transactions.\"",
"Choose \"Transfer.\"",
"Select \"To Other Bank Account.\"",
"Enter 002 (BRI Bank code) and press \"Correct.\"",
"Enter the Amount/Invoice to be paid, then press \"Correct.\"",
"Enter the BRIVA number, then press \"Correct.\"",
"The payment amount, virtual account number, and merchant's name will appear on the payment confirmation page. If the provided information is correct, press \"Correct.\"",
            ]
        },
        {
            "id": "atmBersama",
            "title": "ATM Bersama",
            "instructions": [
                "On the main menu, select \"Others.\"",
"Press the \"Transfer\" menu.",
"Choose the \"Interbank Online\" option.",
"Enter 002 (BRI Bank code) and the BRIVA number, then press \"Correct.\"",
"Enter the Amount/Invoice to be paid, then press \"Correct.\"",
"Leave the reference number empty, then press \"Correct.\"",
"The payment amount, virtual account number, and merchant's name will appear on the payment confirmation page. If the provided information is correct, press \"Correct.\"",
            ]
        },
        {
            "id": "alto",
            "title": "Alto",
            "instructions": [
                "On the main menu, select \"Other Transactions.\"",
"Choose \"Transfer.\"",
"Select \"To Other Bank Account.\"",
"Enter the Amount/Invoice to be paid, then press \"Correct.\"",
"Enter 002 (BRI Bank code) and press \"Correct.\"",
"Enter the BRIVA number, then press \"Correct.\"",
"The payment amount, virtual account number, and merchant's name will appear on the payment confirmation page. If the provided information is correct, press \"Correct.\"",
            ]
        }
    ]
  }]
};