import Svg, { Path } from "react-native-svg";
const MessageIcon = ({ isActive, ...props }) => (
  <Svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <Path
      fill={props?.fill ?? (isActive ? "#6724E2" : "#000")}
      d="m17.43 15.427.358 2.897c.092.76-.724 1.292-1.375.898l-3.171-1.888c-.22-.128-.275-.404-.156-.623a5.789 5.789 0 0 0 .706-2.75c0-3.356-2.879-6.087-6.417-6.087-.724 0-1.43.11-2.09.33a.463.463 0 0 1-.587-.55c.834-3.337 4.043-5.821 7.874-5.821 4.474 0 8.095 3.383 8.095 7.553 0 2.475-1.275 4.666-3.236 6.041Z"
    />
    <Path
      fill={props?.fill ?? (isActive ? "#6724E2" : "#000")}
      d="M12.417 13.96c0 1.091-.403 2.1-1.082 2.897-.907 1.1-2.347 1.806-3.96 1.806l-2.392 1.421c-.404.247-.917-.092-.862-.56l.23-1.805c-1.23-.853-2.018-2.218-2.018-3.758 0-1.614.862-3.034 2.182-3.878a5.21 5.21 0 0 1 2.86-.834c2.787 0 5.042 2.108 5.042 4.712Z"
    />
  </Svg>
);
export default MessageIcon;
