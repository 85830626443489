import React from "react";
import { Modal, Pressable, StyleSheet, Text, View } from "react-native";
import {
  BookIcon,
  BookmarkIcon,
  CalendarIcon,
  CategoryIcon,
  CloseCircleIcon,
  DirectNotifBoxIcon,
  DocumentTextIcon,
  LinkIcon,
  RulerPenIcon,
  ShoppingCartIcon,
  StarOutlineIcon,
  Verify2Icon,
  VideoIcon,
} from "../assets/icons";
import { useNavigation } from "@react-navigation/native";

const HOME_MENU = [
  { id: "journal", label: "journal", icon: <BookmarkIcon />, onPress: "", isTranslate: true },
  { id: "order", label: "order", icon: <ShoppingCartIcon />, isTranslate: true, onPress: (navigation) => {
    navigation.navigate(`Order`);
  }},
  { id: "agenda", label: "Agenda", icon: <CalendarIcon />, onPress: (navigation) => {
    navigation.navigate(`Agenda`);
  } },
  { id: "program", label: "Program", icon: <StarOutlineIcon />, onPress: (navigation) => {
    navigation.navigate(`Program`);
  } },
  { id: "packet", label: "package", icon: <DirectNotifBoxIcon />, isTranslate: true, onPress: (navigation) => {
    navigation.navigate(`Packages`);
  } },
  { id: "other", label: "others", icon: <CategoryIcon />, isTranslate: true, onPress: (navigation) => {

  }  },
  { id: "assesment", label: "assestment", icon: <RulerPenIcon />, isTranslate: true, onPress: (navigation) => {

  }},
  { id: "survey", label: "Survey", icon: <DocumentTextIcon />, onPress: (navigation) => {

  } },
  { id: "event", label: "Event", icon: <VideoIcon />, onPress: (navigation) => {

  }},
  { id: "material", label: "my-material", icon: <BookIcon />, isTranslate: true, onPress: (navigation) => {

  }},
  { id: "join_KOTA", label: "join-city", icon: <Verify2Icon />, isTranslate: true, onPress: (navigation) => {

  }},
  { id: "link", label: "Family Link", icon: <LinkIcon />, onPress: (navigation) => {

  }},
];

const HomeMenu = ({ onMore, t }) => {
  const navigate = useNavigation();
  return (
    <View style={styles.container}>
      {HOME_MENU.slice(0, 6).map((menu) => (
        <Pressable
          key={menu.id}
          onPress={() => {
            if (menu.id === "other") {
              onMore();
            } 
            if (menu.id === "journal") {
              navigate.navigate("Journal");
            } else {
              menu.onPress(navigate);
            }
          }}
        >
          <View style={styles.menu}>
            <View style={styles.menuIcon}>{menu.icon}</View>
            <Text style={styles.menuLabel}>
              {menu.isTranslate ? t(`sub-menu:${menu?.label}`) : menu.label}
            </Text>
          </View>
        </Pressable>
      ))}
    </View>
  );
};

export const MoreMenuModal = ({ isVisible, onClose, t }) => {
  return (
    <Modal animationType="fade" transparent={true} visible={isVisible}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          {/* <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Layanan Lainnya</Text> */}
          <View style={[styles.flexRow, { justifyContent: "space-between" }]}>
            <View style={styles.flexRow}>
              <Text style={styles.modalTitle}>{t("sub-menu:other-service")}</Text>
              <View style={styles.soon}>
                <Text style={styles.txSoon}>{t("sub-menu:soon")}</Text>
              </View>
            </View>
            <Pressable onPress={onClose}>
              <CloseCircleIcon />
            </Pressable>
          </View>
          <View style={styles.moreMenu}>
            {HOME_MENU.slice(6).map((menu) => (
              <Pressable key={menu.id} onPress={menu.onPress}>
                <View style={{ ...styles.menu, flexDirection: "row" }}>
                  <View
                    style={{
                      ...styles.menuIcon,
                      borderWidth: 1,
                      borderColor: "#eaeaea",
                    }}
                  >
                    {menu.icon}
                  </View>
                  <Text
                    style={{
                      ...styles.menuLabel,
                      fontSize: 12,
                      lineHeight: 14,
                      marginTop: 0,
                      marginLeft: 10,
                    }}
                  >
                    {menu.isTranslate ? t(`sub-menu:${menu?.label}`) : menu.label}
                  </Text>
                </View>
              </Pressable>
            ))}
          </View>
        </View>
        <View style={styles.modalOverlay} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 16,
    paddingBottom: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    // borderWidth: 1,
    marginBottom: 24
  },
  menu: {
    flexDirection: "column",
    alignItems: "center",
  },
  menuIcon: {
    width: 36,
    height: 36,
    borderRadius: 36 / 2,
    padding: 9,
    backgroundColor: "#fff",
  },
  menuLabel: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 11,
    marginTop: 8,
    color: "#000",
  },
  modalContainer: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
  modalOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    height: "auto",
    width: "100%",
    padding: 12,
    paddingTop: 18,
    backgroundColor: "#fff",
    borderRadius: 16,
    zIndex: 2,
  },
  flexRow: { flexDirection: "row", alignItems: "center" },
  modalHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#000",
  },
  moreMenu: {
    flexDirection: "column",
    rowGap: 12,
    marginTop: 14,
  },
  soon: {
    backgroundColor: "red",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 25,
    marginLeft: 10,
  },
  txSoon: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#fff",
  },
});

export default HomeMenu;
