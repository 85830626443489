import React, { useEffect, useState } from "react";
import { APP_RUANG_KOTA } from "@env";
import { useTranslation } from "react-i18next";
import { Image, ScrollView, StyleSheet, Text, View, RefreshControl, Dimensions } from "react-native";
import { AddIcon, ForumIcon, StarFillIcon } from "../assets/icons";
import {
  // AppBar,
  BottomNavigation,
  // EmptyState,
  FormNewDiscuss,
  ListDiscussions,
  ModalComment,
  Snackbar,
} from "../components";
// import BuildingIcon from "../assets/icons/BuildingIcon";
// import { Pressable } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createDiscussion, getListDiscussions } from "../services/programs";
import { useNavigation } from "@react-navigation/native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import md5 from "md5";

const Kota = ({route}) => {
  const { t } = useTranslation();
  const widthScreen = Dimensions.get("window").width;
  const navigation = useNavigation();
  const [member, setMember] = useState(undefined);
  const [name, setName] = useState("");
  const [discussions, setDiscussions] = useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [openComment, setOpenComment] = useState(false);
  const [question, setQuestion] = useState("");
  const [isOnSend, setIsOnSend] = useState(false);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [msg, setMsg] = useState("");
  const [additionalContentId, setAdditionalContentId] = useState("0676e2fb8449345090d5fa7a8d7e884e");
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 2000);
  }, []);

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMember(memberId);
      return memberId;
    }

    async function getName() {
      return await AsyncStorage.getItem("592f3e7");
    }

    getMember()
    .then((res) => {
      getName().then((res) => {
        setName(res);
      });

      getListDiscussions(APP_RUANG_KOTA, res).then(
        (res) => {
          setDiscussions(res?.data);
        }
      );
    });
  }, [refreshing, route.params?.refresh]);

  const onOpenComment = (data) => {
    setSelectedComment(data);
    setOpenComment(true);
  };

  const onSendComment = async () => {
    setIsOnSend(true);
    if (question.length === 0) {
      setMsg("Harap isi komentar kamu");
      setIsSnackbar(true);
      setIsOnSend(false);
      return;
    }
    const payload = {
      title: "",
      categories: selectedComment?.categories,
      tags: selectedComment?.tags,
      question: question,
      memberId: member,
      questionId: md5(new Date().getTime()),
      additionalContentId: additionalContentId,
      parentId: selectedComment.questionId,
    };
    await createDiscussion(payload)
      .then((res) => {
        if (res?.data || res?.data === "true" || res?.data === true) {
          setMsg("Komentar kamu berhasil ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
          onSubscribeTopic();
        } else {
          setMsg("Komentar kamu gagal ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
        }
      })
      .catch((err) => {
        setMsg("Komentar kamu gagal ditambahkan");
        setOpenComment(false);
        setIsSnackbar(true);
        setIsOnSend(false);
        setQuestion("");
      });
  };

  return (
    <React.Fragment>
      <ScrollView 
        style={styles.container}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <Image
          style={styles.images}
          source={require("../assets/images/alun-alun-bg.png")}
        />
        <View style={{...styles.parentHeader, padding: 0}}>
          <View style={{
            height: 160,
            marginTop: -90,
            width: "100%",
            borderRadius: 10,
            overflow: "hidden"
          }}>
            <Svg
              width={"100%"}
              height={160}
              style={{
                ...StyleSheet.absoluteFillObject,
                marginTop:0,
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
                opacity: 0.5,
                width: "100%"
              }}
            > 
              <Defs>
                <LinearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
                  <Stop offset="0" stopColor="rgba(217, 217, 217, 0.0)" />
                  <Stop offset={1} stopColor="rgba(0, 0, 0, 0.8)" />
                </LinearGradient>
              </Defs> 
              <Rect width={widthScreen} height="100%" fill="url(#grad)" />
            </Svg>
          </View>
        </View>
        <View style={{marginTop: -56, paddingHorizontal: 16, height: 60}}>
        <Text style={styles.txWelcome}>Halo {name},</Text>
          <Text style={{ ...styles.txWelcome, fontFamily: "Archivo-Bold" }}>
            Selamat Datang di Alun-alun Kota Fammi
          </Text>
        </View>
        <View style={{ paddingHorizontal: 16 }}>
          <FormNewDiscuss
            label="Forum Grup Diskusi"
            icon={<ForumIcon />}
            placeholder="Ingin Bertanya atau Berbagi Cerita? Ketik di sini..."
            navigate={() => {
              navigation.navigate("NewDiscuss", {
                additionalContentId: APP_RUANG_KOTA,
                mode: "ruang-kota"
              });
            }}
          />
          <View style={{ marginTop: 16 }}>
            {discussions?.map((item, index) => (
              <ListDiscussions
                t={t}
                key={index}
                data={item}
                memberId={member}
                onOpenComment={onOpenComment}
                onPress={(alreadyLike) => {
                  navigation.navigate("DetailDiscussions", {
                    additionalContentId: APP_RUANG_KOTA,
                    id: item?.questionId,
                    data: item,
                    alreadyLike,
                  });
                }}
              />
            ))}
          </View>
        </View>
      </ScrollView>
      <BottomNavigation activeIndex={2} />
      <ModalComment
        setValue={setQuestion}
        isOnSend={isOnSend}
        value={question}
        visible={openComment}
        onSendComment={onSendComment}
        onClose={() => {
          setOpenComment(false);
          setSelectedComment(null);
		  setQuestion('')
        }}
      />
      <Snackbar
        visible={isSnackbar}
        hideDialog={() => {
          setIsSnackbar(false);
        }}
        title={msg}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#EEEAFF",
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
  images: {
    width: "100%",
    height: 160,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  txWelcome: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    marginTop: 4,
    lineHeight: 18,
    color: "#fff",
  },
  parentHeader: {
    padding: 16,
    marginTop: -70,
    height: 70,
    // borderWidth: 1
  },
});

export default Kota;
