import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Dimensions,
  Image,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { InformationIcon, MessengerOutlineIcon } from "../assets/icons";

const ListProgram = ({ data, t, isHorizontal }) => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        ...styles.container,
        width: isHorizontal ? Dimensions.get("window").width - 90 : "100%",
        marginRight: isHorizontal ? 16 : 0,
        marginBottom: isHorizontal ? 0 : 24
      }}
    >
      {!isHorizontal && <Text style={styles.title}>{data.productName}</Text>}
      <View style={styles.content}>
        <Pressable
        onPress={() =>
          navigation.navigate("ProgramDetail", {
            program: data,
          })
        }>
        <Image
          source={{
            uri: `https://fammi.ly/uploads/products/images/${data?.productImage}?v=${new Date().getTime()}`,
          }}
          style={styles.image}
        />
        </Pressable>
        <View style={styles.flex}>
          <TouchableOpacity
            style={[styles.flex, styles.btn]}
            onPress={() => {
              navigation.navigate("ChooseExpert", {
                type: "expert",
                status: `program-${data?.additionalContentId}`,
                hideHeader: false,
              });
            }}
          >
            <MessengerOutlineIcon />
            <Text style={styles.bntLabel}>{t("program:consultation")}</Text>
          </TouchableOpacity>
          <View style={styles.divider} />
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ProgramDetail", {
                program: data,
              })
            }
            style={[styles.flex, styles.btn]}
          >
            <InformationIcon />
            <Text style={styles.bntLabel}>{t("program:see-program")}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    rowGap: 10,
    marginBottom: 12,
    maxWidth: 400
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
  },
  image: {
    width: "100%",
    height: 61,
    borderRadius: 10,
    resizeMode: "cover",
  },
  content: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  flex: {
    flexDirection: "row",
    alignItems: "center",
  },
  btn: {
    columnGap: 6,
    width: "50%",
    justifyContent: "center",
    padding: 12,
  },
  bntLabel: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
  },
  divider: {
    alignSelf: "center",
    backgroundColor: "#eaeaea",
    height: 22,
    width: 1,
  },
});
export default ListProgram;
