import React from "react";
import { StyleSheet, Text, View } from "react-native";

const TermsText = (props) => {
  const {lang} = props;
  return (
    lang === "id" ? <IndonesianTerms/> : <EnglishTerms/>
  );
};

const IndonesianTerms = () => {
  return <View>
  <Text style={[termsAndConditionStyles.title]}>
    SYARAT DAN KETENTUAN PENGGUNA FAMMI{" "}
  </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Sebagai pengguna layanan Fammi, Anda diwajibkan untuk membaca Syarat
    dan Ketentuan yang terlampir dengan seksama sebelum menggunakan
    setiap fitur dan/atau layanan yang kami sediakan.
    {"\n\n"}
    Dengan mengakses dan/atau menikmati Layanan Kami, Anda setuju bahwa
    Anda telah membaca, memahami, mengetahui, menerima, dan menyetujui
    seluruh informasi, syarat, dan ketentuan penggunaan Layanan yang
    terdapat dalam Ketentuan Penggunaan ini.
    {"\n\n"}
    Ketentuan Penggunaan ini merupakan suatu perjanjian sah terkait tata
    cara dan persyaratan penggunaan Platform antara Anda dengan
    pengelola Platform yaitu PT Fammi Indonesia Edutech.
    {"\n\n"}
    Mohon diperhatikan bahwa Ketentuan Penggunaan dan Kebijakan Privasi
    dapat diperbarui dari waktu ke waktu. Anda tidak dipersilakan untuk
    mengakses Layanan apabila Anda tidak setuju terhadap Ketentuan
    Penggunaan ini (sebagaimana diperbarui dari waktu ke waktu). Kami
    berhak untuk sewaktu-waktu mengubah, menghapus dan/atau menerapkan
    syarat dan ketentuan baru dalam Ketentuan Penggunaan ini. Pemakaian
    Anda yang berkelanjutan terhadap Layanan akan dianggap sebagai
    persetujuan untuk tunduk kepada perubahan atas syarat dan ketentuan
    dari Ketentuan Penggunaan ini.
    {"\n\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>KETENTUAN UMUM </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Kami adalah PT. Fammi Indonesi Edutech suatu perusahaan yang
    bergerak di bidang penyediaan layanan konsultasi dan edukasi terkait
    permasalahan kesehatan mental dan keluarga.
    {"\n\n"}
    Layanan adalah layanan yang kami sediakan kepada Pengguna yaitu
    sebagai support system bagi para pengguna dalam menghadapi
    permasalahan yang dihadapi sehari-hari berkaitan dengan kesehatan
    mental dan keluarga. Layanan yang diberikan diantaranya konseling
    psikologi, berbagi pengalaman, teman curhat, forum diskusi, edukasi
    dan pelatihan (seminar, webinar atau workshop).
    {"\n\n"}
    Pengguna atau Anda adalah setiap subjek hukum termasuk diantaranya
    adalah individu, badan usaha, dan/atau badan hukum yang menggunakan
    Layanan Kami.
    {"\n\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>LAYANAN </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Konsultasi Ahli: Pada Layanan ini Anda akan bertemu dengan seorang
    Ahli (Psikolog, Psikiater, atau Konselor) dan bisa mendapatkan
    solusi langsung dari ahlinya terkait permasalahan yang sedang Anda
    alami.
    {"\n\n"}
    Sharing Tokoh Ahli: Pada Layanan ini Anda akan bertemu dengan tooh
    (orangtua, guru/dosen, karyawan, mahasiswa) yang sudah berpengalaman
    menghadapi beragam permasalahan. Anda akan mendapatkan
    wawasan/pengetahuan terkait pengalaman orangtua tersebut dalam
    menghadapi masalah yang serupa. Para tokoh telah mengikuti pelatihan
    terkait dengan layanan dari Fammi.
    {"\n\n"}
    Cerita ke Sahabat Ahli: Pada Layanan ini Anda akan bertemu dengan
    teman curhat. Anda bisa mencurahkan perasaan dan pikiran Anda. Teman
    curhat akan menjadi pendengar yang baik dan memahami apa yang Anda
    rasakan. Para Sahabat telah mengikuti pelatihan terkait dengan
    layanan dari Fammi.
    {"\n\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>
    TENTANG KOTA FAMMI
  </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Kota Fammi adalah sebuah komunitas online yang didedikasikan untuk
    kesehatan mental dan dukungan emosional yang berada di dalam
    aplikasi Fammi. Dalam rangka memastikan pengalaman yang aman dan
    bermanfaat bagi semua anggota, berikut adalah ketentuan dan
    kebijakan yang berlaku dalam penggunaan platform Kota Fammi:
    {"\n\n"}
    <Text style={{ fontWeight: "600" }}>Kode Etik </Text>
    {"\n\n"}
    a. Anggota Kota Fammi diharapkan untuk saling menghormati,
    mendukung, dan berinteraksi secara positif.
    {"\n"}
    b. Tidak diperkenankan melakukan tindakan yang dapat merugikan atau
    melanggar privasi anggota lainnya.
    {"\n"}
    c. Setiap bentuk perilaku yang tidak pantas, pelecehan, atau
    diskriminasi tidak akan ditoleransi.
    {"\n"}
    d. Seluruh Ahli Kota Fammi akan menjalankan kode etik sesuai dengan
    profesi yang dijalankan.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Privasi dan Keamanan</Text>
    {"\n"}
    {"\n"}
    a. Informasi pribadi yang disampaikan oleh anggota akan dijaga
    kerahasiaannya sesuai dengan kebijakan privasi yang berlaku.
    {"\n"}
    b. Setiap anggota diharapkan untuk menjaga kerahasiaan informasi
    pribadi yang diperoleh dari anggota lainnya.
    {"\n"}
    c. Anggota dihimbau untuk tidak membagikan informasi pribadi atau
    rahasia kepada anggota lain atau pihak ketiga tanpa izin.
    {"\n"}
    d. Para Ahli, Tokoh Ahli dan Sahabat Ahli akan menjaga kerahasiaan
    informasi yang disampaikan oleh anggota.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Konten dan Komentar</Text>
    {"\n"}
    {"\n"}
    a. Anggota bertanggung jawab untuk tidak mengirimkan atau membagikan
    konten yang melanggar hukum, mengandung unsur kekerasan, SARA atau
    bersifat menghina.
    {"\n"}
    b. Komentar/chat yang dibagikan harus sesuai dengan konteks, tidak
    mengandung spam, promosi yang tidak sah, atau berisi tautan yang
    meragukan.
    {"\n"}
    c. Tim Kota Fammi berhak untuk menghapus konten atau komentar/chat
    yang melanggar ketentuan ini atau yang dianggap tidak pantas.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Tanggung Jawab Pribadi</Text>
    {"\n"}
    {"\n"}
    a. Setiap anggota bertanggung jawab atas tindakan, keputusan, dan
    interaksi yang dilakukan di dalam platform Kota Fammi.
    {"\n"}
    b. Kota Fammi tidak bertanggung jawab atas konsekuensi atau kerugian
    yang mungkin timbul akibat tindakan atau keputusan anggota.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Bantuan Ahli</Text>
    {"\n"}
    {"\n"}
    a. Ahli yang terdaftar di Kota Fammi adalah profesional
    terkualifikasi di bidangnya masing-masing.
    {"\n"}
    b. Anggota yang menggunakan layanan bantuan ahli bertanggung jawab
    untuk menggunakan informasi atau saran yang diberikan dengan bijak.
    {"\n"}
    c. Efektivitas dari layanan yang diberikan oleh ahli dikembalikan
    pada kondisi masing-masing individu.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>
      Waktu atau Durasi Layanan{" "}
    </Text>
    {"\n"}
    {"\n"}
    a. Setiap sesi curhat atau konseling dengan ahli memiliki durasi 30
    menit dalam bentuk percakpaan via teks. Pastikan Anda memanfaatkan
    waktu sesi dengan efektif.
    {"\n"}
    b. Ketepatan waktu sangat dihargai. Usahakan untuk hadir tepat waktu
    dan memanfaatkan seluruh durasi sesi dengan baik.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Reschedule </Text>
    {"\n"}
    {"\n"}
    a. Jika Anda perlu merubah jadwal sesi curhat atau konseling dengan
    ahli, Anda dapat melakukan reschedule dengan memberi tahu ahli
    maksimal 1 x 24 jam sebelum jadwal yang telah ditentukan. Jika ahli
    menyetujui maka bisa dilakukan perubahan jadwal. Jika tidak, anda
    boleh memilih ahli lain yang tersedia.
    {"\n"}
    b. Reschedule hanya boleh dilakukan satu kali.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Perubahan Jadwal Ahli</Text>
    {"\n"}
    {"\n"}
    a. Jika ahli memiliki kepentingan yang mendesak atau dalam keadaan
    sakit, ahli perlu melakukan perubahan jadwal sesi curhat atau
    konseling yang telah ditentukan. Ahli akan memberi tahu Anda secepat
    mungkin tentang perubahan tersebut.
    {"\n"}
    b. Jika terdapat perubahan jadwal dari ahli, Anda memiliki pilihan
    untuk menerima jadwal baru atau melakukan reschedule jika jadwal
    baru tidak sesuai.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>
      Perubahan Ketentuan dan Kebijakan
    </Text>
    {"\n"}
    {"\n"}
    Ketentuan dan kebijakan ini dapat mengalami perubahan sewaktu-waktu.
    Anggota akan diberitahu tentang perubahan tersebut melalui
    pemberitahuan di platform atau melalui email.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Penutup</Text>
    {"\n"}
    {"\n"}
    a. Penggunaan platform Kota Fammi merupakan persetujuan terhadap
    ketentuan dan kebijakan yang berlaku.
    {"\n"}
    b. Kota Fammi berhak untuk membatasi akses atau menghapus akun
    anggota yang melanggar ketentuan dan kebijakan yang ditetapkan.
  </Text>
</View>;
}
const EnglishTerms = () => {
  return <View>
  <Text style={[termsAndConditionStyles.title]}>
  TERMS AND CONDITIONS OF FAMMI USER{" "}
  </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    As a user of Fammi's services, you are required to carefully read the attached Terms and Conditions before using any features and/or services we provide
    {"\n\n"}
    By accessing and/or enjoying Our Services, you agree that you have read, understood, acknowledged, accepted, and agreed to all the information, terms, and conditions of using the Services contained in these Terms of Use.
    {"\n\n"}
    These Terms of Use constitute a valid agreement regarding the procedures and conditions of using the Platform between you and the Platform's manager, PT Fammi Indonesia Edutech.
    {"\n\n"}
    Please note that the Terms of Use and Privacy Policy may be updated from time to time. You are not allowed to access the Services if you do not agree to these Terms of Use (as updated from time to time). We reserve the right to change, delete, and/or implement new terms and conditions in these Terms of Use at any time. Your continued use of the Services will be deemed as your agreement to be bound by the changes to the terms and conditions of these Terms of Use.
    {"\n\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>GENERAL PROVISIONS </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    We are PT. Fammi Indonesia Edutech, a company engaged in providing consultation and education services related to mental health and family issues.
    {"\n\n"}
    Services are the services that we provide to Users as a support system for users in dealing with daily issues related to mental health and family. The services provided include psychological counseling, sharing experiences, confiding with friends, discussion forums, education, and training (seminars, webinars, or workshops).
    {"\n\n"}
    User or You are any legal subject, including individuals, business entities, and/or legal entities, using Our Services.
    {"\n\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>SERVICES </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Expert Consultation: In this service, you will meet with an Expert (Psychologist, Psychiatrist, or Counselor) and can directly obtain solutions related to the issues you are currently experiencing.
    {"\n\n"}
    Expert Figure Sharing: In this service, you will meet with figures (parents, teachers/lecturers, employees, students) who have experience in dealing with various issues. You will gain insights/knowledge related to the experiences of these parents in dealing with similar problems. The figures have undergone training related to Fammi's services.
    {"\n\n"}
    Confide in Expert Friends: In this service, you will meet with a confidant friend. You can express your feelings and thoughts. The confidant friend will be a good listener and understand what you feel. The friends have undergone training related to Fammi's services.
    {"\n\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>
  ABOUT FAMMI CITY
  </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Fammi City is an online community dedicated to mental health and emotional support within the Fammi app. To ensure a safe and beneficial experience for all members, the following are the applicable terms and policies for using the Fammi City platform.

    {"\n\n"}
    <Text style={{ fontWeight: "600" }}>Code of Ethics </Text>
    {"\n\n"}
    a. Fammi City members are expected to respect, support, and interact positively with each other.
    {"\n"}
    b. Actions that may harm or violate the privacy of other members are not allowed.
    {"\n"}
    c. Any form of inappropriate behavior, harassment, or discrimination will not be tolerated.
    {"\n"}
    d. All Fammi City Experts will adhere to the code of ethics in accordance with their respective professions.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Privacy and Security</Text>
    {"\n"}
    {"\n"}
    a. Personal information provided by members will be kept confidential in accordance with the applicable privacy policy.
    {"\n"}
    b. Each member is expected to maintain the confidentiality of personal information obtained from other members.
    {"\n"}
    c. Members are advised not to share personal or confidential information with other members or third parties without permission.
    {"\n"}
    d. Experts, Expert Figures, and Expert Friends will maintain the confidentiality of information shared by members.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Content and Comments</Text>
    {"\n"}
    {"\n"}
    a. Members are responsible for not submitting or sharing content that violates the law, contains violence, racism, or is offensive.
    {"\n"}
    b. Comments/chats shared must be relevant, free from spam, unauthorized promotions, or questionable links.
    {"\n"}
    c. The Fammi City team reserves the right to delete content or comments/chats that violate these terms or are deemed inappropriate.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Personal Responsibility</Text>
    {"\n"}
    {"\n"}
    a. Each member is responsible for their actions, decisions, and interactions within the Fammi City platform.
    {"\n"}
    b. Fammi City is not responsible for the consequences or losses that may arise from the actions or decisions of members.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Expert Assistance</Text>
    {"\n"}
    {"\n"}
    a. Registered Experts in Fammi City are qualified professionals in their respective fields.
    {"\n"}
    b. Members using expert assistance are responsible for using the information or advice given wisely.
    {"\n"}
    c. The effectiveness of the services provided by experts depends on each individual's condition.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>
    Service Time or Duration{" "}
    </Text>
    {"\n"}
    {"\n"}
    a. Each confiding or counseling session with an expert has a 30-minute duration in text chat form. Make sure to utilize the session time effectively.
    {"\n"}
    b. Punctuality is highly valued. Please try to be present on time and make the most of the session duration.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Reschedule </Text>
    {"\n"}
    {"\n"}
    a. If you need to reschedule a confiding or counseling session with an expert, you can do so by informing the expert at least 1 x 24 hours before the scheduled time. If the expert agrees, the schedule can be changed. If not, you may choose another available expert.
    {"\n"}
    b. Rescheduling can only be done once.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Expert Schedule Changes</Text>
    {"\n"}
    {"\n"}
    a. If an expert has urgent commitments or is ill, the expert needs to make changes to the scheduled confiding or counseling session. The expert will inform you as soon as possible about the changes.
    {"\n"}
    b. If there are schedule changes from the expert, you have the option to accept the new schedule or reschedule if the new schedule is not suitable.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>
    Changes to Terms and Policies
    </Text>
    {"\n"}
    {"\n"}
    These terms and policies may change from time to time. Members will be notified of these changes through notifications on the platform or via email.
    {"\n"}
    {"\n"}
    <Text style={{ fontWeight: "600" }}>Conclusion</Text>
    {"\n"}
    {"\n"}
    a. The use of the Fammi City platform constitutes agreement to the applicable terms and policies.
    {"\n"}
    b. Fammi City reserves the right to restrict access or delete member accounts that violate the established terms and policies.
  </Text>
</View>;
}
export const termsAndConditionStyles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    padding: 16,
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16,
  },
  text: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
  },
  list: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 4,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 100,
    backgroundColor: "#000",
    marginTop: 6,
  },
});
export default TermsText;
