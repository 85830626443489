import React from "react";
import MusicalIcon from "../../../assets/icons/MusicalIcon";
import { StyleSheet, Text, View } from "react-native";
import StarIcon from "../../../assets/icons/report-test/StarIcon";
import MessageIcon from "../../../assets/icons/report-test/MessageIcon";
import MathIcon from "../../../assets/icons/report-test/MathIcon";
import GlobalSearchIcon from "../../../assets/icons/report-test/GlobeIconSearchIcon";
import PeoplesIcon from "../../../assets/icons/report-test/PeoplesIcon";
import ProfileIcon from "../../../assets/icons/report-test/ProfileIcon";
import TreeIcon from "../../../assets/icons/report-test/TreeIcon";

function ListJobSuggestion(props) {
  const {item} = props;
  const icon = {
    "kecerdasan naturalis": <TreeIcon />,
    "kecerdasan musikal": <MusicalIcon />,
    "kecerdasan kinestetik": <StarIcon />,
    "kecerdasan verbal": <MessageIcon />,
    "kecerdasan logika": <MathIcon />,
    "kecerdasan spasial": <GlobalSearchIcon />,
    "kecerdasan interpersonal": <PeoplesIcon />,
    "kecerdasan intrapersonal": <ProfileIcon />,
  };

  console.log(item);

  return (
    <View style={styles.parentList}>
      {
        item.label === "kecerdasan naturalis" && 
        <TreeIcon />
      }
	  {
        item.label === "kecerdasan kinestetik" && 
        <TreeIcon />
      }
      {
        item.label === "kecerdasan musikal" && 
        <MusicalIcon />
      }
      {
        item.label === "kecerdasan verbal" && 
        <StarIcon />
      }
      {
        item.label === "kecerdasan verbal" && 
        <MessageIcon />
      }
      {
        item.label === "kecerdasan logika" && 
        <MathIcon />
      }
      {
        item.label === "kecerdasan spasial" && 
        <GlobalSearchIcon />
      }
      {
        item.label === "kecerdasan interpersonal" && 
        <PeoplesIcon />
      }
      {
        item.label === "kecerdasan intrapersonal" && 
        <ProfileIcon />
      }
      <View style={{ marginLeft: 32, width: "80%" }}>
        <View style={styles.labels}>
          <Text style={{...styles.textLabels, textTransform: "capitalize"}}>{item?.label}</Text>
        </View>

        <Text style={styles.textMain}>
          {item?.desc}
        </Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderStyle: "solid",
    backgroundColor: "#F1FBEA",
    paddingHorizontal: 32,
    paddingVertical: 24,
    borderRadius: 14,
    marginTop: 24,
  },
  labels: {
    backgroundColor: "#ECE4FB",
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignSelf: "flex-start",
    borderRadius: 100 / 2,
    marginBottom: 12,
  },
  textLabels: {
    color: "#6724E2",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
  },
  textMain: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
    color: "#0A0316",
    width: "100%",
  },
});

export default ListJobSuggestion;
