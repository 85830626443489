import * as React from "react";
import Svg, { Circle } from "react-native-svg";
const RadioIcon = ({ isSelected, ...props }) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle
      cx={11.165}
      cy={11.165}
      r={7.665}
      stroke={isSelected ? "#4EB826" : "#C2C2C2"}
      strokeWidth={isSelected ? 4 : 3}
    />
  </Svg>
);
export default RadioIcon;
