import Svg, { Path } from "react-native-svg";
const BookBlackIcon = ({ fill, ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      fill={fill ?? "#000"}
      d="M15.375 12v1.875A2.628 2.628 0 0 1 12.75 16.5h-7.5a2.628 2.628 0 0 1-2.625-2.625v-.488c0-1.177.96-2.137 2.138-2.137h9.862c.412 0 .75.338.75.75Zm-3.75-10.5h-5.25c-3 0-3.75.75-3.75 3.75v5.685c.57-.503 1.32-.81 2.138-.81h9.862c.412 0 .75-.338.75-.75V5.25c0-3-.75-3.75-3.75-3.75ZM9.75 8.063H6a.567.567 0 0 1-.563-.563c0-.308.255-.563.563-.563h3.75c.307 0 .563.255.563.563a.567.567 0 0 1-.563.563ZM12 5.437H6a.567.567 0 0 1-.563-.562c0-.308.255-.563.563-.563h6c.307 0 .563.255.563.563a.567.567 0 0 1-.563.563Z"
    />
  </Svg>
);
export default BookBlackIcon;
