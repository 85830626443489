import axios from "axios";
import {API_URL} from "@env";
import moment from "moment";

export const getServicePrice = (speakerId) => {
    return axios.get(API_URL + `/get-schedule-price/${speakerId}`);
}

export const timeCalculation = (scheduledTime) => {
    const diff = scheduledTime.diff(moment(), "seconds");
    const formatted = moment.utc(1000 * diff).format("DD HH:mm:ss");

    return {
        diff, 
        formatted
    };
}