import { useState } from "react";
import { Image, ImageBackground, Text, View } from "react-native";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import { CompanyProfileCarousel } from "../CompanyProfileCarousel";
import testimonies from "./testimoni.json";

export function CompanyProfileTestimoni({ layout }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const styles = responsiveStyles(layout);

  const handleNext = () => {
    if (activeIndex === 2) {
      setActiveIndex(0);
      return;
    }

    setActiveIndex((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (activeIndex === 0) {
      setActiveIndex(2);
      return;
    }

    setActiveIndex((prev) => prev - 1);
  };

  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>
        Pengalaman Mereka Yang Bekerjasama dengan Fammi
      </Text>
      <CompanyProfileCarousel
        data={testimonies}
        activeIndex={activeIndex}
        onPrevious={handlePrev}
        onNext={handleNext}
      >
        <ImageBackground
          source={require("../../../assets/testimoni-bg.svg")}
          style={styles("card")}
          imageStyle={{ borderRadius: 40 }}
        >
          <Image
            source={require(`../../../assets/images/testimoni/${testimonies[activeIndex].id}.png`)}
            style={styles("image")}
          />
          <View style={styles("content")}>
            <View>
              <Text style={styles("txName")}>
                {testimonies[activeIndex].name}
              </Text>
              <Text style={styles("txJob")}>
                {testimonies[activeIndex].job}
              </Text>
            </View>
            <View style={{ gap: 12 }}>
              <Text style={styles("txDesc")}>
                {testimonies[activeIndex].desc}
              </Text>
              <Text style={styles("txTestimoni")}>
                {testimonies[activeIndex].testimoni}
              </Text>
            </View>
          </View>
        </ImageBackground>
      </CompanyProfileCarousel>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    card: {
      width: 1200,
      height: 460,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: 48,
      gap: 48,
      borderRadius: 40,
      position: "relative",
    },
    image: {
      width: 514,
      height: 345,
      borderRadius: 30,
      flexShrink: 0,
    },
    content: {
      alignItems: "flex-start",
      gap: 32,
      width: 542,
    },
    txName: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 34,
      color: "#260C55",
    },
    txJob: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 26,
      color: "#260C55",
    },
    txDesc: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 26,
      color: "#6724E2",
    },
    txTestimoni: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#0A0316",
    },
  },
  {
    container: { gap: 32 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    card: {
      width: "100%",
      height: 913,
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "unset",
      gap: 48,
      padding: 16,
      paddingHorizontal: 16,
      borderRadius: 40,
    },
    image: {
      width: "100%",
      height: 381,
      borderRadius: 33,
      flexShrink: 0,
    },
    content: {
      alignItems: "flex-start",
      gap: 32,
      maxWidth: 318,
    },
    txJob: {
      fontSize: 16,
    },
  }
);
