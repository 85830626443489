import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { CloseIcon, EditIcon } from "../assets/icons";

function ModalScreeningOption(props) {
  const { visible, hideDialog, onFillScreening, onGoToReport } = props;

  return (
    <Modal isVisible={visible} onBackdropPress={hideDialog}>
      <View style={styles.parentBox}>
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            marginBottom: 12
          }}
        >
          <View style={styles.flexOne}>
            <EditIcon width={18} height={18} fill="#000" />
            <Text style={styles.txTitle}>Opsi Screening</Text>
          </View>
          <TouchableOpacity onPress={hideDialog}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <View style={{ gap: 12 }}>
          <TouchableOpacity style={styles.button} onPress={onFillScreening}>
            <Text style={styles.txBtn}>{"Isi Screening"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={onGoToReport}
          >
            <Text style={styles.txBtn}>{"Laporan Screening"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16
  },
  button: {
    backgroundColor: "#fff",
    borderRadius: 10,
    width: "100%",
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#DEDEDE"
  },
  txBtn: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2"
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center"
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#000",
    marginLeft: 6
  }
});

export default ModalScreeningOption;
