import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ChatCircle, HeartParent } from "../assets/icons";
import moment from "moment";
import { Fragment, useState, useEffect } from "react";
import { getComments, setFeedback } from "../services/programs";

function ListDiscussions(props) {
  const { onPress, data, memberId, t, onOpenComment, openComment } = props;
  const [alreadyLike, setAlreadyLike] = useState(data?.totalView > 0);
  const [totalLike, setTotalLike] = useState(data?.totalLike);
  const [threads, setThreads] = useState([]);

  useEffect(() => {
    if (!memberId) {
      return;
    }

    if (openComment) {
      return;
    }

    // getListDiscussions(id).then((res) => {
    //   setListComment(res?.data);
    // });
    getComments(data?.additionalContentId, data?.questionId, memberId+"?prev=true").then(
      (res) => {
        setThreads(res?.data);
      }
    );
  }, [memberId, data?.questionId, openComment]);
  const ListThread = ({ countMore, thread }) => (
    <Fragment>
      <View style={styles.parentThread}>
        <Text style={styles.textContent}>{thread?.question}</Text>
        <Text style={{ ...styles.textDiscussSpan, marginLeft: 0 }}>
          {moment(thread?.created).format("DD MMM YYYY, HH:mm")}
        </Text>
      </View>
      <View style={{...styles.threadDivider, paddingBottom: countMore > 2 ? 12: 0}}>
        {countMore && (
          <Pressable onPress={() => onPress(alreadyLike)}>
            <Text style={{ ...styles.textDiscussSpan, marginLeft: 0 }}>
              Lihat {countMore - 2} komentar lainnya
            </Text>
          </Pressable>
        )}
      </View>
    </Fragment>
  );
  return (
    <Pressable onPress={() => onPress(alreadyLike)} style={styles.listDiscuss}>
      <Text style={styles.textDiscuss}>{data?.title}</Text>
      <Text style={styles.textContent}>{data?.question}</Text>
      <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
        <View style={styles.flexRow}>
          <View style={styles.flexRow}>
            <ChatCircle />
            <Text style={styles.textDiscussSpan}>
              {data?.totalResponses} {t("program-detail:chat")}
            </Text>
          </View>
          <TouchableOpacity
            style={{ ...styles.flexRow, marginLeft: 12 }}
            onPress={() => {
              if (alreadyLike) {
                setTotalLike(parseInt(totalLike) - 1);
              } else {
                setTotalLike(parseInt(totalLike) + 1);
              }
              setAlreadyLike(!alreadyLike);
              setFeedback(data?.questionId, memberId);
            }}
          >
            <HeartParent
              fill={alreadyLike > 0 ? "red" : "#000"}
              circle={alreadyLike > 0 ? "#FFDCDC" : "#EAEAEA"}
            />
            <Text style={styles.textDiscussSpan}>
              {totalLike} {t("program-detail:support")}
            </Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.textDiscussSpan}>
          {moment(data?.created).format("DD MMM YYYY, HH:mm")}
        </Text>
      </View>
      <View style={{ marginTop: 10 }}>
        {threads?.length > 0 &&
          threads
            ?.slice(0, 2)
            .map((thread, index) => (
              <ListThread
                thread={thread}
                countMore={
                  index + 1 === 2 && threads.length > 2 && threads.length
                }
              />
            ))}
        <Pressable onPress={() => onOpenComment(data)} style={styles.input}>
          <Text style={{...styles.textInput, color: "#999999"}}>Balas komentar...</Text>
        </Pressable>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  listDiscuss: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 12,
    marginBottom: 12,
  },
  textDiscuss: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    marginBottom: 10,
    lineHeight: 20,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  textContent: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 20,
    marginBottom: 10,
  },
  textDiscussSpan: {
    color: "#999",
    marginLeft: 6,
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
  },
  input: {
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: "#F6F6F6",
    paddingTop: 14,
    paddingRight: 12,
    paddingBottom: 14,
    paddingLeft: 12,
  },
  textInput: {
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
  },
  parentThread: {
    backgroundColor: "#F6F6F6",
    borderRadius: 10,
    padding: 12,
  },
  threadDivider: {
    marginLeft: 12,
    padding: 12,
    paddingBottom: 0,
    borderLeftColor: "#f6f6f6",
    borderStyle: "solid",
    borderLeftWidth: 2,
  },
});
export default ListDiscussions;
