import * as React from "react";
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";
const CopyIcon = (props) => (
  <Svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_611_394)">
      <Path
        d="M10.6666 8.59992V11.3999C10.6666 13.7333 9.73325 14.6666 7.39992 14.6666H4.59992C2.26659 14.6666 1.33325 13.7333 1.33325 11.3999V8.59992C1.33325 6.26659 2.26659 5.33325 4.59992 5.33325H7.39992C9.73325 5.33325 10.6666 6.26659 10.6666 8.59992Z"
        stroke="#6724E2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.6666 4.59992V7.39992C14.6666 9.73325 13.7333 10.6666 11.3999 10.6666H10.6666V8.59992C10.6666 6.26659 9.73325 5.33325 7.39992 5.33325H5.33325V4.59992C5.33325 2.26659 6.26659 1.33325 8.59992 1.33325H11.3999C13.7333 1.33325 14.6666 2.26659 14.6666 4.59992Z"
        stroke="#6724E2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_611_394">
        <Rect width={16} height={16} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default CopyIcon;
