import React from "react";
import { Image, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

const CareItem = ({ styles, label, desc }) => {
  return (
    <View style={styles("careItem")}>
      <View style={styles("careBox")}>
        <Text style={styles("careBoxText")}>{label[0]}</Text>
      </View>
      <View>
        <Text style={styles("careLabel")}>{label}</Text>
        <Text style={styles("careDesc")}>{desc}</Text>
      </View>
    </View>
  );
};

export const Care = ({ layout }) => {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <View style={styles("content")}>
        <Text style={styles("careTitle")}>We’re Driving Impact with CARE</Text>
        <CareItem
          styles={styles}
          label="Consult & Community Based"
          desc="Private online consultation with experts"
        />
        <CareItem
          styles={styles}
          label="Assess"
          desc="Assessment for mental health conditions"
        />
        <CareItem
          styles={styles}
          label="Relearn"
          desc="Solution-focused podcast, video & e-book"
        />
        <CareItem
          styles={styles}
          label="Empower"
          desc="Communication forum and active support"
        />
      </View>
    </View>
  );
};

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: "row",
      alignItems: "center",
      gap: 50,
      borderRadius: 40,
      backgroundColor: "#FAFAFA",
    },
    image: {
      width: null,
      maxWidth: "50%",
      height: 440,
      flex: 1,
      borderTopLeftRadius: 40,
      borderBottomLeftRadius: 40,
    },
    content: {
      gap: 30,
    },
    careTitle: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 30,
      lineHeight: 40,
    },
    careItem: {
      flexDirection: "row",
      alignItems: "center",
      gap: 20,
    },
    careBox: {
      width: 50,
      height: 50,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 18,
      backgroundColor: "#6724E2",
    },
    careBoxText: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 20,
      lineHeight: 22,
      color: "#fff",
    },
    careLabel: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 16,
      lineHeight: 17,
    },
    careDesc: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 16,
      lineHeight: 17,
      color: "#888",
      marginTop: 6,
    },
  },
  {
    container: {
      flexDirection: "column",
      paddingBottom: 40,
      gap: 40,
    },
    image: {
      height: 383,
      width: "100%",
      maxWidth: "100%",
      flex: undefined,
      borderTopRightRadius: 40,
      borderBottomLeftRadius: 0,
    },
    content: {
      gap: 20,
    },
    careItem: { gap: 14 },
    careTitle: {
      fontSize: 20,
      lineHeight: 40,
    },
    careBox: {
      width: 40,
      height: 40,
      borderRadius: 15,
    },
    careBoxText: {
      fontSize: 16,
      lineHeight: 17,
    },
    careDesc: {
      fontSize: 12,
      lineHeight: 13,
      marginTop: 4,
    },
  }
);
