import React, { useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { AppBar, Snackbar } from "../components";
import DetailZoomCard from "../components/DetailZoomCard";
import { CheckCircleIcon } from "../assets/icons";
import moment from "moment";
import { checkAgenda, recordAgenda } from "../services/agenda";
import { useNavigation } from "@react-navigation/native";
import { getProgramId } from "../services/programs";
import md5 from "md5";

const DetailAgenda = (props) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const {data, misc, speaker, memberId} = props.route?.params;
  const [alreadyJoin, setAlreadyJoin] = useState(false);
  // const [alreadyPass, setAlreadyPass] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const navigation = useNavigation();
  const [program, setProgram] = useState({});

  const onDismissSnackBar = () => {
    setOpenSnackBar(false);
    setMessageSnackBar("");
  };
  const checkSchedule = (data, mode) => {
    // get the date
    const scheduleDate = moment(data.scheduledDate).format("YYYY-MM-DD");
    const start = moment(scheduleDate + " " + data.start);
    const end = moment(scheduleDate + " " + data.end);

    if (mode === "started") {
      return moment() >= moment(start) && moment() <= moment(end);
    }

    return moment() >= moment(end);
  }

  const handleJoin = () => {
    const alreadyStarted = checkSchedule({
      scheduledDate: data?.scheduledDate,
      start: misc.start,
      end: misc.end
    }, "started");

    const alreadyPass = checkSchedule({
      scheduledDate: data?.scheduledDate,
      start: misc.start,
      end: misc.end
    }, "ended");

    if (!alreadyJoin) {
      recordAgenda({
        acId: md5(new Date().getTime()),
        memberId: memberId, 
        agendaId: data?.agendaId
      }).then(() => {
        setAlreadyJoin(true);
        setOpenSnackBar(true);
        setMessageSnackBar("Kamu berhasil mendaftar");
      });
      return;
    }

    if ((alreadyPass) && data.agendaType === "zoom") {
      setMessageSnackBar("Acara sudah selesai");
      setOpenSnackBar(true);
      return;
    }

    if (!alreadyStarted) {
      setOpenSnackBar(true);
      setMessageSnackBar("Acara belum mulai. Cek kembali tanggal dan jamnya");
      return;
    }

    if ((alreadyPass || alreadyStarted) && data.agendaType === "youtube") {
      Linking.openURL(misc.url.indexOf("http") > -1 ? misc.url : "https://" + misc.url);
      return;
    }

    if ((alreadyStarted || alreadyPass) && data.agendaType === "diskusi") {
      navigation.navigate("DiscussPrograms", {
        additionalContentId: data.additionalContentId,
        newDiscuss: "1",
        program: {...program}
      });
      return;
    }
  };

  useEffect(() => {
      checkAgenda(memberId, data?.agendaId)
      .then((res) => {
        if (res?.data.length > 0) {
          setAlreadyJoin(true);
        }
      });

    async function getProgram() {
      getProgramId(data.additionalContentId).then(
        (res) => {
          setProgram(res?.data[0]);
        }
      );
    }
    getProgram();
  }, [data]);
  return (
    <React.Fragment>
      <AppBar title="Detail Agenda" isBack />
      <ScrollView contentContainerStyle={styles.container}>
        {
          misc?.image &&
          <Image
            source={{
              uri: `https://fammi.ly/uploads/kelas-online/images/${misc?.image}`,
            }}
            style={styles.imgCover}
          />
        }
        <DetailZoomCard misc={misc} data={data} speaker={speaker}/>
        <TouchableOpacity 
          onPress={handleJoin} 
          style={{...styles.btn}}
        >
          <Text style={{...styles.txBtn}}>
            {
              !alreadyJoin &&
              "Daftar Sekarang"
            }
            {
              alreadyJoin &&
              data?.agendaType === "diskusi" &&
              `Masuk Diskusi Sekarang` 
            }
            {
              alreadyJoin &&
              data?.agendaType !== "diskusi" &&
              `Masuk ${data?.agendaType === "zoom" ? "Zoom": "YouTube"} Sekarang` 
            }
          </Text>
        </TouchableOpacity>
        <View style={styles.section}>
          <Text style={styles.txTitle}>Tentang Agenda</Text>
          <View style={styles.box}>
            <Text style={styles.txMain}>
              {
                misc?.description?.replace(/&lsquo;/g, "'").replace(/&ldquo;/g, "\"").replace(/&ldquo;/g, "'").replace(/&rdquo;/g, "\"")
              }
            </Text>
          </View>
        </View>
        {
          misc?.benefit?.split(";").length > 0 &&
          <View style={styles.section}>
            <Text style={styles.txTitle}>Apa yang peserta akan dapatkan?</Text>
            {misc?.benefit?.split(";").map((item) => (
              <View style={styles.boxRowed} key={item}>
                <CheckCircleIcon fill="#000" />
                <Text style={{ ...styles.txMain, marginLeft: 10, marginRight: 14 }}>
                  {item?.replace(/&lsquo/g, "'").replace(/&ldquo/g, "\"").replace(/&ldquo/g, "'").replace(/&rdquo/g, "\"").replace(/&lsquo;/g, "'").replace(/&ldquo;/g, "\"").replace(/&ldquo;/g, "'").replace(/&rdquo;/g, "\"")}
                </Text>
              </View>
            ))}
          </View>
        }
      </ScrollView>
      <Snackbar
        visible={openSnackBar}
        hideDialog={onDismissSnackBar}
        title={messageSnackBar}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    padding: 16,
    minHeight: "100%"
  },
  imgCover: {
    height: 100,
    width: "100%",
    borderRadius: 10,
    resizeMode: "cover",
    marginBottom: 16,
  },
  btn: {
    marginTop: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    // width: "100%",
    height: 36,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  section: {
    marginTop: 24,
  },
  txBtn: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
  txTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
  },
  box: {
    backgroundColor: "#FFF",
    borderRadius: 10,
    padding: 12,
    marginTop: 12,
  },
  boxRowed: {
    backgroundColor: "#FFF",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    padding: 12,
    marginTop: 12,
  },
  txMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 16,
  },
});

export default DetailAgenda;
