import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import ListJobSuggestion from "./ListJobSuggestion";
import ProfileIcon from "../../../assets/icons/report-test/ProfileIcon";
import PeoplesIcon from "../../../assets/icons/report-test/PeoplesIcon";
import GlobalSearchIcon from "../../../assets/icons/report-test/GlobeIconSearchIcon";
import MathIcon from "../../../assets/icons/report-test/MathIcon";
import MessageIcon from "../../../assets/icons/report-test/MessageIcon";
import StarIcon from "../../../assets/icons/report-test/StarIcon";
import MusicIcon from "../../../assets/icons/report-test/MusicIcon";
import TreeIcon from "../../../assets/icons/report-test/TreeIcon";
import ListStatic from "../ListStatic";

function JobSuggestion(props) {
  const { descriptions } = props;
  const [job, setJob] = useState([]);
  const lists = [
    {
      id: 1,
      key: "kecerdasan naturalis",
      title: "Naturalis",
      icon: <TreeIcon />,
      color: "#3E951D",
      secondColor: "#DCF4CA",
      bgColor: "#F1FBEA",
      image: require("../../../assets/images/naturalis-bg.png"),
    },
    {
      id: 2,
      key: "kecerdasan musikal",
      title: "Musikal",
      icon: <MusicIcon />,
      color: "#834FE3",
      secondColor: "#ECE4FB",
      bgColor: "#F5F0FF",
      image: require("../../../assets/images/musikal-bg.png"),
    },
    {
      id: 3,
      key: "kecerdasan kinestetik",
      title: "Kinestetik",
      icon: <StarIcon />,
      color: "#305C92",
      secondColor: "#D1DFF0",
      bgColor: "#ECF2F9",
      image: require("../../../assets/images/kinestetik-bg.png"),
    },
    {
      id: 4,
      key: "kecerdasan verbal",
      title: "Verbal",
      icon: <MessageIcon />,
      color: "#034932",
      secondColor: "#A6D9C8",
      bgColor: "#CFFCED",
      image: require("../../../assets/images/verbal-bg.png"),
    },
    {
      id: 5,
      key: "kecerdasan logika",
      title: "Logika - Matematika",
      icon: <MathIcon />,
      color: "#FD681C",
      secondColor: "#FEDECD",
      bgColor: "#FFEEE5",
      image: require("../../../assets/images/logika-bg.png"),
    },
    {
      id: 6,
      key: "kecerdasan spasial",
      title: "Spasial",
      icon: <GlobalSearchIcon />,
      color: "#AE1919",
      secondColor: "#F9D2D2",
      bgColor: "#FCE9E9",
      image: require("../../../assets/images/spasial-bg.png"),
    },
    {
      id: 7,
      key: "kecerdasan interpersonal",
      title: "Interpersonal",
      icon: <PeoplesIcon />,
      color: "#00314D",
      secondColor: "#B2E3FF",
      bgColor: "#CCECFF",
      image: require("../../../assets/images/interpersonal-bg.png"),
    },
    {
      id: 8,
      key: "kecerdasan intrapersonal",
      title: "Intrapersonal",
      icon: <ProfileIcon />,
      color: "#665600",
      secondColor: "#FFEB80",
      bgColor: "#FFF7CC",
      image: require("../../../assets/images/intrapersonal-bg.png"),
    },
  ];

  useEffect(() => {
    if (!descriptions) {
      return;
    }

    const jobData = [];
    Object.keys(descriptions).map((d) => {
      descriptions[d].map((data) => {
        if (data.name === "saran_profesi") {
          jobData.push({
            ...lists.find((l) => l.key === d),
            description: data.desc,
          });
        }
      });
    });

    setJob([...jobData]);
  }, [descriptions]);

  return (
    <View style={styles.wrapper}>
      {job.map((item, index) => (
        <ListStatic
          key={index}
          item={{
            ...item,
            icon: (
              <View
                style={{
                  width: 64,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "solid",
                  borderWidth: 3,
                  borderColor: item.color,
                  borderRadius: 64 / 2,
                  height: 64,
                }}
              >
                {item.icon}
              </View>
            ),
          }}
        />
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    marginTop: 24,
    gap: 24,
  },
});

export default JobSuggestion;
