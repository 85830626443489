import Svg, { Path } from "react-native-svg";
const Message1Icon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      fill="#999"
      d="M9.893 4.5h-4.8c-.196 0-.383.008-.563.03-2.018.172-3.03 1.365-3.03 3.562v3c0 3 1.2 3.593 3.593 3.593h.3c.165 0 .382.112.48.24l.9 1.2c.397.532 1.042.532 1.44 0l.9-1.2a.604.604 0 0 1 .48-.24h.3c2.197 0 3.39-1.005 3.562-3.03.022-.18.03-.367.03-.563v-3c0-2.392-1.2-3.592-3.592-3.592Zm-5.018 6a.747.747 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75-.338.75-.75.75Zm2.617 0a.747.747 0 0 1-.75-.75c0-.412.338-.75.75-.75.413 0 .75.338.75.75s-.33.75-.75.75Zm2.625 0a.747.747 0 0 1-.75-.75c0-.412.338-.75.75-.75.413 0 .75.338.75.75s-.337.75-.75.75Z"
    />
    <Path
      fill="#999"
      d="M16.485 5.093v3c0 1.5-.465 2.52-1.395 3.082-.225.135-.487-.045-.487-.308l.007-2.775c0-3-1.717-4.717-4.717-4.717l-4.568.007c-.262 0-.442-.262-.307-.487C5.58 1.965 6.6 1.5 8.093 1.5h4.8c2.392 0 3.592 1.2 3.592 3.593Z"
    />
  </Svg>
);
export default Message1Icon;
