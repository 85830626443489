import * as React from "react";
import Svg, { Path } from "react-native-svg";
const DirectNotifBoxIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M14.25 9V4.5C14.25 2.8425 14.25 1.5 11.25 1.5H6.75C3.75 1.5 3.75 2.8425 3.75 4.5V9M5.25 9C2.25 9 2.25 10.3425 2.25 12V12.75C2.25 14.82 2.25 16.5 6 16.5H12C15 16.5 15.75 14.82 15.75 12.75V12C15.75 10.3425 15.75 9 12.75 9C12 9 11.79 9.1575 11.4 9.45L10.635 10.26C10.4247 10.4837 10.1708 10.662 9.88894 10.7839C9.60711 10.9057 9.3033 10.9686 8.99625 10.9686C8.6892 10.9686 8.38539 10.9057 8.10356 10.7839C7.82172 10.662 7.56782 10.4837 7.3575 10.26L6.6 9.45C6.21 9.1575 6 9 5.25 9Z"
      stroke={props?.stroke ? props?.stroke : 'black'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.91254 6.92236H10.41M7.29004 4.67236H11.04"
      stroke={props?.stroke ? props?.stroke : 'black'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default DirectNotifBoxIcon;
