import { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import SurveyQuestionCard from "./SurveyQuestionCard";

const AnswerCard = ({ percentage, opt }) => {
  const [optionHeight, setOptionHeight] = useState(0);
  return (
    <View>
      <View
        style={[styles.option]}
        onLayout={(e) => {
          setOptionHeight(e.nativeEvent.layout.height);
          height = e.nativeEvent.layout.height;
        }}
      >
        <Text style={[styles.optionTx, { maxWidth: "88%" }]}>{opt.label}</Text>
        <View
          style={[
            styles.total,
            {
              backgroundColor: opt.isAnswered ? "#4EB826" : "#999",
            },
          ]}
        >
          <Text style={[styles.totalTx]}>{percentage}%</Text>
        </View>
      </View>
      <View
        style={
          // styles.totalBar,
          {
            position: "absolute", 
            top: 0,
            left: 0,
            width: `${percentage}%`,
            height: optionHeight || "100%",
            backgroundColor: opt.isAnswered ? "#DCF4CA" : "#EAEAEA",
            zIndex: -1,
            borderRadius: 10
          }
        }
      />
    </View>
  );
};

const SurveyResultCard = ({ data }) => {
  const {
    answerGrouped,
    orderNo,
    quizType,
    title,
    totalAnsweredGrouped,
    options,
  } = data;
  const isFreeText = quizType === "free_text";

  return (
    <SurveyQuestionCard number={orderNo} type={quizType} question={title}>
      {isFreeText
        ? answerGrouped.map((item, id) => (
            <View key={id} style={styles.option}>
              <Text style={styles.optionTx}>{item}</Text>
            </View>
          ))
        : options.map((opt, id) => {
            return (
              <AnswerCard
                key={id}
                opt={opt}
                percentage={Math.round(
                  (opt.total / Number(totalAnsweredGrouped)) * 100
                )}
              />
            );
          })}
    </SurveyQuestionCard>
  );
};

const styles = StyleSheet.create({
  option: {
    width: "100%",
    minHeight: 46,
    borderWidth: 1,
    borderRadius: 10,
    padding: 12,
    position: "relative",
    borderColor: "#EAEAEA",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  optionTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    zIndex: 1,
  },
  total: {
    zIndex: 1,
    padding: 6,
    borderRadius: 100,
  },
  totalTx: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 14,
    color: "#fff",
  },
  totalBar: {
    borderRadius: 10,
    position: "absolute",
    display: "flex",
    top: 0,
    left: 0,
  },
});

export default SurveyResultCard;
