import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const VerifyCircleIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={12} cy={12} r={12} fill="black" />
    <Path
      d="M18.3731 11.1594L17.4664 10.1061C17.2931 9.90605 17.1531 9.53272 17.1531 9.26605V8.13272C17.1531 7.42605 16.5731 6.84605 15.8664 6.84605H14.7331C14.4731 6.84605 14.0931 6.70605 13.8931 6.53272L12.8398 5.62605C12.3798 5.23272 11.6264 5.23272 11.1598 5.62605L10.1131 6.53939C9.91309 6.70605 9.53309 6.84605 9.27309 6.84605H8.11976C7.41309 6.84605 6.83309 7.42605 6.83309 8.13272V9.27272C6.83309 9.53272 6.69309 9.90605 6.52643 10.1061L5.62643 11.1661C5.23976 11.6261 5.23976 12.3727 5.62643 12.8327L6.52643 13.8927C6.69309 14.0927 6.83309 14.4661 6.83309 14.7261V15.8661C6.83309 16.5727 7.41309 17.1527 8.11976 17.1527H9.27309C9.53309 17.1527 9.91309 17.2927 10.1131 17.4661L11.1664 18.3727C11.6264 18.7661 12.3798 18.7661 12.8464 18.3727L13.8998 17.4661C14.0998 17.2927 14.4731 17.1527 14.7398 17.1527H15.8731C16.5798 17.1527 17.1598 16.5727 17.1598 15.8661V14.7327C17.1598 14.4727 17.2998 14.0927 17.4731 13.8927L18.3798 12.8394C18.7664 12.3794 18.7664 11.6194 18.3731 11.1594ZM14.7731 10.7394L11.5531 13.9594C11.4593 14.053 11.3323 14.1056 11.1998 14.1056C11.0673 14.1056 10.9402 14.053 10.8464 13.9594L9.23309 12.3461C9.14011 12.252 9.08796 12.125 9.08796 11.9927C9.08796 11.8604 9.14011 11.7335 9.23309 11.6394C9.42643 11.4461 9.74643 11.4461 9.93976 11.6394L11.1998 12.8994L14.0664 10.0327C14.2598 9.83939 14.5798 9.83939 14.7731 10.0327C14.9664 10.2261 14.9664 10.5461 14.7731 10.7394Z"
      fill="white"
    />
  </Svg>
);
export default VerifyCircleIcon;
