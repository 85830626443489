import Svg, { Path } from "react-native-svg";
const VerifyIcon = ({ fill, isSmall }) => (
  <>
    {isSmall ? (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        fill="none"
      >
        <Path
          fill={fill}
          d="m10.78 5.37-.68-.79c-.13-.15-.236-.43-.236-.63V3.1a.969.969 0 0 0-.965-.966h-.85c-.195 0-.48-.105-.63-.235l-.79-.68a1.008 1.008 0 0 0-1.26 0l-.785.685c-.15.125-.435.23-.63.23H3.09a.969.969 0 0 0-.965.965v.855c0 .195-.105.475-.23.625l-.675.795c-.29.345-.29.905 0 1.25l.675.795c.125.15.23.43.23.625V8.9c0 .53.435.965.965.965h.865c.195 0 .48.105.63.235l.79.68c.345.295.91.295 1.26 0l.79-.68c.15-.13.43-.235.63-.235h.85c.53 0 .965-.435.965-.965v-.85c0-.195.105-.48.236-.63l.68-.79c.29-.345.29-.915-.005-1.26Zm-2.7-.316L5.663 7.47a.375.375 0 0 1-.53 0l-1.21-1.21a.377.377 0 0 1 0-.53.377.377 0 0 1 .53 0l.945.945 2.15-2.15a.377.377 0 0 1 .53 0 .377.377 0 0 1 0 .53Z"
        />
      </Svg>
    ) : (
      <Svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <Path
          fill={fill ?? "#000"}
          d="m19.763 9.844-1.246-1.448c-.239-.275-.431-.789-.431-1.155V5.682c0-.971-.798-1.769-1.77-1.769h-1.558c-.357 0-.88-.192-1.155-.43l-1.448-1.247c-.632-.541-1.668-.541-2.31 0l-1.44 1.256c-.274.229-.797.421-1.154.421H5.665c-.972 0-1.77.798-1.77 1.77V7.25c0 .357-.192.87-.42 1.146L2.236 9.853c-.532.633-.532 1.66 0 2.292l1.237 1.457c.23.275.422.789.422 1.146v1.568c0 .971.797 1.769 1.769 1.769h1.586c.357 0 .88.192 1.155.43l1.448 1.247c.633.541 1.669.541 2.31 0l1.449-1.246c.274-.239.788-.431 1.155-.431h1.558c.971 0 1.769-.798 1.769-1.77v-1.558c0-.357.193-.88.43-1.155l1.248-1.448c.531-.633.531-1.678-.01-2.31Zm-4.95-.577-4.427 4.427a.687.687 0 0 1-.972 0l-2.218-2.218a.691.691 0 0 1 0-.972.692.692 0 0 1 .971 0L9.9 12.236l3.942-3.941a.692.692 0 0 1 .971 0 .692.692 0 0 1 0 .972Z"
        />
      </Svg>
    )}
  </>
);
export default VerifyIcon;
