import { Image } from "react-native";
import { Text, View } from "react-native-web";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import {
  MessageIcon,
  Note2Icon,
  PeopleIcon,
  PresentationChartIcon,
} from "../../assets/icons";

export function ProgramAntiBullyingBenefits({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>Manfaat Untuk Sekolah Anda</Text>
      <View style={styles("content")}>
        <BenefitCard
          title="Laporan Hasil Tes Tertulis Kepada Setiap Siswa"
          desc="Dilengkapi Saran & Tindak Lanjut dari Psikolog Fammi"
          Icon={<Note2Icon />}
          bgColor="#F1FBEA"
          bgIcon="note-2.svg"
          styles={styles}
        />
        <BenefitCard
          title="Laporan Hasil Tes Tertulis Dalam Bentuk Paparan Eksklusif & Mendetail untuk Sekolah "
          desc="Dilengkapi Saran & Tindak Lanjut dari Psikolog Fammi"
          Icon={<PresentationChartIcon />}
          styles={styles}
          bgColor="#FFF8E5"
          bgIcon="presentation-chart.svg"
        />
        <BenefitCard
          title="Pemetaan Kondisi Indikasi Siswa yang Aman, Waspada & Kritis di Berbagai Jenjang Kelas"
          Icon={<PeopleIcon />}
          styles={styles}
          bgColor="#F5F0FF"
          bgIcon="people.svg"
        />
        <BenefitCard
          title="Ruangan Curhat Tanpa Nama bagi Siswa/Orangtua "
          Icon={<MessageIcon width={28} height={28} fill="#305C92" />}
          styles={styles}
          bgColor="#ECF2F9"
          bgIcon="message-2.svg"
        />
      </View>
    </View>
  );
}

function BenefitCard({ title, desc, bgColor, Icon, bgIcon, styles }) {
  return (
    <View style={[styles("card"), { backgroundColor: bgColor }]}>
      {Icon}
      <View style={styles("cardContent")}>
        <Text style={styles("txTitleCard")}>{title}</Text>
        {desc && <Text style={styles("txDescCard")}>{desc}</Text>}
      </View>
      <Image
        source={require(`../../assets/${bgIcon}`)}
        style={styles("bgIcon")}
      />
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
      padding: 48,
      backgroundColor: "#fff",
      borderRadius: 40,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    content: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: 24,
    },
    card: {
      width: 540,
      height: 130,
      borderRadius: 20,
      paddingHorizontal: 24,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 24,
      position: "relative",
      backgroundColor: "#D9D9D9",
      overflow: "hidden",
    },
    cardContent: {
      gap: 4,
      maxWidth: 433,
    },
    txTitleCard: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 26,
      color: "#260C55",
    },
    txDescCard: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#260C55",
    },
    bgIcon: {
      position: "absolute",
      bottom: -40,
      right: -33,
      width: 162,
      height: 158,
      zIndex: -1,
    },
  },
  {
    container: { gap: 32 },
    header: { gap: 12 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 18,
      lineHeight: 34,
    },
    content: {},
  }
);
