import Svg, { Path } from "react-native-svg";
const ChevronDown = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none">
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.96 4.475 6.7 7.735a.993.993 0 0 1-1.4 0l-3.26-3.26"
    />
  </Svg>
);
export default ChevronDown;
