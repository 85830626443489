import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { AddIcon, StarFillIcon } from "../assets/icons";
import {
  AppBar,
  EmptyState,
  FAB,
  ListProgram,
  ModalAddProgram,
  Snackbar,
} from "../components";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getProgram, sendNotif, subscribeTopic } from "../services/programs";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";

const DUMMY_CODE = "DESA-RZ";

const Program = (props) => {
  const [programs, setPrograms] = useState([]);
  const [addProgramModal, setAddProgramModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loadProgram, setLoadProgram] = useState(true);
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [token, setToken] = useState(undefined);
  const [code, setCode] = useState("");
  const { t } = useTranslation();
  const [successAdd, setSuccessAdd] = useState(false);
  const navigation = useNavigation();

  const onAddProgram = () => {
    setSuccessAdd(true);
    setSnackbarMessage("Program berhasil ditambahkan");
    setOpenSnackBar(true);
  };

  useEffect(() => {
    if (!loadProgram) {
      return;
    }

    async function getMember() {
      return await AsyncStorage.getItem("token");
    }

    getMember().then((res) => {
      setToken(res);
      const theToken = res;
      getProgram(13, res).then((res) => {
        if (res.data.length > 0 && successAdd) {
          subscribeTopic(token);
          // .then(
            // (response) => {
              // if (`${response?.data}` === 'true') {
              //   sendNotif(res.data[0]?.additionalContentId).then((resNotif) => {
              //     if (`${resNotif?.data}` === 'true') {
              //       setSuccessAdd(false);
              //     }
              //   });
              // }
            // }
          // );
        }

        res?.data?.map((r) => {
          const resp = subscribeTopic(theToken, r.additionalContentId);
        });
        setPrograms(res?.data);
        // subscribeTopic(theToken, res.data[2]?.additionalContentId);
        setLoadProgram(false);
      });
    });
  }, [loadProgram, successAdd]);

  return (
    <React.Fragment>
      <AppBar title="Program" isBack />
      {programs?.length === 0 && (
        <ScrollView contentContainerStyle={styles.container}>
          <EmptyState
            Icon={StarFillIcon}
            title={t("program:you-havent")}
            desc={t("program:click-add-program")}
          />
        </ScrollView>
      )}
      {programs?.length > 0 && (
        <ScrollView style={styles.container}>
          {programs.map((p) => (
            <ListProgram key={p.id} data={p} t={t} />
          ))}
        </ScrollView>
      )}
      <FAB
        icon={<AddIcon />}
        label={t("program:add-program")}
        onPress={() => setAddProgramModal(true)}
      />
      <ModalAddProgram
        t={t}
        token={token}
        code={code}
        setCode={setCode}
        onAddProgram={onAddProgram}
        setLoadProgram={setLoadProgram}
        visible={addProgramModal}
        hideDialog={() => setAddProgramModal(false)}
      />
      <Snackbar
        title={snackBarMessage}
        visible={openSnackBar}
        hideDialog={() => {
          setOpenSnackBar(false);
        }}
      />
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
});

export default Program;
