import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const GlobalSearchIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#E54646"
          d="m41.766 39.79-1.38-1.38a6.828 6.828 0 0 0 1.13-3.757 6.855 6.855 0 0 0-6.861-6.862 6.855 6.855 0 0 0-6.862 6.862 6.855 6.855 0 0 0 6.862 6.861 6.78 6.78 0 0 0 3.756-1.13l1.38 1.38c.269.268.633.402.978.402.364 0 .709-.134.977-.402a1.394 1.394 0 0 0 .02-1.975Z"
        />
        <Path
          fill="#E54646"
          d="M3.894 28.04c0 .057-.039.133-.039.19 1.764 3.527 4.639 6.421 8.165 8.166.058 0 .135-.039.192-.039a55.032 55.032 0 0 1-1.514-6.785c-2.3-.383-4.58-.881-6.804-1.533ZM36.555 12.32a18.208 18.208 0 0 0-8.606-8.605c.69 2.28 1.265 4.619 1.649 6.957 2.338.384 4.676.94 6.957 1.649ZM3.703 12.324c2.3-.69 4.638-1.265 6.977-1.649.383-2.28.862-4.542 1.514-6.766-.058 0-.134-.038-.192-.038C8.4 5.654 5.466 8.663 3.703 12.324ZM26.492 10.272c-.46-2.491-1.035-4.983-1.859-7.398-.038-.134-.038-.25-.057-.402-1.419-.345-2.914-.576-4.447-.576-1.553 0-3.028.211-4.466.576-.019.134 0 .249-.038.402-.805 2.415-1.4 4.907-1.86 7.398 4.236-.46 8.491-.46 12.727 0ZM10.278 13.76c-2.51.46-4.983 1.035-7.398 1.859-.134.038-.25.038-.403.057-.345 1.419-.575 2.914-.575 4.447 0 1.553.211 3.028.575 4.466.134.02.25 0 .403.038 2.415.805 4.887 1.4 7.398 1.86-.46-4.236-.46-8.491 0-12.727ZM37.762 15.676c-.134 0-.25-.019-.403-.057-2.415-.805-4.906-1.4-7.398-1.86.48 4.237.48 8.492 0 12.708 2.492-.46 4.983-1.035 7.398-1.859.134-.038.25-.019.403-.038.345-1.438.575-2.914.575-4.466 0-1.514-.211-2.99-.575-4.428ZM13.766 29.975c.46 2.51 1.035 4.983 1.859 7.398.038.134.019.25.038.402 1.438.345 2.913.575 4.466.575 1.533 0 3.028-.21 4.447-.575.019-.134.019-.249.057-.402.805-2.415 1.4-4.888 1.86-7.398-2.109.23-4.236.402-6.364.402-2.128-.02-4.255-.172-6.363-.402ZM13.326 13.318a54.043 54.043 0 0 0 0 13.627c4.523.575 9.085.575 13.627 0a54.043 54.043 0 0 0 0-13.627 54.044 54.044 0 0 0-13.627 0Z"
          opacity={0.4}
        />
      </Svg>
    </View>
  </View>
);
export default GlobalSearchIcon;
