import {
  Image,
  Pressable,
  Text,
  TouchableOpacity,
  View,
  TextInput
} from "react-native";
import { BigCheckIcon, CheckIcon, ChevronRight, CloseIcon } from "../assets/icons";
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import Modal from "react-native-modal";
import { setMember, setName, setProfileData } from "../services/profile";
import AsyncStorage from "@react-native-async-storage/async-storage";

function Verification() {
  const [profile, setProfile] = useState({});
  const [visible, setVisible] = useState(false);
  const [token, setToken] = useState(undefined);
  const navigation = useNavigation();
  const profesi = [
    "Ibu Rumah Tangga",
    "Guru / Dosen / Pendidik",
    "Pelajar / Mahasiswa",
    "Karyawan / Profesional",
    "Profesi ~ Psikolog, Konselor, Dokter, dll",
    "Wirausaha / Entreprener",
    "Belum Bekerja",
    "Lainnya"
  ];

  useEffect(() => {
    async function getUser() {
      return await AsyncStorage.getItem("token");
    }

    getUser()
    .then((res) => {
      setToken(res);
    });
  }, []);
  return (
    <View
      style={{
        backgroundColor: "#EEEAFF",
        height: "100%",
      }}
    >
      <View
        style={{
          padding: 16,
          paddingTop: 18,
        }}
      >
        <Text>
          <Image
            source={require("../assets/header-back.svg")}
            style={{ width: 36, height: 36 }}
          />
        </Text>
        <View
          style={{
            marginTop: 24,
          }}
        >
          <Text style={{
            fontFamily: "Archivo-Bold",
            fontSize: 18
          }}>
            Lengkapi profil kamu
          </Text>
          <Text style={{
            fontFamily: "Archivo-Regular",
            fontWeight: "600",
            fontSize: 12,
            marginTop: 24
          }}>Nama Lengkap</Text>
          <TextInput
            style={{
              width: "100%",
              backgroundColor: "#FFF",
              padding: 12,
              borderRadius: 10,
              marginTop: 10,
              fontSize: 12,
            }}
            placeholderTextColor={"#999"}
            placeholder={"Masukan nama lengkap"}
            onChange={(e) => {
              setProfile({
                ...profile, 
                name: e.target.value
              })
            }}
            onChangeText={(text) => {
              setProfile({
                ...profile, 
                name: text
              })
            }}
          />

          <Text style={{
            fontFamily: "Archivo-Regular",
            fontWeight: "600",
            fontSize: 12,
            marginTop: 24,
            marginTop: 16 
          }}>
            Usia
          </Text>
          <TextInput
            style={{
              width: "100%",
              backgroundColor: "#FFF",
              padding: 12,
              borderRadius: 10,
              marginTop: 10,
              fontSize: 12,
            }}
            placeholderTextColor={"#999"}
            placeholder={"Masukan usia"}
            onChange={(e) => {
              setProfile({
                ...profile, 
                usia: e.target.value
              })
            }}
            onChangeText={(text) => {
              setProfile({
                ...profile, 
                usia: text
              })
            }}
          />

          <Text style={{
            fontFamily: "Archivo-Regular",
            fontWeight: "600",
            fontSize: 12,
            marginTop: 24,
            marginTop: 16 
          }}>
            Jenis Kelamin
          </Text>
          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "center",
              alignItems: "center",
              gap: 16
            }}
          >
            <TouchableOpacity 
              onPress={() => {
                setProfile({
                  ...profile, 
                  gender: 'pria'
                })
              }}
              style={{
                width: "48%"
              }}
              >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  backgroundColor: "#FFF",
                  borderRadius: 10,
                  padding: 12,
                  paddingTop: 9,
                  width: "100%",
                  borderColor:profile?.gender === "pria" ? "#6724E2" : "#FFF",
                  borderWidth: 1
                }}
              >
                <CheckIcon fill={profile?.gender === "pria" ? "#6724E2" : "#000"}/>
                <Text style={{
                  fontFamily: "Archivo-Regular",
                  fontWeight: "600",
                  fontSize: 12,
                  color: profile?.gender === "pria" ? "#6724E2" : "#000"
                }}>Laki-Laki</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity 
              style={{
                width: "48%"
              }}
              onPress={() => {
                setProfile({
                  ...profile, 
                  gender: 'wanita'
                })
              }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  backgroundColor: "#FFF",
                  borderRadius: 10,
                  padding: 12,
                  paddingTop: 9,
                  width: "100%",
                  borderColor:profile?.gender === "wanita" ? "#6724E2" : "#FFF",
                  borderWidth: 1
                }}
              >
                <CheckIcon fill={profile?.gender === "wanita" ? "#6724E2" : "#000"}/>
                <Text style={{
                  fontFamily: "Archivo-Regular",
                  fontWeight: "600",
                  fontSize:12,
                  color: profile?.gender === "wanita" ? "#6724E2" : "#000",
                }}>Perempuan</Text>
              </View>
            </TouchableOpacity>
          </View>

          <Text 
          style={{
            fontFamily: "Archivo-Regular",
            fontWeight: "600",
            fontSize: 12,
            marginTop: 24,
            marginTop: 16 
          }}
          >Profesi</Text>
          <TouchableOpacity
            style={{
              backgroundColor: "#FFF",
              borderRadius: 10,
              padding: 12,
              marginTop: 10
            }}
            onPress={() => {
              setVisible(true);
            }}
          >
            <Text style={{
              fontFamily: "Archivo-Regular",
              fontWeight: "400",
              fontSize: 12,
              color: profile['profesi'] ? "#000" : "#999"
            }}>{
              profile['profesi'] ? profile['profesi'] : `Pilih`
            }</Text>
          </TouchableOpacity>

          <Pressable
            style={{
              backgroundColor: "#6724E2",
              color: "#FFF",
              alignItems: "center",
              padding: 12,
              borderRadius: 10,
              marginTop: 16,
            }}
            onPress={() => {
              setName(profile['name'], token)
              .then((res) => {
                setProfileData(profile, token)
                .then(async (res) => {
                  setMember(token);
                  await AsyncStorage.setItem("592f3e7", profile['name']);
                  navigation.navigate("Home");
                });
              });
            }}
          >
            <Text
              style={{
                color: "#FFF",
                fontFamily: "Archivo-Regular",
                fontWeight: "700", 
                fontSize: 12
              }}
            >
              Simpan
            </Text>
          </Pressable>
        </View>
      </View>
      <Modal isVisible={visible}>
        <View style={{
          backgroundColor: "#FFF",
          borderRadius: 10
        }}>
          <View style={{flexDirection: "row", justifyContent: "space-between", 
          padding: 12, paddingTop: 18, paddingBottom: 16,
          borderBottom: "solid",
          borderBottomWidth: 1,
          borderBottomColor: "#EAEAEA" }}>
            <Text style={{fontFamily: "Archivo-Regular", fontWeight: "700"}}>
              Pilih Profesi
            </Text>
            <TouchableOpacity onPress={() => {
              setVisible(false);
            }}>
              <CloseIcon/>
            </TouchableOpacity>
          </View>
          {
            profesi.map((p, index) => {
              return <View style={{
              padding: 12, paddingTop: 18, paddingBottom: 16, 
              borderBottom: "solid",
              borderBottomWidth: index == profesi?.length - 1 ? 0 : 1,
              borderBottomColor: "#EAEAEA"
               }}>
                <TouchableOpacity style={{
                  flexDirection: "row", justifyContent: "space-between", 
                }}
                  onPress={() => {
                    setProfile({
                      ...profile,
                      profesi: p
                    })
                    setVisible(false);
                  }}
                >
                  <Text>
                    {p}
                  </Text>
                  <ChevronRight/>
                </TouchableOpacity>
              </View>;
            })
          }
        </View>
      </Modal>
    </View>
  );
}

export default Verification;
