import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import { CheckCircle2Icon } from "../../../assets/icons";
import pricing from "./pricing.json";

export function CompanyProfilePricing({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>
        Pilihan Program Fammi Untuk Sekolah Anda
      </Text>
      <View style={styles("content")}>
        {pricing.map((item) => (
          <PriceCard key={item.id} data={item} styles={styles} />
        ))}
      </View>
    </View>
  );
}

function PriceCard({ data, styles }) {
  return (
    <View style={styles("card")}>
      <View style={styles("cardHeader")}>
        <View style={styles("cardTitleBox")}>
          <Text style={styles("txCardTitle")}>{data.title}</Text>
          <Svg width="100%" height="100%" style={styles("overlay")}>
            <Defs>
              <LinearGradient
                id={`grad-${data.id}`}
                x1="60%"
                x2="0%"
                y1="0%"
                y2="0%"
              >
                <Stop offset={0} stopColor={data.colors[1]} />
                <Stop offset={1} stopColor={data.colors[0]} />
              </LinearGradient>
            </Defs>
            <Rect
              width="100%"
              height="100%"
              fill={`url(${`#grad-${data.id}`})`}
            />
          </Svg>
        </View>
        <View style={styles("cardHeaderDesc")}>
          <Text
            style={[
              styles("txCardDesc1"),
              {
                color: data.id === "right" ? data.colors[1] : data.colors[0],
              },
            ]}
          >
            {data.desc1}
          </Text>
          <Text style={styles("txCardDesc2")}>{data.desc2}</Text>
          {Platform.OS === "web" && (
            <Text
              style={[
                styles("txCardPrice"),
                {
                  color: data.colors[0],
                  fontSize: data.id === "left" ? 38 : 48,
                },
              ]}
            >
              {data.price}
            </Text>
          )}
        </View>
      </View>
      <View style={styles("cardContent")}>
        <View style={{ gap: 12 }}>
          <Text style={styles("txContent")}>{data.benefitDesc}</Text>
          <View style={{ gap: 14 }}>
            {data.benefits.map((item, idx) => (
              <View key={idx} style={styles("benefit")}>
                <CheckCircle2Icon
                  color={data.id === "right" ? data.colors[1] : data.colors[0]}
                  style={{ flexShrink: 0 }}
                />
                <Text style={styles("txContent")}>{item}</Text>
              </View>
            ))}
          </View>
          {data.id === "right" && (
            <View style={styles("screeningBox")}>
              <Text style={styles("txScreningBox")}>
                Alokasikan Dana BOS Bapak/Ibu Untuk Tes Psikologi Dari Fammi
              </Text>
            </View>
          )}
        </View>
        <View style={styles("cardFooter")}>
          <TouchableOpacity style={styles("button")}>
            <Text style={styles("txButton")}>Hubungi Fammi</Text>
          </TouchableOpacity>
          {data.id === "right" && (
            <Image
              source={require("../../../assets/images/siplah.png")}
              style={{
                width: 82,
                height: 32,
              }}
            />
          )}
        </View>
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    content: {
      flexDirection: "row",
      alignItems: "center",
      gap: 18,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    card: {
      backgroundColor: "#fff",
      width: 388,
      height: 787,
      borderRadius: 20,
    },
    cardTitleBox: {
      width: "100%",
      height: 82,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 20,
      position: "relative",
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: 20,
      zIndex: -1,
    },
    txCardTitle: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 28,
      lineHeight: 34,
      color: "#fff",
    },
    cardHeader: {
      backgroundColor: "#EAE5FF",
      alignItems: "center",
      borderRadius: 20,
    },
    cardHeaderDesc: {
      alignItems: "center",
      paddingVertical: 24,
    },
    txCardDesc1: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 26,
    },
    txCardDesc2: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 26,
      color: "#260C55",
    },
    txCardPrice: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 48,
      lineHeight: 50,
      marginTop: 12,
    },
    cardContent: {
      padding: 24,
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
    },
    txContent: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#260C55",
    },
    benefit: {
      flexDirection: "row",
      alignItems: "flex-start",
      gap: 12,
    },
    screeningBox: {
      paddingVertical: 12,
      paddingHorizontal: 16,
      borderRadius: 16,
      borderWidth: 1,
      borderColor: "#D12169",
    },
    txScreningBox: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 20,
      color: "#260C55",
    },
    cardFooter: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    button: {
      width: 182,
      backgroundColor: "#FDC52E",
      paddingVertical: 12,
      paddingHorizontal: 40,
      borderRadius: 24,
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 24,
      color: "#260C55",
    },
  },
  {
    container: { gap: 32 },
    content: {
      flexDirection: "column",
      gap: 32,
    },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    card: {
      width: "100%",
      height: 826,
    },
    txCardTitle: {
      fontSize: 26,
      lineHeight: 32,
    },
    cardContent: {
      padding: 24,
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
    },
    button: {
      width: 146,
      paddingHorizontal: 22,
    },
  }
);
