import { StyleSheet } from "react-native";
import { Defs, LinearGradient, Rect, Stop, Svg } from "react-native-svg";

export function GradientBackground({ style, id, coordinates, colors }) {
  return (
    <Svg
      width="100%"
      height="100%"
      style={[{ ...StyleSheet.absoluteFillObject, zIndex: -1 }, style]}
    >
      <Defs>
        <LinearGradient
          id={id}
          x1={coordinates?.x1 ?? "0%"}
          y1={coordinates?.y1 ?? "0%"}
          x2={coordinates?.x2 ?? "0%"}
          y2={coordinates?.y2 ?? "100%"}
        >
          {colors.map((color, idx) => (
            <Stop key={color} offset={idx} stopColor={color} />
          ))}
        </LinearGradient>
      </Defs>
      <Rect width="100%" height="100%" fill={`url(#${id})`} />
    </Svg>
  );
}
