import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const MessageIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#057A53"
          d="M3.84 24.856V13.394c0-5.29 4.293-9.564 9.583-9.564H32.59c5.29 0 9.583 4.274 9.583 9.564v13.379c0 5.27-4.293 9.545-9.583 9.545h-2.875c-.594 0-1.17.287-1.534.766l-2.875 3.814c-1.265 1.687-3.335 1.687-4.6 0l-2.875-3.814c-.306-.421-.996-.766-1.533-.766h-2.875c-5.29 0-9.583-4.275-9.583-9.545v-1.917Z"
          opacity={0.4}
        />
        <Path
          fill="#057A53"
          d="M23.006 23a1.91 1.91 0 0 1-1.916-1.917c0-1.054.862-1.917 1.916-1.917 1.055 0 1.917.863 1.917 1.917a1.91 1.91 0 0 1-1.916 1.916ZM30.67 23a1.91 1.91 0 0 1-1.916-1.917c0-1.054.862-1.917 1.917-1.917 1.054 0 1.916.863 1.916 1.917a1.91 1.91 0 0 1-1.916 1.916ZM15.335 23a1.91 1.91 0 0 1-1.917-1.917c0-1.054.863-1.917 1.917-1.917s1.916.863 1.916 1.917a1.91 1.91 0 0 1-1.916 1.916Z"
        />
      </Svg>
    </View>
  </View>
);
export default MessageIcon;
