import Svg, { Path, Circle } from "react-native-svg";
const Message2 = ({ customColor, circleColor }) => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill={customColor ?? "#fff"}
      d="M5.225 16.867v3.3l3.025-1.742c.825.275 1.742.367 2.75.367 5.042 0 9.167-3.759 9.167-8.525 0-4.675-4.125-8.433-9.167-8.433s-9.166 3.758-9.166 8.524c0 2.659 1.283 4.95 3.391 6.509Z"
    />
    <Circle cx={11} cy={10} r={2} fill={circleColor ?? "#6724E2"} />
  </Svg>
);
export default Message2;
