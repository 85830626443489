import { Image, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import {
  ArrowCircleRightIcon,
  FileCircleIcon,
  MessageCircleIcon,
  MessageTextCircleIcon,
  TickSquareCircleIcon,
} from "../../assets/icons";

export function CompanyProfileHows({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>Bagaimana Cara Fammi Mewujudkannya?</Text>
      <View style={styles("content")}>
        <StepCard
          title="Tes Psikologi"
          desc="Disusun & Dianalisis Oleh Psikolog"
          styles={styles}
        >
          <Image
            source={require("../../assets/images/tes-psikolog-company-profile.png")}
            style={styles("stepOneImg")}
          />
        </StepCard>
        <ArrowCircleRightIcon />
        <StepCard
          title="Dapatkan Laporan"
          desc="Untuk Individu & Institusi Anda"
          styles={styles}
        >
          <Image
            source={require("../../assets/images/dapatkan-laporan-company-profile.png")}
            style={styles("stepTwoImg")}
            resizeMode="contain"
          />
        </StepCard>
        <ArrowCircleRightIcon />
        <StepCard
          title="Intervensi Tepat"
          desc="Didampingi Ahli, Psikolog & Konselor"
          styles={styles}
        >
          <View style={{ gap: 18 }}>
            {[
              {
                Icon: TickSquareCircleIcon,
                label: "Panduan & Pendampingan Praktik Baik",
              },
              {
                Icon: MessageCircleIcon,
                label: "Forum Anonim Tanya Jawab dengan Ahli",
              },
              {
                Icon: MessageTextCircleIcon,
                label: "Konsultasi Privat",
              },
              {
                Icon: FileCircleIcon,
                label: "Modul Praktik Pembelajaran",
              },
            ].map((item, idx) => (
              <View key={idx} style={styles("list")}>
                <item.Icon style={{ flexShrink: 0 }} />
                <Text style={styles("txList")}>{item.label}</Text>
              </View>
            ))}
          </View>
        </StepCard>
      </View>
    </View>
  );
}

function StepCard({ title, desc, children, styles }) {
  return (
    <View style={styles("stepCard")}>
      <View style={styles("stepTitleBox")}>
        <Text style={styles("txStepTitle")}>{title}</Text>
      </View>
      <Text style={styles("txStepDesc")}>{desc}</Text>
      {children}
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      padding: 48,
      gap: 48,
      backgroundColor: "#fff",
      borderRadius: 40,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    content: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 3,
    },
    stepOneImg: { width: 215, height: 353 },
    stepTwoImg: { width: 294, height: 353 },
    stepCard: {
      width: 332,
      height: 520,
      paddingTop: 32,
      paddingHorizontal: 24,
      borderRadius: 20,
      alignItems: "center",
      backgroundColor: "#F5F0FF",
    },
    stepTitleBox: {
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 20,
      backgroundColor: "#6323DA",
    },
    txStepTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      color: "#FDFDFD",
      textAlign: "center",
    },
    txStepDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      marginTop: 24,
      marginBottom: 48,
      textAlign: "center",
    },
    list: {
      width: "100%",
      paddingVertical: 8,
      paddingHorizontal: 18,
      backgroundColor: "#FDFDFD",
      borderRadius: 10,
      flexDirection: "row",
      alignItems: "center",
      gap: 16,
    },
    txList: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 20,
    },
  },
  {
    container: {
      padding: 16,
      gap: 32,
    },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    content: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 2,
    },
    stepOneImg: { width: 190, height: 313 },
    stepTwoImg: { width: 270, height: 313 },
    stepCard: {
      width: "100%",
      height: 480,
    },
  }
);
