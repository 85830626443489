import Svg, { Circle, Path } from "react-native-svg"
const StatusUpIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <Circle cx={14} cy={14} r={14} fill="#FDFDFD" />
    <Path
      fill="#834FE3"
      d="M17.142 6.5h-6.284C8.127 6.5 6.5 8.127 6.5 10.857v6.278c0 2.737 1.627 4.365 4.357 4.365h6.278c2.73 0 4.357-1.628 4.357-4.358v-6.284c.008-2.73-1.62-4.358-4.35-4.358Zm-6.42 12.113a.567.567 0 0 1-.562.562.567.567 0 0 1-.563-.563V17.06c0-.307.255-.563.563-.563.307 0 .563.256.563.563v1.553Zm3.84 0a.567.567 0 0 1-.562.562.567.567 0 0 1-.563-.563V15.5c0-.307.256-.563.563-.563.307 0 .563.256.563.563v3.113Zm3.84 0a.567.567 0 0 1-.562.562.567.567 0 0 1-.563-.563v-4.664c0-.308.255-.563.563-.563.308 0 .563.255.563.563v4.665Zm0-7.035a.567.567 0 0 1-.562.562.567.567 0 0 1-.563-.563v-.727a14.65 14.65 0 0 1-6.982 4.02c-.045.015-.09.015-.135.015a.568.568 0 0 1-.547-.428.56.56 0 0 1 .412-.682 13.553 13.553 0 0 0 6.562-3.832h-.937a.567.567 0 0 1-.563-.563c0-.308.255-.563.563-.563h2.197c.03 0 .053.015.083.015.038.008.075.008.113.023.037.015.067.037.105.06.022.015.044.023.067.038.008.007.008.014.015.014.03.03.052.06.075.09s.045.053.053.083c.014.03.014.06.022.098.008.037.023.075.023.12 0 .007.007.014.007.022v2.197h-.008Z"
    />
  </Svg>
)
export default StatusUpIcon