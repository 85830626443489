import { BackHandler, ScrollView, View } from "react-native";
import { useTranslation } from "react-i18next";
import { AppBar, EmptyState, ListJournal } from "../components";
import { StyleSheet } from "react-native";
import { Fragment, useEffect, useState } from "react";
import { getJournals, inputList } from "../services/journals";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { BookmarkIcon } from "../assets/icons";
import { StackActions, useNavigation } from "@react-navigation/native";

function Journal({route}) {
  const {params} = route;
  const [journals, setJournals] = useState([]);
  const [members, setMembers] = useState({});
  const navigation = useNavigation();
  const { t } = useTranslation();

  const backToPage = () => {
    navigation.dispatch(StackActions.pop(params?.jumpCounter));
    return true;
  };

  if (params?.jumpCounter) {
    BackHandler.addEventListener('hardwareBackPress', backToPage);
  }

  useEffect(() => {
    async function getType() {
      const memberType = await AsyncStorage.getItem("792f3e7");
      return {
        memberId: memberType === "speaker" ? await AsyncStorage.getItem("token") : await AsyncStorage.getItem("token"),
        memberType: memberType
      }
    }

    getType()
    .then((res) => {
      setMembers(res);
      getJournals(res.memberId, "member")
      .then((res) => {
        const composedJournal = [];
        res?.data?.map((r) => {
          const contents = JSON.parse(r?.details);
          let writtenJournal = [];
          if (contents["1"]) {
            const input = inputList(t);
            if (r.speakerType) {
              input[r.speakerType].map((i) => {
                writtenJournal.push({
                  q: i.label, 
                  a: contents[i.id]
                });
              });
            }
          } else {
            writtenJournal.push(contents.message)
            writtenJournal = writtenJournal.join("");
          }
          composedJournal.push({
            id: r?.jouId,
            title: r?.consId,
            date: moment(r?.created).format("DD MMM YYYY"),
            content: writtenJournal,
            writer: r.speakerId,
            speakerPhoto: r.speakerPhoto
          });
        });
        setJournals(composedJournal);
      })
    });
  }, []);
  
  useEffect(() => {
    const writtenJournal = [];
    // const inputList = params?.inputList;
    // inputList.map((i) => {
    //   writtenJournal.push({
    //     q: i.label, 
    //     a: params.journal[i.id]
    //   });
    // });
    // setDummyData([
    //   ...dummyData,
    //   {
    //     id: 2, 
    //     title: "Diagnosa",
    //     date: "3 Mar 2023",
    //     qna: writtenJournal,
    //     writer: "Sofia Nurinawati, M.Psi., Psikolog",
    //   }
    // ])
    // dummyData.push();
  }, []);
  return (
    <Fragment>
      <AppBar title={t("journal:title")} isBack jumpCounter={params?.jumpCounter} />
      {
          journals.length === 0 &&
          <ScrollView contentContainerStyle={styles.container}>
            <View style={{
              flexDirection:"row",
              alignItems: "center",
              padding: 16,
              backgroundColor: "#EEEAFF",
              height: "100%",
              minHeight: "100%",
            }}>
              <EmptyState
                Icon={BookmarkIcon}
                title={t("journal:empty-title")}
                desc={t("journal:empty-desc")}
              />
            </View>
          </ScrollView>
        }
      <ScrollView style={styles.container}>
        {journals?.map((item, index) => (
          <ListJournal data={item} key={index} t={t}/>
        ))}
        <View style={{ marginBottom: 16 }} />
      </ScrollView>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
    flex: 1,
  },
});
export default Journal;
