import Svg, { Path } from "react-native-svg";
const PaperIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      fill="#000"
      d="M12.143 1.5H5.857C3.127 1.5 1.5 3.127 1.5 5.857v6.278c0 2.738 1.627 4.365 4.357 4.365h6.278c2.73 0 4.357-1.627 4.357-4.357V5.857c.008-2.73-1.62-4.357-4.35-4.357Zm-.33 10.313H6.186a.567.567 0 0 1-.562-.563c0-.307.255-.563.563-.563h5.625c.307 0 .562.256.562.563a.567.567 0 0 1-.563.563Zm0-4.5H6.186a.567.567 0 0 1-.562-.563c0-.308.255-.563.563-.563h5.625c.307 0 .562.255.562.563a.567.567 0 0 1-.563.563Z"
    />
  </Svg>
);
export default PaperIcon;
