import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { BigCheckIcon } from "../assets/icons";
import { AppBar, Snackbar } from "../components";
import { confirmCode } from "../services/screening";

function LockedScreening({route}) {
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [toast, setToast] = useState("");
  const navigation = useNavigation();
  const {additionalContentId, quizId, isScreening, fromAgenda, back} = route.params;

  const onSubmit = () => {
    if (!password) {
      setIsError(true);
      setToast("Silakan isi password screening terlebih dahulu");
      return;
    }

    confirmCode(additionalContentId, quizId, {
      passcode: password
    })
    .then((res) => {
      if (res.data?.passcode == password) {
        return navigation.navigate(
          isScreening === "0" ? "SurveyResult" : "ScreeningResult",
          { quizId, additionalContentId, isScreening, fromAgenda, back }
        );
      }
      
      setIsError(true);
      setToast("Password screening tidak sesuai");
    })
  };

  return (
    <React.Fragment>
      <AppBar title="Hasil Screening" isBack />
      <ScrollView contentContainerStyle={styles.container}>
        <View style={{
          ...styles.image,
          backgroundColor: "#FFF",
          borderRadius: 10,
        }}>
          <ImageBackground source={require("../assets/images/locked-screening-bg.png")} resizeMode="cover" style={{paddingTop: 80, alignItems: "center"}}>
            <BigCheckIcon />
            <Text style={styles.txTitle}>Screening Selesai</Text>
            <Text style={styles.txDesc}>
              Gunakan password untuk melihat hasil screening. Kamu bisa
              mendapatkan password di sekolah atau lembaga yang menyelenggarakan
              screening ini.
            </Text>
          </ImageBackground>
        </View>
        <View style={styles.border} />
        <View style={styles.footer}>
            <TextInput
              placeholder="Ketikan password di sini"
              value={password}
              onChangeText={(val) => setPassword(val)}
              onChange={(e) => setPassword(e.target.value)}
              placeholderTextColor="#999"
              style={[
                styles.inputText,
                isError && {
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#ff0000",
                  backgroundColor: "#ffffff"
                }
              ]}
            />
            <TouchableOpacity onPress={onSubmit} style={styles.button}>
              <Text style={styles.txBtn}>Buka Hasil Screening</Text>
            </TouchableOpacity>
          </View>
        {/* <View styles={styles.card}> */}
        {/* source={require("../assets/images/locked-screening-bg.png")} */}
          {/* <View
            style={{
              ...styles.image,
              borderRadius: 10,
              paddingTop: 100,
              borderWidth: 1,
              height: 400
            }}
          >
            <BigCheckIcon />
            <Text style={styles.txTitle}>Screening Selesai</Text>
            <Text style={styles.txDesc}>
              Gunakan password untuk melihat hasil screening. Kamu bisa
              mendapatkan password di sekolah atau lembaga yang menyelenggarakan
              screening ini.
            </Text>
          </View>
          <View style={styles.border} />
          <View style={styles.footer}>
            <TextInput
              placeholder="Ketikan password di sini"
              value={password}
              onChangeText={(val) => setPassword(val)}
              onChange={(e) => setPassword(e.target.value)}
              placeholderTextColor="#999"
              style={[
                styles.inputText,
                isError && {
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#ff0000",
                  backgroundColor: "#ffffff"
                }
              ]}
            />
            <TouchableOpacity onPress={onSubmit} style={styles.button}>
              <Text style={styles.txBtn}>Buka Hasil Screening</Text>
            </TouchableOpacity>
          </View>
        </View> */}
      </ScrollView>
      <Snackbar
        title={toast}
        visible={isError}
        hideDialog={() => setIsError(false)}
      />
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#EEEAFF"
  },
  card: {
    flexDirection: "column"
  },
  image: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    padding: 24,
    paddingBottom: 32
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    marginTop: 8
  },
  txDesc: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    textAlign: "center",
    lineHeight: 16,
    marginTop: 8
  },
  border: {
    height: 1,
    width: "90%",
    alignSelf: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#C9C1EC",
    borderStyle: "dashed"
  },
  footer: {
    padding: 12,
    paddingTop: 16,
    backgroundColor: "#fff",
    borderRadius: 10,
    flexDirection: "column",
    gap: 12
  },
  inputText: {
    width: "100%",
    backgroundColor: "#F3F3F3",
    borderWidth: 1,
    borderColor: "#eaeaea",
    borderStyle: "solid",
    borderRadius: 10,
    padding: 12,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    height: 36
  },
  button: {
    backgroundColor: "#6724E2",
    borderRadius: 10,
    width: "100%",
    height: 36,
    justifyContent: "center",
    alignItems: "center"
  },
  txBtn: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    color: "#FFF"
  }
});

export default LockedScreening;
