import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./resources/english.json";
import indonesia from "./resources/indonesia.json";

const RESOURCES = {
  id: indonesia,
  en: english,
};

i18n.use(initReactI18next).init({
  resources: RESOURCES,
  lng: "id",
  fallbackLng: "id",
  interpolation: {
    escapeValue: false, // not needed for react as it does escape per default to prevent xss!
  },
});

export default i18n;
