import * as React from "react";
import Svg, { Path } from "react-native-svg";
const EmojiSadIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      opacity={0.4}
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
      fill="#E54646"
    />
    <Path
      d="M10.6399 9.5C10.4499 9.5 10.2599 9.43 10.1099 9.28C9.39994 8.57 8.23994 8.57 7.52994 9.28C7.23994 9.57 6.75994 9.57 6.46994 9.28C6.17994 8.99 6.17994 8.51 6.46994 8.22C7.75994 6.93 9.86994 6.93 11.1699 8.22C11.4599 8.51 11.4599 8.99 11.1699 9.28C11.0199 9.43 10.8299 9.5 10.6399 9.5Z"
      fill="#E54646"
    />
    <Path
      d="M13.3598 9.5C13.1698 9.5 12.9798 9.43 12.8298 9.28C12.5398 8.99 12.5398 8.51 12.8298 8.22C14.1198 6.93 16.2298 6.93 17.5298 8.22C17.8198 8.51 17.8198 8.99 17.5298 9.28C17.2398 9.57 16.7598 9.57 16.4698 9.28C15.7598 8.57 14.5998 8.57 13.8898 9.28C13.7498 9.43 13.5598 9.5 13.3598 9.5Z"
      fill="#E54646"
    />
    <Path
      d="M12.0001 11.92C9.31012 11.92 7.12012 14.11 7.12012 16.8C7.12012 17.5 7.69012 18.08 8.39012 18.08H15.5901C16.2901 18.08 16.8601 17.51 16.8601 16.8C16.8801 14.11 14.6901 11.92 12.0001 11.92Z"
      fill="#E54646"
    />
  </Svg>
);
export default EmojiSadIcon;
