import * as React from "react";
import Svg, { Path } from "react-native-svg";
const CheckCircleIcon = ({ isSelected, fill, ...props }) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M10.9997 1.83398C5.94884 1.83398 1.83301 5.94982 1.83301 11.0007C1.83301 16.0515 5.94884 20.1673 10.9997 20.1673C16.0505 20.1673 20.1663 16.0515 20.1663 11.0007C20.1663 5.94982 16.0505 1.83398 10.9997 1.83398ZM15.3813 8.89232L10.1838 14.0898C10.0549 14.2186 9.8802 14.2909 9.69801 14.2909C9.51582 14.2909 9.34108 14.2186 9.21217 14.0898L6.61801 11.4957C6.49015 11.3663 6.41845 11.1917 6.41845 11.0098C6.41845 10.8279 6.49015 10.6534 6.61801 10.524C6.88384 10.2582 7.32384 10.2582 7.58967 10.524L9.69801 12.6323L14.4097 7.92065C14.6755 7.65482 15.1155 7.65482 15.3813 7.92065C15.6472 8.18648 15.6472 8.61732 15.3813 8.89232Z"
      fill={fill ?? (isSelected ? "#4EB826" : "#C2C2C2")}
    />
  </Svg>
);
export default CheckCircleIcon;
