import Svg, { Circle, Path } from "react-native-svg";
const CameraIcon = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
    <Circle cx={15} cy={15} r={15} 
    fill={props?.fill ? props?.fill : "#EEEAFF"}
    />
    <Path
      fill={props?.innerFill ? props?.innerFill : "#6724E2"}
      d="M21.863 10.627c-.308-.165-.953-.337-1.83.278l-1.103.78c-.082-2.333-1.095-3.248-3.555-3.248h-4.5c-2.565 0-3.563.998-3.563 3.563v6c0 1.725.938 3.563 3.563 3.563h4.5c2.46 0 3.473-.915 3.555-3.248l1.102.78c.465.33.87.435 1.193.435.277 0 .495-.082.637-.158.308-.157.826-.584.826-1.657v-5.43c0-1.072-.518-1.5-.825-1.658Zm-7.613 3.908c-.773 0-1.41-.63-1.41-1.41 0-.78.637-1.41 1.41-1.41.773 0 1.41.63 1.41 1.41 0 .78-.637 1.41-1.41 1.41Z"
    />
  </Svg>
);
export default CameraIcon;
