import Svg, { Circle, Path } from "react-native-svg";
const ChatIcon2 = ({ customColor, isActive, fill, ...props }) => (
  <Svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <Circle cx="15" cy="15" r="15" fill="#EEEAFF"/>
    <Path d="M18.75 7.5H11.25C9.18 7.5 7.5 9.1725 7.5 11.235V16.47C7.5 18.5325 9.18 20.205 11.25 20.205H12.375C12.5775 20.205 12.8475 20.34 12.975 20.505L14.1 21.9975C14.595 22.6575 15.405 22.6575 15.9 21.9975L17.025 20.505C17.1675 20.3175 17.3925 20.205 17.625 20.205H18.75C20.82 20.205 22.5 18.5325 22.5 16.47V11.235C22.5 9.1725 20.82 7.5 18.75 7.5ZM12 15C11.58 15 11.25 14.6625 11.25 14.25C11.25 13.8375 11.5875 13.5 12 13.5C12.4125 13.5 12.75 13.8375 12.75 14.25C12.75 14.6625 12.42 15 12 15ZM15 15C14.58 15 14.25 14.6625 14.25 14.25C14.25 13.8375 14.5875 13.5 15 13.5C15.4125 13.5 15.75 13.8375 15.75 14.25C15.75 14.6625 15.42 15 15 15ZM18 15C17.58 15 17.25 14.6625 17.25 14.25C17.25 13.8375 17.5875 13.5 18 13.5C18.4125 13.5 18.75 13.8375 18.75 14.25C18.75 14.6625 18.42 15 18 15Z" fill="#6724E2"/>
  </Svg>
);
export default ChatIcon2;
