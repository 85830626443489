import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { ArrowLeft } from "../assets/icons";
import { StackActions, useNavigation } from "@react-navigation/native";

function AppBar(props) {
  const { title, isBack, jump, jumpCounter, rightSection, params } = props;
  const navigation = useNavigation();
  return (
    <View style={styles.parent}>
      {isBack && (
        <TouchableOpacity
          onPress={() => {
            if (jumpCounter) {
              return navigation.dispatch(StackActions.pop(jumpCounter));
            }
            if (jump && params) {
              return navigation.navigate(jump, {
                ...params
              }); 
            }
            if (jump) {
              return navigation.navigate(jump);
            }

            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate("Home");
            }
          }}
          style={styles.btnBack}
        >
          <ArrowLeft />
        </TouchableOpacity>
      )}
      <Text style={styles.text}>{title}</Text>
      {rightSection && (
        <View style={{ position: "absolute", right: 16, top: 12 }}>
          {rightSection}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  parent: {
    width: "100%",
    paddingTop: 20,
    paddingRight: 16,
    paddingBottom: 20,
    paddingLeft: 16,
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowColor: "rgba(0, 0, 0, 0.05)",
    shadowOpacity: 1,
  },
  text: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    color: "#000000",
  },
  btnBack: {
    marginRight: 12,
  },
});
export default AppBar;
