import * as React from "react";
import Svg, { Path } from "react-native-svg";
const Close2Icon = (props) => (
  <Svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05737 9.05744C9.30741 8.80748 9.64649 8.66706 10 8.66706C10.3536 8.66706 10.6927 8.80748 10.9427 9.05744L16 14.1148L21.0574 9.05744C21.1804 8.93009 21.3275 8.82852 21.4902 8.75864C21.6528 8.68876 21.8278 8.65198 22.0048 8.65044C22.1819 8.6489 22.3574 8.68264 22.5213 8.74968C22.6852 8.81672 22.834 8.91572 22.9592 9.04091C23.0844 9.1661 23.1834 9.31497 23.2505 9.47884C23.3175 9.6427 23.3512 9.81827 23.3497 9.99531C23.3482 10.1723 23.3114 10.3473 23.2415 10.51C23.1716 10.6727 23.0701 10.8198 22.9427 10.9428L17.8854 16.0001L22.9427 21.0574C23.1856 21.3089 23.32 21.6457 23.3169 21.9953C23.3139 22.3449 23.1737 22.6793 22.9265 22.9265C22.6793 23.1737 22.3448 23.314 21.9952 23.317C21.6456 23.32 21.3088 23.1857 21.0574 22.9428L16 17.8854L10.9427 22.9428C10.6912 23.1857 10.3544 23.32 10.0048 23.317C9.65524 23.314 9.32083 23.1737 9.07362 22.9265C8.82641 22.6793 8.68618 22.3449 8.68314 21.9953C8.6801 21.6457 8.8145 21.3089 9.05737 21.0574L14.1147 16.0001L9.05737 10.9428C8.80741 10.6927 8.66699 10.3537 8.66699 10.0001C8.66699 9.64656 8.80741 9.30748 9.05737 9.05744Z"
      fill="#6724E2"
    />
  </Svg>
);
export default Close2Icon;
