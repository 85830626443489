import Svg, { Path } from "react-native-svg";
const TicketIcon = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M12.487 2.895H7.432V5.16c0 .293-.24.525-.525.525a.527.527 0 0 1-.525-.525V2.895h-.87c-2.963 0-3.938.885-4.005 3.653a.509.509 0 0 0 .15.375.493.493 0 0 0 .375.157c1.05 0 1.912.87 1.912 1.92 0 1.05-.862 1.92-1.912 1.92a.504.504 0 0 0-.375.158.509.509 0 0 0-.15.375c.067 2.767 1.042 3.652 4.005 3.652h.87V12.84c0-.293.24-.525.525-.525.285 0 .525.232.525.525v2.265h5.055c3.075 0 4.013-.938 4.013-4.013V6.908c0-3.075-.938-4.013-4.013-4.013Zm1.365 6.03-.697.675c-.03.023-.038.068-.03.105l.165.953a.46.46 0 0 1-.188.457.459.459 0 0 1-.488.037l-.862-.45a.128.128 0 0 0-.105 0l-.863.45a.466.466 0 0 1-.675-.495l.165-.952a.136.136 0 0 0-.03-.105l-.697-.675a.462.462 0 0 1 .02-.687.442.442 0 0 1 .235-.1l.96-.143a.15.15 0 0 0 .09-.06l.428-.87a.47.47 0 0 1 .832 0l.428.87c.015.038.045.06.082.06l.96.143c.18.022.322.15.375.315a.43.43 0 0 1-.105.472Z"
    />
  </Svg>
);
export default TicketIcon;
