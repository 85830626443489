import { useMemo } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ChevronRight } from "../../assets/icons";

export const ScreeningRespondentCard = ({
  total,
  data,
  onWatchRespondent,
  keyGroup,
}) => {
  function groupDataByRecId(data) {
    let groupedData = {};

    data.forEach((item) => {
      const id = item[keyGroup];
      if (!groupedData[id]) {
        groupedData[id] = item;
        groupedData[id].totalpeople =
          data?.filter((d) => d[keyGroup] === id)?.length ?? 1;
      } else {
        groupedData[id].totalpeople =
          data?.filter((d) => d[keyGroup] === id)?.length ?? 1;
        groupedData[id].totalpoin = (
          parseFloat(groupedData[id].totalpoin) + parseFloat(item.totalpoin)
        ).toString();
      }
    });

    const result = Object.values(groupedData);

    return result;
  }

  const groupedData = groupDataByRecId(data);
  const totalPeople = () => {
    return groupedData?.reduce((a, b) => a + parseInt(b.totalpeople), 0);
  };

  const maxValue = useMemo(() => {
    return Math.max(...groupedData.map((d) => parseInt(d.totalpeople) * 100 / totalPeople() ));
  }, [groupedData]);

  const totalPoin = () => {
    return groupedData?.reduce((a, b) => a + parseInt(b.totalpoin), 0);
  };

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <View style={styles.header}>
          <Text style={styles.txTitle}>{`Total Responden ${
            totalPeople() ?? 0
          } Orang`}</Text>
        </View>
        <View style={styles.list}>
          {groupedData.map((d, id) => {
            const percent = Math.round(
              (parseInt(d.totalpeople) / totalPeople()) * 100
            );
            // console.log(maxValue , parseInt(d.totalpoin));
            return (
              <View key={id} style={styles.data}>
                <View style={styles.dataLabel}>
                  <Text style={styles.txDataLabel}>{d.summary}</Text>
                  <Text style={[styles.txDataLabel, { color: "#999" }]}>
                    {parseInt(d.totalpeople)} Orang ({percent}%)
                  </Text>
                </View>
                <View style={styles.bar}>
                  <View
                    style={[
                      styles.totalBar,
                      {
                        width: `${percent}%`,
                        backgroundColor:
                          maxValue <= percent
                            ? "#6724E2"
                            : "#999",
                      },
                    ]}
                  />
                </View>
              </View>
            );
          })}
        </View>
      </View>

      <TouchableOpacity style={styles.button} onPress={onWatchRespondent}>
        <Text style={styles.txButton}>Lihat Responden</Text>
        <ChevronRight />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 10,
    width: "100%",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  header: {
    paddingVertical: 14,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea",
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
  },
  list: {
    paddingVertical: 14,
    paddingHorizontal: 12,
    flexDirection: "column",
    gap: 10,
  },
  data: {
    flexDirection: "column",
    gap: 10,
  },
  dataLabel: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  txDataLabel: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    maxWidth: "80%",
  },
  bar: {
    width: "100%",
    height: 6,
    borderRadius: 10,
    backgroundColor: "#eaeaea",
  },
  totalBar: {
    height: 6,
    borderRadius: 10,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingVertical: 14,
    paddingHorizontal: 12,
  },
  txButton: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#6724E2",
  },
});
