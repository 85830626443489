import { Image, Text, TouchableOpacity, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { ArrowRightIcon } from "../../assets/icons";

export function ProgramAntiBullyingLaporanTes({ layout }) {
  const styles = responsiveStyles(layout);

  return (
    <View style={styles("container")}>
      <View style={styles("content")}>
        <Text style={styles("txTitle")}>
          Lihat Laporan Hasil Tes Fammi untuk Lembaga
        </Text>
        <Text style={styles("txDesc")}>
          Untuk Melihat Laporan Hasil Tes dan Mendapatkan Informasi yang Lebih
          lengkap Sesuai Dengan Kebutuhan Anda, Kami Mengundang Anda untuk
          Menghubungi Tim Fammi.
        </Text>
        <TouchableOpacity style={styles("button")}>
          <Text style={styles("txButton")}>Hubungi Fammi</Text>
          <ArrowRightIcon />
        </TouchableOpacity>
      </View>
      <Image
        source={require("../../assets/images/anti-bullying/laporan-tes.png")}
        style={styles("banner")}
      />
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      borderRadius: 40,
      backgroundColor: "#FDC52E",
      flexDirection: "row",
      alignItems: "center",
      gap: 68,
      padding: 48,
    },
    banner: {
      width: 495,
      height: 364,
      borderRadius: 20,
    },
    content: {
      flexDirection: "column",
      alignItems: "flex-start",
      maxWidth: 542,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 34,
      color: "#0A0316",
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#0A0316",
      marginTop: 16,
      marginBottom: 32,
    },
    button: {
      paddingVertical: 12,
      paddingHorizontal: 22,
      borderRadius: 24,
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
      backgroundColor: "#260C55",
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 24,
      color: "#fff",
    },
  },
  {
    container: {
      flexDirection: "column",
      alignItems: "center",
      padding: 16,
    },
    banner: {
      width: "100%",
      height: 415,
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      borderBottomLeftRadius: 250,
      borderBottomRightRadius: 250,
    },
    content: {
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "unset",
      paddingBottom: 44,
    },
    txTitle: { textAlign: "center" },
    txDesc: {
      marginTop: 24,
      marginBottom: 48,
      textAlign: "center",
    },
  }
);
