import axios from "axios";
import moment from "moment";
import {API_URL, API_PAYMENT_URL} from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const addToCart = async (transactionID, item) => {
    const payload = {
        transaction_id: transactionID,
        transaction_voucher_id: "",
        member_id: await AsyncStorage.getItem("token"),
        amount: item.discountPrice ? item.discountPrice : item.normalPrice,
        discount: 0,
        total: item.discountPrice ? item.discountPrice : item.normalPrice,
        unique_code: 0,
        code:
          "INV-" +
          moment().format("YYYY/MM/DD-" + parseInt(Math.random() * 10000)),
        status: 1,
        note: JSON.stringify(item.note),
        va_number: "",
        payment_method: item.paymentMethod,
        created: moment().format("YYYY-MM-DD HH:mm:ss").toString(),
        product_id: item?.productId,
    };
    return axios.post(API_URL + '/addtocartsp', payload);
}

export const getTransaction = (transactionId) => {
  return axios.get(`${API_URL}/transactions/theid/${transactionId}`);
}

export const getVaCode = (payload) => {
  return axios.post(`${API_PAYMENT_URL}`, payload);
}

export const setVa = (item) => {
  return axios.post(API_URL + `/transactions/setva/`, {
    transactionId: item.transactionId,
    paymentMethod: item.paymentMethod, 
    vaNumber: item.vaNumber 
  });
}

export const getTransactionByMember = (memberId, transactionId) => {
  return axios.get(API_URL + `/transaction-package/${memberId}/${transactionId}`)
}

export const buyWithCredit = (memberId, type, status, payload) => {
  return axios.post(`${API_URL}/buywithcredit/${memberId}/${type}/${status?.replace("-", "")}`, payload);
}

export const getTransactions = (memberId) => {
  return axios.get(`${API_URL}/transactions/${memberId}`);
}

export const emoney = {
  gopay: {
    name: "GoPay",
    appName: "Gojek"
  },
  shopeepay: {
    name: "ShopeePay",
    appName: "Shopee"
  }
};

export const checkVoucher = (voucher) => {
  return axios.post(`${API_URL}/check-vouchers-kota/`, {
    code: voucher
  });
}


export const getVoucher = (item) => {
  return axios.post(`${API_URL}/vouchers-kota/${item.transactionId}`, item);
}