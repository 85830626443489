import * as React from "react";
import Svg, { Path } from "react-native-svg";
const InfoCircleIcon = (props) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M10.4998 18.3334C15.0832 18.3334 18.8332 14.5834 18.8332 10.0001C18.8332 5.41675 15.0832 1.66675 10.4998 1.66675C5.9165 1.66675 2.1665 5.41675 2.1665 10.0001C2.1665 14.5834 5.9165 18.3334 10.4998 18.3334Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.5 6.66675V10.8334"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.4951 13.3333H10.5026"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default InfoCircleIcon;
