import { Image, ScrollView } from "react-native";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import sponsor from "./sponsor.json";

export function CompanyProfileSponsor({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles("container")}
    >
      {sponsor.map((item) => (
        <Image
          key={item.id}
          style={{ width: item.width, height: item.height }}
          source={require(`../../../assets/images/sponsor/${item.id}.png`)}
        />
      ))}
    </ScrollView>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      flexDirection: "row",
      gap: 24,
      marginTop: 84,
    },
  },
  {
    container: {
      gap: 24,
      flexWrap: "wrap",
    },
  }
);
