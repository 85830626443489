import * as React from "react";
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";
const Verify2Icon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_330_950)">
      <Path
        d="M6.28516 9.00006L8.09266 10.8151L11.7152 7.18506"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.06266 1.83773C8.58016 1.39523 9.42766 1.39523 9.95266 1.83773L11.1377 2.85773C11.3627 3.05273 11.7827 3.21023 12.0827 3.21023H13.3577C14.1527 3.21023 14.8052 3.86273 14.8052 4.65773V5.93273C14.8052 6.22523 14.9627 6.65273 15.1577 6.87773L16.1777 8.06273C16.6202 8.58023 16.6202 9.42773 16.1777 9.95273L15.1577 11.1377C14.9627 11.3627 14.8052 11.7827 14.8052 12.0827V13.3577C14.8052 14.1527 14.1527 14.8052 13.3577 14.8052H12.0827C11.7902 14.8052 11.3627 14.9627 11.1377 15.1577L9.95266 16.1777C9.43516 16.6202 8.58766 16.6202 8.06266 16.1777L6.87766 15.1577C6.65266 14.9627 6.23266 14.8052 5.93266 14.8052H4.63516C3.84016 14.8052 3.18766 14.1527 3.18766 13.3577V12.0752C3.18766 11.7827 3.03016 11.3627 2.84266 11.1377L1.83016 9.94523C1.39516 9.42773 1.39516 8.58773 1.83016 8.07023L2.84266 6.87773C3.03016 6.65273 3.18766 6.23273 3.18766 5.94023V4.65023C3.18766 3.85523 3.84016 3.20273 4.63516 3.20273H5.93266C6.22516 3.20273 6.65266 3.04523 6.87766 2.85023L8.06266 1.83773Z"
        stroke="black"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_330_950">
        <Rect width={18} height={18} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default Verify2Icon;
