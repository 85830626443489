import Svg, { Circle, Path } from "react-native-svg"
const LoveIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <Circle cx={32} cy={32} r={30.5} stroke="#FD2E92" strokeWidth={3} />
    <Path
      fill="#FD2E92"
      d="M44.446 25.809c0 .238 0 .475-.015.697-2.423-.903-5.273-.349-7.173 1.361a7.014 7.014 0 0 0-4.718-1.805c-3.895 0-7.062 3.183-7.062 7.11 0 4.48 2.248 7.758 4.402 9.88a1.674 1.674 0 0 1-.444-.096c-4.1-1.409-13.268-7.235-13.268-17.147 0-4.37 3.515-7.9 7.853-7.9 2.581 0 4.861 1.234 6.286 3.15a7.85 7.85 0 0 1 6.286-3.15c4.338 0 7.853 3.53 7.853 7.9Z"
      opacity={0.4}
    />
    <Path
      fill="#FD2E92"
      d="M41.499 28.184a5.24 5.24 0 0 0-4.18 2.09 5.218 5.218 0 0 0-4.18-2.09c-2.882 0-5.225 2.343-5.225 5.256 0 1.124.174 2.154.49 3.104 1.49 4.702 6.065 7.505 8.33 8.28.316.111.838.111 1.17 0 2.265-.775 6.84-3.578 8.33-8.28.316-.966.49-1.995.49-3.104 0-2.913-2.343-5.256-5.225-5.256Z"
    />
  </Svg>
)
export default LoveIcon
