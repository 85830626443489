import { Text, View, StyleSheet, Pressable, Image } from "react-native";
import moment from "moment";
import CameraIcon from "../assets/icons/CameraIcon";
import AlertIcon from "../assets/icons/AlertIcon";
import ProfileTick from "../assets/icons/ProfileTick";
import { useNavigation } from "@react-navigation/native";
import DocumentCircle from "../assets/icons/DocumentCircle";
import { EyeIcon } from "../assets/icons";
import { getMaterial } from "../services/materials";
import { useEffect, useState } from "react";

function MaterialAgendaCard(props) {
  const navigation = useNavigation();
  const {data, additionalContentId, memberId, hideDate} = props;
  const [detail, setDetail] = useState({});
  const [misc, setMisc] = useState(undefined);

  moment().locale("id");

  useEffect(() => {
    getMaterial(data?.materialId)
    .then((res) => {
      setDetail(res?.data[0]);
    });

    setMisc(JSON.parse(data?.misc));
  }, [data]);

  return (
      <Pressable style={styles.wrapperCard}
            onPress={() => {
              navigation.navigate("ReadEbook", {
                url: `https://fammi.ly/uploads/event_materials/document/${detail?.eventMaterialId}.pdf`, 
                title: detail?.title,
                materialId: detail?.eventMaterialId,
                additionalContentId: additionalContentId,
                memberId: memberId
              });
            }}
          >
      {
        !hideDate &&
        <View style={styles.flexRow}>
          <View style={styles.dot} />
          <Text style={styles.dateTx}>
            {
              data?.scheduledDate &&
              moment(data?.scheduledDate).format("dddd, DD MMM YYYY")
            }
          </Text>
        </View>
      }
      <View style={styles.cardParent}>
        <View style={styles.mainContent}>
          <View style={styles.topSection}>
            <DocumentCircle />
            <View style={styles.parentItem}>
              <Text style={styles.txTitle}>Akses Materi PDF</Text>
            </View>
          </View>
          <View style={styles.topSection}>
            <Image
              source={{
                uri: `https://fammi.ly/uploads/event_materials/images/${detail?.cover}`,
              }}
              style={styles.imgAva}
              height={30}
              width={30}
            />
            <View style={{...styles.parentItem, whiteSpace: "no-wrap", width: "86%", overflow: "hidden"}}>
              <Text
                style={{ ...styles.txTitle }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {
                  detail?.title
                }
              </Text>
              {
                misc &&
                <Text style={styles.txSpan} ellipsizeMode="tail" numberOfLines={1}>{misc.speaker_name}</Text>
              }
            </View>
          </View>
        </View>
        <View style={styles.bottomSection}>
          <View style={styles.buttonRight}
            // onPress={() => {
            //   navigation.navigate("ReadEbook", {
            //     url: `https://fammi.ly/uploads/event_materials/document/${detail?.eventMaterialId}.pdf`, 
            //     title: detail?.title,
            //     materialId: detail?.eventMaterialId,
            //     additionalContentId: additionalContentId,
            //     memberId: memberId
            //   });
            // }}
          >
            <EyeIcon outlined />
            <Text
              style={{
                ...styles.txMain,
                marginLeft: 4,
                color: "#6724E2",
                fontFamily: "Archivo-SemiBold",
              }}
            >
              Baca Materi
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
}
const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10
  },
  dot: {
    width: 6,
    height: 15,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: "#6724E2"
  },
  dateTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  wrapperCard: {
    paddingHorizontal: 16,
    // paddingVertical: 12,
    paddingLeft: 0
  },
  cardParent: {
    backgroundColor: "#fff",
    borderRadius: 10,
    marginLeft: 12,
    marginTop: 12
  },
  mainContent: {
    padding: 12,
    paddingBottom: 0,
  },
  parentItem: {
    marginLeft: 10,
  },
  txTitle: {
    color: "#000",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
  },
  txDateTime: {
    color: "#4EB826",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
  },
  imgAva: {
    height: 40,
    width: 30,
    borderRadius: 6,
  },
  txSpan: {
    color: "#999",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
  },
  txMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: 16,
  },
  bottomSection: {
    padding: 12,
    borderTopWidth: 1,
    borderTopColor: "#EAEAEA",
    borderStyle: "solid",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonLeft: {
    width: "47.5%",
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderColor: "#EAEAEA",
    borderWidth: 1,
    borderRadius: 8,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRight: {
    width: "100%",
    backgroundColor: "#EEEAFF",
    borderRadius: 8,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default MaterialAgendaCard;
