import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const MessageTextCircleIcon = (props) => (
  <Svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={22} cy={22} r={22} fill="#EEEAFF" />
    <Path
      d="M27.0027 12.002H17.0028C14.2429 12.002 12.0029 14.2319 12.0029 16.9819V22.9618V23.9617C12.0029 26.7117 14.2429 28.9417 17.0028 28.9417H18.5028C18.7728 28.9417 19.1328 29.1217 19.3028 29.3417L20.8028 31.3316C21.4628 32.2116 22.5427 32.2116 23.2027 31.3316L24.7027 29.3417C24.8927 29.0917 25.1927 28.9417 25.5027 28.9417H27.0027C29.7626 28.9417 32.0026 26.7117 32.0026 23.9617V16.9819C32.0026 14.2319 29.7626 12.002 27.0027 12.002ZM23.0027 23.7517H17.0028C16.5929 23.7517 16.2529 23.4118 16.2529 23.0018C16.2529 22.5918 16.5929 22.2518 17.0028 22.2518H23.0027C23.4127 22.2518 23.7527 22.5918 23.7527 23.0018C23.7527 23.4118 23.4127 23.7517 23.0027 23.7517ZM27.0027 18.7518H17.0028C16.5929 18.7518 16.2529 18.4118 16.2529 18.0018C16.2529 17.5919 16.5929 17.2519 17.0028 17.2519H27.0027C27.4127 17.2519 27.7527 17.5919 27.7527 18.0018C27.7527 18.4118 27.4127 18.7518 27.0027 18.7518Z"
      fill="#6724E2"
    />
  </Svg>
);
export default MessageTextCircleIcon;
