import React from "react";
import { StyleSheet, View, Text } from "react-native";

function ListIntelligences(props) {
  const { item, index } = props;
  return (
    <View style={[styles.parentList, { backgroundColor: item.thirdColor }]}>
      {item.icon}
      <View style={styles.flexRow}>
        <View
          style={{
            ...styles.parentNumbering,
            marginRight: -4,
            backgroundColor: item.secondColor,
          }}
        >
          <Text style={{ ...styles.textNumbering, color: item.color }}>
            {index+1*1}
          </Text>
        </View>
        <View
          style={{
            ...styles.parentNumbering,
            backgroundColor: item.secondColor,
          }}
        >
          <Text style={{ ...styles.textNumbering, color: item.color }}>
            {item.title}
          </Text>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    borderRadius: 13,
    paddingHorizontal: 10,
    paddingVertical: 16,
    flexDirection: "column",
    alignItems: "center",
    width: "32%",
  },
  flexRow: {
    flexDirection: "row",
    marginTop: 32,
  },
  parentNumbering: {
    backgroundColor: "#DCF4CA",
    borderRadius: 100 / 2,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  textNumbering: {
    color: "#3E951D",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
  },
});
export default ListIntelligences;
