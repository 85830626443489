import React, { Fragment } from "react";
import { StyleSheet, Text, View } from "react-native";
import LoveIcon from "../../../assets/icons/report-test/LoveIcon";
import MedalIcon from "../../../assets/icons/report-test/MedalIcon";
import LikeShapesIcon from "../../../assets/icons/report-test/LikeShapesIcon";
import ListStatic from "../ListStatic";

function IntroductionPage(props) {
  const { isSecondSection } = props;
  const lists = [
    {
      id: 1,
      title: "Minat",
      description:
        "Minat (atau interest) merupakan kecenderungan seseorang untuk menyukai objek-objek atau kegiatan-kegiatan yang membutuhkan perhatian dan menghasilkan kepuasan. Minat pada anak terbagi menjadi dua, yaitu:",
      subDescription: [
        "Minat pribadi, yakni sesuatu yang menjadi kesukaan anak, seperti mobil-mobilan, lego, atau mata pelaiaran tertentu.",
        "Minat situasional, yakni minat yang muncul ketika seseorang atau suatu kegiatan dapat menarik perhatian dan membuat anak terlibat sampai muncul rasa puas pada anak. Misalnya aktivitas olahraga di sekolah membuat anak mau ikut mengikuti kegiatantersebut secara berulang.",
      ],
      subDescriptionBold: ["Minat pribadi,", "Minat situasional,"],
      descriptionBold:
        "Minat (atau interest) merupakan kecenderungan seseorang untuk menyukai objek-objek atau kegiatan-kegiatan yang membutuhkan perhatian dan menghasilkan kepuasan.",
      icon: <LoveIcon />,
      color: "#B10256",
      secondColor: "#FEB3D7",
	  bgColor: "#FFF0F7"
    },
    {
      id: 2,
      title: "Bakat",
      description:
        "Bakat yang dikenal juga sebagai talenta merupakan kemampuan bawaan yang memungkinkan untuk dikembangkan atau dilatih. Bakat ini dapat terlihat sejak anak kecil atau bisa juga muncul saat anak menjelang remaja bahkan dewasa. Misalnya, anak yang memiliki bakat di bidang sepak bola akan terlihat lebih luwes atau bermain lebih baik dari anak lainnya dengan porsi latihan yang sama.",
      descriptionBold:
        "Bakat yang dikenal juga sebagai talenta merupakan kemampuan bawaan yang memungkinkan untuk dikembangkan atau dilatih.",
      icon: <MedalIcon />,
      color: "#B18102",
      secondColor: "#FEEAB3",
	  bgColor:'#FFFBF0'
    },
    {
      id: 3,
      description:
        "Ayah Bunda dapat membantu mengarahkan anak dengan memberi informasi dan pilihan aktivitas yang sesuai dengan minat anak. Jadi lebih jelas hal apa saja yang masih perlu diperdalam serta hal apa saja yang perlu dikenalkan. Ayah Bunda mungkin sudah menyadari anak menyukai kegiatan tari sehingga anak dapat didukung dengan kegiatan ekstrakulikuler tari yang tersedia di sekolah atau anak mungkin menyenangi kegiatan olahraga segingga ia tertarik untuk mengikuti perlombaan seperti pertandingan basket, sepak bola atau bulu tangkis.",
      descriptionBold:
        "mengarahkan anak dengan memberi informasi dan pilihan aktivitas yang sesuai dengan minat anak.",
      icon: <LikeShapesIcon />,
	  bgColor: '#FFEEE6'
    },
    {
      id: 4,
      description:
        "Ayah Bunda dapat menentukan informasi dan aktivitas yang masih diperlukan anak untuk mengembangkan minatnya. Pada usia anak dan remaja, anak masih mengembangkan minatnya, sehingga Ayah Bunda masih dapat menawarkan ragam aktivitas lainnya yang mungkin menjadi minat anak.",
      descriptionBold:
        "menentukan informasi dan aktivitas yang masih diperlukan anak untuk mengembangkan minatnya.",
      icon: <LikeShapesIcon color="#91966F" />,
	  bgColor: '#E8E9E2'
    },
    {
      id: 5,
      description:
        "Menjadi bahan pertimbangan untuk perencanaan pendidikan dan menentukan cita-cita yang sesuai dengan minat anak.",
      descriptionBold: "perencanaan pendidikan dan menentukan cita-cita",
      icon: <LikeShapesIcon color="#4D7792" />,
	  bgColor: '#DEE7ED'
    },
  ];
  return (
    <Fragment>
      {!isSecondSection && (
        <View
          style={{
            flexDirection: "column",
            gap: 12,
          }}
        >
          <Text style={styles.textBase}>
            Sebelum membahas terkait gambaran minat bakat para siswa, izinkan
            kami untuk menyampaikan sedikit pendahuluan mengenai minat bakat itu
            sendiri agar Ayah Bunda lebih memahami laporan yang kami berikan
            secara mendalam.
          </Text>
          <Text style={styles.textBase}>
            Ayah Bunda, pernahkah ayah bunda bertanya "Sebenarnya minat bakat
            anakku itu apa ya? Gimana cara untuk tau minat bakat dengan tepat?
            Lalu apa manfaatnya setelah tahu minat bakat anakku?"
          </Text>
          <Text style={styles.textBase}>
            Sebelum kita jauh membahas mengenai minat bakat anak, mari kita coba
            pahami lebih mendalam dulu pengertian minat dan bakat. Karena
            ternyata keduanya adalah hal yang berbeda.
          </Text>
        </View>
      )}
      <View
        style={{
          marginTop: 32,
          gap: 18,
        }}
      >
        {lists
          .slice(isSecondSection ? 2 : 0, isSecondSection ? 5 : 2)
          .map((item, index) => (
            <ListStatic key={index} item={item} />
          ))}
      </View>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  textBase: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
	lineHeight: 22,
  },
});
export default IntroductionPage;
