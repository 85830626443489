import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const QnaGroupIcon = (props) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={24} cy={24} r={24} fill="#FFF4D6" />
    <Path
      d="M29.925 28.4277L30.2825 31.3243C30.3742 32.0852 29.5583 32.6168 28.9075 32.2227L25.7358 30.3343C25.5158 30.206 25.4608 29.931 25.58 29.711C26.0383 28.8677 26.2858 27.9143 26.2858 26.961C26.2858 23.606 23.4075 20.8743 19.8692 20.8743C19.145 20.8743 18.4392 20.9843 17.7792 21.2043C17.44 21.3143 17.11 21.0027 17.1925 20.6543C18.0267 17.3177 21.235 14.8335 25.0667 14.8335C29.54 14.8335 33.1608 18.216 33.1608 22.3868C33.1608 24.8618 31.8867 27.0527 29.925 28.4277Z"
      fill="#FDC52E"
    />
    <Path
      d="M24.9193 26.9607C24.9193 28.0515 24.5159 29.0599 23.8376 29.8574C22.9301 30.9574 21.4909 31.6632 19.8776 31.6632L17.4851 33.084C17.0818 33.3315 16.5684 32.9924 16.6234 32.5249L16.8526 30.719C15.6243 29.8665 14.8359 28.5007 14.8359 26.9607C14.8359 25.3474 15.6976 23.9265 17.0176 23.0832C17.8334 22.5515 18.8143 22.249 19.8776 22.249C22.6643 22.249 24.9193 24.3574 24.9193 26.9607Z"
      fill="#FDC52E"
    />
  </Svg>
);
export default QnaGroupIcon;
