import React from "react";
import Svg, { Path } from "react-native-svg";
const ChevronRight = (props) => (
  <Svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <Path
      stroke={props.stroke ?? "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m4.475 2.04 3.26 3.26a.993.993 0 0 1 0 1.4l-3.26 3.26"
    />
  </Svg>
);
export default ChevronRight;
