import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Dimensions, ScrollView, View } from "react-native";
import {
  HeaderJoin,
  MenuCard,
  PopupFactory,
  SectionContent
} from "../components";
import CardTest from "../components/CardTest";
import ChoosenProgram from "../components/ChoosenProgram";
import { usePopup } from "../components/PopupFactory";

function NewHomePage() {
  const { toggle, visible } = usePopup();
  const navigation = useNavigation();

  return (
    <ScrollView
      contentContainerStyle={{
        backgroundColor: "#EEEAFF",
        gap: 24,
        paddingBottom: 16,
      }}
    >
      <HeaderJoin />
      <View>
        <SectionContent
          title="Kenali Masalah dengan Tes Psikologi"
          handleAllButton={() => navigation.navigate("PsikologiTestPage")}
        >
          <ScrollView
            horizontal={true}
            snapToInterval={Dimensions.get("window").width}
            showsHorizontalScrollIndicator={false}
          >
            {[0, 1, 2, 3]?.map((p, index) => (
              <CardTest key={p} />
            ))}
          </ScrollView>
        </SectionContent>
      </View>
      <MenuCard />
      <SectionContent title="Program Kamu">
        <ScrollView
          horizontal={true}
          snapToInterval={Dimensions.get("window").width}
          showsHorizontalScrollIndicator={false}
        >
          {[1, 2, 3, 4].map((p) => (
            <ChoosenProgram titleCta="Selengkapnya" toggle={toggle} key={p} />
          ))}
        </ScrollView>
      </SectionContent>
      {/* <EbookCard /> */}
      <SectionContent title="Program Pilihan">
        <ScrollView
          horizontal={true}
          snapToInterval={Dimensions.get("window").width}
          showsHorizontalScrollIndicator={false}
        >
          {[1, 2, 3, 4].map((p) => (
            <ChoosenProgram key={p} toggle={toggle} />
          ))}
        </ScrollView>
      </SectionContent>
      <PopupFactory
        type={"buy-package"}
        visible={visible}
        onCancel={toggle}
        onSubmit={() => {
          toggle();
        }}
      />
    </ScrollView>
  );
}

export default NewHomePage;
