import React, { useEffect, useState } from "react";
import {
  AppBar,
  EmptyState,
  ListDiscussions,
  ModalComment,
  Snackbar,
} from "../components";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  createDiscussion,
  getListDiscussions,
  sendNotif,
  subscribeTopic,
} from "../services/programs";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { ChatIcon, ChatIcon2, Message2, Message2Black } from "../assets/icons";
import md5 from "md5";
import Message2Black2 from "../assets/icons/Message2Black2";


function DiscussPrograms(props) {
  const { route } = props;
  const { params } = route;
  const [discussions, setDiscussions] = useState([]);
  const [member, setMember] = useState(undefined);
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [openComment, setOpenComment] = useState(false);
  const [question, setQuestion] = useState("");
  const [isOnSend, setIsOnSend] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMember(memberId);
      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    if (!member) {
      return;
    }

    getListDiscussions(params?.additionalContentId, member).then((res) => {
      setDiscussions(res?.data);
      setLoading(false);
    });
  }, [member]);
  const onSubscribeTopic = () => {
    subscribeTopic(member, params?.additionalContentId);
    sendNotif(params?.additionalContentId, selectedComment.questionId + "comment");
  };

  const onSendComment = async () => {
    setIsOnSend(true);
    if (question.length === 0) {
      setMsg("Harap isi komentar kamu");
      setIsSnackbar(true);
      setIsOnSend(false);
      return;
    }
    const payload = {
      title: "",
      categories: selectedComment?.categories,
      tags: selectedComment?.tags,
      question: question,
      memberId: member,
      questionId: md5(new Date().getTime()),
      additionalContentId: params?.additionalContentId,
      parentId: selectedComment.questionId,
    };
    await createDiscussion(payload)
      .then((res) => {
        if (res?.data || res?.data === "true" || res?.data === true) {
          setMsg("Komentar kamu berhasil ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
          onSubscribeTopic();
        } else {
          setMsg("Komentar kamu gagal ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
        }
      })
      .catch((err) => {
        setMsg("Komentar kamu gagal ditambahkan");
        setOpenComment(false);
        setIsSnackbar(true);
        setIsOnSend(false);
        setQuestion("");
      });
  };
  const onOpenComment = (data) => {
    setSelectedComment(data);
    setOpenComment(true);
  };
  return (
    <React.Fragment>
      <AppBar title={t("program-detail:discuss")} isBack />
      {discussions?.length === 0 && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            padding: 16,
            backgroundColor: "#EEEAFF",
            height: "100%",
            minHeight: "100%",
          }}
        >
          <EmptyState
            Icon={Message2Black}
            title={loading ? "Mohon Menunggu" : "Diskusi Belum Tersedia"}
            desc={loading ? "": "Belum ada diskusi seputar program yang bisa kamu akses saat ini"}
          />
        </View>
      )}
      {
        params.newDiscuss === "1" &&
          <Pressable
                onPress={() => {
                  navigation.navigate("NewDiscuss", {
                    ...params,
                    additionalContentId: params.additionalContentId,
                    hideCategory: false
                  });
                }}
                style={{ ...styles.buttonContainer,backgroundColor: "#EEEAFF",padding: 16, paddingBottom: 0 }}
              >
          <View style={{padding: 12,  flexDirection: "row", justifyContent: "flex-start", alignItems:"center", backgroundColor: "#FFF", borderRadius: 10, flex: 1 , gap: 10}}>
            <Message2Black2/>
            <Text style={{color: "#999"}}>
            Klik di sini untuk bertanya atau berkomentar
            </Text>
          </View>
          </Pressable>
      }
      {discussions?.length > 0 && (
        <ScrollView style={styles.container}>
          <View
            style={{
              padding: 16,
              flex: 1,
            }}
          >
            {discussions?.map((item, index) => (
              <ListDiscussions
                key={index}
                data={item}
                t={t}
                memberId={member}
                onPress={(alreadyLike) => {
                  navigation.navigate("DetailDiscussions", {
                    additionalContentId: params?.additionalContentId,
                    id: item?.questionId,
                    data: item,
                    alreadyLike,
                  });
                }}
                onOpenComment={onOpenComment}
                openComment={openComment}
              />
            ))}
          </View>
        </ScrollView>
      )}
      <ModalComment
        setValue={setQuestion}
        isOnSend={isOnSend}
        value={question}
        visible={openComment}
        onSendComment={onSendComment}
        onClose={() => {
          setOpenComment(false);
          setSelectedComment(null);
          setQuestion("");
        }}
      />
      <Snackbar
        visible={isSnackbar}
        hideDialog={() => {
          setIsSnackbar(false);
        }}
        title={msg}
      />
    </React.Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#EEEAFF",
  },
  buttonContainer: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: "row",
    // backgroundColor: "#FFF",
    // marginTop: 16,
    justifyContent: "space-between",
  },
});

export default DiscussPrograms;
