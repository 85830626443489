import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const PeoplesIcon = (props) => (
  <View style={{ height: props?.height || 46, width: props?.width || 46 }}>
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 46 46"
        width="100%"
        height="100%"
        fill="none"
      >
        <Path
          fill="#0071B2"
          d="M33.606 14.89a1.414 1.414 0 0 0-.403 0 5.508 5.508 0 0 1-5.328-5.52 5.542 5.542 0 0 1 5.54-5.54 5.542 5.542 0 0 1 5.538 5.54c-.019 2.99-2.376 5.423-5.347 5.52ZM39.851 28.174c-2.146 1.437-5.156 1.974-7.935 1.61.729-1.572 1.112-3.316 1.131-5.156 0-1.917-.422-3.738-1.227-5.328 2.837-.384 5.846.153 8.012 1.59 3.028 1.994 3.028 5.271.02 7.284ZM12.348 14.89c.134-.02.268-.02.402 0a5.508 5.508 0 0 0 5.328-5.52 5.542 5.542 0 0 0-5.539-5.54A5.542 5.542 0 0 0 7 9.37c.02 2.99 2.377 5.423 5.348 5.52ZM12.559 24.626c0 1.86.402 3.623 1.13 5.214-2.702.287-5.52-.288-7.59-1.649-3.028-2.012-3.028-5.29 0-7.302 2.051-1.38 4.945-1.936 7.667-1.63-.786 1.61-1.207 3.432-1.207 5.367Z"
          opacity={0.4}
        />
        <Path
          fill="#0071B2"
          d="M23.233 30.416a2.172 2.172 0 0 0-.498 0 6.565 6.565 0 0 1-6.344-6.575 6.58 6.58 0 0 1 6.593-6.593 6.593 6.593 0 0 1 6.593 6.593 6.551 6.551 0 0 1-6.344 6.575ZM17.007 34.384c-2.895 1.935-2.895 5.117 0 7.034 3.296 2.204 8.701 2.204 11.998 0 2.894-1.936 2.894-5.118 0-7.034-3.278-2.205-8.683-2.205-11.998 0Z"
        />
      </Svg>
    </View>
  </View>
);
export default PeoplesIcon;
