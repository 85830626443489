import { Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { CheckCircleIcon } from "../../assets/icons";

const INSTRUMENTS = [
  "Identifikasi Korban & Pelaku",
  "Kondisi Kesulitan Belajar",
  "Identifikasi Tempat Bullying",
  "Kondisi Pengelolaan Emosi",
  "Komunikasi/Keterbukaan",
  "Kondisi Relasi Pertemanan",
  "Kondisi Manajemen Stress",
  "Kondisi Pengaruh Teknologi",
];

export function ProgramAntiBullyingInstrument({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>
        Apa Saja Instrumen Penilaian yang Diukur Dalam Tes ini?
      </Text>
      <View style={styles("content")}>
        {INSTRUMENTS.map((item) => (
          <InstrumentCard key={item} label={item} styles={styles} />
        ))}
      </View>
    </View>
  );
}

function InstrumentCard({ label, styles }) {
  return (
    <View style={styles("card")}>
      <Text style={styles("txCard")}>{label}</Text>
      <CheckCircleIcon
        width={62}
        height={62}
        fill="#4eb826d6"
        style={styles("checkIcon")}
      />
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
      justifyContent: "center",
      alignItems: "center",
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    content: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      rowGap: 12,
      columnGap: 56,
    },
    card: {
      minWidth: 458,
      paddingHorizontal: 48,
      paddingVertical: 24,
      borderRadius: 52,
      backgroundColor: "#FDC52E",
      position: "relative",
    },
    txCard: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 26,
      color: "#260C55",
    },
    checkIcon: {
      position: "absolute",
      top: -15,
      left: -28,
    },
  },
  {
    container: { gap: 32 },
    header: { gap: 12 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 18,
      lineHeight: 34,
    },
    content: {},
  }
);
