import React, { useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { TickCircleIcon } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";

const DUMMY_LIST = [
  {
    name: "Curhat ke Orang yang Tepat",
    session: "2",
  },
  {
    name: "Sharing ke Orangtua Ahli",
    session: "1",
  },
  {
    name: "Konsultasi dengan Ahli",
    session: "1",
  },
];

const ServiceCard = ({ data, t, fullwidth }) => {
  const navigation = useNavigation();
  const [noteList, setNoteList] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const sessions = data?.additionalDescriptions?.split(",");
    const desc = data?.additionalNotes?.split(",");

    const notes = [];
    sessions.map((s, index) => {
      notes.push({
        name: desc[index],
        session: s,
      });
    });

    setNoteList(notes);
  }, [data]);
  return (
    <View
      style={{
        ...styles.container,
        width: Dimensions.get("window").width - 90,
        maxWidth: 300,
      }}
    >
      <TouchableOpacity onPress={() => {
            navigation.navigate("ProductDetail", {
              ...data
            })
          }}
      >
      <Image
        source={{
          uri: `https://fammi.ly/uploads/products/images/${data?.productImage}`,
        }}
        style={{
          width: "100%",
          height: 100,
          borderRadius: 10,
        }}
      />
      <View style={styles.contentContainer}>
        <Text style={styles.title}>
          {data?.productName ?? data?.additionalName}
        </Text>
        <Text
          style={{
            ...styles.title,
            fontFamily: "Archivo-Regular",
            fontWeight: "400",
            marginBottom: 0,
          }}
        >
          {data?.priceDescription}
        </Text>

        <View style={styles.list}>
          {noteList?.map((s, id) => (
            <View key={id} style={styles.service}>
              <TickCircleIcon />
              <Text style={styles.text}>{s.name}</Text>
              <View style={styles.session}>
                <Text style={styles.sessionText}>{s.session}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.bottom}>
        <Text style={styles.price}>
          {data?.productPrice &&
            `Rp ${parseFloat(data?.productPrice).toLocaleString("id-ID")}`}
          <Text
            style={{
              fontFamily: "Archivo-Regular",
              fontWeight: "400",
              marginLeft: 8,
              color: "#999999",
            }}
          >&nbsp;
            Durasi {data?.lifeTimeNumber} {data?.lifeTimeMetric}
          </Text>
        </Text>
        <Text
          style={{ ...styles.price, color: "#6724E2" }}
          onPress={() => {
            navigation.navigate("ProductDetail", {
              ...data,
            });
          }}
        >{t("common:more-details")}</Text>
      </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 10,
    marginRight: 16,
  },
  contentContainer: {
    paddingTop: 16,
    paddingHorizontal: 12,
    paddingBottom: 12,
  },
  top: {},
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 15,
    marginBottom: 6,
  },
  text: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
  },

  list: {
    flexDirection: "column",
    rowGap: 10,
    marginTop: 12,
  },
  service: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 8,
  },
  session: {
    backgroundColor: "#EAEAEA",
    borderRadius: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  sessionText: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 11,
  },
  bottom: {
    borderTopColor: "#EAEAEA",
    borderTopWidth: 1,
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  price: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16,
  },
});

export default ServiceCard;
