import * as React from "react";
import Svg, { Path } from "react-native-svg";
const PlayIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66895 4.76C5.69576 4.53284 5.77524 4.31509 5.90106 4.12407C6.02689 3.93306 6.19558 3.77407 6.3937 3.65976C6.59182 3.54545 6.81389 3.47899 7.04223 3.46566C7.27058 3.45233 7.49888 3.49251 7.70895 3.583C8.77095 4.037 11.1509 5.116 14.1709 6.859C17.1919 8.603 19.3169 10.126 20.2399 10.817C21.0279 11.408 21.0299 12.58 20.2409 13.173C19.3269 13.86 17.2279 15.363 14.1709 17.129C11.1109 18.895 8.75895 19.961 7.70695 20.409C6.80095 20.796 5.78695 20.209 5.66895 19.232C5.53095 18.09 5.27295 15.497 5.27295 11.995C5.27295 8.495 5.52995 5.903 5.66895 4.76Z"
      fill={props.fill ?? "#FDFDFD"}
    />
  </Svg>
);
export default PlayIcon;
