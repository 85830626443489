import * as React from "react"
const ShopeePay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#EE4E2C"
      fillRule="evenodd"
      d="M1.886 2.796h12.572c.836 0 1.52.694 1.52 1.543v8.94c0 .849-.684 1.543-1.52 1.543H1.518C.684 14.822 0 14.128 0 13.28V2.234c0-.403.325-.682.717-.729L12.311 0c.396 0 .717.33.717.729V1.98H1.16c0 .523.156.81.726.81v.005Zm7.05 9.797c-1.06.206-2.418-.15-3.156-.96-.333-.365.202-.914.54-.55.498.545 1.384.798 2.105.781.92-.021 1.743-.875 1.22-1.827-.726-1.316-2.51-.947-3.43-1.985-.743-.836-.393-2.152.48-2.735.912-.61 2.481-.583 3.228.287.32.373-.215.922-.54.549-.603-.703-2.03-.609-2.574.116-.776 1.033.384 1.59 1.194 1.839 1.114.343 2.35.969 2.553 2.268.16 1.046-.633 2.015-1.62 2.213v.004Z"
      clipRule="evenodd"
    />
    <path
      fill="#FEFEFE"
      fillRule="evenodd"
      d="M8.936 12.593c-1.058.206-2.417-.15-3.155-.96-.334-.365.202-.914.54-.55.498.545 1.384.798 2.105.781.92-.021 1.742-.875 1.22-1.827-.726-1.316-2.511-.947-3.43-1.985-.743-.836-.393-2.152.48-2.735.911-.61 2.48-.583 3.228.287.32.373-.215.926-.54.549-.604-.703-2.03-.609-2.574.116-.776 1.033.384 1.59 1.194 1.839 1.114.343 2.35.969 2.553 2.268.16 1.046-.633 2.015-1.62 2.213v.004Z"
      clipRule="evenodd"
    />
    <path
      fill="#EE4E2C"
      d="M30.591 2.247c-.45 0-.852.193-1.13.501v-.355c0-.322-.51-.322-.51 0v4.219c0 .321.51.325.51 0V4.734a1.52 1.52 0 0 0 1.13.501c.836 0 1.515-.669 1.515-1.492s-.68-1.492-1.515-1.492v-.004Zm0 2.444a.96.96 0 0 1-.966-.952c0-.472.35-.866.81-.943h.009a.977.977 0 0 1 1.034.557v.004a.95.95 0 0 1 .08.382.96.96 0 0 1-.966.952Zm-3.759.54a1.505 1.505 0 0 1-1.514-1.492c0-.823.68-1.492 1.514-1.492.836 0 1.515.669 1.515 1.492s-.68 1.492-1.515 1.492Zm0-2.444a.962.962 0 0 0-.966.952.96.96 0 0 0 .966.952.962.962 0 0 0 .967-.952.96.96 0 0 0-.967-.952ZM39 3.709c-.013-.686-.367-1.308-1.105-1.445-.638-.12-1.38.21-1.65.797-.283.61-.165 1.445.392 1.87.6.459 1.536.317 2.152-.022.287-.158.03-.591-.257-.433-.389.215-.9.322-1.338.176-.329-.107-.51-.386-.565-.699h2.118c.135 0 .257-.116.253-.253v.009Zm-2.354-.253.012-.056c.123-.407.54-.647.962-.656.485-.013.756.317.84.712H36.64h.005Zm-1.038.257c-.013-.686-.367-1.308-1.105-1.445-.638-.12-1.38.21-1.65.798-.283.609-.165 1.445.392 1.87.6.458 1.536.316 2.152-.022.287-.159.03-.592-.258-.433-.388.214-.898.321-1.337.175-.329-.107-.51-.385-.565-.698h2.118c.135 0 .257-.116.253-.253v.008Zm-2.354-.253.012-.056c.123-.407.54-.647.962-.656.485-.012.755.318.84.712h-1.819.005Zm-8.531-.231v1.745c0 .33-.51.33-.51 0V3.263a.614.614 0 0 0-.313-.497c-.362-.16-1.02-.086-1.413.42v1.788c0 .33-.51.33-.51 0V.494c0-.331.51-.327.51 0v2.01c.624-.424 1.418-.377 1.793-.116 0 0 .447.249.443.84ZM21.145.699C20.648.14 19.597.124 18.99.515c-.582.373-.818 1.217-.32 1.753.616.665 1.805.429 2.29 1.274.35.613-.198 1.157-.814 1.17-.48.009-1.075-.154-1.409-.501-.223-.236-.582.115-.358.351.493.519 1.4.75 2.105.618.658-.125 1.19-.75 1.08-1.42-.135-.832-.962-1.235-1.704-1.453-.54-.159-1.317-.519-.798-1.18.363-.467 1.317-.527 1.721-.072.216.24.574-.112.36-.352L21.144.7Z"
    />
    <path
      fill="#EE4E2C"
      fillRule="evenodd"
      d="M34.426 17.425H32.81l1.73-3.323-2.844-5.458h1.755l1.966 3.773 1.966-3.773H39l-4.573 8.781Zm-16.12-2.603h1.358v-2.516h1.827c1.894 0 3.434-1.351 3.434-3.019 0-1.668-1.536-3.018-3.434-3.018h-3.186v8.558-.005Zm1.358-3.897v-3.28h1.827c1.03 0 1.864.733 1.864 1.642 0 .905-.835 1.642-1.864 1.642h-1.827v-.004Zm10.34-2.23h1.19v6.076h-1.19v-.682a3.006 3.006 0 0 1-1.97.738c-1.679 0-3.042-1.385-3.042-3.092 0-1.706 1.363-3.091 3.042-3.091.751 0 1.439.279 1.97.737v-.686Zm-1.97 1.248c.97 0 1.76.802 1.76 1.788s-.79 1.788-1.76 1.788-1.759-.802-1.759-1.788.789-1.788 1.76-1.788Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ShopeePay
