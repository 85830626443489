import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import Modal from "react-native-modal";
import { CloseIcon, WaIcon } from "../../assets/icons";

function FindUserPopup(props) {
  const { visible, value, setValue, hidePopup, handleFindUser } = props;
  const [isError, setIsError] = useState(false);

  useEffect(() => setValue(""), []);

  const onSubmit = () => {
    if (value === "") {
      setIsError(true);
      return;
    }
    handleFindUser();
  };
  return (
    <Modal isVisible={visible} onBackdropPress={hidePopup}>
      <View style={styles.parentBox}>
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            marginBottom: 12
          }}
        >
          <View style={styles.flexOne}>
            <WaIcon width={18} height={18} fill="#000" />
            <Text style={styles.txTitle}>Pencarian Pengguna</Text>
          </View>
          <TouchableOpacity onPress={hidePopup}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <TextInput
          value={value}
          onChangeText={setValue}
          placeholder="Input No WhatsApp Pengguna Lain"
          placeholderTextColor="#999"
          style={[styles.inputText, isError && styles.errorField]}
        />
        <TouchableOpacity onPress={onSubmit} style={styles.button}>
          <Text style={styles.txBtn}>{"Cari Pengguna"}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16
  },
  inputText: {
    width: "100%",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderRadius: 10,
    padding: 12,
    paddingBottom: 11,
    textAlign: "center",
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 12,
    maxHeight: 36
  },
  button: {
    backgroundColor: "#6724E2",
    borderRadius: 10,
    width: "100%",
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12
  },
  txBtn: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFF",
    marginLeft: 6
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center"
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#000",
    marginLeft: 6
  },
  errorField: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ff0000"
  }
});

export default FindUserPopup;
