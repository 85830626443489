import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import TableDepartment from "./TableDepartment";
import { useSSR } from "react-i18next";

function DepartmentSuggestion(props) {
  const {descriptions} = props;
  const ipaResults = ["kecerdasan naturalis", "kecerdasan logika", "kecerdasan kinestetik", "kecerdasan spasial"];
  const ipsResults = ["kecerdasan verbal", "kecerdasan interpersonal", "kecerdasan intrapersonal", "kecerdasan musikal"];
  const lists = [
    {
      id: 0,
      title: "IPA",
      subTitle: "Ilmu Pengetahuan Alam (IPA)",
      description:
        "Mengambil jurusan IPA dapat menjadi pilihan yang sangat bermanfaat bagi siswa yang memiliki ketertarikan dan bakat dalam ilmu pengetahuan alam, matematika, dan teknologi. Jurusan ini memberikan dasar kuat dalam pemahaman konsep-konsep ilmiah, serta melatih siswa dalam metode penelitian dan eksperimen. Siswa jurusan IPA juga memiliki kesempatan untuk mengembangkan keterampilan analitis dan logis.",
      color: "#CBE6DE",
    },
    {
      id: 1,
      title: "IPS",
      subTitle: "Ilmu Pengetahuan Sosial (IPS)",
      description: "Sedangkan jurusan IPS memberikan landasan yang kokoh dalam memahami aspek-aspek sosial, ekonomi, dan sejarah. Siswa IPS dilatih untuk memahami kompleksitas masyarakat dan dunia secara lebih luas. Jurusan ini juga mengembangkan keterampilan analisis, interpretasi data, serta pemahaman terhadap dinamika sosial, jurusan IPS mempersiapkan siswa untuk berperan aktif dalam pembentukan pemikiran kritis.",
      color: '#F3BEC5'
    }
  ];

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const results = [];
    Object.keys(descriptions).map((d) => {
      if (ipaResults.indexOf(d) > -1) {
        results.push("ipa");
      }
      if (ipsResults.indexOf(d) > -1) {
        results.push("ips");
      }
    });

    if (results.indexOf("ipa") < 0) {
      lists[0].description = "Tidak ditemukan aspek mendukung untuk ipa";
    }
    if (results.indexOf("ips") < 0) {
      lists[1].description = "Tidak ditemukan aspek mendukung untuk ips";
    }

    setDataList(lists);

    // console.log("descriptions", descriptions);

  }, [descriptions]);

  return (
    <View style={styles.wrapper}>
      <TableDepartment lists={lists} />
      <View style={styles.parentDetail}>
        {dataList.map((item, index) => (
          <View key={index} style={{ marginBottom: 24 }}>
            <View style={[styles.labels, {backgroundColor: item.color}]}>
              <Text style={styles.textLabels}>{item.subTitle}</Text>
            </View>
            <Text style={styles.textMain}>
             {item.description}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    marginTop: 24,
  },
  parentDetail: {
    width: "100%",
    marginTop: 24,
    paddingHorizontal: 16,
    paddingTop: 32,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderStyle: "solid",
    backgroundColor: "#FFF",
    borderRadius: 14,
  },
  labels: {
    backgroundColor: "#DCF4CA",
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignSelf: "flex-start",
    borderRadius: 100 / 2,
    marginBottom: 16,
  },
  textLabels: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
  },
  textMain: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
	lineHeight: 22,
    fontWeight: "300",
  },
});
export default DepartmentSuggestion;
