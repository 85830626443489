import * as React from "react";
import Svg, { Path } from "react-native-svg";
const LinkIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M11.2425 13.125H12.375C14.64 13.125 16.5 11.2725 16.5 9C16.5 6.735 14.6475 4.875 12.375 4.875H11.2425M6.75 4.875H5.625C4.53159 4.87698 3.48353 5.31222 2.71037 6.08537C1.93722 6.85853 1.50198 7.90659 1.5 9C1.5 11.265 3.3525 13.125 5.625 13.125H6.75M6 9H12"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default LinkIcon;
