import React, { useMemo } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  CategoryIcon,
  EmojiHappyIcon,
  HeartTickIcon,
  MessengerFillIcon,
} from "../assets/icons";

const ProgramServiceFilter = ({ type, isActive, onFilter }) => {
  const renderContent = useMemo(() => {
    switch (type) {
      case "all":
        return {
          I: CategoryIcon,
          t: "Semua",
          b: "Layanan",
        };
      case "consultation":
        return {
          I: MessengerFillIcon,
          t: "Konsultasi",
          b: "dengan Ahli",
        };
      case "parent":
        return {
          I: HeartTickIcon,
          t: "Sharing ke",
          b: "Orangtua Ahli",
        };
      case "friend":
        return {
          I: EmojiHappyIcon,
          t: "Semua",
          b: "Layanan",
        };
    }
  }, [type]);

  const isActiveLabelColor = isActive ? "#fff" : "#000";

  return (
    <TouchableOpacity
      onPress={onFilter}
      style={[
        styles.button,
        { backgroundColor: isActive ? "#6724E2" : "#fff" },
      ]}
    >
      <renderContent.I fill={isActive ? "#fff" : "#6724E2"} />
      <View>
        <Text style={[styles.txTop, { color: isActiveLabelColor }]}>
          {renderContent.t}
        </Text>
        <Text style={[styles.txBottom, { color: isActiveLabelColor }]}>
          {renderContent.b}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  txTop: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 11,
  },
  txBottom: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 11,
  },
});

export default ProgramServiceFilter;
