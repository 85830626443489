import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ArchiveAddIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12.6133 1.5H5.38328C3.78578 1.5 2.48828 2.805 2.48828 4.395V14.9625C2.48828 16.3125 3.45578 16.8825 4.64078 16.23L8.30078 14.1975C8.69078 13.98 9.32078 13.98 9.70328 14.1975L13.3633 16.23C14.5483 16.89 15.5158 16.32 15.5158 14.9625V4.395C15.5083 2.805 14.2108 1.5 12.6133 1.5ZM10.8733 8.55H9.56078V9.9075C9.56078 10.215 9.30578 10.47 8.99828 10.47C8.69078 10.47 8.43578 10.215 8.43578 9.9075V8.55H7.12328C6.81578 8.55 6.56078 8.295 6.56078 7.9875C6.56078 7.68 6.81578 7.425 7.12328 7.425H8.43578V6.1575C8.43578 5.85 8.69078 5.595 8.99828 5.595C9.30578 5.595 9.56078 5.85 9.56078 6.1575V7.425H10.8733C11.1808 7.425 11.4358 7.68 11.4358 7.9875C11.4358 8.295 11.1808 8.55 10.8733 8.55Z"
      fill="#292D32"
    />
  </Svg>
);
export default ArchiveAddIcon;
