import { Text, View, StyleSheet, Pressable, Image, Linking } from "react-native";
import moment from "moment";
import CameraIcon from "../assets/icons/CameraIcon";
import AlertIcon from "../assets/icons/AlertIcon";
import md5 from "md5";
import ProfileTick from "../assets/icons/ProfileTick";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { getSpeaker } from "../services/speakers";
import { ChatIcon2, YoutubeIcon } from "../assets/icons";
import { checkAgenda, recordAgenda } from "../services/agenda";
import Snackbar from "./SnackBar";
import { getProgramId } from "../services/programs";

function ZoomCard(props) {
  const navigation = useNavigation();
  const {data, additionalContentId, memberId, hideDate} = props;
  const [misc, setMisc] = useState({});
  const [speaker, setSpeaker] = useState({});
  const [alreadyJoin, setAlreadyJoin] = useState(false);
  const [alreadyPass, setAlreadyPass] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [program, setProgram] = useState({});

  moment().locale("id");

  const onDismissSnackBar = () => {
    setOpenSnackBar(false);
    setMessageSnackBar("");
  };

  const checkSchedule = (data, mode) => {
    // get the date
    const scheduleDate = moment(data.scheduledDate).format("YYYY-MM-DD");
    const start = moment(scheduleDate + " " + data.start);
    const end = moment(scheduleDate + " " + data.end);

    if (mode === "started") {
      return moment() >= moment(start) && moment() <= moment(end);
    }

    return moment() >= moment(end);
  }

  useEffect(() => {
    setMisc(JSON.parse(data.misc));
    getSpeaker(data?.speakerId)
    .then((res) => {
      setSpeaker(res?.data[0]);
    });

    if (data?.agendaType === "diskusi") {
      getProgramId(additionalContentId)
      .then((res) => {
          setProgram(res?.data[0]);
        }
      );
    }

    // if (!alreadyPassed) {
      checkAgenda(memberId, data?.agendaId)
      .then((res) => {
        if (res?.data.length > 0) {
          setAlreadyJoin(true);
        }
      })
    // }
  }, [data]);

  return (
    <View style={styles.wrapperCard}>
      {
        !hideDate &&
        <View style={styles.flexRow}>
          <View style={styles.dot} />
          <Text style={styles.dateTx}>{moment(data?.scheduledDate).format("dddd, DD MMM YYYY")}</Text>
        </View>
      }
      <View style={styles.cardParent}>
        {
          misc?.image &&
          <Image
            alt="cover"
            style={styles.image}
            source={{
              uri: `https://fammi.ly/uploads/kelas-online/images/${misc?.image}`
            }}
          />
        }
        <View style={styles.mainContent}>
          <View style={styles.topSection}>
            {
              data?.agendaType === "zoom" &&
              <CameraIcon />
            }
            {
              data?.agendaType === "youtube" &&
              <YoutubeIcon />
            }
            {
              data?.agendaType === "diskusi" &&
              <ChatIcon2 />
            }

            <View style={styles.parentItem}>
              {
                data?.agendaType === "diskusi" &&
                <Text style={{...styles.txTitle, textTransform: "capitalize"}}>{data?.agendaType} Bersama Ahli</Text>
              }
              {
                data?.agendaType !== "diskusi" &&
                <Text style={{...styles.txTitle, textTransform: "capitalize"}}>{data?.name.replace(/&lsquo;/g, "'").replace(/&ldquo;/g, "\"").replace(/&rdquo;/g, "'").replace(/&rdquo;/g, "\"").replace(/&Lsquo;/g, "'").replace(/&Ldquo;/g, "\"").replace(/&rsquo;/g, "'").replace(/&Rdquo;/g, "\"")}</Text>
              }
              <Text style={styles.txDateTime}>
              {moment(data?.scheduledDate).format("dddd, DD MMM YYYY")}
              , Pukul {misc.start} - {misc.end} WIB
              </Text>
            </View>
          </View>
          <View style={styles.topSection}>
            <Image
              source={{
                uri: `http://fammi.ly/uploads/speakers/images/${speaker?.photo}`,
              }}
              style={styles.imgAva}
              height={30}
              width={30}
            />
            <View style={{...styles.parentItem, maxWidth: "90%"}}>
              <Text style={{...styles.txTitle}}>{speaker?.name}</Text>
              <Text style={{...styles.txSpan}} ellipsizeMode="tail" numberOfLines={1}>{speaker?.description}</Text>
            </View>
          </View>
          <Text style={styles.txMain}>
            {misc?.short_desc?.replace("&lsquo;", "'").replace("&ldquo;", "\"").replace("&ldquo;", "'").replace("&rdquo;", "\"")}
          </Text>
        </View>
        <View style={styles.bottomSection}>
          <Pressable
            onPress={() => {
              navigation.navigate("DetailAgenda", {
                data: data,
                misc: misc,
                speaker: speaker,
                memberId: memberId
              });
            }}
            style={styles.buttonLeft}
          >
            <AlertIcon />
            <Text style={{ ...styles.txMain, marginLeft: 4 }}>
              Lihat Detail
            </Text>
          </Pressable>
          <Pressable 
            style={{...styles.buttonRight, backgroundColor: alreadyJoin  ? "#EEEAFF" : "#EEEAFF"}}
            onPress={() => {
              if (alreadyJoin) {
                const alreadyEnded = checkSchedule({
                  scheduledDate: data?.scheduledDate,
                  start: misc.start,
                  end: misc.end
                }, "ended");

                const alreadyStarted = checkSchedule({
                  scheduledDate: data?.scheduledDate,
                  start: misc.start,
                  end: misc.end
                }, "started");

                if (alreadyEnded && data.agendaType === "zoom") {
                  setMessageSnackBar("Acara sudah selesai");
                  setOpenSnackBar(true);
                  return;
                }

                if ((alreadyStarted || alreadyEnded) && data.agendaType !== "diskusi") {
                  Linking.openURL(misc.url.indexOf("http") > -1 ? misc.url : "https://" + misc.url);
                  return;
                }

                if ((alreadyStarted || alreadyEnded) && data.agendaType === "diskusi") {
                  navigation.navigate("DiscussPrograms", {
                    additionalContentId: additionalContentId,
                    newDiscuss: "1",
                    program: {...program}
                  });
                  return;
                }

                if ((!alreadyStarted) ) {
                  setMessageSnackBar("Acara belum mulai. Cek kembali tanggal dan jamnya");
                  setOpenSnackBar(true);
                }
                return;
              }


              recordAgenda({
                acId: md5(new Date().getTime()),
                memberId: memberId, 
                agendaId: data?.agendaId
              }).then(() => {
                setAlreadyJoin(true);
              })
            }}
          >
            <ProfileTick stroke={alreadyJoin ? "#6724E2" : "#6724E2"} />
            <Text style={{ ...styles.txMain, marginLeft: 4, color: (alreadyJoin ? "#6724E2" : "#6724E2") }}>
              {
                alreadyJoin ?
                'Masuk'
                : 'Gabung'
              }
            </Text>
          </Pressable>
        </View>
      </View>
      <Snackbar
        visible={openSnackBar}
        hideDialog={onDismissSnackBar}
        title={messageSnackBar}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10
  },
  dot: {
    width: 6,
    height: 15,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: "#6724E2"
  },
  dateTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  wrapperCard: {
    paddingHorizontal: 16,
    paddingVertical: 0,
    paddingLeft: 0,
    paddingBottom: 0
  },
  cardParent: {
    backgroundColor: "#fff",
    borderRadius: 10,
    marginLeft: 12,
    marginTop: 12
  },
  mainContent: {
    padding: 12,
  },
  parentItem: {
    marginLeft: 10
  },
  txTitle: {
    color: "#000",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
  },
  txDateTime: {
    color: "#4EB826",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
  },
  imgAva: {
    height: 30,
    width: 30,
    borderRadius: 30 / 2,
  },
  txSpan: {
    color: "#999",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
  },
  txMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 16,
  },
  bottomSection: {
    padding: 12,
    borderTopWidth: 1,
    borderTopColor: "#EAEAEA",
    borderStyle: "solid",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonLeft: {
    width: "47.5%",
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderColor: "#EAEAEA",
    borderWidth: 1,
    borderRadius: 8,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRight: {
    width: "47.5%",
    backgroundColor: "#EEEAFF",
    borderRadius: 8,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    borderRadius: 10,
    width: "100%",
    height: 100
  },
});

export default ZoomCard;
