import { Image, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { PlayStore, ViaWeb } from "../../assets/icons";
export function CompanyProfileBanner({ layout }) {
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  const storeImageStyle = isMobile
    ? { width: 152, height: 44 }
    : { width: 164, height: 48 };

  return (
    <View style={styles("container")}>
      <Image
        source={require(`../../assets/images/cp-banner${
          isMobile ? "-mobile" : ""
        }.png`)}
        style={styles("banner")}
      />
      <View style={styles("content")}>
        <Text style={styles("txTitle")}>
          Ayo Bergabung Bersama Fammi untuk Memberikan Dampak Kepada Jutaan
          Keluarga di Indonesia
        </Text>
        <Text style={styles("txDesc")}>
          Unduh aplikasi Fammi sekarang! Kamu juga dapat mengakses layanan Fammi
          melalui aplikasi web.
        </Text>
        <View style={styles("store")}>
          <PlayStore {...storeImageStyle} />
          <ViaWeb {...storeImageStyle} />
        </View>
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      borderRadius: 40,
      backgroundColor: "#FDC52E",
      flexDirection: "row",
      alignItems: "center",
      gap: 68,
      padding: 48,
    },
    banner: {
      width: 495,
      height: 364,
    },
    content: {
      flexDirection: "column",
      alignItems: "flex-start",
      maxWidth: 542,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 34,
      color: "#0A0316",
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#0A0316",
      marginTop: 16,
      marginBottom: 32,
    },
    store: {
      flexDirection: "row",
      gap: 12,
    },
    storeImage: {
      width: 164,
      height: 48,
      borderRadius: 10,
    },
  },
  {
    container: {
      flexDirection: "column",
      alignItems: "center",
      padding: 16,
    },
    banner: {
      width: "100%",
      height: 415,
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      borderBottomLeftRadius: 250,
      borderBottomRightRadius: 250,
    },
    content: {
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "unset",
      paddingBottom: 44,
    },
    txTitle: { textAlign: "center" },
    txDesc: {
      marginTop: 24,
      marginBottom: 48,
      textAlign: "center",
    },
    store: {
      flexDirection: "row",
      gap: 14,
    },
    storeImage: {
      width: 152,
      height: 44,
      borderRadius: 10,
    },
  }
);
