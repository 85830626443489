import Svg, { Circle, Path } from "react-native-svg";
const TeachIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <Circle cx={14} cy={14} r={14} fill="#FDFDFD" />
    <Path
      fill="#834FE3"
      d="M17.622 16.73a.75.75 0 0 1 1.163.63v.968c0 .952-.743 1.972-1.635 2.272l-2.393.795c-.42.143-1.102.143-1.515 0L10.85 20.6c-.9-.3-1.635-1.32-1.635-2.272v-.975a.75.75 0 0 1 1.155-.63l1.545 1.005c.592.397 1.342.592 2.092.592s1.5-.195 2.093-.592l1.522-.998Z"
    />
    <Path
      fill="#834FE3"
      d="m19.986 9.845-4.493-2.948c-.81-.532-2.145-.532-2.955 0L8.023 9.845c-1.447.937-1.447 3.06 0 4.005l1.2.78 3.315 2.16c.81.532 2.145.532 2.955 0l3.293-2.16 1.027-.675v2.295c0 .307.255.562.563.562a.567.567 0 0 0 .562-.562v-3.69c.3-.968-.007-2.093-.952-2.715Z"
    />
  </Svg>
);
export default TeachIcon;
