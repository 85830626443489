import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { DangerIcon, ProfileTickCircleIcon } from "../../assets/icons";

const UserFoundPopup = ({ visible, hidePopup, handleCompare }) => {
  const status = "fill";
  const isFill = status === "fill";

  return (
    <Modal isVisible={visible} onBackdropPress={hidePopup}>
      <View style={styles.container}>
        <View style={styles.header}>
          <ProfileTickCircleIcon />
          <Text style={styles.txHeader}>Pengguna Berhasil Ditemukan</Text>
        </View>
        <View style={styles.content}>
          <Data label="Nama" value="Anggih Nur" />
          <Data label="Nomor WhatsApp" value="0812345678" />
          <Data
            label="Status"
            value={`Pengguna ${isFill ? "Sudah" : "Belum"} Mengisi Screening`}
            status={status}
          />
        </View>
        {!isFill && (
          <View style={styles.warning}>
            <DangerIcon width={18} height={18} fill="#000" />
            <Text style={styles.txWarning}>
              Hasil Screening belum bisa dibandingkan
            </Text>
          </View>
        )}
        <TouchableOpacity
          style={[styles.button, isFill ? styles.compareBtn : styles.closeBtn]}
          onPress={() => {
            if (isFill) {
              handleCompare();
            }
            hidePopup();
          }}
        >
          <Text
            style={[styles.txButton, { color: isFill ? "#fff" : "#6724E2" }]}
          >
            {isFill ? "Bandingkan" : "Tutup"}
          </Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

function Data({ label, value, status }) {
  const color = status === "fill" ? "#4EB826" : "#FF0000";
  return (
    <View>
      <Text style={styles.txLabel}>{label}</Text>
      <Text style={[styles.txValue, { color: status ? color : "#000" }]}>
        {value}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 12,
    paddingTop: 24
  },
  header: {
    alignItems: "center",
    gap: 16
  },
  txHeader: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 14
  },
  content: {
    backgroundColor: "#F3F3F3",
    borderRadius: 10,
    alignItems: "flex-start",
    gap: 12,
    padding: 12,
    marginTop: 16
  },
  txLabel: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    marginBottom: 2,
    color: "#999"
  },
  txValue: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12
  },
  warning: {
    borderRadius: 10,
    backgroundColor: "#FFEEC1",
    borderWidth: 1,
    borderColor: "#FDC52E",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginTop: 12
  },
  txWarning: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12
  },
  button: {
    borderRadius: 10,
    padding: 12,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 12
  },
  closeBtn: {
    borderWidth: 1,
    borderColor: "#eaeaea",
    backgroundColor: "#fff"
  },
  compareBtn: {
    backgroundColor: "#6724E2"
  },
  txButton: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12
  }
});
export default UserFoundPopup;
