import Svg, { Path } from "react-native-svg";
const Message2Black = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <Path
      fill={props?.fill ? props?.fill : "#000"}
      d="m13.853 12.623.292 2.37a.746.746 0 0 1-1.125.734l-2.595-1.545c-.18-.104-.225-.33-.127-.51.375-.69.577-1.47.577-2.25 0-2.745-2.355-4.98-5.25-4.98-.592 0-1.17.09-1.71.27a.379.379 0 0 1-.48-.45C4.118 3.533 6.743 1.5 9.878 1.5c3.66 0 6.622 2.768 6.622 6.18 0 2.025-1.042 3.818-2.647 4.943Z"
    />
    <Path
      fill={props?.fill ? props?.fill : "#000"}
      d="M9.75 11.422c0 .893-.33 1.718-.885 2.37-.742.9-1.92 1.478-3.24 1.478l-1.958 1.162c-.33.203-.75-.075-.704-.457l.187-1.478C2.145 13.8 1.5 12.682 1.5 11.422c0-1.32.705-2.482 1.785-3.172a4.262 4.262 0 0 1 2.34-.683c2.28 0 4.125 1.725 4.125 3.855Z"
    />
  </Svg>
);
export default Message2Black;
