import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const MessageCircleIcon = (props) => (
  <Svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={22} cy={22} r={22} fill="#FFF4D6" />
    <Path
      d="M28.4635 26.832L28.8535 29.992C28.9535 30.822 28.0635 31.402 27.3535 30.972L23.8935 28.912C23.6535 28.772 23.5935 28.472 23.7235 28.232C24.2235 27.312 24.4935 26.272 24.4935 25.232C24.4935 21.572 21.3535 18.592 17.4935 18.592C16.7035 18.592 15.9335 18.712 15.2135 18.952C14.8435 19.072 14.4835 18.732 14.5735 18.352C15.4835 14.712 18.9835 12.002 23.1635 12.002C28.0435 12.002 31.9935 15.692 31.9935 20.242C31.9935 22.942 30.6035 25.332 28.4635 26.832Z"
      fill="#FDC52E"
    />
    <Path
      d="M23.0029 25.2279C23.0029 26.4179 22.5629 27.5179 21.8229 28.3879C20.8329 29.5879 19.2629 30.3579 17.5029 30.3579L14.8929 31.9079C14.4529 32.1779 13.8929 31.8079 13.9529 31.2979L14.2029 29.3279C12.8629 28.3979 12.0029 26.9079 12.0029 25.2279C12.0029 23.4679 12.9429 21.9179 14.3829 20.9979C15.2729 20.4179 16.3429 20.0879 17.5029 20.0879C20.5429 20.0879 23.0029 22.3879 23.0029 25.2279Z"
      fill="#FDC52E"
    />
  </Svg>
);
export default MessageCircleIcon;
