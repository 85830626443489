import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const YoutubeCircleIcon = (props) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={24} cy={24} r={24} fill="#FDFDFD" />
    <Path
      d="M29.833 14.6667H18.1663C14.6663 14.6667 12.333 17.0001 12.333 20.5001V27.5001C12.333 31.0001 14.6663 33.3334 18.1663 33.3334H29.833C33.333 33.3334 35.6663 31.0001 35.6663 27.5001V20.5001C35.6663 17.0001 33.333 14.6667 29.833 14.6667ZM26.2047 25.2017L23.323 26.9284C22.1563 27.6284 21.1996 27.0918 21.1996 25.7268V22.2618C21.1996 20.8968 22.1563 20.3601 23.323 21.0601L26.2047 22.7867C27.313 23.4634 27.313 24.5367 26.2047 25.2017Z"
      fill="#260C55"
    />
  </Svg>
);
export default YoutubeCircleIcon;
