import Svg, { Path } from "react-native-svg";
const EyeIcon = ({ outlined }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <Path
      fill={outlined ? "none" : "#6724E2"}
      stroke={outlined ? "#6724E2" : "none"}
      strokeWidth={outlined ? 1 : 0}
      d="M15.938 6.862C14.204 4.14 11.67 2.572 9 2.572c-1.335 0-2.633.39-3.817 1.118-1.185.735-2.25 1.807-3.12 3.172-.75 1.178-.75 3.09 0 4.268C3.795 13.86 6.33 15.42 9 15.42c1.335 0 2.633-.39 3.818-1.118 1.184-.735 2.25-1.807 3.12-3.172.75-1.17.75-3.09 0-4.268ZM9 12.03A3.026 3.026 0 0 1 5.97 9c0-1.673 1.35-3.03 3.03-3.03 1.68 0 3.03 1.357 3.03 3.03 0 1.672-1.35 3.03-3.03 3.03Z"
    />
    <Path
      fill={outlined ? "none" : "#6724E2"}
      stroke={outlined ? "#6724E2" : "none"}
      strokeWidth={outlined ? 1 : 0}
      d="M8.999 6.855a2.141 2.141 0 1 0 0 4.283c1.177 0 2.145-.96 2.145-2.138a2.154 2.154 0 0 0-2.145-2.145Z"
    />
  </Svg>
);
export default EyeIcon;
