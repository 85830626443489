import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const ProfileIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#B39700"
          d="M23.006 3.83c-5.021 0-9.104 4.083-9.104 9.104 0 4.926 3.853 8.913 8.874 9.085.154-.019.307-.019.422 0h.134c4.907-.172 8.76-4.159 8.779-9.085 0-5.021-4.083-9.104-9.104-9.104Z"
          opacity={0.4}
        />
        <Path
          fill="#B39700"
          d="M32.738 27.12c-5.348-3.566-14.069-3.566-19.454 0-2.434 1.628-3.776 3.832-3.776 6.19 0 2.357 1.342 4.542 3.757 6.152 2.683 1.802 6.21 2.703 9.736 2.703 3.527 0 7.053-.9 9.737-2.703 2.415-1.629 3.757-3.814 3.757-6.19-.02-2.358-1.342-4.543-3.757-6.153Z"
        />
      </Svg>
    </View>
  </View>
);
export default ProfileIcon;
