import React, { useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import { HeartCircle } from "../assets/icons";

const FeedbackLike = ({ totalLike }) => {
  const likeLabel = useMemo(() => {
    switch (totalLike) {
      case 1:
        return "Sangat Jelek";
      case 2:
        return "Jelek";
      case 3:
        return "Lumayan";
      case 4:
        return "Wow! Bagus";
      case 5:
        return "Paling Mantap";
    }
  }, [totalLike]);

  return (
    <View style={styles.container}>
      <View style={{ ...styles.container, columnGap: 1 }}>
        {[1, 2, 3, 4, 5].map((l) => (
          <HeartCircle key={l} customColor={l <= totalLike ? "red" : "#999"} />
        ))}
      </View>
      <Text style={styles.label}>{likeLabel}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
  },
  label: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 10,
    lineHeight: 11,
    color: "#4EB826",
  },
});

export default FeedbackLike;
