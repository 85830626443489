import {
  StyleSheet,
  TouchableOpacity,
  Text,
  ActivityIndicator,
} from "react-native";

function PrimaryButton(props) {
  const { icon, title, loading, disabled, style } = props;
  return (
    <TouchableOpacity
      style={[styles.btnConsul, style]}
      disabled={disabled || loading}
      onPress={() => {
        if (props?.onPress) {
          props.onPress();
        }
      }}
    >
      {loading ? (
        <ActivityIndicator color="#fff" />
      ) : (
        <>
          {icon && icon}
          <Text style={{ ...styles.txConsul, marginLeft: icon ? 6 : 0 }}>
            {title}
          </Text>
        </>
      )}
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 7,
    paddingRight: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
  },
  txConsul: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});
export default PrimaryButton;
