import Svg, { Circle, Path } from "react-native-svg"
const BookBoldIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <Circle cx={14} cy={14} r={14} fill="#FDFDFD" />
    <Path
      fill="#834FE3"
      d="M21.5 8.637v8.918c0 .727-.593 1.395-1.32 1.485l-.232.03c-1.23.165-2.963.675-4.358 1.26a.748.748 0 0 1-1.027-.698V9.2c0-.278.157-.533.404-.668 1.373-.742 3.45-1.402 4.86-1.522h.045c.9 0 1.628.727 1.628 1.627ZM13.032 8.532C11.66 7.79 9.582 7.13 8.172 7.01H8.12c-.9 0-1.628.727-1.628 1.627v8.918c0 .727.593 1.395 1.32 1.485l.233.03c1.23.165 2.962.675 4.357 1.26a.748.748 0 0 0 1.028-.698V9.2a.75.75 0 0 0-.398-.668ZM8.75 10.805h1.687a.562.562 0 1 1 0 1.125H8.75a.562.562 0 1 1 0-1.125ZM11 14.18H8.75a.562.562 0 1 1 0-1.125H11a.562.562 0 1 1 0 1.125Z"
    />
  </Svg>
)
export default BookBoldIcon