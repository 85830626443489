import { ImageBackground, StyleSheet, Text, View } from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { QuoteDownIcon, QuoteUpIcon } from "../../assets/icons";

export function CompanyProfileMission({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <ImageBackground
      source={require("../../assets/images/banner-mission-company-profile.png")}
      style={styles("container")}
      imageStyle={{ borderRadius: 40 }}
      resizeMode="cover"
    >
      <View style={styles("content")}>
        <Text style={styles("txTitle")}>Fammi Memiliki Misi</Text>
        <View style={{ position: "relative" }}>
          <QuoteUpIcon style={styles("quoteUp")} />
          <Text style={styles("txDesc")}>
            Membantu Sekolah & Keluarga{" "}
            <Text style={styles("txBoldYellow")}>Mengenali</Text> &{" "}
            <Text style={styles("txBoldYellow")}>Mengatasi Hingga Tuntas</Text>{" "}
            Berbagai Persoalan Anak Serta Memastikan Solusinya Dapat{" "}
            <Text style={styles("txBoldYellow")}>Diakses Oleh Semua</Text>{" "}
            Kalangan
          </Text>
          <QuoteDownIcon style={styles("quoteDown")} />
        </View>
      </View>
      <Svg width="100%" height="100%" style={styles("overlay")}>
        <Defs>
          <LinearGradient id="gradMission" x1="50%" x2="0%" y1="0%" y2="0%">
            <Stop offset={0} stopColor="#6724e2d1" />
            <Stop offset={1} stopColor="#d300ffb1" />
          </LinearGradient>
        </Defs>
        <Rect width="100%" height="100%" fill="url(#gradMission)" />
      </Svg>
    </ImageBackground>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: 460,
      padding: 48,
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: 40,
    },
    content: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 96,
      zIndex: 1,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#fff",
      maxWidth: 600,
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 28,
      lineHeight: 48,
      maxWidth: 774,
      color: "#fff",
      textAlign: "center",
    },
    txBoldYellow: {
      fontFamily: "Archivo-Bold",
      fontStyle: "normal",
      fontWeight: 700,
      color: "#FDC52E",
    },
    quoteUp: {
      position: "absolute",
      top: "-40px",
      left: "-40px",
    },
    quoteDown: {
      position: "absolute",
      bottom: "-35px",
      right: "-40px",
    },
  },
  {
    container: {
      height: 620,
      padding: 16,
    },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
      maxWidth: "unset",
    },
    txDesc: {
      fontSize: 20,
      lineHeight: 40,
      maxWidth: 280,
    },
    quoteUp: {
      width: 43,
      height: 42,
      left: 0,
    },
    quoteDown: {
      width: 43,
      height: 42,
      right: 0,
    },
  }
);
