import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ZoomIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.8625 4.6275C15.555 4.4625 14.91 4.29 14.0325 4.905L12.93 5.685C12.8475 3.3525 11.835 2.4375 9.375 2.4375H4.875C2.31 2.4375 1.3125 3.435 1.3125 6V12C1.3125 13.725 2.25 15.5625 4.875 15.5625H9.375C11.835 15.5625 12.8475 14.6475 12.93 12.315L14.0325 13.095C14.4975 13.425 14.9025 13.53 15.225 13.53C15.5025 13.53 15.72 13.4475 15.8625 13.3725C16.17 13.215 16.6875 12.7875 16.6875 11.715V6.285C16.6875 5.2125 16.17 4.785 15.8625 4.6275ZM8.25 8.535C7.4775 8.535 6.84 7.905 6.84 7.125C6.84 6.345 7.4775 5.715 8.25 5.715C9.0225 5.715 9.66 6.345 9.66 7.125C9.66 7.905 9.0225 8.535 8.25 8.535Z"
      fill="#6724E2"
    />
  </Svg>
);
export default ZoomIcon;
