import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View, Text } from "react-native";
import {
  AddIcon,
  Calendar2Icon,
  CalendarIcon,
  StarFillIcon,
} from "../assets/icons";
import { AppBar, EmptyState, ZoomCard } from "../components";
import { useTranslation } from "react-i18next";
import MaterialAgendaCard from "../components/MaterialAgendaCard";

const Agenda = (props) => {
  const { t } = useTranslation("agenda");
  return (
    <React.Fragment>
      <AppBar title="Agenda" isBack />
      <ScrollView contentContainerStyle={{ ...styles.container, padding: 0 }}>
        {["materials", "zoom"].map((item, index) => (
          <View key={item} style={styles.mainParent}>
            <View style={styles.topSection}>
              <View style={styles.indicator} />
              <Text style={styles.textDate}>Jumat, 15 Sep 2023</Text>
            </View>
            {item === "zoom" ? <ZoomCard /> : <MaterialAgendaCard />}
          </View>
        ))}
        {/* <EmptyState
          Icon={Calendar2Icon}
          title={t("agenda:soon")}
          desc={t("agenda:soon-desc")}
        /> */}
      </ScrollView>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#EEEAFF",
  },
  mainParent: {
    marginTop: 16,
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center",
  },
  indicator: {
    width: 6,
    height: 15,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 0,
    marginRight: 10,
  },
  textDate: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
  },
  fab: {
    height: 34,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#6724E2",
    borderRadius: 100,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    position: "absolute",
    bottom: 12,
    right: 12,
  },
  fabLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#fff",
  },
});

export default Agenda;
