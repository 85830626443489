import Svg, { Circle, Path } from "react-native-svg";
const SchoolBuildingIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <Circle cx={32} cy={32} r={30.5} stroke="#4EB826" strokeWidth={3} />
    <Path
      fill="#4EB826"
      d="M36.766 36.355v11.463l-19 .032V36.355l-.016-2.375v-9.785c0-1.06.538-2.043 1.41-2.628l6.332-4.228a3.157 3.157 0 0 1 3.515 0l6.334 4.228a3.136 3.136 0 0 1 1.409 2.628v9.785l.016 2.375Z"
      opacity={0.4}
    />
    <Path
      fill="#4EB826"
      d="M47.833 46.644h-2.011v-4.75a3.756 3.756 0 0 0 2.596-3.563v-3.166a3.777 3.777 0 0 0-3.768-3.768 3.777 3.777 0 0 0-3.768 3.768v3.166c0 1.647 1.077 3.04 2.549 3.547v4.766H16.166c-.65 0-1.188.538-1.188 1.188 0 .649.539 1.187 1.188 1.187h28.39c.03 0 .047.016.078.016.032 0 .048-.016.08-.016h3.119c.649 0 1.187-.538 1.187-1.188 0-.649-.538-1.187-1.187-1.187Z"
    />
    <Path
      fill="#4EB826"
      d="M36.766 36.353h-19l-.016-2.375h19l.016 2.375ZM27.25 49.02c-.65 0-1.188-.539-1.188-1.188v-5.938c0-.649.539-1.187 1.188-1.187.65 0 1.188.538 1.188 1.188v5.937c0 .65-.539 1.188-1.188 1.188ZM27.25 30.02a2.77 2.77 0 1 0 0-5.542 2.77 2.77 0 0 0 0 5.542Z"
    />
  </Svg>
);
export default SchoolBuildingIcon;
