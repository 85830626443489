import { StyleSheet, View, Text, TextInput } from "react-native";

function InputJournal(props) {
  const { item, setJournal, journal } = props;
  return (
    <View style={{ marginBottom: 16 }}>
      <Text style={styles.txLabel}>{item.label}</Text>
      <TextInput
        style={styles.inputStyle}
        placeholder={item.placeholder}
        multiline={true}
        numberOfLines={5}
        onChangeText={(text) => {
          setJournal({
            ...journal, 
            [item.id]: text
          });
        }}
        onChange={(e) => {
          setJournal({
            ...journal, 
            [item.id]: e.target.value
          })
        }}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  txLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
  },
  inputStyle: {
    textAlignVertical: 'top',
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    marginTop: 10,
  },
});
export default InputJournal;
