import { useMemo, useState } from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native-web";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { Close2Icon, MenuIcon } from "../../assets/icons";

export function CompanyProfileTopbar({
  layout,
  isCompanyProfile,
  setIsScroll,
}) {
  const [menu, setMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  const menus = useMemo(() => {
    const m = ["Download Aplikasi"];
    if (isCompanyProfile) {
      m.unshift("Program Anti-Bullying");
    } else {
      m.unshift("Tentang Kami");
    }
    return m;
  }, [isCompanyProfile]);

  return (
    <>
      <View style={styles("container")}>
        <Image
          source={require("../../assets/fammi-logo-white.svg")}
          style={styles("logo")}
        />
        {isMobile ? (
          <TouchableOpacity
            onPress={() => {
              setIsScroll(false);
              setMenu(true);
            }}
          >
            <MenuIcon />
          </TouchableOpacity>
        ) : (
          <View style={styles("navigation")}>
            {menus.map((item) => (
              <Pressable
                key={item}
                onHoverIn={() => setIsHovered(item)}
                onHoverOut={() => setIsHovered(null)}
              >
                <Text
                  style={[
                    styles("txNavigation"),
                    { color: isHovered === item ? "#FDC52E" : "#fff" },
                  ]}
                >
                  {item}
                </Text>
              </Pressable>
            ))}
          </View>
        )}
      </View>

      {menu && (
        <View style={menuStyles.container}>
          <View style={menuStyles.header}>
            <Image
              source={require("../../assets/images/fammi-logo-text.png")}
              style={menuStyles.logo}
            />
            <TouchableOpacity
              onPress={() => {
                setIsScroll(true);
                setMenu(false);
              }}
            >
              <Close2Icon />
            </TouchableOpacity>
          </View>
          <View>
            {menus.map((item) => (
              <TouchableOpacity key={item} style={menuStyles.menu}>
                <Text style={menuStyles.txMenu}>{item}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      )}
    </>
  );
}

const menuStyles = StyleSheet.create({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 99,
    padding: 20,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 24,
  },
  logo: { width: 96, height: 36 },
  menu: {
    paddingVertical: 24,
    borderTopWidth: 1,
    borderTopColor: "#E3E3E3",
  },
  txMenu: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 28,
    lineHeight: 34,
    color: "#260C55",
  },
});

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      paddingVertical: 16,
      paddingHorizontal: 48,
      backgroundColor: "#6724E2",
      borderRadius: 100,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    logo: { width: 120, height: 40 },
    navigation: {
      flexDirection: "row",
      alignItems: "center",
      gap: 24,
    },
    txNavigation: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
    },
  },
  {
    container: {
      paddingVertical: 10,
      paddingHorizontal: 16,
    },
    logo: { width: 97, height: 32 },
  }
);
