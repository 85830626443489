import * as React from "react";
import Svg, { Path } from "react-native-svg";
const Note2Icon = (props) => (
  <Svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.291 23.329C15.6116 23.4036 15.6409 23.8234 15.3287 23.9275L13.4854 24.5341C8.85373 26.0275 6.4154 24.7791 4.9104 20.1475L3.41706 15.5391C1.92373 10.9075 3.1604 8.45747 7.79206 6.96414L8.57273 6.70561C8.97559 6.57219 9.36514 6.97532 9.24634 7.38273C9.1624 7.67057 9.08276 7.97438 9.0054 8.29414L7.86206 13.1825C6.57873 18.6775 8.45706 21.7108 13.9521 23.0175L15.291 23.329Z"
      fill="#4EB826"
    />
    <Path
      d="M20.5316 3.78952L18.5832 3.33452C14.6866 2.41286 12.3649 3.17119 10.9999 5.99452C10.6499 6.70619 10.3699 7.56952 10.1366 8.56119L8.99322 13.4495C7.84988 18.3262 9.35488 20.7295 14.2199 21.8845L16.1799 22.3512C16.8566 22.5145 17.4866 22.6195 18.0699 22.6662C21.7099 23.0162 23.6466 21.3129 24.6266 17.1012L25.7699 12.2245C26.9132 7.34786 25.4199 4.93286 20.5316 3.78952ZM18.3382 15.5962C18.2332 15.9929 17.8832 16.2495 17.4866 16.2495C17.4166 16.2495 17.3466 16.2379 17.2649 16.2262L13.8699 15.3629C13.4032 15.2462 13.1232 14.7679 13.2399 14.3012C13.3566 13.8345 13.8349 13.5545 14.3016 13.6712L17.6966 14.5345C18.1749 14.6512 18.4549 15.1295 18.3382 15.5962ZM21.7566 11.6529C21.6516 12.0495 21.3016 12.3062 20.9049 12.3062C20.8349 12.3062 20.7649 12.2945 20.6832 12.2829L15.0249 10.8479C14.5582 10.7312 14.2782 10.2529 14.3949 9.78619C14.5116 9.31952 14.9899 9.03952 15.4566 9.15619L21.1149 10.5912C21.5932 10.6962 21.8732 11.1745 21.7566 11.6529Z"
      fill="#4EB826"
    />
  </Svg>
);
export default Note2Icon;
