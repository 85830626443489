import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const MessagesCircleIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={12} cy={12} r={12} fill="black" />
    <Path
      d="M18.6661 12.5732C18.6661 14.0999 17.8794 15.4532 16.6661 16.3065L15.7728 18.2732C15.5661 18.7199 14.9661 18.8065 14.6528 18.4265L13.6661 17.2399C12.4261 17.2399 11.2861 16.8199 10.4194 16.1199L10.8194 15.6465C13.8994 15.4132 16.3328 12.9732 16.3328 9.99985C16.3328 9.49318 16.2594 8.99318 16.1261 8.51318C17.6394 9.31318 18.6661 10.8332 18.6661 12.5732Z"
      fill="white"
    />
    <Path
      d="M14.8668 8.04683C14.0868 6.44683 12.3468 5.3335 10.3335 5.3335C7.5735 5.3335 5.3335 7.42016 5.3335 10.0002C5.3335 11.5268 6.12016 12.8802 7.3335 13.7335L8.22683 15.7002C8.4335 16.1468 9.0335 16.2268 9.34683 15.8535L9.7135 15.4135L10.3335 14.6668C13.0935 14.6668 15.3335 12.5802 15.3335 10.0002C15.3335 9.30016 15.1668 8.64016 14.8668 8.04683ZM12.0002 10.5002H8.66683C8.3935 10.5002 8.16683 10.2735 8.16683 10.0002C8.16683 9.72683 8.3935 9.50016 8.66683 9.50016H12.0002C12.2735 9.50016 12.5002 9.72683 12.5002 10.0002C12.5002 10.2735 12.2735 10.5002 12.0002 10.5002Z"
      fill="white"
    />
  </Svg>
);
export default MessagesCircleIcon;
