import Svg, { Path } from "react-native-svg"
const InstagramIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
    <Path
      fill="#6724E2"
      d="M13.529 2c1.125.003 1.696.009 2.189.023l.194.007c.224.008.445.018.712.03 1.064.05 1.79.218 2.427.465.66.254 1.216.598 1.772 1.153.508.5.901 1.105 1.153 1.772.247.637.415 1.363.465 2.428.012.266.022.487.03.712l.006.194c.015.492.02 1.063.023 2.188v2.056c.003.73-.005 1.459-.022 2.188l-.006.194c-.008.225-.018.446-.03.712-.05 1.065-.22 1.79-.466 2.428a4.883 4.883 0 0 1-1.153 1.772c-.5.508-1.105.902-1.772 1.153-.637.247-1.363.415-2.427.465l-.712.03-.194.006c-.493.014-1.064.021-2.19.023l-.745.001h-1.31c-.73.003-1.459-.005-2.188-.023l-.194-.006c-.238-.009-.475-.02-.712-.031-1.064-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.771-1.153 4.903 4.903 0 0 1-1.154-1.772c-.247-.637-.415-1.363-.465-2.428a74.175 74.175 0 0 1-.03-.712l-.005-.194a79.01 79.01 0 0 1-.025-2.188v-2.056c-.003-.73.004-1.459.022-2.188l.007-.194c.008-.225.018-.446.03-.712.05-1.065.218-1.79.465-2.428A4.88 4.88 0 0 1 4.18 3.678a4.897 4.897 0 0 1 1.77-1.153c.638-.247 1.363-.415 2.428-.465.266-.012.488-.022.712-.03l.194-.006a79.01 79.01 0 0 1 2.188-.023L13.529 2ZM12.5 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 2a3 3 0 1 1-.001 6 3 3 0 0 1 .002-6m5.25-3.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
    />
  </Svg>
)
export default InstagramIcon
