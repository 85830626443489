import { APP_SPECIAL_PACKAGE } from "@env";
import {
    Image,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { AppBar } from "../components";
import { useEffect, useState } from "react";
import { getSurveys } from "../services/survey";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";

function PsikologiTestPage({route}) {
  const [tests, setTests] = useState([]);
  const navigation = useNavigation();
  const {resultOnly} = route.params;

  useEffect(() => {
    async function getToken() {
      const token = await AsyncStorage.getItem("token");
      if (!token) {
        navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
        // return navigation.navigate("Login");
        return;
      }

      return token;
    }
    getToken().then((res) => {
      getSurveys(APP_SPECIAL_PACKAGE, res, 1)
      .then((res) => {
        let theData = res?.data;
        if (resultOnly && resultOnly === "true") {
          theData = theData.filter((d) => (d.isAnswered === 1));
        }
        setTests(theData);
      });
    });
  }, [])
  return (
    <>
      <AppBar title="Tes Psikologi" isBack />
      <ScrollView contentContainerStyle={styles.container}>
        {tests.map((item) => {
          const isAnswered = item === 2;
          return (
            <View key={item} style={styles.card}>
              <Image
                source={{
                  uri: `https://fammi.ly/uploads/quiz/images/${item?.cover}`
                }}
                style={styles.image}
                resizeMode="cover"
              />
              <View style={styles.cardContent}>
                <View style={styles.textWrapper}>
                  <Text style={styles.txTitle}>
                    {item.title}
                  </Text>
                  <Text style={[styles.txPrice, { color: "#999" }]}>
                    GRATIS
                  </Text>
                </View>
                <TouchableOpacity
                  style={[
                    styles.button,
                    { backgroundColor: item.isAnswered === 1 ? "#DCF4CA" : "#DFD8FF" },
                  ]}
                  onPress={() => {
                    if (item?.isAnswered === 1) {
                      return navigation.navigate(
                        item?.isScreening2 === "0" ? "SurveyResult" : "ScreeningResult",
                        { quizId: item?.quizId, additionalContentId:APP_SPECIAL_PACKAGE, isScreening: item?.isScreening2, back: "Home"}
                      );
                    }
        
                    navigation.navigate("SurveyDetail", {
                      additionalContentId: APP_SPECIAL_PACKAGE,
                      isScreening: item?.isScreening2,
                      quizId: item?.quizId,
                      back: "Home"
                    });
                  }}
                >
                  <Text
                    style={[
                      styles.txPrice,
                      { color: item.isAnswered === 1 ? "#4EB826" : "#6724E2" },
                    ]}
                  >
                    {item.isAnswered === 1 ? "Lihat Hasil" : "Ikuti Tes"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          );
        })}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    gap: 16,
    padding: 16,
    paddingBottom: 16,
    width: "100%",
    flex: 1
  },
  card: {
    backgroundColor: "#FFFFFF",
    padding: 8,
    paddingRight: 16,
    borderRadius: 10,
    flexDirection: "row",
    gap: 16,
    width: "100%",
    height: 164,
  },
  image: {
    width: 156,
    height: 148,
    borderRadius: 10,
  },
  cardContent: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flex: 1,
  },
  textWrapper: {
    flex: 1,
    gap: 12,
    paddingTop: 8,
  },
  txTitle: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 18,
  },
  txPrice: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
  },
  button: {
    width: 132,
    height: 32,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default PsikologiTestPage;
