import Svg, { Path } from "react-native-svg";
const MailIcon = () => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M15.583 3.208H6.417c-2.75 0-4.584 1.375-4.584 4.583v6.417c0 3.208 1.834 4.583 4.584 4.583h9.166c2.75 0 4.584-1.375 4.584-4.583V7.791c0-3.208-1.834-4.583-4.584-4.583Zm.431 5.583-2.87 2.291c-.604.486-1.374.724-2.144.724s-1.55-.238-2.145-.724l-2.87-2.291a.706.706 0 0 1-.11-.972.686.686 0 0 1 .963-.11L9.708 10c.696.559 1.879.559 2.575 0l2.87-2.292a.677.677 0 0 1 .962.11.695.695 0 0 1-.1.972Z"
    />
  </Svg>
);
export default MailIcon;
