import Svg, { Path } from "react-native-svg";
const LogoutIcon = () => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M15.4 1.833h-2.383c-2.934 0-4.767 1.833-4.767 4.767v3.712h5.73c.375 0 .687.312.687.688a.693.693 0 0 1-.688.687H8.25V15.4c0 2.933 1.833 4.766 4.767 4.766h2.374c2.933 0 4.766-1.833 4.766-4.766V6.6c.01-2.934-1.824-4.767-4.757-4.767Z"
    />
    <Path
      fill="#000"
      d="m4.18 10.313 1.898-1.898a.68.68 0 0 0 .202-.485.665.665 0 0 0-.202-.486.691.691 0 0 0-.971 0l-3.071 3.07a.692.692 0 0 0 0 .972l3.07 3.071a.692.692 0 0 0 .972 0 .692.692 0 0 0 0-.972l-1.897-1.897h4.07v-1.375H4.18Z"
    />
  </Svg>
);
export default LogoutIcon;
