import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const StarIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#305C92"
          d="m29.501 9.983 2.702 5.405c.365.747 1.342 1.456 2.166 1.61l4.888.805c3.124.517 3.852 2.779 1.61 5.04l-3.814 3.815c-.633.632-.997 1.878-.786 2.779l1.092 4.715c.863 3.718-1.13 5.175-4.408 3.22l-4.58-2.722c-.825-.498-2.205-.498-3.03 0l-4.58 2.722c-3.277 1.936-5.27.498-4.408-3.22l1.092-4.715c.211-.882-.153-2.128-.786-2.78l-3.814-3.814c-2.242-2.242-1.514-4.504 1.61-5.04l4.888-.805c.824-.135 1.801-.863 2.166-1.61l2.702-5.405c1.438-2.933 3.814-2.933 5.29 0Z"
        />
        <Path
          fill="#305C92"
          d="M15.336 11.016h-11.5a1.448 1.448 0 0 1-1.438-1.438c0-.786.652-1.437 1.438-1.437h11.5c.786 0 1.437.651 1.437 1.437s-.651 1.438-1.437 1.438ZM9.586 37.852h-5.75a1.448 1.448 0 0 1-1.438-1.438c0-.786.652-1.437 1.438-1.437h5.75c.786 0 1.437.651 1.437 1.437s-.651 1.438-1.437 1.438ZM5.753 24.434H3.836a1.448 1.448 0 0 1-1.438-1.438c0-.786.652-1.437 1.438-1.437h1.917c.785 0 1.437.651 1.437 1.437s-.652 1.438-1.437 1.438Z"
          opacity={0.4}
        />
      </Svg>
    </View>
  </View>
);
export default StarIcon;
