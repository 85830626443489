import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const TiktokCircleIcon = (props) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={24} cy={24} r={24} fill="#FDFDFD" />
    <Path
      d="M29.3669 16.79C28.5693 15.8796 28.1299 14.7103 28.1302 13.5H24.5252V27.9667C24.4974 28.7495 24.1669 29.4911 23.6032 30.0351C23.0396 30.5791 22.2869 30.8832 21.5035 30.8833C19.8468 30.8833 18.4702 29.53 18.4702 27.85C18.4702 25.8433 20.4068 24.3383 22.4018 24.9567V21.27C18.3768 20.7333 14.8535 23.86 14.8535 27.85C14.8535 31.735 18.0735 34.5 21.4918 34.5C25.1552 34.5 28.1302 31.525 28.1302 27.85V20.5117C29.592 21.5615 31.3471 22.1248 33.1469 22.1217V18.5167C33.1469 18.5167 30.9535 18.6217 29.3669 16.79Z"
      fill="#260C55"
    />
  </Svg>
);
export default TiktokCircleIcon;
