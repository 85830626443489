import { printToFileAsync } from "expo-print";
import { shareAsync } from "expo-sharing";
import React, { Fragment, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import BookBoldIcon from "../assets/icons/BookBoldIcon";
import BriefCaseIcon from "../assets/icons/BriefCaseIcon";
import DocumentTextIcon from "../assets/icons/DocumentTextIcon";
import StatsIcon from "../assets/icons/StatsIcon";
import StatusUpIcon from "../assets/icons/StatusUpIcon";
import TeachIcon from "../assets/icons/TeachIcon";
import AboutMultipleIntelligences from "../components/ReportTestComponents/AboutMultipleIntelligences";
import AboutPage from "../components/ReportTestComponents/AboutPage";
import BasePaper from "../components/ReportTestComponents/BasePaper";
import CoverPage from "../components/ReportTestComponents/CoverPage";
import DepartmentSuggestion from "../components/ReportTestComponents/DepartmentSuggestion";
import DevelopSuggestion from "../components/ReportTestComponents/DevelopSuggestion";
import EducationSuggestion from "../components/ReportTestComponents/EducationSuggestion";
import HeaderContent from "../components/ReportTestComponents/HeaderContent";
import HeaderDownload from "../components/ReportTestComponents/HeaderDownload";
import IntroductionPage from "../components/ReportTestComponents/IntroductionPage";
import JobSuggestion from "../components/ReportTestComponents/JobSuggestion";
import MultipleIntelligences from "../components/ReportTestComponents/MultipleIntelligences";
import PageFooter from "../components/ReportTestComponents/PageFooter";
import {
  getDetailSurvey,
  getScreeningDescriptions,
  getScreeningResultAnswer,
  getScreeningResultRespondents,
  getScreeningResults,
} from "../services/survey";

function ReportTest({ route }) {
  const [label, setLabel] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [quiz, setQuiz] = useState({});
  const [quizAnswer, setQuizAnswer] = useState(undefined);
  const {quizId, memberId, additional_content_id, resp_id, signature_date} = route.params;

  useEffect(() => {

    if (memberId === resp_id) {
      getScreeningResultRespondents(
        quizId,
        memberId,
        additional_content_id,
        resp_id
      ).then((res) => {
        const labels = res?.data.map((r) => {
          return r.additionalInfo.replace("-Matematika", "");
        });

        setLabel(labels);
      });
    } else {
      getScreeningResults(
        quizId,
        memberId,
        additional_content_id,
        resp_id
      ).then((res) => {
        const labels = res?.data.map((r) => {
          return r.additionalInfo.replace("-Matematika", "");
        });

        setLabel(labels);
      });
    }

    getDetailSurvey(quizId).then((res) => {
      const quizWithLabel = res?.data?.filter(
        (q) => q.indexLabel !== null && q.indexLabel !== "" && q.indexLabel
      );
      setQuiz(quizWithLabel);
    });

    getScreeningResultAnswer(quizId, memberId, additional_content_id).then(
      (res) => {
        setQuizAnswer(res?.data);
      }
    );
  }, [route.params]);

  useEffect(() => {
    if (label.length === 0) {
      return;
    }

    const desc = {};

    label.map((l) => {
      getScreeningDescriptions(l.toLowerCase()).then((res) => {
        const data = res?.data;
        if (data.length === 0) {
          return;
        }

        desc[data[0].label] = data;
        setDescriptions({
          ...descriptions,
          ...desc,
        });
      });
    });
  }, [label]);

  const ExportComponent = () => (
    <View style={styles.mains}>
      <BasePaper isHideHeader isSocmedFooter>
        <CoverPage
          quizAnswer={quizAnswer}
          title="MINAT BAKAT"
          coverImg="cover-page.png"
        />
      </BasePaper>
      <BasePaper isHideHeader isSocmedFooter>
        <AboutPage quizAnswer={quizAnswer} signatureDate={signature_date}/>
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Pendahuluan: Apa Itu Minat Bakat?"
        pageNumber="1"
        customFooter={
          <Image
            source={require("../assets/images/footer-intro.png")}
            style={{
              width: "100%",
              height: 500,
              zIndex: -1,
            }}
          />
        }
      >
        <IntroductionPage />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Mengapa Kita Perlu Tahu Minat Bakat Anak?"
        pageNumber="2"
        customFooter={
          <Image
            source={require("../assets/images/footer-intro-2.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <IntroductionPage isSecondSection />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="8 Kecerdasan Majemuk"
        pageNumber="3"
        description="Untuk mengeksplorasi minat dan bakat anak, Ayah Bunda juga perlu mengetahui ragam minat dan bakat anak. Adapun kategori minat bakat bisa dilihat berdasarkan teori kecerdasan manjemuk yang dikemukakan oleh Howard Gardner, yaitu:"
        isHideFooter
      >
        <AboutMultipleIntelligences />
      </BasePaper>
      <BasePaper pageNumber="4" isHideFooter>
        <AboutMultipleIntelligences isSecondPage />
      </BasePaper>
      <BasePaper
        icon={<StatsIcon />}
        title="Hasil Tes Kecerdasan Majemuk"
        description="Berikut adalah kecerdasan majemuk yang dominan dimiliki oleh ananda:"
        pageNumber="5"
      >
        <MultipleIntelligences descriptions={descriptions} />
      </BasePaper>
      <BasePaper
        icon={<StatusUpIcon />}
        title="Saran Pengembangan"
        description="Untuk meningkatkan kecerdasan majemuk, Ayah Bunda dapat menerapkan beberapa saran berikut guna mengasah kemampuan yang dimiliki Ananda."
        pageNumber="6"
        customFooter={
          <Fragment>
            <Image
              source={require("../assets/images/footer-developments.png")}
              style={{
                width: "100%",
                height: 400,
                zIndex: -1,
              }}
            />
            <PageFooter quizAnswer={quizAnswer} />
          </Fragment>
        }
      >
        <DevelopSuggestion descriptions={descriptions} />
      </BasePaper>
      <BasePaper
        icon={<TeachIcon />}
        title="Saran Jurusan"
        description="Berikut adalah penjurusan yang sesuai dengan kecerdasan majemuk yang ananda Celine Dion miliki:"
        pageNumber="7"
        customFooter={
          <Fragment>
            <Image
              source={require("../assets/images/footer-education.png")}
              style={{
                width: "100%",
                height: 400,
                zIndex: -1,
              }}
            />
            <PageFooter  quizAnswer={quizAnswer} />
          </Fragment>
        }
      >
        <DepartmentSuggestion descriptions={descriptions} />
      </BasePaper>
      <BasePaper
        icon={<BookBoldIcon />}
        title="Saran Perencanaan Pendidikan"
        description="Berikut adalah hal-hal yang bisa dilakukan Ayah Bunda untuk menentukan perencanaan pendidikan Ananda:"
        pageNumber="8"
        customFooter={
          <Fragment>
            <Image
              source={require("../assets/images/footer-education-2.png")}
              style={{
                width: "100%",
                height: 400,
                zIndex: -1,
              }}
            />
            <PageFooter  quizAnswer={quizAnswer} />
          </Fragment>
        }
      >
        <EducationSuggestion descriptions={descriptions} />
      </BasePaper>
      <BasePaper
        icon={<BriefCaseIcon />}
        title="Saran Profesi"
        description="Berikut adalah pilihan profesi yang sesuai untuk Ananda:"
        pageNumber="9"
        customFooter={
          <Fragment>
            <Image
              source={require("../assets/images/footer-job.png")}
              style={{
                width: "100%",
                height: 500,
                zIndex: -1,
              }}
            />
            <PageFooter  quizAnswer={quizAnswer} />
          </Fragment>
        }
      >
        <JobSuggestion descriptions={descriptions} />
      </BasePaper>
    </View>
  );
  const convertToPDF = async () => {
    try {
      const htmlContent = `<html><body>${renderToString(
        ExportComponent()
      )}</body></html>`;
      if (Platform.OS === "web") {
        const pW = window.open("", "", "width=1366,height=900");
        pW.document.write(htmlContent);
        pW.document.close();
        pW.print();
      } else {
        const file = await printToFileAsync({
          html: htmlContent,
          base64: false,
        });
        await shareAsync(file.uri);
      }

      //   console.log(`PDF saved to: ${file}`);
    } catch (error) {
      console.error("Error exporting PDF:", error);
    }
  };
  return (
    <View style={styles.container}>
      <HeaderDownload onDownload={convertToPDF} />
      <View style={styles.mainContent}>
        <HeaderContent quizAnswer={quizAnswer}/>
        <ExportComponent />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FDFDFD",
    width: "100%",
    alignItems: "center",
    paddingTop: 24,
    minHeight: "100%",
  },
  mainContent: {
    width: "100%",
    maxWidth: 1200,
    backgroundColor: "#F1F2F9",
    borderRadius: 40,
    marginTop: 24,
  },
  mains: {
    paddingHorizontal: 48,
    paddingVertical: 32,
    width: "100%",
    alignItems: "center",
  },
  textBase: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "300",
  },
  textAboutHeaderPage: {
    color: "#0A0316",
    fontFamily: "Archivo-Bold",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 12,
  },
});

export default ReportTest;
