import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const LinkedinCircleIcon = (props) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={24} cy={24} r={24} fill="#FDFDFD" />
    <Path
      d="M18.0964 15.8333C18.096 16.4521 17.8499 17.0455 17.4121 17.4829C16.9743 17.9202 16.3807 18.1658 15.7619 18.1654C15.143 18.1651 14.5496 17.919 14.1123 17.4812C13.6749 17.0434 13.4294 16.4498 13.4297 15.8309C13.43 15.2121 13.6761 14.6187 14.1139 14.1814C14.5517 13.744 15.1453 13.4985 15.7642 13.4988C16.383 13.4991 16.9764 13.7452 17.4138 14.183C17.8511 14.6208 18.0967 15.2144 18.0964 15.8333ZM18.1664 19.8933H13.4997V34.4999H18.1664V19.8933ZM25.5397 19.8933H20.8964V34.4999H25.493V26.8349C25.493 22.5649 31.058 22.1683 31.058 26.8349V34.4999H35.6664V25.2483C35.6664 18.0499 27.4297 18.3183 25.493 21.8533L25.5397 19.8933Z"
      fill="#260C55"
    />
  </Svg>
);
export default LinkedinCircleIcon;
