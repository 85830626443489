import React from "react";
import { StyleSheet, View, Text } from "react-native";
import TreeIcon from "../../../assets/icons/report-test/TreeIcon";
import MusicIcon from "../../../assets/icons/report-test/MusicIcon";
import StarIcon from "../../../assets/icons/report-test/StarIcon";
import MessageIcon from "../../../assets/icons/report-test/MessageIcon";
import MathIcon from "../../../assets/icons/report-test/MathIcon";
import GlobalSearchIcon from "../../../assets/icons/report-test/GlobeIconSearchIcon";
import PeoplesIcon from "../../../assets/icons/report-test/PeoplesIcon";
import ProfileIcon from "../../../assets/icons/report-test/ProfileIcon";
import ListStatic from "../ListStatic";

function AboutMultipleIntelligences(props) {
  const { isSecondPage } = props;
  const lists = [
    {
      id: 1,
      title: "Naturalis",
      subDescription: [
        "Anak dengan kecerdasan naturalistik akan lebih selaras dan sangat mencintai alam.",
        "Anak dengan kecerdasan ini memiliki kemampuan yang lebih dan mudah dalam mengingat spesies tumbuhan, binatang, dan senang dengan aktivitas yang berhubungan dengan alam.",
      ],
      subDescriptionBold: ["lebih selaras dan sangat mencintai alam."],
      icon: TreeIcon,
      color: "#3E951D",
      secondColor: "#DCF4CA",
      thirdColor: "#F1FBEA",
      bgColor: "#F1FBEA",
    },
    {
      id: 2,
      title: "Musikal",
      subDescription: [
        "Kecerdasan musikal adalah kemampuan untuk menikmati, mengubah, mengarang, atau melakukan apapun dengan musik.",
        "Hal ini akan terlihat dari bagaimana anak menikmati atau mengekspresikan diri melalui musik. Biasanya anak-anak akan terlihat lebih mudah menguasai hal-hal berkaitan dengan musik dibandingkan anak lainnya",
      ],
      subDescriptionBold: [
        "kemampuan untuk menikmati, mengubah, mengarang, atau melakukan apapun dengan musik.",
      ],
      icon: MusicIcon,
      color: "#834FE3",
      secondColor: "#ECE4FB",
      thirdColor: "#F5F0FF",
      bgColor: "#F5F0FF",
    },
    {
      id: 3,
      title: "Kinestetik",
      subDescription: [
        "Kecerdasan kinestetik berarti kemampuan untuk mengekspresikan ide dan berpikir melalui pergerakan tubuh.",
        "Anak dengan kecerdasan di area ini biasanya memiliki bakat atau talenta untuk berpikir ketika bergerak atau menggerakan tubuh seperti para atlet ternama.",
      ],
      subDescriptionBold: [
        "mengekspresikan ide dan berpikir melalui pergerakan tubuh.",
      ],
      icon: StarIcon,
      color: "#305C92",
      secondColor: "#D1DFF0",
      thirdColor: "#ECF2F9",
      bgColor: "#ECF2F9",
    },
    {
      id: 4,
      title: "Verbal",
      subDescription: [
        "Pernahkah Ayah Bunda menemukan anak yang tidak berhenti bicara atau menulis? Nah anak-anak dengan kecerdasan linguistik biasanya memiliki bakat di area bahasa baik verbal dan non-verbal termasuk tidak berhenti bicara atau menulis.",
        "Menurut Gardner, kecerdasan ini adalah kemampuan untuk memahami, menggunakan, dan memanipulasi bahasa/kata-kata baik tertulis ataupun yang disampaikan secara lisan.",
      ],
      subDescriptionBold: ["area bahasa baik verbal dan non-verbal"],
      icon: MessageIcon,
      color: "#034932",
      secondColor: "#A6D9C8",
      thirdColor: "#CFFCED",
      bgColor: "#CFFCED",
    },
    {
      id: 5,
      title: "Logika - Matematika",
      subDescription: [
        "Kecerdasan logika biasanya menunjukan kemampuan nalar yang tinggi.",
        "Anak dengan bakat di bidang ini memiliki kapasitas pemahaman yang tinggi berkaitan dengan logika dan nalar. Biasanya anak-anak ini akan memiliki kemampuan yang lebih dari teman-temannya pada bidang matematika atau ilmu pengetahuan.",
      ],
      subDescriptionBold: [
        "kemampuan nalar yang tinggi.",
        "matematika atau ilmu pengetahuan.",
      ],
      icon: MathIcon,
      color: "#FD681C",
      secondColor: "#FEDECD",
      thirdColor: "#FFEEE5",
      bgColor: "#FFEEE5",
    },
    {
      id: 6,
      title: "Spasial",
      subDescription: [
        "Kecerdasan spasial berkaitan dengan kemampuan menangkap arah, bentuk, dan ruang yang akurat.",
        "Anak-anak dengan bakat di bidang ini biasanya juga memiliki penglihatan yang tajam mengenai suatu desain atau hal-hal aestetik.",
      ],
      subDescriptionBold: [
        "kemampuan menangkap arah, bentuk, dan ruang yang akurat",
      ],
      icon: GlobalSearchIcon,
      color: "#AE1919",
      secondColor: "#F9D2D2",
      thirdColor: "#FCE9E9",
      bgColor: "#FCE9E9",
    },
    {
      id: 7,
      title: "Interpersonal",
      subDescription: [
        "Anak yang dengan kecerdasan interpersonal memiliki kemampuan dalam berkomunikasi dan berhubungan dengan orang lain.",
        "Anak yang memiliki bakat di bidang ini biasanya terampil berkomunikasi secara verbal dan non-verbal, mudah membangun hubungan yang positif dengan orang lain, dan juga cepat menyelesaikan konflik dengan damai",
      ],
      subDescriptionBold: [
        "kemampuan dalam berkomunikasi dan berhubungan dengan orang lain.",
      ],
      icon: PeoplesIcon,
      color: "#00314D",
      secondColor: "#B2E3FF",
      thirdColor: "#CCECFF",
      bgColor: "#CCECFF",
    },
    {
      id: 8,
      title: "Intrapersonal",
      subDescription: [
        "Keterampilan mengelola dan memahami diri menjadi hal yang menonjol bagi anak yang memiliki kecerdasan intrapersonal.",
        "Anak yang mampu memiliki bakat di area ini akan terlihat mampu mengenali kekuatan dan kelemahan dirinya dengan mudah, memiliki konsep diri yang positif, juga lebih mengenali kemauannya dibandingkan dengan anak lainnya. Biasanya akan berkembang menjadi ilmuan, filsuf, atau penulis.",
      ],
      subDescriptionBold: ["mengelola dan memahami diri"],
      icon: ProfileIcon,
      color: "#665600",
      secondColor: "#FFEB80",
      thirdColor: "#FFF7CC",
      bgColor: "#FFF7CC",
    },
  ];
  return (
    <View>
      {!isSecondPage && (
        <View style={styles.parentItem}>
          {lists.map((item, index) => (
            <View
              key={index}
              style={[
                styles.item,
                { backgroundColor: item.thirdColor },
                index == 0 && {
                  borderTopLeftRadius: 80,
                },
                index == 3 && {
                  borderTopRightRadius: 80,
                },
                index == 4 && {
                  borderBottomLeftRadius: 80,
                },
                index == 7 && {
                  borderBottomRightRadius: 80,
                },
                index < 4 && {
                  flexDirection: "column-reverse",
                },
              ]}
            >
              <View
                style={{ ...styles.labels, backgroundColor: item.secondColor }}
              >
                <Text style={{ ...styles.textLabels, color: item.color }}>
                  {item.title}
                </Text>
              </View>
              {<item.icon />}
            </View>
          ))}
        </View>
      )}
      <View style={styles.parentDetail}>
        {lists
          .slice(isSecondPage ? 3 : 0, isSecondPage ? 8 : 3)
          .map((item, index) => (
            <ListStatic
              key={index}
              item={{
                ...item,
                title: `Kecerdasan ${item.title}`,
                icon: (
                  <View
                    style={{ ...styles.parentIcon, borderColor: item.color }}
                  >
                    <View style={{ height: 38, width: 38 }}>
                      {<item.icon width={38} height={38} />}
                    </View>
                  </View>
                ),
              }}
            />
          ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  parentItem: {
    borderTopLeftRadius: 90,
    borderTopRightRadius: 90,
    borderBottomRightRadius: 90,
    borderBottomLeftRadius: 90,
    padding: 10,
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderStyle: "solid",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginVertical: 24,
  },
  parentIcon: {
    width: 64,
    height: 64,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100 / 2,
    borderStyle: "solid",
    borderWidth: 3,
  },
  item: {
    width: "25%",
    height: 168,
    paddingHorizontal: 10,
    paddingVertical: 30,
    flexDirection: "column",
    alignItems: "center",
    gap: 32,
  },
  labels: {
    borderRadius: 100 / 2,
    paddingVertical: 8,
    paddingHorizontal: 10,
    width: "100%",
  },
  textLabels: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "400",
    textAlign: "center",
  },
  parentDetail: {
    flexDirection: "column",
    gap: 15,
  },
});
export default AboutMultipleIntelligences;
