import Svg, { Path } from "react-native-svg";
const WalletIcon = ({ customColor }) => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill={customColor ?? "#fff"}
      d="M15.727 12.06c-.18 2.002-1.627 3.315-3.727 3.315H5.25c-2.07 0-3.75-1.68-3.75-3.75v-5.25c0-2.04 1.23-3.465 3.143-3.705.195-.03.397-.045.607-.045H12c.195 0 .383.007.563.038 1.792.21 3.007 1.462 3.164 3.277a.367.367 0 0 1-.375.397H14.19c-.72 0-1.387.278-1.867.773a2.638 2.638 0 0 0-.788 2.115c.12 1.365 1.32 2.438 2.745 2.438h1.072c.218 0 .398.18.375.397Z"
    />
    <Path
      fill={customColor ?? "#fff"}
      d="M16.5 8.227v1.545a.77.77 0 0 1-.75.765h-1.47c-.81 0-1.552-.592-1.62-1.402a1.506 1.506 0 0 1 .45-1.223c.278-.285.66-.45 1.08-.45h1.56a.77.77 0 0 1 .75.765Z"
    />
  </Svg>
);
export default WalletIcon;
