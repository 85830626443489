import { Image, Text, TouchableOpacity, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { GradientBackground } from "../GradientBackground";

export function ProgramAntiBullyingHero({ layout }) {
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  return (
    <View style={styles("container")}>
      <View style={styles("content")}>
        <Text style={styles("txTitle")}>
          Deteksi Dini & Tuntaskan Bullying di Sekolah Anda Bersama Fammi
        </Text>
        <Text style={styles("txDesc")}>
          200+ Sekolah Sudah Melakukan Tes General Checkup Anti-Bullying bersama
          Fammi
        </Text>
        <View style={styles("buttons")}>
          <TouchableOpacity style={styles("button")}>
            <Text style={styles("txButton")}>Daftar Sekarang</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles("contactButton")}>
            <Text style={[styles("txButton"), { color: "#FDC52E" }]}>
              Hubungi Fammi
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <Image
        source={require("../../assets/images/anti-bullying/hero.png")}
        style={styles("image")}
      />
      <GradientBackground
        id="antiBullyingHero"
        colors={["#1E0354", "#F885FA"]}
        style={{ borderRadius: 40 }}
        coordinates={{
          x2: "100%",
          y1: "100%",
        }}
      />
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      padding: 48,
      marginTop: 24,
      gap: 76,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
    },
    content: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 24,
      zIndex: 1,
    },
    image: {
      width: 474,
      height: 418,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#fff",
      maxWidth: 554,
    },
    txDesc: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 26,
      color: "#fff",
      maxWidth: 420,
    },
    buttons: {
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    },
    button: {
      backgroundColor: "#FDC52E",
      paddingVertical: 12,
      paddingHorizontal: 22,
      borderRadius: 24,
    },
    contactButton: {
      paddingVertical: 12,
      paddingHorizontal: 22,
      borderRadius: 24,
      borderWidth: 1,
      borderColor: "#FDC52E",
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 24,
      color: "#000",
    },
  },
  {
    container: {
      height: 705,
      paddingHorizontal: 16,
      justifyContent: "flex-start",
    },
    content: {
      gap: 0,
    },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 14,
      lineHeight: 24,
      marginTop: 12,
      marginBottom: 32,
    },
  }
);
