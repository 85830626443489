import * as React from "react";
import Svg, { Path } from "react-native-svg";
const GraphIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M16.2536 5.21206C15.7736 3.58456 14.4161 2.22706 12.7886 1.74706C11.5511 1.38706 10.6961 1.41706 10.1036 1.85956C9.39109 2.39206 9.30859 3.35206 9.30859 4.03456V5.90206C9.30859 7.74706 10.1486 8.68456 11.7986 8.68456H13.9511C14.6261 8.68456 15.5936 8.60206 16.1261 7.88956C16.5836 7.30456 16.6211 6.44956 16.2536 5.21206Z"
      fill="#292D32"
    />
    <Path
      d="M14.1806 10.0208C13.9856 9.79584 13.7006 9.66834 13.4081 9.66834H10.7231C9.40309 9.66834 8.33059 8.59584 8.33059 7.27584V4.59084C8.33059 4.29834 8.20309 4.01334 7.97809 3.81834C7.76059 3.62334 7.46059 3.53335 7.17559 3.57085C5.41309 3.79585 3.79309 4.76334 2.73559 6.21834C1.67059 7.68084 1.28059 9.46584 1.61809 11.2508C2.10559 13.8308 4.16809 15.8933 6.75559 16.3808C7.16809 16.4633 7.58059 16.5008 7.99309 16.5008C9.35059 16.5008 10.6631 16.0808 11.7806 15.2633C13.2356 14.2058 14.2031 12.5858 14.4281 10.8233C14.4656 10.5308 14.3756 10.2383 14.1806 10.0208Z"
      fill="#292D32"
    />
  </Svg>
);
export default GraphIcon;
