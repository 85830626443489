import { StyleSheet, Text, View } from "react-native";

const SurveyQuestionCard = ({ number, question, type, children }) => {
  const label =
    type === "single_choice"
      ? "Pilih Satu Jawaban"
      : type === "multiple_choice"
      ? "Boleh Pilih Lebih dari Satu Jawaban"
      : type === "data_source"
      ? "Pilih Jawaban"
      : "Ketikan Jawaban Kamu";

  return (
    <View style={styles.container}>
      <View style={styles.question}>
        <View style={[styles.flexRow]}>
          <View style={styles.number}>
            <Text style={styles.numberTx}>{number}</Text>
          </View>
          <View style={styles.label}>
            <Text style={styles.labelTx}>{label}</Text>
          </View>
        </View>
        <Text style={styles.title}>{question}</Text>
      </View>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 10,
    display: "flex",
    flexDirection: "column",
  },
  question: {
    gap: 12,
    padding: 12,
    borderRadius: 10,
    backgroundColor: "#EEEAFF",
  },
  number: {
    width: 26,
    height: 26,
    // borderWidth: 1,
    // padding: 2,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    backgroundColor: "#6724E2",
  },
  label: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 100,
    backgroundColor: "#DFD8FF",
  },
  numberTx: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 10,
    lineHeight: 14,
    color: "#fff",
  },
  labelTx: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 14,
    color: "#6724E2",
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 16,
  },
  flexRow: {
    // display: "row",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default SurveyQuestionCard;
