import Svg, { Path } from "react-native-svg";
const ChevronLeft = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m11.25 14.94-4.89-4.89a1.49 1.49 0 0 1 0-2.1l4.89-4.89"
    />
  </Svg>
);
export default ChevronLeft;
