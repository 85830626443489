import Svg, { Path } from "react-native-svg";
const ArchiveTickIcon = ({ customColor }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <Path
      stroke={customColor ? customColor : "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fill={customColor ? customColor : "#fff"}
      d="M12.615 1.5h-7.23A2.902 2.902 0 0 0 2.49 4.395v10.568c0 1.35.968 1.92 2.153 1.267l3.66-2.033c.39-.217 1.02-.217 1.402 0l3.66 2.033c1.185.66 2.153.09 2.153-1.268V4.396A2.913 2.913 0 0 0 12.615 1.5Z"
    />
    <Path
      stroke={customColor ? customColor : "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      fill={customColor ? customColor : "#fff"}
      d="m7.192 8.25 1.125 1.125 3-3"
    />
  </Svg>
);
export default ArchiveTickIcon;
