import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Pressable } from "react-native";
import { BookIcon, ChatIcon, ChevronRight, Message2 } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import { getMaterials } from "../services/materials";
import { getConsultants } from "../services/consultants";

function ScreeningCtaBox(props) {
  const {additionalContentId, result, program} = props;
  const navigation = useNavigation();
  const [isMaterialsAvailable, setIsMaterialsAvailable] = useState(false);
  const [isConsultantAvaialble, setIsConsultantAvaialble] = useState(false);

  useEffect(() => {
    getMaterials(additionalContentId)
    .then((res) => {
      if (res?.data?.length > 0) {
        setIsMaterialsAvailable(true);
      }
    })

    getConsultants("all", `program-${additionalContentId}`,).then((res) => {
      if (res?.data.length > 0) {
        setIsConsultantAvaialble(true);
      }
    });
  }, [additionalContentId]);

  return (
    <View style={styles.parentBox}>
      <View style={styles.top}>
        <View style={styles.labelParent}>
          <Text style={styles.textLabel}>Tips Setelah Screening</Text>
        </View>
        <Text style={styles.txTitle}>
          Kamu bisa pilih salah satu atau lebih solusi Fammi di bawah ini
        </Text>
      </View>
      <View style={styles.main}>
        <Pressable 
          style={styles.btn}
          onPress={() => {
            navigation.navigate("NewDiscuss", {
              program:{...program},
              additionalContentId: additionalContentId,
              hideCateogry: true
            });
          }}
        >
          <View style={styles.row}>
            <ChatIcon fill="#6724E2" />
            <Text style={styles.txBtn}>
              {
                result?.recommendationMode === "label" &&
                `Berbagi Hasil Positif`
              }
              {
                result?.recommendationMode !== "label" &&
                result?.rangeEnd === result?.maxRange?.split("-")?.[0] &&
                `Berbagi Hasil Positif`
              }
              {
                result?.recommendationMode !== "label" &&
                result?.rangeEnd !== result?.maxRange?.split("-")?.[0] &&
                `Tanya di Forum Diskusi`
              }
            </Text>
          </View>
          <ChevronRight />
        </Pressable>
        {
          isConsultantAvaialble && 
          <Pressable 
            style={styles.btn}
            onPress={() => {
              navigation.navigate("ChooseExpert", {
                type: "all",
                status: `program-${additionalContentId}`,
              });
            }}
          >
            <View style={styles.row}>
              <Message2 customColor="#6724E2" circleColor="#fff" />
              <Text style={styles.txBtn}>Konsultasi ke Ahli, Tokoh & Sahabat</Text>
            </View>
            <ChevronRight />
          </Pressable>
        }
        {
          isMaterialsAvailable &&
          <Pressable 
            style={styles.btn}
            onPress={() => {
              navigation.navigate("EbookList", {
                additionalContentId: additionalContentId,
              });
            }}
          >
            <View style={styles.row}>
              <BookIcon fill="#6724E2" stroke="#fff" strokeWidth={1} width={22} height={22} />
              <Text style={styles.txBtn}>Pelajari Berbagai Materi</Text>
            </View>
            <ChevronRight />
          </Pressable>
        }
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    borderRadius: 10,
    backgroundColor: "#fff",
    marginBottom: 16
  },
  top: {
    flexDirection: "column",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderBottomColor: "#EAEAEA",
    borderBottomWidth: 1,
    borderStyle: "solid",
  },
  main: {
    paddingHorizontal: 12,
    paddingVertical: 16,
	flexDirection: "column",
	gap: 12,
  },
  labelParent: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: "#4EB826",
    borderRadius: 12,
  },
  textLabel: {
    color: "#FFF",
    fontFamily: "Archivo-Bold",
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: "700",
  },
  txTitle: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    maxWidth: 250,
    marginTop: 12,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  txBtn: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    marginLeft: 10,
  },
  btn: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 18,
    paddingHorizontal: 16,
    paddingVertical: 9,
    borderWidth: 1,
    borderColor: "#EAEAEA",
    borderStyle: "solid",
  },
});

export default ScreeningCtaBox;
