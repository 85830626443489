import Svg, { Path } from "react-native-svg";
const ArchiveIcon = () => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="M15.418 1.751H6.582a3.552 3.552 0 0 0-3.539 3.538v12.916c0 1.65 1.183 2.347 2.631 1.55l4.473-2.485c.477-.266 1.247-.266 1.714 0l4.474 2.484c1.448.807 2.63.11 2.63-1.549V5.29a3.56 3.56 0 0 0-3.547-3.538Zm-1.1 6.527-3.666 3.666a.68.68 0 0 1-.486.202.68.68 0 0 1-.486-.202L8.305 10.57a.691.691 0 0 1 0-.971.692.692 0 0 1 .972 0l.889.889 3.18-3.181a.692.692 0 0 1 .972 0 .692.692 0 0 1 0 .972Z"
    />
  </Svg>
);
export default ArchiveIcon;
