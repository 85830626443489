import { LinearGradient } from "expo-linear-gradient";
import React, { Fragment } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  EmojiHappy2Icon,
  EmojiNormalIcon,
  EmojiSadIcon,
} from "../../assets/icons";

function ResultDescription(props) {
  const lists = [
    {
      id: 1,
      title: "Aman",
      icon: EmojiHappy2Icon,
      color: ["#3E951D", "#4EB826", "#DCF4CA", "#F1FBEA"],
      result: [
        "Ananda tidak mengalami kesulitan belajar",
        "Ananda sudah mengetahui minat bakat yang dimiliki",
        "Ananda tidak pernah diejek oleh temannya",
        "Ananda tidak pernah merasa cemas, takut atau khawatir",
        "Ananda tidak pernah sulit mengendalikan emosi",
        "Ananda tidak pernah sulit lepas dari HP",
        "Ananda tidak pernah kesulitan berinteraksi dengan orang lain",
      ],
    },
    {
      id: 2,
      title: "Waspada",
      icon: EmojiNormalIcon,
      color: ["#A48A00", "#B29600", "#FFEB80", "#FFF7CC"],
      result: [
        "Ananda tidak mengalami kesulitan belajar",
        "Ananda sudah mengetahui minat bakat yang dimiliki",
        "Ananda tidak pernah diejek oleh temannya",
        "Ananda tidak pernah merasa cemas, takut atau khawatir",
        "Ananda tidak pernah sulit mengendalikan emosi",
        "Ananda tidak pernah sulit lepas dari HP",
        "Ananda tidak pernah kesulitan berinteraksi dengan orang lain",
      ],
    },
    {
      id: 3,
      title: "Kritis",
      icon: EmojiSadIcon,
      color: ["#E54646", "#E54646", "#F9D2D2", "#FCE9E9"],
      result: [
        "Ananda tidak mengalami kesulitan belajar",
        "Ananda sudah mengetahui minat bakat yang dimiliki",
        "Ananda tidak pernah diejek oleh temannya",
        "Ananda tidak pernah merasa cemas, takut atau khawatir",
        "Ananda tidak pernah sulit mengendalikan emosi",
        "Ananda tidak pernah sulit lepas dari HP",
        "Ananda tidak pernah kesulitan berinteraksi dengan orang lain",
      ],
    },
  ];
  return (
    <Fragment>
      <View style={styles.container}>
        {lists.map((item) => (
          <ResultCard key={item.id} data={item} />
        ))}
      </View>
    </Fragment>
  );
}

function ResultCard({ data }) {
  return (
    <LinearGradient
      colors={[data.color[3], "#fff"]}
      start={{ x: 0.4, y: 0 }}
      end={{ x: 0.6, y: 0 }}
      style={styles.card}
    >
      <View style={[styles.icon, { borderColor: data.color[1] }]}>
        <data.icon width={38} height={38} />
      </View>
      <View style={{ gap: 16 }}>
        <View style={[styles.cardTitle, { backgroundColor: data.color[2] }]}>
          <Text style={[styles.textCardTitle, { color: data.color[0] }]}>
            {data.title}
          </Text>
        </View>
        <View style={{ gap: 4 }}>
          {[
            "Belajar",
            "Minat Bakat",
            "Bullying",
            "Kecemasan",
            "Emosi",
            "Gadget",
            "Relasi",
          ].map((aspek, index) => (
            <View style={styles.item}>
              <Text style={styles.textItem}>•</Text>
              <Text style={styles.textItem}>
                <Text style={{ fontWeight: 600 }}>{aspek}: </Text>
                {data.result[index]}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 32,
    gap: 18,
  },
  textBase: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "300",
  },
  card: {
    width: "100%",
    height: 291,
    padding: 32,
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderRadius: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 32,
  },
  icon: {
    width: 64,
    height: 64,
    borderWidth: 3,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  cardTitle: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 50,
    alignSelf: "flex-start",
  },
  textCardTitle: {
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 17.41,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  textItem: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontWeight: 300,
    fontStyle: "normal",
    lineHeight: 22,
  },
});
export default ResultDescription;
