import Svg, { Circle, Path } from "react-native-svg";
const DocumentCircle = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
    <Circle cx={15} cy={15} r={15} fill="#EEEAFF" />
    <Path
      fill="#6724E2"
      d="M21.375 13.643h-2.168a3.23 3.23 0 0 1-3.225-3.226V8.25a.752.752 0 0 0-.75-.75h-3.18c-2.31 0-4.177 1.5-4.177 4.178v6.645c0 2.677 1.867 4.177 4.178 4.177h5.895c2.31 0 4.177-1.5 4.177-4.177v-3.93a.752.752 0 0 0-.75-.75Z"
    />
    <Path
      fill="#6724E2"
      d="M17.85 7.658c-.308-.308-.84-.098-.84.33v2.617c0 1.095.93 2.003 2.062 2.003.713.007 1.703.007 2.55.007.428 0 .653-.502.353-.802-1.08-1.088-3.015-3.045-4.125-4.155Z"
    />
  </Svg>
);
export default DocumentCircle;
