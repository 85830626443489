import moment from "moment";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";

function CoverPage(props) {
  const { quizAnswer, title, coverImg } = props;
  const [name, setName] = useState("");
  useEffect(() => {
    if (!quizAnswer) {
      return;
    }

    const filteredName = quizAnswer?.filter((q) => q.indexLabel === "nama");
    if (filteredName.length > 0) {
      setName(filteredName[0].chosenAnswer);
    }
  }, [quizAnswer]);
  return (
    <View style={styles.parentCover}>
      <View style={styles.header}>
        <View>
          <Text style={styles.textHeader}>Hasil Tes Psikologi</Text>
          <Text style={styles.textHeaderDate}>
            {moment(quizAnswer?.[0]["created"]).format("MMMM YYYY")}
          </Text>
        </View>
        <Image
          source={require("../../../assets/images/fammi-logo-text.png")}
          alt="fammi-logo"
          style={{ width: 115, height: 42 }}
          resizeMode="contain"
        />
      </View>
      <View style={{ height: 146, justifyContent: "center" }}>
        <Text style={styles.textTitleCover}>{title}</Text>
      </View>

      <Image
        source={require(`../../../assets/images/${coverImg}`)}
        alt="cover-page"
        style={{ width: "100%", height: 648 }}
        resizeMode="contain"
      />
      <View style={{ marginTop: 48 }}>
        <View style={styles.parentLabels}>
          <View style={styles.labelName}>
            <Text style={styles.textName}>{name}</Text>
          </View>
        </View>
        <Text
          style={{
            ...styles.textHeaderDate,
            textAlign: "center",
            fontSize: 15,
          }}
        >
          Seluruh laporan tes berikut sudah disusun, diskoring dan dianalisis
          oleh Psikolog Fammi
        </Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentCover: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",
    paddingTop: 48,
    paddingHorizontal: 72,
    paddingBottom: 0,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderBottomColor: "#D9DADC",
    borderBottomWidth: 1,
    borderStyle: "solid",
    paddingBottom: 12,
  },
  textHeader: {
    color: "#834FE3",
    fontFamily: "Archivo-Regular",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  textHeaderDate: {
    color: "#959895",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
  },
  textTitleCover: {
    color: "#6724E2",
    fontFamily: "Archivo-Bold",
    fontSize: 44,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 48,
    maxWidth: 500,
  },
  parentLabels: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 48,
    marginBottom: 24,
  },
  labelName: {
    maxWidth: 496,
    padding: 12,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#834FE3",
    borderRadius: 100 / 2,
    width: "100%",
  },
  textName: {
    color: "#FFF",
    fontFamily: "Archivo-Bold",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 26,
  },
  paperFooter: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 32,
  },
  footer: {
    width: "100%",
    maxWidth: 730,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#E4DAF7",
    paddingHorizontal: 24,
    paddingVertical: 12,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textFooterPage: {
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    color: "#674EE2",
  },
});

export default CoverPage;
