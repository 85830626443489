import * as React from "react";
import Svg, { Path } from "react-native-svg";
const TickCircleIcon = (props) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M11.0002 20.1668C16.0418 20.1668 20.1668 16.0418 20.1668 11.0002C20.1668 5.9585 16.0418 1.8335 11.0002 1.8335C5.9585 1.8335 1.8335 5.9585 1.8335 11.0002C1.8335 16.0418 5.9585 20.1668 11.0002 20.1668Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M7.104 10.9999L9.69817 13.5941L14.8957 8.40576"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default TickCircleIcon;
