import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";

function ListStatic(props) {
  const { item } = props;

  const formattedDescription = (description, descriptionBold) => {
    if (description) {
      const descriptionReplace =
        description &&
        description?.replace(descriptionBold ?? "", "| {bold} |");
      const descriptionSplit = descriptionReplace?.split("|");
      return descriptionSplit?.map((i, index) => {
        if (i === " {bold} ") {
          return <Text style={styles.textBold}>{descriptionBold}</Text>;
        }
        return <Text style={styles.textMain}>{i}</Text>;
      });
    }
  };
  return (
    <View style={styles.parentList}>
      <LinearGradient
        style={{
          flexDirection: "row",
          paddingHorizontal: 32,
          paddingVertical: 24,
          zIndex: 5,
          width: item?.image ? "80%" : "100%",
          borderRadius: 14,
        }}
        colors={[item.bgColor ?? "#fff", "rgba(255, 255, 255, 0.1)"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      >
        {item?.icon && item.icon}
        <View style={{ marginLeft: 32, width: "85%", gap: 12 }}>
          {item?.title && (
            <View
              style={{ ...styles.labels, backgroundColor: item.secondColor }}
            >
              <Text style={{ ...styles.textLabels, color: item.color }}>
                {item.title}
              </Text>
            </View>
          )}
          {item.description && (
            <Text style={{ lineHeight: 22 }}>
              {formattedDescription(item?.description, item?.descriptionBold)}
            </Text>
          )}

          {item?.subDescription?.map((i, index) => (
            <View
              key={index}
              style={[styles.flexRow, { alignItems: "flex-start" }]}
            >
              <View
                style={{ ...styles.indicators, backgroundColor: item?.color }}
              />
              <Text style={{ marginTop: -6,  }}>
                {formattedDescription(
                  i,
                  item?.subDescriptionBold?.[index] ?? null
                )}
              </Text>
            </View>
          ))}
        </View>
      </LinearGradient>
      {item?.image && (
        <LinearGradient
          style={{
            width: "20%",
          }}
          colors={["#fff", "rgba(255, 255, 255, 0.1)"]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        >
          <Image source={item?.image} style={styles.imgBg} resizeMode="cover" />
        </LinearGradient>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  parentList: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderStyle: "solid",
    backgroundColor: "#fff",
    borderRadius: 14,
  },
  labels: {
    backgroundColor: "#DCF4CA",
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignSelf: "flex-start",
    borderRadius: 100 / 2,
  },
  textLabels: {
    color: "#3E951D",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
  },
  indicators: {
    backgroundColor: "#4EB826",
    height: 10,
    width: 10,
    borderRadius: 10 / 2,
    marginRight: 8,
  },
  flexRow: {
    flexDirection: "row",
    marginBottom: 8,
    width: "100%",
  },
  textMain: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
    color: "#0A0316",
    width: "100%",
	lineHeight: 22
  },
  textBold: {
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    color: "#0A0316",
    width: "100%",
	lineHeight: 22
  },
  imgBg: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    opacity: 0.7,
    position: "relative",
	borderTopRightRadius: 14,
	borderBottomRightRadius: 14,
  },
});
export default ListStatic;
