import React, { Fragment, useEffect, useState } from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import Fammi from "../../../assets/icons/Fammi";
import moment from "moment";

function AboutPage(props) {
  const {quizAnswer, signatureDate} = props;
  const [identity, setIdentity] = useState();

  useEffect(() => {
    if (!quizAnswer) {
      return;
    }

    let filteredData = {};
    quizAnswer?.map((q) => {
      if (q.indexLabel && q.indexLabel !== null && q.indexLabel !== "") {
        filteredData[q.indexLabel] = q.chosenAnswer;
      }
    });

    setIdentity(filteredData);
  }, [quizAnswer]);

  return (
    <Fragment>
      <View style={styles.header}>
        <Fammi />
        <View>
          <Text style={styles.textTitleHeader}>Fammi Indonesia</Text>
          <Text style={styles.textMainHeader}>
            Jl. Ir. H. Juanda No.299, Kec. Coblong, Kota Bandung, Jawa Barat
            40135
          </Text>
          <Text style={styles.textMainHeader}>
            Email : mitra@fammi.ly | Contact : 0838 2450 6194 (Nadya)
          </Text>
        </View>
      </View>
      <View style={styles.parentMain}>
        <Text style={{...styles.textMainBase, fontWeight: 600, fontSize: 14, textAlign: "center", marginBottom: 24}}>
          SURAT PENGANTAR PSIKOLOGI
        </Text>
        <Text style={styles.textMainBase}>
        Minat bakat anak merupakan sesuatu hal yang perlu untuk diketahui oleh orangtua dan guru untuk mengetahui sebetulnya kegiatan apa saja yang paling diminati oleh ananda. Hal ini bisa dijadikan sebagai dasar perencanaan pendidikan ananda dan menentukan cita-cita atau profesi apa yang tepat baginya sesuai dengan kecerdasan majemuk yang dominan dimilikinya. Agar kelak ananda bisa menjadi profesi tersebut dengan perasaan yang nyaman dan mampu bekerja secara profesional.
        </Text>
        <Text style={styles.textMainBase}>
        Fammi bekerja sama dengan SMP Salman Al-Farisi telah melaksanakan Tes Psikologi Minat Bakat pada siswa-siswi. Berikut kami sampaikah hasil Tes Minat Bakat yang telah ananda selesaikan. Hasil yang Ayah Bunda dapatkan meliputi 3 kecerdasan majemuk yang dominan dimiliki oleh ananda beserta penjelasannya, saran pengembangan kecerdasan majemuk yang dimiliki oleh ananda, saran perencanaan pendidikan, hingga saran profesi yang sesuai dengan kecerdasan majemuk ananda. Berikut adalah identitas dari ananda yang telah mengikuti Tes Psikologi Minat Bakat: 
        </Text>
        <View style={{ flexDirection: "column", gap: 4, marginVertical: 32 }}>
          <View style={styles.detailedItem}>
            <Text style={{ ...styles.textMainBase, width: 152 }}>Nama </Text>
            <Text style={styles.textMainBaseBold}>: {identity?.nama} </Text>
          </View>
          <View style={styles.detailedItem}>
            <Text style={{ ...styles.textMainBase, width: 152 }}>
              Tanggal Lahir{" "}
            </Text>
            <Text style={styles.textMainBaseBold}>
              : {identity?.tanggal_lahir}{" "}
            </Text>
          </View>
          {/* <View style={styles.detailedItem}>
            <Text style={{ ...styles.textMainBase, width: 152 }}>Usia </Text>
            <Text style={styles.textMainBaseBold}>: {moment().diff(moment(identity?.tanggal_lahir), "year")} Tahun </Text>
          </View> */}
          <View style={styles.detailedItem}>
            <Text style={{ ...styles.textMainBase, width: 152 }}>
              Jenis Kelamin{" "}
            </Text>
            <Text style={styles.textMainBaseBold}>: {identity?.gender} </Text>
          </View>
          {/* <View style={styles.detailedItem}>
            <Text style={{ ...styles.textMainBase, width: 152 }}>
              Nama Sekolah{" "}
            </Text>
            <Text style={styles.textMainBaseBold}>
              : Fammi Corner TK Muslimat NU Dewi Mashithoh 4 Kalipare Malang{" "}
            </Text>
          </View> */}
          <View style={styles.detailedItem}>
            <Text style={{ ...styles.textMainBase, width: 152 }}>
              Tanggal Tes{" "}
            </Text>
            <Text style={styles.textMainBaseBold}>
              : {moment(quizAnswer?.[0].created).format("DD MMMM YYYY")}{" "}
            </Text>
          </View>
        </View>
        <Text style={styles.textMainBase}>
        Hasil Tes Psikologi Minat Bakat ananda telah dianalisis oleh Psikolog Fammi. Semoga hasil ini dapat membantu dan menjadi pedoman bagi Ayah Bunda untuk melakukan pendampingan perencanaan karir ananda serta melakukan stimulasi yang lebih tepat agar potensi yang ananda miliki bisa berkembang secara optimal sesuai dengan kecerdasan majemuk yang dimilikinya. Semoga Ayah Bunda juga selalu bersemangat mendampingi ananda. Terima kasih.
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 64,
          }}
        >
          <View style={{ flexDirection: "column", gap: 10, alignItems: 'flex-start' }}>
            <Text style={styles.textMainBase}>
              Bandung, {signatureDate?.replace(/%20/g, " ")}
            </Text>
            <View>
              <Image source={require("../../../assets/images/signature.png")} style={{ width: 185, height: 185 }}/>
            </View>
            <View>
              <Text style={styles.textMainBaseBold}>
                Sofia Nurinawati, M.Psi., Psikolog
              </Text>
              <Text style={styles.textMainBase}>SIPP: 3550-21-2-1</Text>
            </View>
          </View>
        </View>
      </View>
    </Fragment>
  );
}
const styles = StyleSheet.create({
  header: {
    width: "100%",
    paddingHorizontal: 32,
    paddingVertical: 24,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 14,
    borderBottomLeftRadius: 14,
    backgroundColor: "#834FE3",
    flexDirection: "row",
    alignItems: "center",
    gap: 48,
  },
  textTitleHeader: {
    color: "#FDFDFD",
    fontFamily: "Archivo-Bold",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "600",
  },
  textMainHeader: {
    color: "#FDFDFD",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: 22,
  },
  parentMain: {
    paddingHorizontal: 32,
    paddingVertical: 48,
    gap: 12,
    flexDirection: "column",
    width: "100%",
  },
  textMainBase: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: 22,
  },
  textMainBaseBold: {
    color: "#0A0316",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 22,
  },
  detailedItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 48,
  },
});

export default AboutPage;
