import Svg, { Path } from "react-native-svg";
const ProfileTick = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <Path
      stroke={props?.stroke ? props?.stroke : "#6724E2"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m12.033 15.875 1.267 1.267 2.533-2.534M10.134 9.059a1.516 1.516 0 0 0-.275 0A3.683 3.683 0 0 1 6.3 5.367a3.69 3.69 0 0 1 3.692-3.7c2.042 0 3.7 1.658 3.7 3.7 0 2-1.583 3.625-3.558 3.692ZM9.991 18.175c-1.516 0-3.025-.383-4.175-1.15-2.016-1.35-2.016-3.55 0-4.892 2.292-1.533 6.05-1.533 8.342 0"
    />
  </Svg>
);
export default ProfileTick;
