import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import {
  EmojiHappyIcon,
  HeartTickIcon,
  MessengerFillIcon,
} from "../assets/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Fammi from "../assets/icons/Fammi";
import FeatureButton from "./FeatureButton";
import { useTranslation } from "react-i18next";

// const FeatureButton = ({ icon, topLabel, bottomLabel, onPress }) => {
//   return (
//     <Pressable onPress={onPress}>
//       <View style={{ flexDirection: "row", alignItems: "center" }}>
//         {icon && icon()}
//         <View>
//           <Text style={styles.buttonLabel}>{topLabel}</Text>
//           <Text style={{...styles.buttonLabel, fontWeight: "700"}}>{bottomLabel}</Text>
//         </View>
//       </View>
//     </Pressable>
//   );
// };

const HomeHeader = (props) => {
  const {credits} = props;

  const widthScreen = Dimensions.get("window").width;
  const navigation = useNavigation();
  const [name, setName] = useState("");
  const [credit, setCredit] = useState({});
  const { t } = useTranslation("home-header");
  useEffect(() => {
    async function getName() {
      return await AsyncStorage.getItem("592f3e7");
    }
    getName().then((res) => {
      setName(res);
    });
  }, []);

  useEffect(() => {
    let creditObj = {};
    credits?.map((c) => {
      creditObj[c?.type + "-" + c?.status] = c.total;
    });
    setCredit(creditObj);
  }, [credits]);
  return (
    <View style={{...styles.container, borderBottomLeftRadius: 10,
    borderBottomEndRadius: 10, overflow: "hidden"}}>
      <Svg
        width={"110%"}
        height="120%"
        style={{
          ...StyleSheet.absoluteFillObject,
        }}
      > 
        <Defs>
          <LinearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0" stopColor="#6724E2" />
            <Stop offset={1} stopColor="#933FF4" />
          </LinearGradient>
        </Defs> 
        <Rect width={widthScreen} height="100%" fill="url(#grad)" />
      </Svg>

      <View style={{}}>
        <Fammi/>
        <View>
          <Text style={styles.greetingText}>{t("greeting1")} {name},</Text>
          <Text style={{ ...styles.greetingText, marginTop: 0, fontWeight: "700" }}>
            {t("greeting2")}
          </Text>
        </View>
        {
          
          <View style={styles.buttonContainer}>
            <FeatureButton
              onPress={() => {
                navigation.navigate("ChooseExpert", {
                  type: "expert"
                });
              }}
              icon={MessengerFillIcon}
              topLabel={credit['11-general'] > 0 ? `${credit['11-general']}x ${t("consult1")}` : `${t("consult1")}`}
              bottomLabel={t("consult2")}
            />
            <View style={styles.buttonDivider} />
            <FeatureButton
              icon={HeartTickIcon}
              topLabel={credit['9-general'] > 0 ? `${credit['9-general']}x ${t("sharing1")}` : `${t("sharing1")}`}
              bottomLabel={t("sharing2")}
              onPress={() => {
                navigation.navigate("ChooseExpert", {
                  type: "figure"
                });
              }}
            />
            <View style={styles.buttonDivider} />
            <FeatureButton
              icon={EmojiHappyIcon}
              topLabel={credit['10-general'] > 0 ? `${credit['10-general']}x ${t("confide1")}` : `${t("confide1")}`}
              bottomLabel={t("confide2")}
              onPress={() => {
                navigation.navigate("ChooseExpert", {
                  type: "partner"
                });
              }}
            />
          </View>
        }
      </View>
      {/* <Image
        source={require("../assets/fammi-logo-white.svg")}
        style={styles.brandLogo}
      /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    height: "auto"
  },
  brandLogo: {
    width: 109,
    height: 36,
  },
  greetingText: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 18,
    marginTop: 24,
    color: "#fff",
  },
  buttonContainer: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: "row",
    backgroundColor: "#FFF",
    marginTop: 16,
    justifyContent: "space-between",
  },
  buttonLabel: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 10,
    lineHeight: 11,
    marginLeft: 6,
  },
  buttonDivider: {
    backgroundColor: "#eaeaea",
    height: "auto",
    width: 1,
  },
});

export default HomeHeader;
