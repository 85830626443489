import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import PageFooter from "./PageFooter";

function BasePaper(props) {
  const {
    children,
    title,
    icon,
    pageNumber,
    description,
    isHideHeader,
    isSocmedFooter,
    customFooter,
    isHideFooter,
  } = props;
  return (
    <View style={styles.parentPaper}>
      {!isHideHeader && (
        <View style={styles.wrapNumberPage}>
          {title ? (
            <View style={styles.labelPage}>
              {icon && icon}
              <Text style={styles.textLabelPage}>{title}</Text>
            </View>
          ) : (
            <View style={{ height: 48 }}></View>
          )}
          <Text style={styles.textNumberPage}>{pageNumber}</Text>
        </View>
      )}
      <LinearGradient
        colors={[
          "#F6F6F6",
          "#FFFFFF",
          "rgba(255, 255, 255, 0.71)",
          "rgba(255, 255, 255, 0)",
        ]}
        locations={[0.04, 0.8501, 0.9218, 0.9778]}
        start={{ x: 0.5, y: 0 }}
        end={{ x: 0.5, y: 1 }}
        style={!isHideHeader ? styles.mainPaper : {}}
      >
        {description && (
          <>
            {!isHideHeader && typeof description === "string" ? (
              <Text style={styles.textDescription}>{description}</Text>
            ) : (
              description
            )}
          </>
        )}
        {children}
      </LinearGradient>
      {!isHideFooter && (
        <PageFooter isSocmed={isSocmedFooter} customFooter={customFooter} />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  parentPaper: {
    maxWidth: 794,
    width: "100%",
    backgroundColor: "#fdfdfd",
    marginBottom: 24,
    minHeight: 1200,
    height: "auto",
  },
  wrapNumberPage: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 32,
  },
  textNumberPage: {
    color: "rgba(131, 79, 227, 0.40)",
    fontFamily: "Archivo-Bold",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "600",
  },
  labelPage: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 34,
    borderBottomLeftRadius: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 32,
    paddingVertical: 12,
    backgroundColor: "#834FE3",
  },
  textLabelPage: {
    fontFamily: "Archivo-Bold",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: "600",
    color: "#fff",
    marginLeft: 8,
  },
  textDescription: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: 22,
    marginTop: 12,
  },
  mainPaper: {
    paddingHorizontal: 32,
    paddingVertical: 24,
    zIndex: 1,
    paddingBottom: 48,
    minHeight: 900,
  },
  textBase: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: 22,
  },
});

export default BasePaper;
