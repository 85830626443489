import * as React from "react";
import Svg, { Path } from "react-native-svg";
const CalendarIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill={props?.fill ? props?.fill : 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M6 1.5V3.75M12 1.5V3.75M2.625 6.8175H15.375M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z"
      stroke={props?.stroke ? props?.stroke : 'black'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M8.99645 10.2749H9.00395M6.2207 10.2749H6.2282M6.2207 12.5249H6.2282"
      stroke={props?.stroke ? props?.stroke : 'black'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default CalendarIcon;
