import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const CheckCircle2Icon = (props) => (
  <Svg
    width={23}
    height={24}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={11.4115} cy={12.3881} r={11.4115} fill="#F5F0FF" />
    <Path
      d="M6.94629 12.9454L10.6237 16.8526L15.8771 7.92188"
      stroke={props.color}
      strokeWidth={2.97692}
      strokeLinecap="round"
    />
  </Svg>
);
export default CheckCircle2Icon;
