import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import GlobeIcon from "../../assets/icons/GlobeIcon";
import InstagramIcon from "../../assets/icons/InstagramIcon";
import YtIcon from "../../assets/icons/YtIcon";
import TiktokIcon from "../../assets/icons/TiktokIcon";

function PageFooter(props) {
  const { isSocmed, customFooter, quizAnswer } = props;

  const [identity, setIdentity] = useState({});
  useEffect(() => {
    if (!quizAnswer) {
      return;
    }

    let filteredData = {};
    quizAnswer?.map((q) => {
      if (q.indexLabel && q.indexLabel !== null && q.indexLabel !== "") {
        filteredData[q.indexLabel] = q.chosenAnswer;
      }
    });

    setIdentity(filteredData);
    console.log(filteredData);
  }, [quizAnswer]);
  return (
    <View style={styles.paperFooter}>
      {customFooter ? (
        customFooter
      ) : isSocmed ? (
        <View style={styles.footer}>
          <View style={styles.itemFooter}>
            <GlobeIcon />
            <Text style={styles.textFooterPage}>www.fammi.ly</Text>
          </View>
          <View style={styles.itemFooter}>
            <InstagramIcon />
            <Text style={styles.textFooterPage}>@fammimenemani</Text>
          </View>
          <View style={styles.itemFooter}>
            <YtIcon />
            <Text style={styles.textFooterPage}>Fammi Official</Text>
          </View>
          <View style={styles.itemFooter}>
            <TiktokIcon />
            <Text style={styles.textFooterPage}>@fammimenemani</Text>
          </View>
        </View>
      ) : (
        <View style={styles.footer}>
          <Text style={styles.textFooterPage}>{identity?.nama}</Text>
          <Text style={styles.textFooterPage}>
            {/* Fammi Corner TK Muslimat NU Dewi Mashithoh 4 Kalipare Malang */}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  paperFooter: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
  },
  footer: {
    width: "100%",
    maxWidth: 730,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#E4DAF7",
    paddingHorizontal: 24,
    paddingVertical: 12,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textFooterPage: {
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    color: "#674EE2",
  },
  itemFooter: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
});
export default PageFooter;
