import Svg, { Path } from "react-native-svg";
const ShieldIcon = () => (
  <Svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      fill="#000"
      d="m16.995 3.777-5.042-1.888c-.522-.193-1.375-.193-1.897 0L5.014 3.777c-.972.367-1.76 1.503-1.76 2.539v7.425c0 .742.486 1.723 1.082 2.163l5.041 3.768c.89.669 2.347.669 3.236 0l5.042-3.768c.596-.449 1.082-1.42 1.082-2.163V6.316c.009-1.036-.78-2.172-1.742-2.54ZM14.19 8.91l-3.942 3.942a.68.68 0 0 1-.486.201.68.68 0 0 1-.485-.201L7.81 11.367a.691.691 0 0 1 0-.972.692.692 0 0 1 .972 0l.99.99 3.455-3.456a.692.692 0 0 1 .972 0 .694.694 0 0 1-.009.981Z"
    />
  </Svg>
);
export default ShieldIcon;
