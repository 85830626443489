import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { InfoCircleIcon, ProfileTickIcon, ZoomIcon } from "../assets/icons";

const AgendaProgramCard = () => {
  const isJoined = false;
  return (
    <View>
      <View style={styles.flexRow}>
        <View style={styles.dot} />
        <Text style={styles.dateTx}>Kamis, 14 Sep 2023</Text>
      </View>
      <View style={styles.card}>
        <View style={styles.header}>
          {/* DATE */}
          <View style={styles.flexRow}>
            <View style={[styles.icon, { backgroundColor: "#EEEAFF" }]}>
              <ZoomIcon />
            </View>
            <View style={{ gap: 4 }}>
              <Text style={styles.semiboldTx}>Zoom Bersama Ahli</Text>
              <Text style={[styles.mediumTx, { color: "#4EB826" }]}>
                Kamis, 13 Jun 23, Pukul 13.00-14.00 WIB
              </Text>
            </View>
          </View>

          {/* AGENT */}
          <View style={styles.flexRow}>
            <Image
              style={styles.icon}
              resizeMode="cover"
              source={{
                uri: "https://fammi.ly/uploads/qna/images/27438267dea29945fe335d36672ca910.png"
              }}
            />
            <View style={{ gap: 4 }}>
              <Text style={styles.semiboldTx}>Anggih Nur Hidayat</Text>
              <Text style={[styles.mediumTx, { color: "#999" }]}>
                Pakar Percintaan Kesayangan Anak Anda
              </Text>
            </View>
          </View>
          <Text style={styles.regularTx}>
            4 Seni Membangun Kepercayaan Diri Menghadapi Ukhti2 Scoopy/Vesmet
          </Text>
        </View>
        <View style={styles.footer}>
          <TouchableOpacity
            style={[styles.button, { borderWidth: 1, borderColor: "#eaeaea" }]}
          >
            <InfoCircleIcon />
            <Text style={styles.mediumTx}>Lihat Detail</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.button,
              { backgroundColor: isJoined ? "#EEEAFF" : "#eaeaea" }
            ]}
          >
            <ProfileTickIcon stroke={isJoined ? "#6724E2" : "#999"} />
            <Text
              style={[
                styles.mediumTx,
                { color: isJoined ? "#6724E2" : "#999" }
              ]}
            >
              {isJoined ? "Gabung" : "Belum Mulai"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10
  },
  dot: {
    width: 6,
    height: 15,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: "#6724E2"
  },
  dateTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    margin: 12
  },
  header: {
    gap: 12,
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EAEAEA"
  },
  icon: {
    width: 30,
    height: 30,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center"
  },
  regularTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12
  },
  mediumTx: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12
  },
  semiboldTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12
  },
  footer: {
    padding: 12,
    flexDirection: "row",
    gap: 12
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    flexGrow: 1,
    padding: 6,
    gap: 4
  }
});

export default AgendaProgramCard;
