import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";
import Modal from "react-native-modal";
import { CloseIcon, Message2 } from "../assets/icons";
import { setJournals } from "../services/journals";
import AsyncStorage from "@react-native-async-storage/async-storage";

function ModalOptionChat(props) {
  const { visible, hideDialog, setShowSnackbar, repliedMessage, onReply } = props;

  return (
    <Modal isVisible={visible} onBackdropPress={hideDialog}>
      <View style={styles.parentBox}>
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <View style={styles.flexOne}>
            <Message2 customColor="#000" circleColor="#fff" />
            <Text style={styles.txTitle}>Opsi Chat</Text>
          </View>
          <TouchableOpacity onPress={hideDialog}>
            <CloseIcon />
          </TouchableOpacity>
        </View>

        <TouchableOpacity
          onPress={async () => {
            setJournals(repliedMessage);
            setShowSnackbar("journal");
            hideDialog();
          }}
          style={styles.btnAction}
        >
          <Text style={styles.txBtn}>Tambahkan Ke Jurnal</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            onReply(repliedMessage);
            hideDialog();
          }}
          style={{...styles.btnAction, display: "none"}}
        >
          <Text style={styles.txBtn}>Balas</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
  },

  btnAction: {
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    height: 40,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  txBtn: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
    marginLeft: 6,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },

  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
});

export default ModalOptionChat;
