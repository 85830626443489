 import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  BackHandler,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import {
  ArchiveAddIcon,
  ArrowLiteRight,
  BigCheckIcon,
  ChecklistIcon,
  CompareIcon,
  InfoIcon,
  Paper2Icon
} from "../assets/icons";
import {
  AppBar,
  FindUserPopup,
  ScreeningResultCard,
  ScreeningTutorialPopup,
  Snackbar,
  UserFoundPopup
} from "../components";
import { getAspects, getScreeningResult, getScreeningResultRespondent, getAspectsRespondent, getScreeningResultRespondents, getScreeningResults } from "../services/survey";
import ScreeningCtaBox from "../components/ScreeningCtaBox";
import { getProgramId } from "../services/programs";
const dummyUserNumber = "0812345678";

const popupType = {
  tutorial: "tutorial",
  findUser: "findUser",
  userFound: "userFound"
};

const ScreeningResult = ({ route }) => {
  const [results, setResults] = useState([]);
  const [program, setProgram] = useState({});
  const [memberId, setMemberId] = useState(undefined);
  const [popupId, setPopupId] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isCompared, setIsCompared] = useState(false);
  const [toast, setToast] = useState(false);

  const [aspects, setAspects] = useState([]);
  const {quizId, additionalContentId, respId, fromAgenda, back} = route.params;
  const navigation = useNavigation();

  const handleBackButtonClick = async () => {
    if (back) {
      navigation.navigate(back);
      return true;
    }

    if (fromAgenda === 1) {
      navigation.navigate("AgendaProgram", {
        additionalContentId: additionalContentId,
        isScreening: "1",
        refresh: new Date().getTime(),
        memberId: memberId
      });  
      return true;
    }

    if (respId) {
      navigation.navigate("Screening", {
        additionalContentId: route.params?.additionalContentId,
        quizId: quizId,
        isScreening: "1",
        fromAgenda: fromAgenda
      });
      return true;
    }
    navigation.navigate("Survey", {
      additionalContentId: route.params?.additionalContentId,
      isScreening: "1"
    });

    return true;
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackButtonClick
    );

    return () => backHandler.remove();
  }, [memberId]);

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMemberId(memberId);
      return memberId;
    }

    async function getProgram() {
      getProgramId(route.params?.additionalContentId).then(
        (res) => {
          setProgram(res?.data[0]);
        }
      );
    }
    getProgram();

    getMember();
  }, []);

  useEffect(() => {
    if (!memberId) {
      return;
    }

    let targetId = memberId;

    if (respId && respId !== "")  {
      getScreeningResultRespondents(quizId, targetId, additionalContentId, respId)
      .then((res) => {
        setResults(res.data);
      });

      getAspectsRespondent(quizId, targetId, additionalContentId, respId)
      .then((res) => {
        const filteredAspects = res.data?.filter((a) => a.aspect !== null && a.aspect !== "null" && a.aspect !== "undefined" && a.aspect !== "");
        setAspects(filteredAspects);
      });

      return;
    }

    getScreeningResults(quizId, targetId, additionalContentId)
    .then((res) => {
      setResults(res.data);
    });

    getAspects(quizId, targetId, additionalContentId)
    .then((res) => {
      const filteredAspects = res.data?.filter((a) => a.aspect !== null && a.aspect !== "null" && a.aspect !== "undefined" && a.aspect !== "");
      setAspects(filteredAspects);
    });
  }, [memberId]);

  const handleResetPopupId = () => setPopupId("");

  const handleFindUser = () => {
    if (whatsappNumber !== dummyUserNumber) {
      handleResetPopupId();
      setToast(true);
      return;
    }

    setPopupId(popupType.userFound);
  };

  const handleCompare = () => {
    setIsCompared(true);
  };

  return (
    <>
      <AppBar
        title="Hasil Screening"
        isBack
        jump={ fromAgenda === 1 ? "AgendaProgram" : ( respId ? "Screening" : back ? back : "Survey")}
        params={{ additionalContentId: additionalContentId, isScreening: "1", quizId: quizId, memberId: memberId, refresh: new Date().getTime() }}
      />
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ padding: 0 }}
      >
        {
          results?.[0]?.recommendationMode !== "label" &&
            <View style={{marginBottom: 0}}>
              <View>
                <View style={styles.header}>
                  <BigCheckIcon />
                  <Text style={styles.headerTx}>Hasil Screening</Text>
                </View>
                <View style={styles.dashLine} />
                <View style={[styles.header, { paddingHorizontal: 12 }]}>
                  <Text style={styles.subHeaderTx}>{results?.[0]?.summary}</Text>
                </View>
              </View>
              <TouchableOpacity
                style={[styles.compareBtn, isCompared && styles.isComparedBtn, {display: "none"}]}
                onPress={async () => {
                  const isShowTutorial = await AsyncStorage.getItem("dontshow");

                  if (isShowTutorial === "false") {
                    setPopupId(popupType.tutorial);
                  } else {
                    setPopupId(popupType.findUser);
                  }
                }}
              >
                <View style={styles.buttonLabel}>
                  <CompareIcon fill={isCompared ? "#fff" : "#292D32"} />
                  <Text style={[styles.txLabel, isCompared && { color: "#fff" }]}>
                    {isCompared
                      ? "Lihat Perbandingan Hasil Screening"
                      : "Bandingkan Hasil Screening"}
                  </Text>
                </View>
                <ArrowLiteRight fill={isCompared ? "#fff" : "#000"} />
              </TouchableOpacity>

              <View style={styles.list}>
                {results?.[0]?.detail !== "undefined" && results?.[0]?.detail !== "" && (
                  <ScreeningResultCard
                    Icon={InfoIcon}
                    title="Deskripsi Hasil Screening"
                    value={results?.[0]?.detail}
                  />
                )}
                {results?.[0]?.conclusion !== "undefined" && results?.[0]?.conclusion !== "" && (
                  <ScreeningResultCard
                    Icon={Paper2Icon}
                    title="Kesimpulan"
                    value={results?.[0]?.conclusion}
                  />
                )}
                {results?.[0]?.recommendation !== "undefined" &&
                  results?.[0]?.recommendation !== "" && (
                    <ScreeningResultCard
                      Icon={ChecklistIcon}
                      title="Saran"
                      value={results?.[0]?.recommendation}
                    />
                  )}
                {aspects?.length > 0 && (
                  <ScreeningResultCard
                    Icon={ArchiveAddIcon}
                    title="Tindak Lanjut"
                    aspectValue={aspects}
                  />
                )}
              </View>

            </View>
        }
        {
          results?.[0]?.recommendationMode === "label" &&
          <View>
            <View style={styles.header}>
              <BigCheckIcon />
              <Text style={styles.headerTx}>Hasil Screening</Text>
            </View>
          </View>
        }
        {
          results?.[0]?.recommendationMode === "label" &&
          results?.map((result, index) => {
            return <View style={{marginBottom: 16}}>
              <View style={{
                backgroundColor: "#fff",
                padding: 12,
                borderRadius: 10,
                marginTop: 16
              }}>
                <View style={{alignItems: "center", gap: 16}}>
                  <Text style={{
                        fontFamily: "Archivo-Bold",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 10,
                        backgroundColor: "#DFD8FF",
                        borderRadius: 11,
                        padding: 12,
                        paddingTop: 4, 
                        paddingBottom: 4,
                        color: "#6724E2"
                      }}>
                    Hasil {index+1}
                  </Text>
                  <Text style={styles.subHeaderTx}>{result?.summary}</Text>
                </View>
                <View style={{...styles.dashLine, marginTop: 16, marginBottom: 16}} />
                {result?.detail !== "undefined" && result?.detail !== "" && (
                  <View style={{marginBottom: 16}}>
                    <View style={{flexDirection: "row", gap: 6, alignItems: "center", marginBottom: 12}}>                      
                      <InfoIcon/>
                      <Text style={{
                        fontFamily: "Archivo-Bold",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                      }}>
                        Deskripsi Hasil Screening
                      </Text>
                    </View>
                    <Text style={{
                      fontFamily: "Archivo-Regular",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 16,
                    }}>
                      {result?.detail}
                    </Text>
                  </View>
                )}

                {result?.conclusion !== "undefined" && result?.conclusion !== "" && (
                  <View style={{marginBottom: 16}}>
                    <View style={{flexDirection: "row", gap: 6, alignItems: "center", marginBottom: 12}}>                      
                      <Paper2Icon/>
                      <Text style={{
                        fontFamily: "Archivo-Bold",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                      }}>
                        Kesimpulan
                      </Text>
                    </View>
                    <Text style={{
                      fontFamily: "Archivo-Regular",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 16,
                    }}>
                      {result?.conclusion}
                    </Text>
                  </View>
                )}

                {result?.recommendation !== "undefined" && result?.recommendation !== "" && (
                  <View style={{marginBottom: 16}}>
                    <View style={{flexDirection: "row", gap: 6, alignItems: "center", marginBottom: 12}}>                      
                      <ChecklistIcon/>
                      <Text style={{
                        fontFamily: "Archivo-Bold",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                      }}>
                        Saran
                      </Text>
                    </View>
                    <Text style={{
                      fontFamily: "Archivo-Regular",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 16,
                    }}>
                      {result?.recommendation}
                    </Text>
                  </View>
                )}
              </View>

              <TouchableOpacity
                style={[styles.compareBtn, isCompared && styles.isComparedBtn, {display: "none"}]}
                onPress={async () => {
                  const isShowTutorial = await AsyncStorage.getItem("dontshow");

                  if (isShowTutorial === "false") {
                    setPopupId(popupType.tutorial);
                  } else {
                    setPopupId(popupType.findUser);
                  }
                }}
              >
                <View style={styles.buttonLabel}>
                  <CompareIcon fill={isCompared ? "#fff" : "#292D32"} />
                  <Text style={[styles.txLabel, isCompared && { color: "#fff" }]}>
                    {isCompared
                      ? "Lihat Perbandingan Hasil Screening"
                      : "Bandingkan Hasil Screening"}
                  </Text>
                </View>
                <ArrowLiteRight fill={isCompared ? "#fff" : "#000"} />
              </TouchableOpacity>
            </View>   
          })
        }
        
        {
          results?.[0]?.recommendationMode === "label" &&
          aspects?.length > 0 && 

                <View style={styles.list}>
                  {/* {result?.detail !== "undefined" && result?.detail !== "" && (
                    <ScreeningResultCard
                      Icon={InfoIcon}
                      title="Deskripsi Hasil Screening"
                      value={result?.detail}
                    />
                  )}
                  {result?.conclusion !== "undefined" && result?.conclusion !== "" && (
                    <ScreeningResultCard
                      Icon={Paper2Icon}
                      title="Kesimpulan"
                      value={result?.conclusion}
                    />
                  )}
                  {result?.recommendation !== "undefined" &&
                    result?.recommendation !== "" && (
                      <ScreeningResultCard
                        Icon={ChecklistIcon}
                        title="Saran"
                        value={result?.recommendation}
                      />
                    )} */}
                    <ScreeningResultCard
                      Icon={ArchiveAddIcon}
                      title="Tindak Lanjut"
                      aspectValue={aspects}
                    />
                </View>
              }
        <ScreeningCtaBox additionalContentId={additionalContentId} result={results[0]} program={program} />
              {
                <View style={{
                  marginBottom: 50
                }}>
                </View>
              }
      </ScrollView>
      <ScreeningTutorialPopup
        visible={popupId === popupType.tutorial}
        onStart={() => setPopupId(popupType.findUser)}
        onCancel={handleResetPopupId}
      />
      <FindUserPopup
        visible={popupId === popupType.findUser}
        value={whatsappNumber}
        setValue={(val) => setWhatsappNumber(val)}
        hidePopup={handleResetPopupId}
        handleFindUser={handleFindUser}
      />
      <UserFoundPopup
        visible={popupId === popupType.userFound}
        hidePopup={handleResetPopupId}
        handleCompare={handleCompare}
      />
      <Snackbar
        visible={toast}
        title="Pencarian pengguna tidak ditemukan"
        hideDialog={() => setToast(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    flex: 1,
    padding: 16,
  },
  header: {
    backgroundColor: "#fff",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
    padding: 16,
    borderRadius: 10
  },
  dashLine: {
    height: 1,
    width: "96%",
    borderBottomColor: "#C9C1EC",
    borderStyle: "dashed",
    borderBottomWidth: 1,
    alignSelf: "center"
  },
  headerTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14
  },
  subHeaderTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    textAlign: "center",
    color: "#6724E2"
  },
  list: {
    flexDirection: "column",
    gap: 16,
    marginVertical: 16
  },
  compareBtn: {
    padding: 10,
    paddingLeft: 12,
    backgroundColor: "#FDC52E",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16
  },
  isComparedBtn: {
    backgroundColor: "#4EB826"
  },
  buttonLabel: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10
  },
  txLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12
  }
});

export default ScreeningResult;
