import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Message2 } from "../assets/icons";
import moment from "moment";
import { useEffect, useState } from "react";

function ListOrder(props) {
  const { data, t } = props;
  const [duration, setDuration] = useState("");

  useEffect(() => {
    const displayedDuration = [];
    const limitTime = moment(data?.timeLimit);
    const currentTime = moment();
    const theDiff = limitTime.diff(currentTime, "seconds");

      const hours = Math.floor(theDiff / 3600);
      const minutes = Math.floor((theDiff % 3600) / 60);
      const seconds = Math.floor((theDiff % 3600) % 60);

      if (hours > 0) {
        displayedDuration.push(hours);
      }

      if (minutes > 0) {
        displayedDuration.push(('0'+minutes).slice(-2));
      }

      if (seconds > 0) {
        displayedDuration.push(('0'+seconds).slice(-2));
      }

      setDuration(displayedDuration.join(":"))
  }, [data]);

  return (
    <View style={styles.parentList}>
      <View style={styles.midSection}>
        <View style={styles.flexOne}>
          <Message2 customColor="#000" circleColor="#fff" />
          <Text style={{ ...styles.textName, marginLeft: 8, textTransform: "capitalize" }}>
            {
              t("order:"+data?.type.toLowerCase())
            }
          </Text>
        </View>
        <View
          style={{
            ...styles.labelStatus,
            backgroundColor:
              data?.status === "3"
                ? "#4EB826"
                : "#FF0000",
          }}
        >
          <Text style={styles.textLabel}>
            {
              duration === "" &&
              data?.status !== "3" &&
              t("order:expired")
            }
            {
              data?.status === "3" &&
              t("order:paid")
            }
            {
              duration !== "" &&
              data?.status !== "3" &&
              t("order:remaining")+ `${duration}`
            }
          </Text>
        </View>
      </View>
      <View style={styles.topSection}>
        <View style={styles.flexOne}>
          {
            data?.type === "Konsultasi" &&
            <Image
              source={{
                uri: `https://fammi.ly/uploads/speakers/images/${data?.speakerImage}`
              }}
              style={styles.profilePic}
            />
          }
          {
            data?.type !== "Konsultasi" &&
            <Image
              source={{
                uri: `https://fammi.ly/uploads/products/images/${data?.productImage}`
              }}
              style={styles.profilePic}
            />
          }
          <View style={{ maxWidth: "80%", marginTop: 4 }}>
            <Text style={styles.textName}>{data?.title}</Text>
            <Text style={styles.textSpan}>{data?.price}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 16,
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
  },
  avatar: {
    marginRight: 12,
    flexShrink: 0,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%",
  },
  textName: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#000000",
  },
  textSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  labelStatus: {
    borderRadius: 25 / 2,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 6,
    paddingLeft: 12,
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 13,
    color: "#fff",
  },
  midSection: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EAEAEA",
    width: "100%",
    borderStyle: "solid",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
  },
});
export default ListOrder;
