import * as React from "react";
import Svg, { Path } from "react-native-svg";
const VideoIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.3975 15.3151H4.6575C2.2875 15.3151 1.5 13.7401 1.5 12.1576V5.84256C1.5 3.47256 2.2875 2.68506 4.6575 2.68506H9.3975C11.7675 2.68506 12.555 3.47256 12.555 5.84256V12.1576C12.555 14.5276 11.76 15.3151 9.3975 15.3151Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.64 12.8251L12.555 11.3626V6.6301L14.64 5.1676C15.66 4.4551 16.5 4.8901 16.5 6.1426V11.8576C16.5 13.1101 15.66 13.5451 14.64 12.8251ZM8.625 8.2501C8.92337 8.2501 9.20952 8.13157 9.4205 7.92059C9.63147 7.70962 9.75 7.42347 9.75 7.1251C9.75 6.82673 9.63147 6.54058 9.4205 6.3296C9.20952 6.11863 8.92337 6.0001 8.625 6.0001C8.32663 6.0001 8.04048 6.11863 7.8295 6.3296C7.61853 6.54058 7.5 6.82673 7.5 7.1251C7.5 7.42347 7.61853 7.70962 7.8295 7.92059C8.04048 8.13157 8.32663 8.2501 8.625 8.2501Z"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default VideoIcon;
