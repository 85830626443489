import { View, StyleSheet } from "react-native";

function Container(props) {
  const { children } = props;
  return <View style={styles.container}>{children}</View>;
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
});

export default Container;
