import Svg, { Path } from "react-native-svg";
const ArrowLeft = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.178 4.447 2.625 9l4.553 4.552M15.374 9H2.752"
    />
  </Svg>
);
export default ArrowLeft;
