import * as React from "react";
import Svg, { Path } from "react-native-svg";
const TickSquareIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.135C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.135C14.865 16.5 16.4925 14.8725 16.4925 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM12.585 7.275L8.3325 11.5275C8.22703 11.6328 8.08406 11.692 7.935 11.692C7.78594 11.692 7.64297 11.6328 7.5375 11.5275L5.415 9.405C5.31039 9.29915 5.25172 9.15632 5.25172 9.0075C5.25172 8.85868 5.31039 8.71585 5.415 8.61C5.6325 8.3925 5.9925 8.3925 6.21 8.61L7.935 10.335L11.79 6.48C12.0075 6.2625 12.3675 6.2625 12.585 6.48C12.8025 6.6975 12.8025 7.05 12.585 7.275Z"
      fill={props.fill || props.color || "#6724E2"}
    />
  </Svg>
);
export default TickSquareIcon;
