import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ShoppingCartIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M1.5 1.5H2.805C3.615 1.5 4.2525 2.1975 4.185 3L3.5625 10.47C3.53819 10.7594 3.57427 11.0507 3.66843 11.3254C3.76259 11.6001 3.91279 11.8523 4.10951 12.066C4.30622 12.2796 4.54517 12.45 4.81121 12.5665C5.07726 12.683 5.36459 12.7429 5.655 12.7425H13.6425C14.7225 12.7425 15.6675 11.8575 15.75 10.785L16.155 5.16C16.245 3.915 15.3 2.9025 14.0475 2.9025H4.365M6.75 6H15.75M12.1875 16.5C12.4361 16.5 12.6746 16.4012 12.8504 16.2254C13.0262 16.0496 13.125 15.8111 13.125 15.5625C13.125 15.3139 13.0262 15.0754 12.8504 14.8996C12.6746 14.7238 12.4361 14.625 12.1875 14.625C11.9389 14.625 11.7004 14.7238 11.5246 14.8996C11.3488 15.0754 11.25 15.3139 11.25 15.5625C11.25 15.8111 11.3488 16.0496 11.5246 16.2254C11.7004 16.4012 11.9389 16.5 12.1875 16.5ZM6.1875 16.5C6.43614 16.5 6.6746 16.4012 6.85041 16.2254C7.02623 16.0496 7.125 15.8111 7.125 15.5625C7.125 15.3139 7.02623 15.0754 6.85041 14.8996C6.6746 14.7238 6.43614 14.625 6.1875 14.625C5.93886 14.625 5.7004 14.7238 5.52459 14.8996C5.34877 15.0754 5.25 15.3139 5.25 15.5625C5.25 15.8111 5.34877 16.0496 5.52459 16.2254C5.7004 16.4012 5.93886 16.5 6.1875 16.5Z"
      stroke="black"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ShoppingCartIcon;
