import Svg, { Circle, Path } from "react-native-svg"
const TeacherIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none">
    <Circle cx={32} cy={32} r={30.5} stroke="#B3514A" strokeWidth={3} />
    <Path
      fill="#B3514A"
      d="M42.103 33.33v7.806c0 2.01-1.567 4.164-3.451 4.797L33.6 47.611c-.887.301-2.328.301-3.198 0l-5.051-1.678c-1.9-.633-3.452-2.787-3.452-4.797l.016-7.806 6.998 4.56c1.71 1.124 4.529 1.124 6.239 0l6.95-4.56Z"
      opacity={0.4}
    />
    <Path
      fill="#B3514A"
      d="m44.634 23.23-9.484-6.223c-1.71-1.125-4.528-1.125-6.238 0l-9.532 6.222c-3.056 1.98-3.056 6.46 0 8.455l2.533 1.647 6.999 4.56c1.71 1.124 4.528 1.124 6.238 0l6.95-4.56 2.17-1.425v4.845c0 .649.538 1.187 1.188 1.187.649 0 1.187-.538 1.187-1.187v-7.79c.633-2.043-.016-4.418-2.01-5.732Z"
    />
  </Svg>
)
export default TeacherIcon