import Svg, { Circle, Path } from "react-native-svg";
const Message2Black2 = (props) => (
  <Svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<Circle cx="18" cy="18" r="18" fill="black"/>
<Path d="M22.8527 21.6225L23.1452 23.9925C23.2202 24.615 22.5527 25.05 22.0202 24.7275L19.4252 23.1825C19.2452 23.0775 19.2002 22.8525 19.2977 22.6725C19.6727 21.9825 19.8752 21.2025 19.8752 20.4225C19.8752 17.6775 17.5202 15.4425 14.6252 15.4425C14.0327 15.4425 13.4552 15.5325 12.9152 15.7125C12.8513 15.7322 12.7834 15.7346 12.7183 15.7196C12.6532 15.7046 12.5933 15.6726 12.5445 15.6269C12.4958 15.5812 12.46 15.5234 12.4408 15.4594C12.4216 15.3954 12.4197 15.3275 12.4352 15.2625C13.1177 12.5325 15.7427 10.5 18.8777 10.5C22.5377 10.5 25.5002 13.2675 25.5002 16.68C25.5002 18.705 24.4577 20.4975 22.8527 21.6225Z" fill="white"/>
<Path d="M18.75 20.4234C18.75 21.3159 18.42 22.1409 17.865 22.7934C17.1225 23.6934 15.945 24.2709 14.625 24.2709L12.6675 25.4334C12.3375 25.6359 11.9175 25.3584 11.9625 24.9759L12.15 23.4984C11.145 22.8009 10.5 21.6834 10.5 20.4234C10.5 19.1034 11.205 17.9409 12.285 17.2509C12.9525 16.8159 13.755 16.5684 14.625 16.5684C16.905 16.5684 18.75 18.2934 18.75 20.4234Z" fill="white"/>
</Svg>

);
export default Message2Black2;
