import { useState } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { UserGroup } from "../assets/icons";

function ListJournal(props) {
  const { data, t } = props;
  const [imageError, setImageError] = useState(false);
  return (
    <View style={styles.parentList}>
      <View style={styles.topSection}>
        <Text style={styles.textName}>{data?.title}</Text>
        <Text style={{ ...styles.textSpan, marginBottom: 12, marginTop: 4 }}>
          {data?.date}
        </Text>
        {
          !data?.content?.[0]?.q &&
          <Text style={styles.textMain}>{data?.content}</Text>
        }
        {
          data?.content?.[0]?.q &&
          data?.content?.map((q) => {
            return <View>
              <Text style={styles.textMain}>{q.q}</Text>
              <Text style={{...styles.textMain, marginBottom: 12}}>{q.a}</Text>
            </View>;
          })
        }
      </View>
      {data?.writer && (
        <View style={styles.bottomSection}>
          <View style={styles.flexOne}>
            {
              !imageError &&
              <Image
                source={{ uri: `https://fammi.ly/uploads/speakers/images/${data?.speakerPhoto}` }}
                height={30}
                width={30}
                style={styles.avatar}
                onError={()=> setImageError(true)}
              />
            }
            {
              imageError &&
              <View style={{width: 30, height: 30, marginRight: 12}}>
                <UserGroup/>
              </View>
            }
            <View>
              <Text style={{ ...styles.textSpan, marginBottom: 4 }}>
                {t("journal:written-by")}
              </Text>

              <Text style={styles.textName}>{data?.writer}</Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  parentList: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 16,
  },
  topSection: {
    padding: 12,
  },
  bottomSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    borderTopColor: "#eaeaea",
    borderTopWidth: 1,
  },
  avatar: {
    borderRadius: 30 / 2,
    marginRight: 12,
    height: 30,
    width: 30,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },
  textName: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#000000",
  },
  textSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  labelStatus: {
    backgroundColor: "#4EB826",
    borderRadius: 25 / 2,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 6,
    paddingRight: 6,
    paddingBottom: 6,
    paddingLeft: 6,
  },
  indicator: {
    backgroundColor: "#ECF8E6",
    height: 6,
    width: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    marginRight: 4,
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#ECF8E6",
  },
  midSection: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EAEAEA",
    borderStyle: "solid",
  },
  textMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
  },
});

export default ListJournal;
