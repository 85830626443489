import * as React from "react";
import Svg, { Path } from "react-native-svg";
const PersonalCardIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M13.5 2.25H4.5C2.8425 2.25 1.5 3.585 1.5 5.2275V12.7725C1.5 14.415 2.8425 15.75 4.5 15.75H13.5C15.1575 15.75 16.5 14.415 16.5 12.7725V5.2275C16.5 3.585 15.1575 2.25 13.5 2.25ZM6.375 5.3775C7.3275 5.3775 8.1075 6.1575 8.1075 7.11C8.1075 8.0625 7.3275 8.8425 6.375 8.8425C5.4225 8.8425 4.6425 8.0625 4.6425 7.11C4.6425 6.1575 5.4225 5.3775 6.375 5.3775ZM9.2775 12.495C9.21 12.57 9.105 12.615 9 12.615H3.75C3.645 12.615 3.54 12.57 3.4725 12.495C3.405 12.42 3.3675 12.315 3.375 12.21C3.5025 10.95 4.5075 9.9525 5.7675 9.8325C6.165 9.795 6.5775 9.795 6.975 9.8325C8.235 9.9525 9.2475 10.95 9.3675 12.21C9.3825 12.315 9.345 12.42 9.2775 12.495ZM14.25 12.5625H12.75C12.4425 12.5625 12.1875 12.3075 12.1875 12C12.1875 11.6925 12.4425 11.4375 12.75 11.4375H14.25C14.5575 11.4375 14.8125 11.6925 14.8125 12C14.8125 12.3075 14.5575 12.5625 14.25 12.5625ZM14.25 9.5625H11.25C10.9425 9.5625 10.6875 9.3075 10.6875 9C10.6875 8.6925 10.9425 8.4375 11.25 8.4375H14.25C14.5575 8.4375 14.8125 8.6925 14.8125 9C14.8125 9.3075 14.5575 9.5625 14.25 9.5625ZM14.25 6.5625H10.5C10.1925 6.5625 9.9375 6.3075 9.9375 6C9.9375 5.6925 10.1925 5.4375 10.5 5.4375H14.25C14.5575 5.4375 14.8125 5.6925 14.8125 6C14.8125 6.3075 14.5575 6.5625 14.25 6.5625Z"
      fill="#292D32"
    />
  </Svg>
);
export default PersonalCardIcon;
