import React from "react";
import Svg, { Path } from "react-native-svg";
const ChevronRightIcon = (props) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.17957 18.9158C8.00609 18.9158 7.83261 18.8519 7.69566 18.715C7.43087 18.4502 7.43087 18.0119 7.69566 17.7471L13.6487 11.7941C14.087 11.3558 14.087 10.6437 13.6487 10.2054L7.69566 4.25235C7.43087 3.98757 7.43087 3.54931 7.69566 3.28452C7.96044 3.01974 8.3987 3.01974 8.66348 3.28452L14.6165 9.23757C15.0822 9.70322 15.347 10.3332 15.347 10.9997C15.347 11.6663 15.0913 12.2963 14.6165 12.7619L8.66348 18.715C8.52653 18.8428 8.35305 18.9158 8.17957 18.9158Z"
      fill="#6323DA"
    />
  </Svg>
);
export default ChevronRightIcon;
