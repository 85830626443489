import { Fragment } from "react";
import { AppBar, EmptyState } from "../components";
import { ScrollView, StyleSheet } from "react-native";
import { BookBlackIcon } from "../assets/icons";

function Materials() {
    return (
        <Fragment>
            <AppBar title="Materi" isBack />
            <ScrollView contentContainerStyle={styles.container}>
                <EmptyState
                    Icon={BookBlackIcon}
                    title="Materi Belum Tersedia"
                    desc="Saat ini belum tersedia materi terkait program yang bisa kamu akses"
                />
            </ScrollView>
        </Fragment>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: "#EEEAFF",
    },
})

export default Materials