import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import {
  AppBar,
  ConsultationSchedule,
  FeedbackExpert,
  InformationExpert,
  Snackbar,
} from "../components";
import { EmojiHappyIcon, HeartTickIcon, Message2 } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import { checkSchedule, getSchedules } from "../services/schedules";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PopupFactory, { usePopup } from "../components/PopupFactory";
// import DatePicker from 'react-native-date-picker';

function DetailExpert({ route }) {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { speaker, type, status } = route.params;
  const [schedules, setSchedules] = useState([]);
  const [chosenHourSchedule, setChosenHourSchedule] = useState(undefined);
  const [chosenDateSchedule, setChosenDateSchedule] = useState(undefined);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(
    t("choose-expert:choose-schedule")
  );
  const [userMode, setUserMode] = useState("speaker");
  const { toggle, visible } = usePopup();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSchedules(speaker.speakerId).then((res) => {
      setSchedules(res?.data);
      setLoading(false);
    });

    async function getMode() {
      const userMode = await AsyncStorage.getItem("792f3e7");
      setUserMode(userMode);
      return userMode;
    }

    getMode();
  }, [speaker]);

  const onConsultation = () => {
    if (!chosenHourSchedule) {
      return setOpenSnackBar(true);
    }

    const dateFormatted = moment(chosenDateSchedule).format("YYYY-MM-DD");
    const timeFormatted = moment(
      dateFormatted + " " + chosenHourSchedule.split("-")[0]
    );
    if (moment(timeFormatted).isBefore(moment())) {
      setSnackBarMsg("Jadwal yang telah lewat tidak bisa dipesan.");
      setTimeout(() => {
        setSnackBarMsg(t("choose-expert:choose-schedule"));
      }, 3000);
      return setOpenSnackBar(true);
    }

    checkSchedule(
      speaker?.speakerId,
      moment(chosenDateSchedule).format("YYYY-MM-DD") +
        " " +
        chosenHourSchedule.split("-")[0]
    ).then((res) => {
      if (res?.data !== false) {
        setSnackBarMsg(
          "Maaf, jadwal sudah dibook. Silahkan pilih jadwal lain."
        );
        setTimeout(() => {
          setSnackBarMsg(t("choose-expert:choose-schedule"));
        }, 3000);
        return setOpenSnackBar(true);
      }

      return navigation.navigate("PlaceOrder", {
        speaker,
        chosenDateSchedule,
        chosenHourSchedule,
        type,
        status,
      });
    });
  };
  return (
    <Fragment>
      <AppBar title={t("detail_expert:appbar_title")} isBack />
      <ScrollView style={styles.container}>
        <InformationExpert onOpenSipp={toggle} data={speaker} t={t} />
        <ConsultationSchedule
          t={t}
          data={schedules}
          loading={loading}
          setChosenHourSchedule={setChosenHourSchedule}
          chosenHourSchedule={chosenHourSchedule}
          setChosenDateSchedule={setChosenDateSchedule}
          setSnackBarMsg={setSnackBarMsg}
          setOpenSnackBar={setOpenSnackBar}
        />
        {userMode === "member" && (
          <TouchableOpacity onPress={onConsultation} style={styles.btnConsul}>
            {type === "figure" && <HeartTickIcon fill={"#FFF"} />}
            {type === "expert" && <Message2 />}
            {type === "partner" && <EmojiHappyIcon fill={"#FFF"} />}

            {type === "figure" && (
              <Text style={styles.txConsul}>
                {t("detail_expert:cta_share")}
              </Text>
            )}
            {type === "expert" && (
              <Text style={styles.txConsul}>
                {t("detail_expert:cta_consult")}
              </Text>
            )}
            {type === "partner" && (
              <Text style={styles.txConsul}>
                {t("detail_expert:cta_confide")}
              </Text>
            )}
          </TouchableOpacity>
        )}
        <FeedbackExpert speaker={speaker} t={t} />
        <View style={{ marginBottom: 16 }} />
      </ScrollView>
      <Snackbar
        visible={openSnackBar}
        hideDialog={() => {
          setOpenSnackBar(false);
        }}
        title={snackBarMsg}
      />
      <PopupFactory
        type="sipp"
        visible={visible}
        onCancel={toggle}
        data={speaker}
        onSubmit={() => {
          onConsultation();
          toggle();
        }}
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
  btnConsul: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    paddingTop: 7,
    paddingRight: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  txConsul: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
});
export default DetailExpert;
