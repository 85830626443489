import React from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";

function FormNewDiscuss(props) {
  const { label, icon, placeholder, navigate } = props;
  return (
    <View style={styles.buttonContainer}>
      <View style={styles.flexRow}>
        {icon}
        <Text style={styles.textLabel}>{label}</Text>
      </View>
      <Pressable onPress={navigate} style={styles.input}>
        <Text style={styles.textInput}>{placeholder}</Text>
      </Pressable>
    </View>
  );
}
const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: "row",
    backgroundColor: "#FFF",
    marginTop: 16,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: "#F6F6F6",
    paddingTop: 6,
    paddingRight: 12,
    paddingBottom: 14,
    paddingLeft: 12,
    marginTop: 10,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  textInput: {
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
    marginTop: 10,
    color: "#999"
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 13,
    marginLeft: 6,
  },
});
export default FormNewDiscuss;
