import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  BackHandler,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Calendar } from "../assets/icons";
import {
  AgendaProgramCard,
  AgendaProgramScreeningCard,
  AppBar,
  EmptyState,
  ZoomCard
} from "../components";
import MaterialAgendaCard from "../components/MaterialAgendaCard";
import { getProgramAgendas } from "../services/agenda";
import { getProgramId } from "../services/programs";
import { useNavigation } from "@react-navigation/native";

const FILTER = ["Semua", "Terdekat"];

const AgendaProgram = (props) => {
  const { params } = props.route;
  const [filter, setFilter] = useState(FILTER[0]);
  const [data, setData] = useState([]);
  const [dataTemp, setDataTemp] = useState([]);
  const [program, setProgram] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigation();

  const handleBackButtonClick = async () => {
    navigation.navigate("ProgramDetail", {
      program: program
    });

    return true;
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBackButtonClick
    );

    return () => backHandler.remove();
  }, [program]);


  useEffect(() => {
    if (!params?.additionalContentId || !params?.memberId) {
      return;
    }

    getProgramAgendas(params.additionalContentId)
    .then((res) => {
      setDataTemp(res?.data);
      setLoading(false);
    })

      getProgramId(params.additionalContentId).then(
        (res) => {
          setProgram(res?.data[0]);
        }
      );
  }, [params?.additionalContentId, params?.refresh]);

  useEffect(() => {
    if (dataTemp.length === 0) {
      return;
    }

    let filteredData = dataTemp;

    if (filter === "Terdekat") {
      filteredData = dataTemp.filter((d) => {
        const theMisc = JSON.parse(d.misc);
        if (theMisc?.end) {
          const theDate = d.scheduledDate.split(" ")[0];
          return moment(theDate + " " + theMisc.end) > moment();
        }

        return moment(d.scheduledDate) > moment();
      });
    }

    return setData(filteredData);
  }, [filter, dataTemp]);

  return (
    <React.Fragment>
      {
        program?.productName &&
        <AppBar title="Agenda Program" isBack jump={"ProgramDetail"} params={{program:program}} />
      }
      {
        loading &&
        <View style={styles.container}>
          <EmptyState
            Icon={Calendar}
            title={
              "Mohon Menunggu"
            }
            desc={
              "Sedang memuat program"
            }
          />
        </View>
      }
      {data?.length === 0 && !loading && (
        <View style={styles.container}>
          <EmptyState
            Icon={Calendar}
            title={
              "Belum Ada Agenda " +
              (filter === FILTER[0]
                ? "Terdekat"
                : filter === FILTER[1]
                ? "Selesai"
                : "")
            }
            desc={
              "Kamu belum punya agenda " +
              (filter === FILTER[0]
                ? "terdekat di minggu ini dan minggu depan"
                : filter === FILTER[1]
                ? "yang sudah selesai di program ini"
                : "apapun di program ini")
            }
          />
        </View>
      )}
      {data?.length > 0 && !loading && (
        <ScrollView
          style={styles.container}
          contentContainerStyle={{ gap: 16 }}
        >
          <View style={styles.filter}>
            {FILTER.map((f) => (
              <TouchableOpacity
                key={f}
                onPress={() => setFilter(f)}
                style={[
                  styles.filterBtn,
                  {
                    backgroundColor: filter === f ? "#6724E2" : "#fff"
                  }
                ]}
              >
                <Text
                  style={[
                    styles.filterTx,
                    filter === f ? styles.activeFilterTx : { color: "#000" }
                  ]}
                >
                  {f}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
          {data.map((a) => {
            switch(a.agendaType) {
              case "youtube": return <ZoomCard data={a} hideDate={false} additionalContentId={params?.additionalContentId} memberId={params?.memberId}/>;
              case "zoom": return <ZoomCard data={a} hideDate={false} additionalContentId={params?.additionalContentId} memberId={params?.memberId}/>;
              case "diskusi": return <ZoomCard data={a} hideDate={false} additionalContentId={params?.additionalContentId} memberId={params?.memberId}/>;
              case "materi": return <MaterialAgendaCard data={a} hideDate={false} additionalContentId={params?.additionalContentId} memberId={params?.memberId}/>;
              case "survey": return <AgendaProgramScreeningCard data={a} hideDate={false} additionalContentId={params?.additionalContentId} memberId={params?.memberId}/>
              case "screening": return <AgendaProgramScreeningCard data={a} hideDate={false} additionalContentId={params?.additionalContentId} memberId={params?.memberId}/>
            }
          })}
          <View style={{marginBottom: 12}}>
            <Text></Text>
          </View>
        </ScrollView>
      )}
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: "#EEEAFF",
  },
  filter: {
    backgroundColor: "#EEEAFF",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 0,
    paddingHorizontal: 16,
    gap: 12
  },
  filterBtn: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 10
  },
  filterTx: {
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontWeight: 400,
    fontStyle: "normal"
  },
  activeFilterTx: {
    fontFamily: "Archivo-SemiBold",
    color: "#fff"
  }
});

export default AgendaProgram;
