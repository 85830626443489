import * as React from "react";
import Svg, { Path } from "react-native-svg";

const HeartTickIcon = (props) => {
  const fill = (props && props.fill) ? props.fill : (props && props.color) ? props.color : "#6724E2";
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12.8998 9.29834C10.9123 9.29834 9.2998 10.9108 9.2998 12.8983C9.2998 13.5733 9.48731 14.2108 9.82481 14.7508C10.1419 15.2837 10.592 15.725 11.1311 16.0314C11.6702 16.3377 12.2797 16.4987 12.8998 16.4983C14.2123 16.4983 15.3598 15.7933 15.9748 14.7508C16.3048 14.2108 16.4998 13.5733 16.4998 12.8983C16.4998 10.9108 14.8873 9.29834 12.8998 9.29834ZM14.6848 12.4258L12.7648 14.1958C12.6598 14.2933 12.5173 14.3458 12.3823 14.3458C12.2398 14.3458 12.0973 14.2933 11.9848 14.1808L11.0998 13.2958C10.9952 13.19 10.9365 13.0472 10.9365 12.8983C10.9365 12.7495 10.9952 12.6067 11.0998 12.5008C11.3173 12.2833 11.6773 12.2833 11.8948 12.5008L12.3973 13.0033L13.9198 11.5933C14.1448 11.3833 14.5048 11.3983 14.7148 11.6233C14.9248 11.8558 14.9098 12.2158 14.6848 12.4258Z"
        fill={fill}
      />
      <Path
        d="M16.5 6.54748C16.5 7.43998 16.3575 8.26498 16.11 9.02998C16.065 9.18748 15.8775 9.23248 15.7425 9.13498C14.9195 8.52761 13.9228 8.2015 12.9 8.20498C10.2975 8.20498 8.175 10.3275 8.175 12.93C8.175 13.74 8.385 14.535 8.7825 15.24C8.9025 15.45 8.76 15.72 8.535 15.6375C6.7275 15.0225 3.075 12.78 1.89 9.02998C1.6425 8.26498 1.5 7.43998 1.5 6.54748C1.5 4.22998 3.3675 2.35498 5.67 2.35498C7.0275 2.35498 8.2425 3.01498 9 4.02748C9.3869 3.50909 9.88931 3.08802 10.4673 2.7977C11.0454 2.50738 11.6831 2.35579 12.33 2.35498C14.6325 2.35498 16.5 4.22998 16.5 6.54748Z"
        fill={fill}
      />
      <Path
        d="M16.5 6.54748C16.5 7.43998 16.3575 8.26498 16.11 9.02998C16.065 9.18748 15.8775 9.23248 15.7425 9.13498C14.9195 8.52761 13.9228 8.2015 12.9 8.20498C10.2975 8.20498 8.175 10.3275 8.175 12.93C8.175 13.74 8.385 14.535 8.7825 15.24C8.9025 15.45 8.76 15.72 8.535 15.6375C6.7275 15.0225 3.075 12.78 1.89 9.02998C1.6425 8.26498 1.5 7.43998 1.5 6.54748C1.5 4.22998 3.3675 2.35498 5.67 2.35498C7.0275 2.35498 8.2425 3.01498 9 4.02748C9.3869 3.50909 9.88931 3.08802 10.4673 2.7977C11.0454 2.50738 11.6831 2.35579 12.33 2.35498C14.6325 2.35498 16.5 4.22998 16.5 6.54748Z"
        fill={fill}
      />
    </Svg>
  );
};
export default HeartTickIcon;
