import { Image } from "react-native";
import { Text, View } from "react-native-web";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { ArrowCircleRightIcon } from "../../assets/icons";

export function ProgramAntiBullyingTeknis({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>Bagaimana Teknis Pelaksanaanya?</Text>
      <View style={styles("content")}>
        <TeknisCard
          day="Hari Ke - 1"
          image="teknis-1.png"
          title="Persiapan Kelengkapan Pelaksanaan Tes"
          styles={styles}
        />
        <ArrowCircleRightIcon color="#fff" width={62} height={62} />
        <TeknisCard
          day="Hari Ke 3-7"
          image="teknis-2.png"
          title="Sosialisasi & Pengisisan Tes Secara Daring"
          styles={styles}
        />
        <ArrowCircleRightIcon color="#fff" width={62} height={62} />
        <TeknisCard
          day="Hari Ke - 9"
          image="teknis-3.png"
          title="Pemberian Laporan Tes Screening untuk Sekolah"
          styles={styles}
        />
      </View>
    </View>
  );
}

function TeknisCard({ title, day, image, styles }) {
  return (
    <View style={styles("card")}>
      <Image
        source={require(`../../assets/images/anti-bullying/${image}`)}
        style={styles("image")}
      />
      <View style={{ gap: 16, alignItems: "center" }}>
        <View style={styles("day")}>
          <Text style={styles("txDay")}>{day}</Text>
        </View>
        <Text style={styles("txTitleCard")}>{title}</Text>
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
      padding: 48,
      backgroundColor: "#402777",
      borderRadius: 40,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#fff",
      textAlign: "center",
    },
    content: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    card: {
      width: 326,
      height: 379,
      borderRadius: 20,
      padding: 16,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 32,
      backgroundColor: "#fff",
    },
    day: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      borderRadius: 20,
      backgroundColor: "#FDC52E",
    },
    txDay: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      color: "#260C55",
    },
    txTitleCard: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 34,
      color: "#0A0316",
      textAlign: "center",
    },
    image: {
      width: 294,
      height: 184,
      borderRadius: 10,
    },
  },
  {
    container: { gap: 32 },
    header: { gap: 12 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    content: {},
  }
);
