import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { AppBar } from "../components";
import TermsText from "../components/TermsText";
import i18n from "../translation/index";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Terms = ({route}) => {
  const [lang, setLang] = useState("id");
  const {langParam} = route?.params;
  const [title, setTitle] = useState("Ketentuan Layanan");

  useEffect(() => {
    async function getLang() {
      if (langParam) {
        return setLang(langParam);
      }

      setLang(await AsyncStorage.getItem("lang"));
    }
    getLang();
  }, []);

  useEffect(() => {
    if (lang === "eng") {
      return setTitle("Terms of Use");
    }
  }, [lang]);

  return (
    <>
      <AppBar title={title} isBack />
      <ScrollView contentContainerStyle={termsAndConditionStyles.container}>
        <TermsText lang={lang}/>
      </ScrollView>
    </>
  );
};

export const termsAndConditionStyles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    padding: 16,
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16,
  },
  text: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
  },
  list: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 4,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 100,
    backgroundColor: "#000",
    marginTop: 6,
  },
});

export default Terms;
