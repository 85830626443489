import React from "react";
import { useTranslation } from "react-i18next";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { ChatIcon, HomeIcon, MessageIcon, ProfileIcon } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import BuildingIcon from "../assets/icons/BuildingIcon";

function BottomNavigation(props) {
  const { activeIndex, setCurrentTime } = props;
  const { t } = useTranslation();
  const navigation = useNavigation();
  return (
    <>
      <View style={styles.parent}>
        {[
          { id: "home", title: "Beranda", icon: HomeIcon, path: "Home" },
          { id: "chat", title: "Chat", icon: ChatIcon, path: "Chat" },
          { id: "city", title: "Kota", icon: BuildingIcon, path: "Kota" },
          { id: "profile", title: "Profile", icon: ProfileIcon, path: "Profile" },
        ].map((item, index) => (
          <TouchableOpacity
            onPress={() => {
              if (setCurrentTime) {
                setCurrentTime(new Date().getTime());
              }

              navigation.navigate(item.path, {
                newTime: new Date().getTime()
              });
            }}
            style={styles.menus}
            key={index}
          >
            <item.icon isActive={activeIndex === index} />
            <Text
              style={
                activeIndex === index ? styles.textMenuActive : styles.textMenu
              }
            >
              {t("bottom-nav:"+item.id)}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </>
  );
}
const styles = StyleSheet.create({
  parent: {
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  menus: {
    width: "25%",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    flexDirection: "column",
  },
  textMenu: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 13,
    color: "#000000",
    marginTop: 4,
  },
  textMenuActive: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
    marginTop: 4,
  },
});
export default BottomNavigation;
