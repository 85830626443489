import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const TreeIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#4EB826"
          d="M30.99 19.278H15.005c-2.262 0-3.048-1.514-1.725-3.354l7.993-11.193c.939-1.342 2.51-1.342 3.45 0l7.992 11.193c1.322 1.84.537 3.354-1.725 3.354Z"
          opacity={0.4}
        />
        <Path
          fill="#4EB826"
          d="M33.712 34.496H12.284c-3.029 0-4.064-2.013-2.281-4.466l7.647-10.753h10.695l7.648 10.753c1.782 2.453.747 4.466-2.281 4.466ZM24.438 34.496v7.667c0 .786-.652 1.437-1.438 1.437a1.448 1.448 0 0 1-1.438-1.437v-7.667h2.875Z"
        />
      </Svg>
    </View>
  </View>
);
export default TreeIcon;
