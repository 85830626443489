import { View } from "react-native";
import Svg, { Path } from "react-native-svg";
const MusicIcon = (props) => (
  <View
    style={{
      width: props?.width || 46,
      height: props?.height || 46,
    }}
  >
    <View style={{ aspectRatio: 1 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 46 46"
        fill="none"
      >
        <Path
          fill="#834FE3"
          d="M19.73 19.814v15.468c0 3.795-3.105 6.88-6.88 6.88a6.887 6.887 0 0 1-6.881-6.88c0-3.776 3.086-6.862 6.88-6.862 1.591 0 3.029.556 4.198 1.457v-9.296l2.683-.767ZM40.047 14.025v17.557a6.887 6.887 0 0 1-6.881 6.88c-3.776 0-6.88-3.085-6.88-6.88 0-3.776 3.104-6.862 6.88-6.862a6.75 6.75 0 0 1 4.217 1.476V14.792l2.664-.767Z"
        />
        <Path
          fill="#834FE3"
          d="M40.047 9.926v4.102l-23 6.555v-7.647c0-2.818 1.706-5.003 4.408-5.731l11.078-3.029c2.243-.613 4.14-.402 5.482.633 1.361 1.016 2.032 2.74 2.032 5.117Z"
          opacity={0.4}
        />
      </Svg>
    </View>
  </View>
);
export default MusicIcon;
