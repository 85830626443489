import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import BuildingCircleIcon from "../../../assets/icons/report-test/BuildingCircleIcon";
import ListStatic from "../ListStatic";
import TeacherIcon from "../../../assets/icons/report-test/TeacherIcon";

function EducationSuggestion(props) {
  const {descriptions} = props;

  const lists = [
    {
      id: 0,
      title: "Sekolah Menengah Atas",
      icon: <BuildingCircleIcon />,
      color: "#91966F",
      secondColor: "#DDDFD3",
      subDescription: [],
	  bgColor: '#F4F4F0'
    },
    {
      id: 1,
      title: "Perguruan Tinggi",
      icon: <TeacherIcon />,
      color: "#B3514A",
      secondColor: "#E9CBC9",
      subDescription: [],
	  bgColor: '#F8EEED'
    },
  ];
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (!descriptions) {
      return;
    }

    Object.keys(descriptions).map((d) => {
      // console.log("d", d, descriptions[d]);
      descriptions[d].map((data) => {
        if (data.name === "sma") {
          lists[0].subDescription.push(data.desc);
        }

        if (data.name === "kuliah") {
          lists[1].subDescription.push(data.desc);
        }
      })
    });

    // console.log("lists", lists);

    setListData(lists);
  }, [descriptions]);

  return (
    <View style={styles.wrapper}>
      {listData.map((item, index) => (
        <ListStatic item={item} key={index} />
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    marginTop: 24,
	gap: 24
  },
});

export default EducationSuggestion;
