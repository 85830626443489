import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { VerifyIcon } from "../../assets/icons";

function SIPPContent({data}) {
  return (
    <View>
      <View style={{ ...styles.flexed, justifyContent: "center" }}>
        <View style={styles.parentVerify}>
          <VerifyIcon fill="#6724E2" />
        </View>
      </View>
      <View
        style={{
          ...styles.flexed,
          justifyContent: "center",
          marginBottom: 12,
          marginTop: 24,
        }}
      >
        <Text style={styles.txTitle}>Ahli Telah Tersertifikasi</Text>
      </View>
      <View style={styles.parentDetail}>
		<View style={styles.flexed}>
			<Text style={styles.txDetail}>Nomor SIPP </Text>
			<Text style={styles.txDetail}>: {data?.sipp}</Text>
		</View>
		<View style={styles.flexed}>
			<Text style={styles.txDetail}>Nomor STR </Text>
			<Text style={styles.txDetail}> : {data?.str}</Text>
		</View>
	  </View>
    </View>
  );
}
const styles = StyleSheet.create({
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
  parentVerify: {
    height: 60,
    width: 60,
    borderRadius: 30,
    backgroundColor: "#E2DCFC",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  txTitle: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "700",
  },
  parentDetail: {
    borderRadius: 10,
    backgroundColor: "#F3F3F3",
    padding: 12,
  },
  txDetail: {
    color: "#000",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 18,
  },
});
export default SIPPContent;
