import { API_URL, API_MODULE, APP_MAIN_SITE } from "@env";
import axios from "axios";

export const getSurveys = (additionalContentId, memberId, isScreening) => {
  return axios.get(
    API_URL + `/quiz_program/${additionalContentId}/${memberId}/${isScreening}`
  );
};

export const getSurveysProgramById = (quizId, memberId) => {
  return axios.get(
    API_URL + `/quiz_program_by_id/${quizId}/${memberId}`
  );
};

export const getDetailSurvey = (quizId) => {
  return axios.get(API_URL + `/quiz/${quizId}`);
};

export const getSurveyStatistic = (quizId) => {
  return axios.get(API_URL + `/quiz_statistics/${quizId}`);
};

export const checkEligibility = (quizId, token, additionalContentId) => {
  return axios.get(API_URL + `/check-eligible/${quizId}/${token}/${additionalContentId}`);
}

export const submitSurvey = (quizId, token, postedAnswer, additionalContentId) => {
  return axios.post(
    API_URL + `/answer/${quizId}/${token}/${additionalContentId}`,
    postedAnswer
  );
}

export const calculate = (quizId, token) => {
  return axios.get(
    API_MODULE + `/quiz/calculate/${quizId}/${token}`
  );
}

export const getScreeningResult = (quizId, token, additional_content_id) => {
  return axios.get(
    API_URL + `/get_screening_result/${quizId}/${token}/${additional_content_id}`
  );
}

export const getScreeningResultAnswer = (quizId, token, additional_content_id) => {
  return axios.get(
    API_URL + `/quiz_answers_with_additional/${quizId}/${token}/${additional_content_id}`
  );
}

export const getScreeningResults = (quizId, token, additional_content_id) => {
  return axios.get(
    API_URL + `/get_screening_results/${quizId}/${token}/${additional_content_id}`
  );
}

export const getScreeningResultRespondent = (quizId, token, additional_content_id, responde_id) => {
  return axios.get(
    API_URL + `/get_screening_result/${quizId}/${token}/${additional_content_id}/${responde_id}`
  );
}

export const getScreeningResultRespondents = (quizId, token, additional_content_id, responde_id) => {
  return axios.get(
    API_URL + `/get_screening_results/${quizId}/${token}/${additional_content_id}/${responde_id}`
  );
}

export const getAspects = (quizId, token, additionalContentId) => {
  return axios.get(
    API_URL + `/get_screening_aspects/${quizId}/${token}/${additionalContentId}`
  );
}

export const getAspectsRespondent = (quizId, token, additionalContentId, respondent_id) => {
  return axios.get(
    API_URL + `/get_screening_aspects/${quizId}/${token}/${additionalContentId}/${respondent_id}`
  );
}

export const getOptions = (name) => {
  return axios.get(
    API_MODULE + `/data-source/${name}`
  );
}

export const getScreeningDescriptions = (label) => {
  return axios.get(
    API_URL + `/get-screening-descriptions/${label}`
  );
}