import { View, StyleSheet, Pressable, Image, Text } from "react-native";
import { BookmarkIcon, ChevronLeft, UserGroup } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { useEffect, useState } from "react";
import { getSpeaker } from "../services/speakers";
import { timeCalculation } from "../services/services";

function AppBarChat(props) {
  const { isSenderTyping, data, setOpenFeedback, calculatedTime, consId, setIsDone, setIsReady, isDone, isReady, t } = props;
  const navigation = useNavigation();
  const [time, setTime] = useState(undefined);
  const [openTime, setOpenTime] = useState(undefined);
  const [consultationStart, setConsultationStart] = useState(undefined);
  const [imageSrc, setImageSrc] = useState("");
  const [defaultImage, setDefaultImage] = useState(false);
  const isAnonymous = data?.anonymous === "true";

  useEffect(() => {
    setTime(calculatedTime);
  }, [calculatedTime]);

  useEffect(() => {
    if (!time) {
      return ;
    }

    if (time <= 1 && time !== -100) {
      setIsDone(true);
    }

    if (time < -1) {
      return;
    }

    setTimeout(() => {
      return setTime(time - 1);
    }, 1000);

    return () => {
      clearTimeout(time);
    };
  }, [time]);

  useEffect(() => {
    setTime(calculatedTime);
  }, [calculatedTime]);

  useEffect(() => {
    if (!openTime) {
      return ;
    }

    if (openTime <= 1 && time > 0) {
      setIsReady(true);
    }

    if (openTime < -1) {
      return;
    }

    setTimeout(() => {
      return setOpenTime(openTime - 1);
    }, 1000);

    return () => {
      clearTimeout(openTime);
    };
  }, [openTime]);

  useEffect(() => {
    const consultationStartedTime = data?.consultationDate+" "+data?.consultationHour.split("-")[0];
    setIsReady(moment() >= moment(consultationStartedTime));
    setConsultationStart(consultationStartedTime);
    setOpenTime(timeCalculation(moment(consultationStartedTime)).diff);

    const consultationEndedTime = data?.consultationDate+" "+data?.consultationHour.split("-")[1];
    setIsDone(moment() >= moment(consultationEndedTime));

    let image = data?.photo;
    setImageSrc(image);
  }, [data]);

  return (
    <View style={[styles.parent, styles.justifyBetween]}>
      <View style={styles.flexed}>
        <Pressable
          onPress={() => {
            navigation.navigate("Chat", {
              "foo": new Date().getTime()
            })
          }}
        >
          <ChevronLeft />
        </Pressable>
        {
            (imageSrc === "" || !imageSrc || defaultImage ||
              (data?.loggedInMemberId !== data?.memberId && isAnonymous)
            ) &&
            <UserGroup/>
          }
        {
          imageSrc && imageSrc !== "" && !defaultImage &&
          <Image
            source={{ uri: 
              data?.loggedInMemberId === data?.memberId && data?.photo ? 
                  `https://fammi.ly/uploads/speakers/images/${imageSrc}` :
                  (data?.loggedInMemberId !== data?.memberId && data?.photo ? 
                  `https://fammi.ly/uploads/members/images/${imageSrc}` :
                  `https://via.placeholder.com/40?mode=${data?.mode}`) }}
            height={36}
            width={36}
            style={styles.ava}
            onError={() => setDefaultImage(true)}
          />
        }
        <View style={{ marginLeft: 10 }}>
          <Text style={styles.textName}>
            {(data?.loggedInMemberId !== data?.memberId && isAnonymous) ? "Anonim" : data?.name}
          </Text>
          {
          time > 0 &&
          isReady &&
          !isDone &&
            <View style={styles.flexed}>
              <Text style={styles.textSpan}>
                {t("remaining")} {moment.utc(1000 * time).format("HH:mm:ss")}
              </Text>
              {isSenderTyping && (
                <Text style={styles.textTyping}>{t("typing")}...</Text>
              )}
            </View>
          }
          {
          !isReady &&
            <View style={styles.flexed}>
              <Text style={styles.textSpan}>
                {t("will")} {moment(consultationStart).format("DD MMM YYYY HH:mm")}
              </Text>
            </View>
          }
          {
            isDone &&
            <Text style={styles.textDone}>{t("done")}, {moment(data?.consultationDate+" "+data?.consultationHour?.split("-")[1]).format("DD MMM YYYY HH:mm")}</Text>
          }
        </View>
      </View>
      <Pressable
        onPress={async() => {
          if (data?.memberId === data?.loggedInMemberId) {
            return navigation.navigate("Journal");
          }

          const speakerId = await AsyncStorage.getItem("792f3e1");
          getSpeaker(speakerId)
          .then((r) => {
            const speakerType = r?.data[0].speakerType;
            navigation.navigate("WriteJournal", {
              speakerType: speakerType,
              speaker: r?.data[0],
              consId: consId
            });
            return;
          })
        }}
      >
        <BookmarkIcon />
      </Pressable>
    </View>
  );
}
const styles = StyleSheet.create({
  parent: {
    width: "100%",
    paddingTop: 20,
    paddingRight: 16,
    paddingBottom: 20,
    paddingLeft: 16,
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    alignItems: "center",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowColor: "rgba(0, 0, 0, 0.05)",
    shadowOpacity: 1,
  },
  flexed: {
    flexDirection: "row",
    alignItems: "center",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  ava: {
    height: 36,
    width: 36,
    borderRadius: 36 / 2,
    marginLeft: 10,
  },
  textName: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#000000",
    marginBottom: 4,
  },
  textSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  textTyping: {
    fontFamily: "Archivo-SemiBoldItalic",
    fontStyle: "italic",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#4EB826",
    marginLeft: 4,
  },
  textDone: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#FF0000",
  },
});

export default AppBarChat;
