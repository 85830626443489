import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const ReadModulMaterialIcon = (props) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={24} cy={24} r={24} fill="#DCF4CA" />
    <Path
      d="M28.5859 14.8335H19.4193C16.8893 14.8335 14.8359 16.8777 14.8359 19.3985V24.8802V25.7968C14.8359 28.3177 16.8893 30.3618 19.4193 30.3618H20.7943C21.0418 30.3618 21.3718 30.5268 21.5276 30.7285L22.9026 32.5527C23.5076 33.3593 24.4976 33.3593 25.1026 32.5527L26.4776 30.7285C26.6518 30.4993 26.9268 30.3618 27.2109 30.3618H28.5859C31.1159 30.3618 33.1693 28.3177 33.1693 25.7968V19.3985C33.1693 16.8777 31.1159 14.8335 28.5859 14.8335ZM24.9193 25.6043H19.4193C19.0434 25.6043 18.7318 25.2927 18.7318 24.9168C18.7318 24.541 19.0434 24.2293 19.4193 24.2293H24.9193C25.2951 24.2293 25.6068 24.541 25.6068 24.9168C25.6068 25.2927 25.2951 25.6043 24.9193 25.6043ZM28.5859 21.021H19.4193C19.0434 21.021 18.7318 20.7093 18.7318 20.3335C18.7318 19.9577 19.0434 19.646 19.4193 19.646H28.5859C28.9618 19.646 29.2734 19.9577 29.2734 20.3335C29.2734 20.7093 28.9618 21.021 28.5859 21.021Z"
      fill="#4EB826"
    />
  </Svg>
);
export default ReadModulMaterialIcon;
