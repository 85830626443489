import axios from "axios";
import {API_URL} from "@env";

export const setName = (name, token) => {
    return axios.post(API_URL + `/members/update/${token}`, {
        name: name
    });
}

export const setProfileData = (profile, token) => {
    return axios.post(API_URL + `/profile`, {
        memberId: token,
        profileData: JSON.stringify(profile)
    });
}

export const setMember = (token) => {
    return axios.get(API_URL + `/members/member/${token}`);
}