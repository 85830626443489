import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Svg, { Defs, LinearGradient, Rect, Stop } from "react-native-svg";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { PlayIcon } from "../../assets/icons";

export function CompanyProfileHero({ layout }) {
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  return (
    <ImageBackground
      source={require(`../../assets/images/cp-hero-${
        isMobile ? "mobile" : "desktop"
      }.png`)}
      style={styles("container")}
      imageStyle={{ borderRadius: 40 }}
    >
      <View style={styles("content")}>
        <Text style={styles("txTitle")}>
          Platform Pendidikan & Kesehatan untuk Sekolah dan Keluarga
        </Text>
        <Text style={styles("txDesc")}>
          Telah Dipercaya Oleh Institusi & Sekolah Selama 3 tahun+ dengan
          Tingkat Kepuasan {">"} 90%
        </Text>
        <TouchableOpacity style={styles("button")}>
          <PlayIcon style={styles("playIcon")} fill="#000" />
          <Text style={styles("txButton")}>Lihat Dampak Fammi</Text>
        </TouchableOpacity>
      </View>
      <Svg width="100%" height="100%" style={styles("overlay")}>
        <Defs>
          <LinearGradient
            id="gradHero"
            x1="0%"
            x2={isMobile ? "0%" : "100%"}
            y1="0%"
            y2={isMobile ? "80%" : "0%"}
          >
            <Stop offset={0} stopColor="#082C48B2" />
            <Stop offset={1} stopColor="transparent" />
          </LinearGradient>
        </Defs>
        <Rect width="100%" height="100%" fill="url(#gradHero)" />
      </Svg>
    </ImageBackground>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: 460,
      justifyContent: "center",
      padding: 48,
      marginTop: 24,
      position: "relative",
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: 40,
    },
    content: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 24,
      zIndex: 1,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#fff",
      maxWidth: 600,
    },
    txDesc: {
      fontFamily: "Archivo-Medium",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 26,
      color: "#fff",
      maxWidth: 420,
    },
    button: {
      backgroundColor: "#FDC52E",
      paddingVertical: 12,
      paddingHorizontal: 22,
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
      borderRadius: 24,
    },
    playIcon: {
      width: 24,
      height: 24,
    },
    txButton: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 24,
      color: "#000",
    },
  },
  {
    container: {
      height: 705,
      paddingHorizontal: 16,
      justifyContent: "flex-start",
    },
    content: {
      gap: 0,
    },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 14,
      lineHeight: 24,
      marginTop: 12,
      marginBottom: 32,
    },
    playIcon: {
      width: 18,
      height: 18,
    },
    contactButton: {
      backgroundColor: "#6724E2",
      paddingVertical: 12,
      paddingHorizontal: 24,
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
      borderRadius: 40,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 20,
      position: "absolute",
      bottom: 0,
      right: 0,
    },
  }
);
