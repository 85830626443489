import { printToFileAsync } from "expo-print";
import { shareAsync } from "expo-sharing";
import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import DocumentTextIcon from "../assets/icons/DocumentTextIcon";
import StatsIcon from "../assets/icons/StatsIcon";
import {
  GeneralScreeningResult,
  ResultDescription,
  SuggestionAspectCard,
} from "../components";
import AboutPage from "../components/ReportTestComponents/AboutPage";
import BasePaper from "../components/ReportTestComponents/BasePaper";
import CoverPage from "../components/ReportTestComponents/CoverPage";
import HeaderContent from "../components/ReportTestComponents/HeaderContent";
import HeaderDownload from "../components/ReportTestComponents/HeaderDownload";
import {
  getDetailSurvey,
  getScreeningDescriptions,
  getScreeningResultAnswer,
  getScreeningResultRespondents,
  getScreeningResults,
} from "../services/survey";

function AntiBullyingReportTest({ route }) {
  const [label, setLabel] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [quiz, setQuiz] = useState({});
  const [quizAnswer, setQuizAnswer] = useState(undefined);

  useEffect(() => {
    // const {quizId, memberId, additional_content_id, resp_id} = route.params;
    const quizId = "a6a292d8c7c66893159e09ed07c7545f";
    const memberId = "00c6348a245d35af0da4421d274dafca";
    const additional_content_id = "e738c68be7b540d5698c14fe2b76969c";
    const resp_id = "00c6348a245d35af0da4421d274dafca";

    if (memberId === resp_id) {
      getScreeningResultRespondents(
        quizId,
        memberId,
        additional_content_id,
        resp_id
      ).then((res) => {
        const labels = res?.data.map((r) => {
          return r.additionalInfo;
        });

        setLabel(labels);
      });
    } else {
      getScreeningResults(
        quizId,
        memberId,
        additional_content_id,
        resp_id
      ).then((res) => {
        const labels = res?.data.map((r) => {
          return r.additionalInfo;
        });

        setLabel(labels);
      });
    }

    getDetailSurvey(quizId).then((res) => {
      const quizWithLabel = res?.data?.filter(
        (q) => q.indexLabel !== null && q.indexLabel !== "" && q.indexLabel
      );
      setQuiz(quizWithLabel);
    });

    getScreeningResultAnswer(quizId, memberId, additional_content_id).then(
      (res) => {
        setQuizAnswer(res?.data);
      }
    );
  }, [route.params]);

  useEffect(() => {
    if (label.length === 0) {
      return;
    }

    const desc = {};
    label.map((l) => {
      getScreeningDescriptions(l.toLowerCase()).then((res) => {
        const data = res?.data;
        if (data.length === 0) {
          return;
        }

        desc[data[0].label] = data;
        setDescriptions({
          ...descriptions,
          ...desc,
        });
      });
    });
  }, [label]);

  const ExportComponent = () => (
    <View style={styles.mains}>
      <BasePaper isHideHeader isSocmedFooter>
        <CoverPage
          quizAnswer={quizAnswer}
          title="GENERAL CHECKUP ANTI-BULLYING"
          coverImg="cover-antibullying-report.png"
        />
      </BasePaper>
      <BasePaper isHideHeader isSocmedFooter>
        <AboutPage
          quizAnswer={quizAnswer}
          header={
            <Text style={{ maxWidth: 130, marginBottom: 12 }}>
              Kepada Yth.{" "}
              <Text style={{ fontWeight: 600 }}>Bapak/Ibu Guru</Text>
            </Text>
          }
          description={[
            "Terima kasih atas kepercayaan pihak sekolah yang telah ikut berpartisipasi dalam Tes Psikologi Minat Bakat siswa. Pada tanggal 2 Desember 2023 telah dilaksanakan Tes Psikologi Minat Bakat yang diikuti oleh seluruh siswa kelas 9 dengan jumlah 88 siswa. Masing-masing siswa akan menerima laporan mereka langsung dari handphone yang telah dilengkapi dengan saran dari psikolog Fammi. Berikut kami sampaikan gambaran keseluruhan mengenai hasil Tes Psikologi Minat Bakat siswa-siswi di sekolah.",
          ]}
          footerDesc="Semoga hasil yang kami sampaikan bisa bermanfaat bagi Bapak/Ibu Guru dan Orangtua dalam membantu para siswa mengembangkan potensi, mencapai pembelajaran yang lebih optimal dan membantu siswa menentukan cita-citanya di masa yang akan datang."
        />
      </BasePaper>
      <BasePaper
        icon={<StatsIcon />}
        title="Hasil Tes Screening General Check Up"
        description="Tes General Check-Up merupakan tes deteksi dini yang bertujuan mengetahui gambaran awal kondisi para siswa saat ini. Screening ini melihat beberapa aspek yang menunjang prestasi akademik Ananda seperti kesulitan belajar, minat bakat, kecemasan, pengelolaan emosi atau suasana hati, relasi, penggunaan gadget dan perilaku bullying. Saat Ayah Bunda sudah mengetahui kondisi Ananda, harapannya bisa memberikan dukungan yang tepat sesuai kebutuhan Ananda saat ini."
        pageNumber="01"
        customFooter={
          <Image
            source={require("../assets/images/footer-intro-2.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <GeneralScreeningResult />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Deskripsi Hasil"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed enim ut sem viverra aliquet eget sit amet. Id eu nisl nunc mi ipsum faucibus vitae aliquet nec."
        pageNumber="02"
      >
        <ResultDescription />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Kesimpulan Hasil Tes"
        description={`Ananda sering memiliki emosi-emosi yang tidak nyaman seperti perasaan cemas, khawatir, dan takut yang sebenarnya adalah perasaan yang wajar dirasakan ketika ada masalah atau hal yang mengganggunya. Namun, jika terlalu sering merasakan emosi yang tidak nyaman ini dan dibiarkan maka bisa jadi berdampak pada ananda yang sulit mengendalikan perasaan ini, emosi ananda sering berubah-ubah dan menjadi sulit beraktivitas seperti biasa. Ananda perlu mengelola perasaan dengan mulai terbuka dengan orang lain, menceritakan apa yang membuatnya tidak nyaman, atau menulis jurnal harian yang berisi apa yang ananda rasakan.\n\nAnanda sudah terlalu berlebihan bermain HP yang membuatnya menjadi lupa waktu atau melakukan aktivitas lainnya. Bantu ananda membuat jadwal dan memasang alarm sebagai pengingat bahwa waktu ananda main HP sudah habis. Bantu juga ananda membuat jadwal rutinitas sehari-hari dari mulai bangun tidur sampai tidur kembali dan buat kesepakatan bersama ananda berapa lama boleh bermain HP dalam sehari dan aplikasi atau konten apa saja yang boleh diakses.
        \nAnanda sudah cukup bisa menyelesaikan masalah yang terjadi pada dirinya. Namun, ia masih cukup sering merasa ada masalah-masalah yang teriadi di hidupya dan sulit untuk bisa diselesaikan. Ananda pun masih sering merasa emosi-emosi tidak nyaman seperti cemas, khawatir, marah, dan akhirnya membuatnya tidak nyaman. la pun sebenarnya bisa menceritakan masalahnya pada orang lain seperti teman, keluarga, guru, atau orang-orang yang dapat dipercayai. Namun, ada beberapa hal yang masih belum bisa diceritakan kepada orang lain.`}
        pageNumber="03"
        customFooter={
          <Image
            source={require("../assets/images/result-summary.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      />
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Kecemasan"
        pageNumber="04"
        customFooter={
          <Image
            source={require("../assets/images/footer-anxiety.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Kecemasan" condition="critical" />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Gadget"
        pageNumber="05"
        customFooter={
          <Image
            source={require("../assets/images/footer-gadget.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Gadget" condition="critical" />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Bullying"
        pageNumber="06"
        customFooter={
          <Image
            source={require("../assets/images/footer-bullying.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Bullying" condition="warning" />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Belajar"
        pageNumber="07"
        customFooter={
          <Image
            source={require("../assets/images/footer-study.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Belajar" condition="secure" />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Minat Bakat"
        pageNumber="08"
        customFooter={
          <Image
            source={require("../assets/images/footer-minat.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Minat Bakat" condition="secure" />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Emosi"
        pageNumber="09"
        customFooter={
          <Image
            source={require("../assets/images/footer-emosion.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Emosi" condition="secure" />
      </BasePaper>
      <BasePaper
        icon={<DocumentTextIcon isCircle />}
        title="Saran Untuk Aspek Relasi"
        pageNumber="10"
        customFooter={
          <Image
            source={require("../assets/images/footer-relation.png")}
            style={{
              width: "100%",
              height: 400,
              zIndex: -1,
            }}
          />
        }
      >
        <SuggestionAspectCard aspect="Relasi  " condition="secure" />
      </BasePaper>
    </View>
  );
  const convertToPDF = async () => {
    try {
      const htmlContent = `<html><body>${renderToString(
        ExportComponent()
      )}</body></html>`;
      if (Platform.OS === "web") {
        const pW = window.open("", "", "width=1366,height=900");
        pW.document.write(htmlContent);
        pW.document.close();
        pW.print();
      } else {
        const file = await printToFileAsync({
          html: htmlContent,
          base64: false,
        });
        await shareAsync(file.uri);
      }

      //   console.log(`PDF saved to: ${file}`);
    } catch (error) {
      console.error("Error exporting PDF:", error);
    }
  };
  return (
    <View style={styles.container}>
      <HeaderDownload onDownload={convertToPDF} />
      <View style={styles.mainContent}>
        <HeaderContent />
        <ExportComponent />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FDFDFD",
    width: "100%",
    alignItems: "center",
    paddingTop: 24,
    minHeight: "100%",
  },
  mainContent: {
    width: "100%",
    maxWidth: 1200,
    backgroundColor: "#F1F2F9",
    borderRadius: 40,
    marginTop: 24,
  },
  mains: {
    paddingHorizontal: 48,
    paddingVertical: 32,
    width: "100%",
    alignItems: "center",
  },
  textBase: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "300",
  },
});

export default AntiBullyingReportTest;
