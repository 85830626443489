import { useState } from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import CreateResponsiveStyle from "../Utils/createResponsiveStyle";
import {
  CompanyProfileFooter,
  CompanyProfileTestimoni,
  CompanyProfileTopbar,
  ProgramAntiBullyingBanner,
  ProgramAntiBullyingBenefits,
  ProgramAntiBullyingDetailVideo,
  ProgramAntiBullyingFAQ,
  ProgramAntiBullyingHero,
  ProgramAntiBullyingInstrument,
  ProgramAntiBullyingLaporanTes,
  ProgramAntiBullyingTeknis,
  ProgramAntiBullyingWhy,
} from "../components";

function ProgramAntiBullying() {
  const [isScroll, setIsScroll] = useState(true);
  const layout = useWindowDimensions();
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  return (
    <ScrollView
      style={{ overflow: isScroll ? "auto" : "hidden" }}
      contentContainerStyle={{
        alignItems: "center",
        backgroundColor: "#F1F2F9",
        paddingHorizontal: isMobile ? 16 : 0,
        overflow: isScroll ? "auto" : "hidden",
        height: "100vh",
      }}
    >
      <View style={styles("container")}>
        <CompanyProfileTopbar layout={layout} setIsScroll={setIsScroll} />
        <View style={{ gap: 84 }}>
          <ProgramAntiBullyingHero layout={layout} />
          <ProgramAntiBullyingDetailVideo layout={layout} />
          <ProgramAntiBullyingInstrument layout={layout} />
          <ProgramAntiBullyingBenefits layout={layout} />
          <ProgramAntiBullyingLaporanTes layout={layout} />
          <ProgramAntiBullyingWhy layout={layout} />
          <ProgramAntiBullyingTeknis layout={layout} />
          <CompanyProfileTestimoni layout={layout} />
          <ProgramAntiBullyingBanner layout={layout} />
          <ProgramAntiBullyingFAQ layout={layout} />
          <CompanyProfileFooter layout={layout} />
        </View>
      </View>
    </ScrollView>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      maxWidth: 1200,
      paddingTop: 24,
      overflow: "hidden",
    },
  },
  {
    container: {
      width: "100%",
    },
  }
);

export default ProgramAntiBullying;
