import { Fragment, useEffect, useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { AppBar, EmptyState, ModalFeedback, PDFReader, Snackbar } from "../components";
import { BookBlackIcon, HeartIcon, HeartLinearIcon } from "../assets/icons";
import { checkFeedback } from "../services/materials";
import WebView from "react-native-webview";


function ReadEbook({ route }) {
  const { params } = route;
  const [openFeedback, setOpenFeedback] = useState(false);
  const [successFeedback, setSuccesssFeedback] = useState(false);
  const [alreadyFeedback, setAlreadyFeedback] = useState(false);
  const [isErrorLoad, setIsErrorLoad] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!params?.materialId) {
      return;
    }
    checkFeedback(params?.materialId, params?.memberId).then((res) => {
      if (res?.data.length > 0) {
        setAlreadyFeedback(true);
      }
    });
  }, [params?.materialId, params?.memberId]);

  return (
    <Fragment>
      <AppBar
        title={params?.title}
        isBack
        rightSection={
          <Pressable
            onPress={() => {
              if (alreadyFeedback) {
                return;
              }
              setOpenFeedback(true);
            }}
            style={{
              ...styles.btnFeedback,
              backgroundColor:
                alreadyFeedback || successFeedback ? "#FFDCDC" : "#FFF",
            }}
          >
            {(alreadyFeedback || successFeedback) && (
              <HeartIcon bgColour={"#FFDCDC"} />
            )}
            {!alreadyFeedback && !successFeedback && <HeartLinearIcon />}
          </Pressable>
        }
      />
	     {
          loading &&
          <View style={{
            flexDirection:"row",
            alignItems: "center",
            padding: 16,
            backgroundColor: "#EEEAFF",
            height: "100%",
            minHeight: "100%",
            flex: 1
          }}>
            <EmptyState
              Icon={BookBlackIcon}
              title="Mohon Menunggu"
              desc=""
            />
          </View>
        }
      {isErrorLoad ?
          <View style={{ flex: 1 }}>
            <WebView
              source={{
                uri: `https://docs.google.com/gview?url=${params?.url}&embedded=true`,
              }}
              style={{ flex: 1 }}
            />
            <View style={{
              width: 40, 
              height: 40, 
              position: "absolute", 
              right: 11,
              top: 13,
              backgroundColor: "#FFF",
              opacity: 0
            }}>
              <Text></Text>
            </View>
          </View>
          :
          <PDFReader fadeInDuration={250.0}
          style={{ flex: 1 }}
          resource={params?.url}
          resourceType="url"
          onLoad={() => setLoading(false)}
          onError={(_error) => setIsErrorLoad(true)}
          />
      }
      <ModalFeedback
        visible={openFeedback}
        hideDialog={() => {
          setOpenFeedback(false);
        }}
        setSuccess={setSuccesssFeedback}
        isEbook
        data={{
          materialId: params?.materialId,
          additionalContentId: params?.additionalContentId,
        }}
      />
      <Snackbar
        visible={successFeedback}
        hideDialog={() => {
          setSuccesssFeedback(false);
        }}
        title="Penilaian berhasil dikirim"
      />
    </Fragment>
  );
}
const styles = StyleSheet.create({
  btnFeedback: {
    width: 36,
    height: 36,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#eaeaea",
    borderStyle: "solid",
    borderRadius: 36 / 2,
  },
});
export default ReadEbook;
