import * as React from "react";
import Svg, { Path } from "react-native-svg";

const QuoteUpIcon = (props) => (
  <Svg
    width={48}
    height={49}
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M16.18 23.7609H6.8C6.96 14.4209 8.8 12.8809 14.54 9.48092C15.2 9.08092 15.42 8.24092 15.02 7.56092C14.64 6.90092 13.78 6.68092 13.12 7.08092C6.36 11.0809 4 13.5209 4 25.1409V35.9209C4 39.3409 6.78 42.1009 10.18 42.1009H16.18C19.7 42.1009 22.36 39.4409 22.36 35.9209V29.9209C22.36 26.4209 19.7 23.7609 16.18 23.7609Z"
      fill="#FDC52E"
    />
    <Path
      d="M37.8201 23.7609H28.4401C28.6001 14.4209 30.4401 12.8809 36.1801 9.48092C36.8401 9.08092 37.0601 8.24092 36.6601 7.56092C36.2601 6.90092 35.4201 6.68092 34.7401 7.08092C27.9801 11.0809 25.6201 13.5209 25.6201 25.1609V35.9409C25.6201 39.3609 28.4001 42.1209 31.8001 42.1209H37.8001C41.3201 42.1209 43.9801 39.4609 43.9801 35.9409V29.9409C44.0001 26.4209 41.3401 23.7609 37.8201 23.7609Z"
      fill="#FDC52E"
    />
  </Svg>
);
export default QuoteUpIcon;
