import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  EmojiHappy2Icon,
  EmojiNormalIcon,
  EmojiSadIcon,
  TickIcon,
} from "../../assets/icons";

function GeneralScreeningResult(props) {
  const list = [
    {
      id: 1,
      title: "Kritis",
      icon: <EmojiSadIcon />,
      color: ["#E54646", "#E54646", "#F9D2D2", "#FCE9E9"],
    },
    {
      id: 2,
      title: "Waspada",
      icon: <EmojiNormalIcon />,
      color: ["#A48A00", "#B29600", "#FFEB80", "#FFF7CC"],
    },
    {
      id: 3,
      title: "Aman",
      icon: <EmojiHappy2Icon />,
      color: ["#3E951D", "#4EB826", "#DCF4CA", "#F1FBEA"],
    },
  ];

  const dataTable = [
    {
      aspek: "Belajar",
      kritis: true,
      waspada: null,
      aman: null,
    },
    {
      aspek: "Minat Bakat",
      kritis: null,
      waspada: true,
      aman: null,
    },
    {
      aspek: "Bullying",
      kritis: null,
      waspada: null,
      aman: true,
    },
    {
      aspek: "Kecemasan",
      kritis: true,
      waspada: null,
      aman: null,
    },
    {
      aspek: "Emosi",
      kritis: null,
      waspada: true,
      aman: null,
    },
    {
      aspek: "Gadget",
      kritis: true,
      waspada: null,
      aman: null,
    },
    {
      aspek: "Relasi",
      kritis: null,
      waspada: null,
      aman: true,
    },
  ];
  return (
    <View style={styles.container}>
      <View style={styles.hrLine} />
      <View style={styles.cards}>
        {list.map((item) => (
          <View
            key={item.id}
            style={[styles.card, { backgroundColor: item.color[3] }]}
          >
            <View style={[styles.labels, { backgroundColor: item.color[2] }]}>
              <View style={styles.icon}>{item.icon}</View>
              <Text style={[styles.textLabels, { color: item.color[0] }]}>
                {item.title}
              </Text>
            </View>
            <View style={[styles.value, { backgroundColor: item.color[2] }]}>
              <Text style={[styles.textValue, { color: item.color[1] }]}>
                5
              </Text>
            </View>
          </View>
        ))}
      </View>
      <View>
        <TableRow
          isHeader
          data={{
            number: "No",
            aspek: "Aspek",
            kritis: "Kritis",
            waspada: "Waspada",
            aman: "Aman",
          }}
        />
        <View>
          {dataTable.map((item, id) => (
            <TableRow data={{ ...item, number: id + 1 }} />
          ))}
        </View>
      </View>
    </View>
  );
}

function TableRow({ data, isHeader }) {
  const { number, aspek, kritis, waspada, aman } = data;
  return (
    <View
      style={[
        styles.tableRow,
        isHeader && {
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
          borderTopWidth: 1,
        },
        number === 7 && {
          borderBottomLeftRadius: 14,
          borderBottomRightRadius: 14,
        },
      ]}
    >
      <TableCell value={number} width={44} isHeader={isHeader} />
      <TableCell value={aspek} width={290} isHeader={isHeader} />
      <TableCell
        value={kritis}
        width={132}
        isHeader={isHeader}
        color={isHeader ? "#F9D2D2" : "#FCE9E9"}
      />
      <TableCell
        value={waspada}
        width={132}
        isHeader={isHeader}
        color={isHeader ? "#FFEB80" : "#FFF7CC"}
      />
      <TableCell
        value={aman}
        width={132}
        isHeader={isHeader}
        color={isHeader ? "#DCF4CA" : "#F1FBEA"}
        isEndRight
      />
    </View>
  );
}

function TableCell({ value, color, width, isHeader, isEndRight }) {
  return (
    <View
      style={[
        styles.tableCell,
        {
          borderRightWidth: isEndRight ? 0 : 1,
          backgroundColor: color,
          width,
        },
        (isHeader || typeof value === "boolean") && { alignItems: "center" },
      ]}
    >
      {typeof value === "boolean" ? (
        value && <TickIcon />
      ) : (
        <Text style={isHeader ? styles.textHeaderTable : styles.textTable}>
          {value}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 32,
    marginTop: 32,
  },
  hrLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#D9DADC",
  },
  cards: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
  },
  card: {
    width: 237,
    height: 146,
    paddingHorizontal: 12,
    paddingVertical: 16,
    flexDirection: "column",
    alignItems: "center",
    gap: 32,
    borderRadius: 14,
  },
  labels: {
    borderRadius: 100 / 2,
    padding: 8,
    width: "100%",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: 4,
    left: 4,
  },
  textLabels: {
    fontFamily: "Archivo-Bold",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    textAlign: "center",
  },
  value: {
    width: 50,
    height: 50,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  textValue: {
    fontFamily: "Archivo-Bold",
    fontSize: 36,
    fontStyle: "normal",
    fontWeight: 600,
  },
  textHeaderTable: {
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: 15.23,
  },
  textTable: {
    fontFamily: "Archivo-SemiBold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: 22,
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: "#D9DADC",
  },
  tableCell: {
    height: 42,
    borderColor: "#D9DADC",
    paddingHorizontal: 16,
    justifyContent: "center",
  },
});
export default GeneralScreeningResult;
