import { useState } from "react";
import { ImageBackground, Pressable, Text, View } from "react-native";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";
import { CompanyProfileCarousel } from "./CompanyProfileCarousel";

const IMAGES = ["cp-checkup", "cp-abdi-paud"];

export function CompanyProfileProgramPilihan({ layout }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  const handleClick = () => {
    if (activeIndex === 0) {
      setActiveIndex(1);
    } else {
      setActiveIndex(0);
    }
  };

  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>Ikuti Program Pilihan Fammi</Text>
      <CompanyProfileCarousel
        data={IMAGES}
        activeIndex={activeIndex}
        onPrevious={handleClick}
        onNext={handleClick}
      >
        <View style={styles("images")}>
          {isMobile ? (
            <ImageBackground
              source={require(`../../assets/images/program-pilihan/${IMAGES[activeIndex]}-mobile.png`)}
              style={styles("image")}
            >
              <Pressable style={styles("button")} />
            </ImageBackground>
          ) : (
            <>
              <ImageBackground
                source={require(`../../assets/images/program-pilihan/${IMAGES[activeIndex]}.png`)}
                style={styles("image")}
              >
                <Pressable style={styles("button")} />
              </ImageBackground>

              <ImageBackground
                source={require(`../../assets/images/program-pilihan/${
                  IMAGES[activeIndex === 1 ? 0 : 1]
                }.png`)}
                style={styles("image")}
              >
                <Pressable style={styles("button")} />
              </ImageBackground>
            </>
          )}
        </View>
      </CompanyProfileCarousel>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    images: {
      flexDirection: "row",
      gap: 24,
    },
    image: {
      width: 997,
      height: 360,
      position: "relative",
    },
    button: {
      width: 212,
      height: 48,
      position: "absolute",
      bottom: 41,
      left: 48,
    },
  },
  {
    container: { gap: 32 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    images: { gap: 0 },
    image: {
      width: "100%",
      height: 196,
      objecFit: "contain",
    },
    button: {
      width: 212,
      height: 48,
      position: "absolute",
      bottom: 41,
      left: 48,
    },
  }
);
