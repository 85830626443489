import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import {
  BookBlackIcon,
  ChatIcon,
  ChevronRight,
  MessengerFillIcon
} from "../../assets/icons";

const AfterScreeningTips = () => {
  const navigation = useNavigation();

  const TIPS_MENU = [
    {
      id: "ask",
      Icon: <ChatIcon isActive width={18} height={18} />,
      label: "Tanya di Forum Diskusi",
      action: navigation.navigate("Forum")
    },
    {
      id: "consult",
      Icon: <MessengerFillIcon />,
      label: "Konsultasi ke Ahli, Tokoh & Sahabat",
      action: navigation.navigate("Forum")
    },
    {
      id: "learn",
      Icon: <BookBlackIcon fill="#6724E2" />,
      label: "Pelajari Berbagai Materi",
      action: navigation.navigate("Forum")
    }
  ];

  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.tipsLabel}>
          <Text style={styles.txTips}>Tips Setelah Screening</Text>
        </View>
        <Text style={styles.txTitle}>
          Kamu bisa pilih salah satu atau lebih solusi Fammi di bawah ini
        </Text>
      </View>
      <View style={styles.bottom}>
        {TIPS_MENU.map((t) => (
          <TouchableOpacity key={t.id} style={styles.button}>
            <View style={styles.buttonLabel}>
              {t.Icon}
              <Text style={styles.txLabel}>{t.label}</Text>
            </View>
            <ChevronRight />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 10
  },
  top: {
    padding: 16,
    alignItems: "center",
    rowGap: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea"
  },
  tipsLabel: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 20,
    backgroundColor: "#4EB826"
  },
  txTips: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#fff"
  },
  txTitle: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 15,
    textAlign: "center",
    maxWidth: 250
  },
  bottom: {
    padding: 16,
    gap: 12
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 100,
    paddingVertical: 9,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: "#eaeaea"
  },
  buttonLabel: {
    flexDirection: "row",
    alignItems: "center",
    gap: 10
  },
  txLabel: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12
  }
});

export default AfterScreeningTips;
