import AsyncStorage from "@react-native-async-storage/async-storage";
import { Fragment, useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from "react-native";
import { AppBar, EmptyState } from "../components";
import SurveyCard from "../components/Survey/SurveyCard";
import { getSurveys } from "../services/survey";
import { EditIcon } from "../assets/icons";
import { checkRespondentEligible } from "../services/screening";

const SurveyPage = ({ route }) => {
  const { params } = route;
  const [survey, setSurvey] = useState([]);
  const [memberId, setMemberId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState("");

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMemberId(memberId);
      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    if (!memberId) {
      return;
    }

    getSurveys(params?.additionalContentId, memberId, params?.isScreening === "1" ? "1" : "0")
      .then((res) => {
        const surveyData = res.data;

        checkRespondentEligible(memberId, params?.additionalContentId)
        .then((res) => {
          if (res.data[0]?.roleName) {
            setRole(res.data[0].roleName);
          }

          setSurvey(surveyData);
        });
      })
      .finally(() => setIsLoading(false));
  }, [memberId, params, params?.refresh]);

  return (
    <Fragment>
      <AppBar title={params?.isScreening === "1" ? "Screening" : "Survey"} isBack />
      {isLoading && (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#EEEAFF",
          }}
        >
          <ActivityIndicator color="#6724E2" />
        </View>
      )}
      { 
        !isLoading &&
        survey?.length > 0 &&
      (
        <ScrollView
          style={{
            flex: 1,
            padding: 16,
            backgroundColor: "#EEEAFF",
            gap: 16,
          }}
        >
          {survey.map((item) => (
            <SurveyCard key={item.quizId} data={item} additionalContentId={params?.additionalContentId} isScreening={params?.isScreening} role={role} />
          ))}
          <View style={{height: 16}}>
            <Text>
              &nbsp;
            </Text>
          </View>
        </ScrollView>
      )}
      {
        !isLoading &&
        survey?.length === 0 &&
        <ScrollView contentContainerStyle={styles.container}>
                <EmptyState
                    Icon={EditIcon}
                    title={"Kamu Belum Punya "+(params?.isScreening === "1" ? "Screening" : "Survey")}
                    desc={"Saat ini belum ada "+(params?.isScreening === "1" ? "screening" : "survey")+" diberikan di dalam program"}
                />
            </ScrollView>

      }
    </Fragment>
  );
};
const styles = StyleSheet.create({
  container: {
      flex: 1,
      padding: 16,
      backgroundColor: "#EEEAFF",
  },
})


export default SurveyPage;
