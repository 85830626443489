import React, { useEffect } from "react";
import { StyleSheet, View, Text } from "react-native";
import Modal from "react-native-modal";

function Snackbar(props) {
  const { visible, hideDialog, title } = props;
  useEffect(() => {
    setTimeout(() => {
      hideDialog();
    }, 4000);
  }, [visible]);
  return (
    <Modal
      backdropOpacity={0}
      isVisible={visible}
      style={styles.modal}
      onBackdropPress={hideDialog}
    >
      <View style={styles.parentBox}>
        <Text style={styles.txSnackbar}>{title}</Text>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  modal: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  parentBox: {
    width: "100%",
    backgroundColor: "#47464D",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
  },
  txSnackbar: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
  },
});

export default Snackbar;
