import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";
import Modal from "react-native-modal";
import { CloseIcon, TicketIcon } from "../assets/icons";
import { checkVoucher } from "../services/transactions";

function ModalVoucher(props) {
  const { visible, hideDialog, setVoucherCode, voucherCode, setIsError, setErrorMsg, t, setDiscount } = props;
  return (
    <Modal isVisible={visible} onBackdropPress={hideDialog}>
      <View style={styles.parentBox}>
        <View
          style={{
            ...styles.flexOne,
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <View style={styles.flexOne}>
            <TicketIcon />
            <Text style={styles.txTitle}>{t("popup-voucher:title")}</Text>
          </View>
          <TouchableOpacity onPress={hideDialog}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <TextInput
          placeholder={t("popup-voucher:placeholder")}
          style={styles.inputText}
          onChange={(e) => {
            setVoucherCode(e.target.value);
          }}
          onChangeText={(text) => {
            setVoucherCode(text);
          }}
        />
        <TouchableOpacity
          onPress={() => {
            checkVoucher(voucherCode)
            .then((res) => {
              if (res?.data === 0) {
                setErrorMsg("Voucher tidak ditemukan");
                return setIsError(true);
              }

              setDiscount(res?.data);

              return hideDialog();
            })
          }}
          style={styles.btnVoucher}
        >
          <Text style={styles.txVoucher}>{t("popup-voucher:button")}</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}
const styles = StyleSheet.create({
  parentBox: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
  },
  inputText: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#DEDEDE",
    borderStyle: "solid",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: 12,
    paddingLeft: 12,
    textAlign: "center",
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 12,
    lineHeight: 13,
  },
  btnVoucher: {
    marginBottom: 16,
    backgroundColor: "#6724E2",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: "100%",
    height: 36,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16,
  },
  txVoucher: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#FFFFFF",
    marginLeft: 6,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
  },

  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
    marginLeft: 6,
  },
});

export default ModalVoucher;
