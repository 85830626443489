import * as React from "react";
import Svg, { Path } from "react-native-svg";
const CompareIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.135C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.135C14.865 16.5 16.4925 14.8725 16.4925 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM13.2675 10.5375C13.2375 10.605 13.2 10.665 13.1475 10.7175L10.935 12.93C10.8225 13.0425 10.68 13.095 10.5375 13.095C10.395 13.095 10.2525 13.0425 10.14 12.93C9.9225 12.7125 9.9225 12.3525 10.14 12.135L11.3925 10.8825H5.25C4.9425 10.8825 4.6875 10.6275 4.6875 10.32C4.6875 10.0125 4.9425 9.7575 5.25 9.7575H12.75C12.825 9.7575 12.8925 9.7725 12.9675 9.8025C13.1025 9.8625 13.215 9.9675 13.275 10.11C13.3275 10.245 13.3275 10.395 13.2675 10.5375ZM12.75 8.2425H5.25C5.175 8.2425 5.1075 8.2275 5.0325 8.1975C4.8975 8.1375 4.785 8.0325 4.725 7.89C4.665 7.755 4.665 7.5975 4.725 7.4625C4.7625 7.395 4.8 7.335 4.8525 7.2825L7.065 5.07C7.2825 4.8525 7.6425 4.8525 7.86 5.07C8.0775 5.2875 8.0775 5.6475 7.86 5.865L6.6075 7.1175H12.75C13.0575 7.1175 13.3125 7.3725 13.3125 7.68C13.3125 7.9875 13.0575 8.2425 12.75 8.2425Z"
      fill={props.fill || "#292D32"}
    />
  </Svg>
);
export default CompareIcon;
