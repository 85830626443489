import Svg, { Path } from "react-native-svg";
const HeartLinearIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <Path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.465 15.608c-.255.09-.675.09-.93 0-2.175-.743-7.035-3.84-7.035-9.09 0-2.318 1.868-4.193 4.17-4.193 1.365 0 2.572.66 3.33 1.68a4.147 4.147 0 0 1 3.33-1.68c2.303 0 4.17 1.875 4.17 4.193 0 5.25-4.86 8.347-7.035 9.09Z"
    />
  </Svg>
);
export default HeartLinearIcon;
