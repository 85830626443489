import * as React from "react";
import Svg, { Path } from "react-native-svg";

const EmojiNormalIcon = (props) => (
  <Svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      opacity={0.4}
      d="M16.6631 2H8.28314C4.64314 2 2.47314 4.17 2.47314 7.81V16.18C2.47314 19.83 4.64314 22 8.28314 22H16.6531C20.2931 22 22.4631 19.83 22.4631 16.19V7.81C22.4731 4.17 20.3031 2 16.6631 2Z"
      fill="#B29600"
    />
    <Path
      d="M9.29309 9.75C8.44309 9.75 7.59309 9.43 6.94309 8.78C6.65309 8.49 6.65309 8.01 6.94309 7.72C7.23309 7.43 7.71309 7.43 8.00309 7.72C8.71309 8.43 9.87309 8.43 10.5831 7.72C10.8731 7.43 11.3531 7.43 11.6431 7.72C11.9331 8.01 11.9331 8.49 11.6431 8.78C10.9931 9.42 10.1431 9.75 9.29309 9.75Z"
      fill="#B29600"
    />
    <Path
      d="M15.6529 9.75C14.8029 9.75 13.9529 9.43 13.3029 8.78C13.0129 8.49 13.0129 8.01 13.3029 7.72C13.5929 7.43 14.0729 7.43 14.3629 7.72C15.0729 8.43 16.2329 8.43 16.9429 7.72C17.2329 7.43 17.713 7.43 18.003 7.72C18.293 8.01 18.293 8.49 18.003 8.78C17.353 9.42 16.5029 9.75 15.6529 9.75Z"
      fill="#B29600"
    />
    <Path
      d="M16.073 12.62H8.87303C8.17303 12.62 7.60303 13.19 7.60303 13.9C7.60303 16.59 9.79303 18.78 12.483 18.78C15.173 18.78 17.363 16.59 17.363 13.9C17.353 13.2 16.773 12.62 16.073 12.62Z"
      fill="#B29600"
    />
  </Svg>
);
export default EmojiNormalIcon;
