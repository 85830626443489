import { useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import CreateResponsiveStyle from "../../../Utils/createResponsiveStyle";
import { CompanyProfileCarousel } from "../CompanyProfileCarousel";
import psikologiTes from "./psikologi_tests.json";

export function CompanyProfilePsikologiTes({ layout }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [category, setCategory] = useState(psikologiTes[0]);
  const isMobile = layout.width < 500;
  const styles = responsiveStyles(layout);

  const handleNext = () => {
    const itemsLength = category.items.length;
    const newIndex = itemsLength % 4;

    if (isMobile) {
      if (activeIndex === itemsLength - 1) return setActiveIndex(0);
      setActiveIndex((prev) => prev + 1);
    } else {
      setActiveIndex(
        newIndex
          ? activeIndex !== newIndex
            ? newIndex
            : 0
          : activeIndex !== 4
          ? 4
          : 0
      );
    }
  };

  const handlePrev = () => {
    const itemsLength = category.items.length;
    const newIndex = itemsLength % 4;

    if (isMobile) {
      if (activeIndex === 0) return setActiveIndex(itemsLength - 1);
      setActiveIndex((prev) => prev - 1);
    } else {
      setActiveIndex(
        newIndex
          ? activeIndex === newIndex
            ? 0
            : newIndex
          : activeIndex === 4
          ? 0
          : 4
      );
    }
  };

  return (
    <View style={styles("container")}>
      <View style={{ gap: 16 }}>
        <Text style={styles("txTitle")}>
          Pilihan Tes Psikologi Untuk Sekolah Anda
        </Text>
        <Text style={styles("txDesc")}>
          Segera Hadir Akses Mudah Dalam Melayani Konseling Psikologi Sekolah
          Anda.
        </Text>
      </View>
      <CompanyProfileCarousel
        data={
          isMobile
            ? category.items
            : category.items.length > 4
            ? [0, category.items.length % 4 ?? 4]
            : []
        }
        activeIndex={activeIndex}
        customIndex={activeIndex === 4}
        onPrevious={handlePrev}
        onNext={handleNext}
      >
        <View style={styles("content")}>
          <View style={styles("categories")}>
            {psikologiTes.map((item) => (
              <TouchableOpacity
                key={item.id}
                onPress={() => {
                  setActiveIndex(0);
                  setCategory(item);
                }}
                style={[
                  styles("category"),
                  item.id === category.id
                    ? {
                        backgroundColor: "#6323DA",
                      }
                    : {
                        borderWidth: 1,
                        borderColor: "#6323DA",
                      },
                ]}
              >
                <Text
                  style={[
                    styles("txCategory"),
                    { color: item.id === category.id ? "#fff" : "#6323DA" },
                  ]}
                >
                  {item.label}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
          <View
            style={[
              styles("cards"),
              category.items.length < 4 && { justifyContent: "center" },
            ]}
          >
            {isMobile ? (
              <Card
                imageUrl={require(`../../../assets/images/psikologi/${category.id}/${category.items[activeIndex].id}.jpg`)}
                label={category.items[activeIndex].label}
                styles={styles}
              />
            ) : (
              category.items
                .slice(activeIndex)
                .map((item, idx) => (
                  <Card
                    key={item.id}
                    label={item.label}
                    styles={styles}
                    imageUrl={require(`../../../assets/images/psikologi/${category.id}/${item.id}.jpg`)}
                  />
                ))
            )}
          </View>
        </View>
      </CompanyProfileCarousel>
    </View>
  );
}

function Card({ imageUrl, label, styles }) {
  return (
    <View style={styles("card")}>
      <Image source={imageUrl} style={styles("cardImg")} />
      <Text style={styles("txCard")}>{label}</Text>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    txDesc: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 26,
      lineHeight: 34,
      color: "#0A0316",
      textAlign: "center",
    },
    content: { alignItems: "center", gap: 29 },
    categories: {
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
      gap: 24,
    },
    category: {
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 20,
    },
    txCategory: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
    },
    cards: {
      flexDirection: "row",
      gap: 20,
      width: "100%",
    },
    card: {
      width: 285,
      height: 309,
      padding: 16,
      gap: 24,
      borderRadius: 20,
      alignItems: "flex-start",
      backgroundColor: "#fff",
    },
    cardImg: { width: 253, height: 175, borderRadius: 10 },
    txCard: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 26,
      color: "#260C55",
    },
  },
  {
    container: { gap: 32 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 18,
      lineHeight: 34,
    },
    content: { gap: 16 },
    categories: {
      justifyContent: "center",
      gap: 12,
    },
    category: {
      paddingVertical: 12,
      paddingHorizontal: 24,
    },
    txCategory: {
      fontSize: 12,
    },
    cards: {
      flexDirection: "row",
      gap: 20,
    },
    card: {
      width: "100%",
      height: 356,
      gap: 32,
      flex: 1,
      borderRadius: 24,
    },
    cardImg: {
      width: "100%",
      height: 214,
      borderRadius: 12,
    },
    txCard: {
      paddingBottom: 26,
    },
  }
);
