import React, { useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Calendar, HeartIcon, PeoplesIcon, UserGroup } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
import Snackbar from "./SnackBar";

function ListExpert(props) {
  const navigate = useNavigation();
  const { data, type, status, t, quota, isProgram } = props;
  const [defaultImage, setDefaultImage] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);


  return (
    <>
      <View style={styles.parentList}>
        <TouchableOpacity onPress={() => {
          if (isProgram && quota === 0) {
            setShowSnackBar(true)
            return
          }
          navigate.navigate("DetailExpert", {
            speaker: data,
            type: data.speakerType,
            status: status
          });
        }}>
          <View style={styles.topSection}>
            <View style={styles.flexOne}>
              {
                defaultImage &&
                <View style={{ marginRight: 12 }}>
                  <UserGroup />
                </View>
              }
              {
                !defaultImage &&
                <Image
                  source={{
                    uri: `https://fammi.ly/uploads/speakers/images/${data?.photo}`,
                  }}
                  style={styles.avatar}
                  onError={() => setDefaultImage(true)}
                />
              }
              <View style={{ width: "85%" }}>
                <Text ellipsizeMode="tail" numberOfLines={1} style={{ ...styles.textName, width: "100%" }}>
                  {data?.name}
                </Text>
                <Text style={styles.textSpan}>{data?.description}</Text>
              </View>
            </View>
            <View style={styles.labelStatus}>
              <View style={styles.indicator} />
              <Text style={styles.textLabel}>ON</Text>
            </View>
          </View>
          <View style={styles.midSection}>
            <Text style={styles.textMain}>
              {data?.speciality}
            </Text>
          </View>
          <View style={styles.topSection}>
            <View style={styles.flexOne}>
              <View style={{ ...styles.flexOne, marginRight: 10 }}>
                <HeartIcon />
                <Text style={{ ...styles.textMain, marginLeft: 6 }}>{Math.ceil(data?.rate * 100 / 5)}%</Text>
              </View>
              <View style={styles.flexOne}>
                <PeoplesIcon />
                <Text style={{ ...styles.textMain, marginLeft: 6 }}>
                  {data?.helped} {t("common:helped")}
                </Text>
              </View>
              {
                data?.isAvailable === 1 &&
                <View style={{...styles.flexOne, marginLeft: 10, gap: 1}}>
                  <Calendar customColor={"#4EB826"} />
                  <Text style={{ ...styles.textMain, marginLeft: 4, color: "#4EB826" }}>
                    {t("common:today")}
                  </Text>
                </View>
              }
            </View>
            <TouchableOpacity
              onPress={() => {
                if (isProgram && quota === 0) {
                  setShowSnackBar(true)
                  return
                }
                navigate.navigate("DetailExpert", {
                  speaker: data,
                  type: data.speakerType,
                  status: status
                });
              }}
            >
              <Text style={styles.textAction}>{t("choose-expert:choose")}</Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
      <Snackbar
        visible={showSnackBar}
        hideDialog={() => {
          setShowSnackBar(false);
        }}
        title="Kuota anda tidak mencukupi!"
      />
    </>
  );
}
const styles = StyleSheet.create({
  parentList: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginBottom: 16,
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
  },
  avatar: {
    marginRight: 12,
    flexShrink: 0,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  flexOne: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%"
  },
  textName: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
    color: "#000000",
  },
  textSpan: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    color: "#999999",
  },
  labelStatus: {
    display: "none",
    backgroundColor: "#4EB826",
    borderRadius: 25 / 2,
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 6,
    paddingRight: 6,
    paddingBottom: 6,
    paddingLeft: 6,
  },
  indicator: {
    backgroundColor: "#ECF8E6",
    height: 6,
    width: 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    marginRight: 4,
  },
  textLabel: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#ECF8E6",
  },
  midSection: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EAEAEA",
    borderStyle: "solid",
  },
  textMain: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
  },
  textAction: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
    color: "#6724E2",
  },
});

export default ListExpert;
