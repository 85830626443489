import Svg, { Path } from "react-native-svg";
const RefreshIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      fill="#000"
      d="M12.143 1.5H5.857C3.127 1.5 1.5 3.127 1.5 5.857v6.278c0 2.738 1.627 4.365 4.357 4.365h6.278c2.73 0 4.357-1.627 4.357-4.357V5.857c.008-2.73-1.62-4.357-4.35-4.357ZM9 13.508a4.318 4.318 0 0 1-4.313-4.313A4.318 4.318 0 0 1 9 4.882c.105 0 .217.016.322.023l-.21-.24a.567.567 0 0 1 .053-.795.567.567 0 0 1 .795.052l1.252 1.433c.008.007.008.015.015.022.008.008.015.008.015.016.015.022.023.052.038.082.015.038.038.068.045.105.008.037.008.067.015.105 0 .038.008.068.008.105 0 .037-.016.067-.03.105-.015.038-.023.075-.038.105s-.045.06-.067.09c-.023.022-.03.053-.053.067-.008.008-.015.008-.023.016-.007.007-.007.014-.014.014L9.668 7.253a.54.54 0 0 1-.33.104.576.576 0 0 1-.458-.232.57.57 0 0 1 .12-.787l.42-.308c-.143-.008-.277-.022-.42-.022a3.195 3.195 0 0 0-3.188 3.187A3.195 3.195 0 0 0 9 12.383a3.195 3.195 0 0 0 2.655-4.958.559.559 0 0 1 .158-.78.56.56 0 0 1 .78.158c.472.712.727 1.537.727 2.392A4.324 4.324 0 0 1 9 13.508Z"
    />
  </Svg>
);
export default RefreshIcon;
