import { Image } from "react-native";
import { Text, View } from "react-native-web";
import CreateResponsiveStyle from "../../Utils/createResponsiveStyle";

export function ProgramAntiBullyingWhy({ layout }) {
  const styles = responsiveStyles(layout);
  return (
    <View style={styles("container")}>
      <Text style={styles("txTitle")}>Mengapa Memilih Fammi?</Text>
      <View style={styles("content")}>
        <WhyCard
          title="Waktu Tes Fleksibel"
          desc="Cukup 10-15 menit, dapat dilakukan di sekolah atau di rumah"
          icon="calendar-tick.svg"
          styles={styles}
        />
        <WhyCard
          title="Didampingi Ahli"
          desc="Tes psikologi disusun, diskoring & dianalisis oleh tim psikolog Fammi berpengalaman"
          icon="user-tick.svg"
          styles={styles}
        />
        <WhyCard
          title="Bayar Sesuai Kemampuan"
          desc="Biaya terjangkau disesuaikan dengan kemampuan sekolah"
          icon="empty-wallet-tick.svg"
          styles={styles}
        />
      </View>
    </View>
  );
}

function WhyCard({ title, desc, icon, styles }) {
  return (
    <View style={styles("card")}>
      <Image
        source={require(`../../assets/${icon}`)}
        style={styles("cardIcon")}
      />
      <View style={styles("cardContent")}>
        <Text style={styles("txTitleCard")}>{title}</Text>
        <Text style={styles("txDescCard")}>{desc}</Text>
      </View>
    </View>
  );
}

const responsiveStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      gap: 48,
    },
    txTitle: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 42,
      color: "#260C55",
      textAlign: "center",
    },
    content: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    card: {
      width: 387,
      height: 300,
      borderRadius: 40,
      padding: 24,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 48,
      backgroundColor: "#fff",
      overflow: "hidden",
    },
    cardIcon: {
      width: 94,
      height: 94,
    },
    cardContent: {
      gap: 24,
      maxWidth: 339,
      justifyContent: "center",
    },
    txTitleCard: {
      fontFamily: "Archivo-SemiBold",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 34,
      color: "#0A0316",
      textAlign: "center",
    },
    txDescCard: {
      fontFamily: "Archivo-Regular",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 26,
      color: "#0A0316",
      textAlign: "center",
    },
  },
  {
    container: { gap: 32 },
    header: { gap: 12 },
    txTitle: {
      fontSize: 28,
      lineHeight: 34,
    },
    txDesc: {
      fontSize: 18,
      lineHeight: 34,
    },
    content: {},
  }
);
