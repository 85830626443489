import Svg, { Path } from "react-native-svg";
const Like1Icon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <Path
      fill={props?.customColor ?? "#000"}
      stroke={props?.customStrokeColor ?? props?.customColor ?? "#000"}
	  strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.293 13.867v-7.62c0-.3.09-.592.255-.84l2.047-3.045c.322-.487 1.125-.832 1.807-.577.736.247 1.223 1.072 1.066 1.807l-.39 2.453c-.03.225.03.427.157.585a.698.698 0 0 0 .518.232h3.082c.592 0 1.102.24 1.402.66.286.405.338.93.15 1.463l-1.845 5.617c-.232.93-1.245 1.688-2.25 1.688H9.367c-.502 0-1.207-.173-1.53-.495l-.96-.743a1.489 1.489 0 0 1-.584-1.185ZM3.908 4.784h-.773c-1.162 0-1.635.45-1.635 1.56v7.545c0 1.11.472 1.56 1.635 1.56h.773c1.162 0 1.635-.45 1.635-1.56V6.344c0-1.11-.473-1.56-1.635-1.56Z"
    />
  </Svg>
);
export default Like1Icon;
