import { Text, View, StyleSheet, ScrollView, Linking, TouchableOpacity, Pressable } from "react-native";
import {
  AppBar,
  BottomNavigation,
  Container,
  MenuProfile,
  ProfileInformation,
} from "../components";
import {
  ArchiveIcon,
  ChevronRight,
  CloseIcon,
  DocumentIcon,
  LangIcon,
  LogoutIcon,
  MailIcon,
  ShieldIcon,
  SubscribeIcon,
  VerifyIcon,
  WaIcon,
} from "../assets/icons";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getSpeaker } from "../services/speakers";
import { getAdditionalMember } from "../services/login";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import i18n from "../translation/index";
import Modal from "react-native-modal";

function Profile() {
  const [profile, setProfile] = useState({});
  const navigation = useNavigation();
  const { t } = useTranslation("profile");
  const [modalSpeakerVisible, setModalSpeakerVisible] = useState(false);

  useEffect(() => {
    async function getMode() {
      const mode = await AsyncStorage.getItem("792f3e7");
      const token = await AsyncStorage.getItem("token");
      const phoneNumber = await AsyncStorage.getItem("592f3e1");
      const name = await AsyncStorage.getItem("592f3e7");

      const speakerId = await AsyncStorage.getItem("792f3e1");
      if (!speakerId) {
        return {
          name,
          mode,
          token,
          phoneNumber
        }
      }

      return getSpeaker(speakerId)
      .then((r) => {
        const res = {
          name,
          mode,
          token,
          phoneNumber,
          speakerType: r?.data[0].speakerType
        }
        return res;
      });
    }
    getMode()
    .then((res) => {
      setProfile(res);
    });
  }, []);
  return (
    <>
      <AppBar title="Profile" />
      <ScrollView>
        <Container>
          <ProfileInformation profile={profile} t={t} />
          {/* <MenuProfile
            list={[
              {
                title: "Langganan",
                icon: SubscribeIcon,
                customRight: (
                  <View style={styles.badge}>
                    <Text style={styles.textBadge}>Segera</Text>
                  </View>
                ),
              },
              { title: "Verifikasi Akun", icon: VerifyIcon },
              { title: "Tandai Obrolan", icon: ArchiveIcon },
            ]}
          /> */}
          <MenuProfile
            list={[
              {
                title: t("lang"),
                icon: LangIcon,
                onPress: () => {
                  setModalSpeakerVisible(true);
                }
              },
              {
                title: t("email"),
                icon: MailIcon,
                onPress: () => {
                  Linking.openURL("mailto:care@fammi.ly");
                  return;
                }
              },
              // { title: "Hubungi Whatsapp", icon: WaIcon },
              { title: t("privacy"), icon: ShieldIcon, onPress: () => {
                  navigation.navigate("Condition");
                  return;
              } },
              { title: t("service"), icon: DocumentIcon, onPress: () => {
                  navigation.navigate("Terms");
                  return;
              } },
              { title: t("quit"), icon: LogoutIcon, hideRight: true, onPress: () => {
                  AsyncStorage.getAllKeys()
                  .then(keys => AsyncStorage.multiRemove(keys))
                  .then(() => navigation.navigate("Login"));
                  return;
              } },
            ]}
          />
        </Container>
        <View style={{ marginBottom: 10 }} />
      </ScrollView>
      <Modal isVisible={modalSpeakerVisible} onBackdropPress={() => {
        setModalSpeakerVisible(false);
      }}>
          <View style={{width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
            }}>
            <View style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 16,
              paddingLeft: 12,
              paddingRight: 12
            }}>
              <View style={{
                flexDirection: "row", 
                alignItems: "center",
                alignItems: "center",
                gap: 6,
                fontFamily: "Archivo-Bold",
                fontSize: 14,
                fontWeight: 700
              }}>
                {/* <HeartIcon /> */}
                <Text>{t("profile:choose")}</Text>
              </View>
              <Pressable onPress={() => {
                setModalSpeakerVisible(false);
              }}>
                <CloseIcon/>
              </Pressable>
            </View>
            <View style={{borderBottomWidth: 1, borderBottomColor: "#EAEAEA"}}>

            </View>
            <View style={{
              padding: 16,
              paddinLeft: 12,
            }}>
                  <TouchableOpacity onPress={() => {
                    AsyncStorage.setItem("lang", "id");
                    i18n.changeLanguage("id");
                  }}>
                    <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                          <Text style={{fontFamily: "Archivo-Regular", fontWeight: "600", fontSize: 12}}>
                            Bahasa
                          </Text>
                          <ChevronRight/>
                      </View>
                  </TouchableOpacity>
            </View>
            <View style={{borderBottomWidth: 1, borderBottomColor: "#EAEAEA"}}>

            </View>
            <View style={{
              padding: 16,
              paddinLeft: 12,
            }}>
                  <TouchableOpacity onPress={() => {
                    AsyncStorage.setItem("lang", "en");
                    i18n.changeLanguage("en");
                  }}>
                    <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                          <Text style={{fontFamily: "Archivo-Regular", fontWeight: "600", fontSize: 12}}>
                            English
                          </Text>
                          <ChevronRight/>
                      </View>
                  </TouchableOpacity>
            </View>
          </View>
        </Modal>
      <BottomNavigation activeIndex={3} />
    </>
  );
}
const styles = StyleSheet.create({
  badge: {
    backgroundColor: "#FF0000",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
  },
  textBadge: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#FFFFFF",
  },
});
export default Profile;
