import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const ProfileTickCircleIcon = (props) => (
  <Svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={20} cy={20} r={20} fill="#DCF4CA" />
    <Path
      d="M20 10C17.38 10 15.25 12.13 15.25 14.75C15.25 17.32 17.26 19.4 19.88 19.49C19.96 19.48 20.04 19.48 20.1 19.49C20.12 19.49 20.13 19.49 20.15 19.49C20.16 19.49 20.16 19.49 20.17 19.49C22.73 19.4 24.74 17.32 24.75 14.75C24.75 12.13 22.62 10 20 10Z"
      fill="#4EB826"
    />
    <Path
      d="M25.08 22.1606C22.29 20.3006 17.74 20.3006 14.93 22.1606C13.66 23.0006 12.96 24.1506 12.96 25.3806C12.96 26.6106 13.66 27.7506 14.92 28.5906C16.32 29.5306 18.16 30.0006 20 30.0006C21.84 30.0006 23.68 29.5306 25.08 28.5906C26.34 27.7406 27.04 26.6006 27.04 25.3606C27.03 24.1406 26.34 22.9906 25.08 22.1606ZM22.33 24.5606L19.81 27.0806C19.69 27.2006 19.53 27.2606 19.37 27.2606C19.21 27.2606 19.05 27.1906 18.93 27.0806L17.67 25.8206C17.43 25.5806 17.43 25.1806 17.67 24.9406C17.91 24.7006 18.31 24.7006 18.55 24.9406L19.37 25.7606L21.45 23.6806C21.69 23.4406 22.09 23.4406 22.33 23.6806C22.58 23.9206 22.58 24.3206 22.33 24.5606Z"
      fill="#4EB826"
    />
  </Svg>
);
export default ProfileTickCircleIcon;
