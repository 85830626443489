import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ProfileTickIcon = (props) => (
  <Svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12.5332 15.8751L13.7999 17.1417L16.3332 14.6084"
      stroke={props.stroke ?? "#999999"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.6337 9.05817C10.5503 9.04984 10.4503 9.04984 10.3587 9.05817C8.37532 8.9915 6.80032 7.3665 6.80032 5.3665C6.79199 3.32484 8.45032 1.6665 10.492 1.6665C12.5337 1.6665 14.192 3.32484 14.192 5.3665C14.192 7.3665 12.6087 8.9915 10.6337 9.05817Z"
      stroke={props.stroke ?? "#999999"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.4915 18.1748C8.97479 18.1748 7.46646 17.7915 6.31646 17.0248C4.29979 15.6748 4.29979 13.4748 6.31646 12.1332C8.60812 10.5998 12.3665 10.5998 14.6581 12.1332"
      stroke={props.stroke ?? "#999999"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ProfileTickIcon;
