import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";
import Fammi from "../assets/icons/Fammi";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setProgram } from "../services/programs";

function HeaderJoin(props) {
  const {setLoadProgram, token, setSnackBarMsg, setShowSnackBar} = props;
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setCode("");
  }, []);

  const onSubmit = () => {
    setIsError(false);
    if (code === "") {
      setIsError(true);
      setSnackBarMsg("Ketik kode program terlebih dahulu");
      setShowSnackBar(true);
      return;
    }
    
    setProgram(code, 13, token).then((res) => {
      if (res?.data === true) {
        setLoadProgram(true);
        setTimeout(() => {
          setLoadProgram(false);
        }, 500);
        return;
      } else {
        setIsError(true);
        setSnackBarMsg("Kode program tidak valid. Silahkan cek kembali");
        setShowSnackBar(true);
      }
    });
  };

  useEffect(() => {
    async function getName() {
      return await AsyncStorage.getItem("592f3e7");
    }
    getName().then((res) => {
      setName(res);
    });
  }, []);

  return (
    <View style={styles.headerJoin}>
      <Fammi />
      <View>
        <Text style={styles.greetingText}>Halo {name},</Text>
        <Text
          style={{ ...styles.greetingText, marginTop: 0, fontWeight: "700" }}
        >
          Punya kode program? Ketik di bawah ini ya!
        </Text>
        <View style={{...styles.parentInput, backgroundColor: isError ? "#FFDCDC": "#FFF"}}>
          <TextInput 
            style={{...styles.input}} 
            placeholder="Ketik kode program di sini ..." 
            placeholderTextColor={isError ? "#F00" : "#999" }
            onChangeText={(text) => {
              setCode(text);
            }}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <TouchableOpacity style={styles.btn} onPress={() => {
            onSubmit();
          }}>
            <Text style={styles.txBtn}>Gabung Program</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

export default HeaderJoin;

const styles = StyleSheet.create({
  headerJoin: {
    width: "100%",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 23,
    borderBottomLeftRadius: 23,
    backgroundColor: "#6724E2",
    padding: 16,
  },
  greetingText: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 18,
    marginTop: 24,
    color: "#fff",
  },
  parentInput: {
    backgroundColor: "#fff",
    borderRadius: 100 / 2,
    padding: 5,
    paddingLeft: 10,
    flexDirection: "row",
    backgroundColor: "#FFF",
    marginTop: 16,
    justifyContent: "space-between",
  },
  input: {
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    width: "60%",
  },
  btn: {
    paddingTop: 10,
    paddingRight: 16,
    paddingBottom: 10,
    paddingLeft: 16,
    backgroundColor: "#6724E2",
    borderRadius: 100 / 2,
  },
  txBtn: {
    color: "#FFF",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
  },
});
