import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import {
  EmojiHappyIcon,
  HeartTickIcon,
  MessengerFillIcon,
  TickSquareIcon
} from "../../assets/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const TipsList = ({ Icon, title, desc }) => {
  return (
    <View style={styles.tips}>
      <View style={styles.tipsIcon}>
        <Icon fill="#6724E2" width={18} height={18} />
      </View>
      <View>
        <Text style={styles.txTipsTitle}>{title}</Text>
        <Text style={styles.txTipsDesc}>{desc}</Text>
      </View>
    </View>
  );
};

const TipsContent = () => {
  const { t } = useTranslation();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.tipsLabel}>
          <Text style={styles.txTips}>{t("popup-tips:subtitle")}</Text>
        </View>
        <Text style={styles.txTitle}>{t("popup-tips:title")}</Text>
      </View>
      <View style={styles.lists}>
        <TipsList
          Icon={MessengerFillIcon}
          title={t("popup-tips:tips-title1")}
          desc={t("popup-tips:tips-desc1")}
        />
        <TipsList
          Icon={HeartTickIcon}
          title={t("popup-tips:tips-title2")}
          desc={t("popup-tips:tips-desc2")}
        />
        <TipsList
          Icon={EmojiHappyIcon}
          title={t("popup-tips:tips-title3")}
          desc={t("popup-tips:tips-desc3")}
        />
      </View>
      <View style={styles.bottom}>
        <TouchableOpacity
          onPress={async () => {
            setDontShowAgain(!dontShowAgain);
            await AsyncStorage.setItem("dontshow", "true");
          }}
        >
          <TickSquareIcon color={dontShowAgain ? "#6724E2" : "#999"} />
        </TouchableOpacity>
        <Text style={styles.txTipsDesc}>{t("popup-tips:check-label")}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  top: {
    padding: 16,
    alignItems: "center",
    rowGap: 12
  },
  tipsLabel: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 20,
    backgroundColor: "#4EB826"
  },
  txTips: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#fff"
  },
  txTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 15,
    textAlign: "center",
    maxWidth: 230
  },
  lists: {
    paddingVertical: 16,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderBottomColor: "#eaeaea",
    borderTopColor: "#eaeaea",
    rowGap: 24
  },
  tips: {
    flexDirection: "row",
    alignItems: "flex-start",
    columnGap: 12
  },
  tipsIcon: {
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: "#E2DCFC",
    padding: 8
  },
  txTipsTitle: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#6724E2",
    marginBottom: 4
  },
  txTipsDesc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    maxWidth: 260
  },
  bottom: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 6,
    paddingTop: 12,
    paddingHorizontal: 12
  }
});

export default TipsContent;
