import React from "react";
import { Image, StyleSheet, View, Text } from "react-native";

function ListDetail(props) {
  const { item } = props;
  const descs = item.description.filter((i) => i.name === "hasil_test");
  return (
    <View
      style={styles.parentDetail}
    >
      <Image
        source={item.image}
        style={styles.img}
      />
      <View style={styles.mainContent}>
        <View style={[styles.labels, { backgroundColor: item.secondColor }]}>
          <Text style={[styles.textLabel, {color: item.color}]}>{item.title}</Text>
        </View>
        <Text style={styles.textMain}>
          {
            descs.length > 0 &&
            descs[0].desc
          }
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  parentDetail: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 24,
  },
  img: {
    width: "25%",
    height: 213,
    borderRadius: 14,
    marginRight: -32,
  },
  mainContent: {
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderStyle: "solid",
    borderRadius: 14,
    paddingHorizontal: 32,
    paddingVertical: 16,
    backgroundColor: "#fff",
    width: "80%",
    zIndex: 100,
    height: 213,
  },
  labels: {
    backgroundColor: "#DCF4CA",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 100 / 2,
    alignSelf: "flex-start",
    marginBottom: 16,
  },
  textLabel: {
    color: "#3E951D",
    fontFamily: "Archivo-Regular",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
  },
  textMain: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
	lineHeight: 22,
  },
});
export default ListDetail;
