import Svg, { Path } from "react-native-svg";
const Heart2Icon = ({ customColor }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <Path
      fill={customColor ?? "#999"}
      d="M12.33 2.326c-1.357 0-2.572.66-3.33 1.673a4.162 4.162 0 0 0-3.33-1.673c-2.302 0-4.17 1.875-4.17 4.193 0 .892.143 1.717.39 2.482 1.185 3.75 4.838 5.993 6.645 6.608.255.09.675.09.93 0 1.808-.615 5.46-2.858 6.645-6.608.248-.765.39-1.59.39-2.482 0-2.318-1.867-4.193-4.17-4.193Z"
    />
  </Svg>
);
export default Heart2Icon;
