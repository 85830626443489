import Svg, { Circle, Path } from "react-native-svg";
const BriefCaseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <Circle cx={14} cy={14} r={14} fill="#FDFDFD" />
    <Path
      fill="#834FE3"
      d="M20.817 10.236c-.637-.705-1.702-1.058-3.247-1.058h-.18v-.03c0-1.26 0-2.82-2.82-2.82h-1.14c-2.82 0-2.82 1.568-2.82 2.82v.038h-.18c-1.553 0-2.61.352-3.248 1.057-.742.825-.72 1.935-.645 2.693l.008.052.052.55c.014.15.095.286.221.368.182.119.447.288.612.38a12.874 12.874 0 0 0 4.455 1.598c.067.704.375 1.53 2.017 1.53 1.643 0 1.965-.818 2.018-1.546 1.53-.247 3.007-.78 4.342-1.56.045-.022.075-.045.113-.067.282-.16.574-.354.843-.547a.491.491 0 0 0 .2-.346l.007-.052.037-.353c.008-.045.008-.082.015-.135.06-.757.045-1.792-.66-2.572Zm-6 5.137c0 .795 0 .915-.922.915-.923 0-.923-.142-.923-.907v-.945h1.845v.937Zm-3.135-6.195v-.03c0-1.275 0-1.747 1.748-1.747h1.14c1.747 0 1.747.48 1.747 1.747v.038h-4.635v-.008Z"
    />
    <Path
      fill="#834FE3"
      d="M20.454 15.397a.51.51 0 0 1 .726.504l-.25 2.742c-.157 1.5-.772 3.03-4.072 3.03h-5.715c-3.3 0-3.915-1.53-4.072-3.022l-.236-2.593a.51.51 0 0 1 .716-.508c.862.385 2.235.972 3.14 1.22a.601.601 0 0 1 .374.315c.468.934 1.443 1.43 2.838 1.43 1.382 0 2.37-.515 2.839-1.452a.603.603 0 0 1 .373-.316c.964-.265 2.433-.924 3.34-1.35Z"
    />
  </Svg>
);
export default BriefCaseIcon;
