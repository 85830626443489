import Svg, { Path } from "react-native-svg";
const ArchiveTick = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
    <Path
      fill="#000"
      d="M15.418 1.751H6.581a3.552 3.552 0 0 0-3.538 3.538v12.916c0 1.65 1.182 2.347 2.63 1.55l4.474-2.485c.477-.266 1.247-.266 1.714 0l4.474 2.484c1.448.807 2.63.11 2.63-1.549V5.29a3.56 3.56 0 0 0-3.547-3.538Zm-1.1 6.527-3.667 3.666a.68.68 0 0 1-.486.202.68.68 0 0 1-.485-.202L8.305 10.57a.691.691 0 0 1 0-.971.692.692 0 0 1 .971 0l.89.889 3.18-3.181a.692.692 0 0 1 .972 0 .692.692 0 0 1 0 .972Z"
    />
  </Svg>
);
export default ArchiveTick;
