import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const PlayCircleIcon = (props) => (
  <Svg
    width={72}
    height={72}
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={36} cy={36} r={36} fill="white" fillOpacity={0.6} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6694 28.7597C29.6962 28.5326 29.7757 28.3148 29.9016 28.1238C30.0274 27.9328 30.1961 27.7738 30.3942 27.6595C30.5923 27.5452 30.8144 27.4787 31.0427 27.4654C31.2711 27.4521 31.4994 27.4922 31.7094 27.5827C32.7714 28.0367 35.1514 29.1157 38.1714 30.8587C41.1924 32.6027 43.3174 34.1257 44.2404 34.8167C45.0284 35.4077 45.0304 36.5797 44.2414 37.1727C43.3274 37.8597 41.2284 39.3627 38.1714 41.1287C35.1114 42.8947 32.7594 43.9607 31.7074 44.4087C30.8014 44.7957 29.7874 44.2087 29.6694 43.2317C29.5314 42.0897 29.2734 39.4967 29.2734 35.9947C29.2734 32.4947 29.5304 29.9027 29.6694 28.7597Z"
      fill="#6323DA"
    />
  </Svg>
);
export default PlayCircleIcon;
