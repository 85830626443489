import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const PsikologResultTestIcon = (props) => (
  <Svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Circle cx={24} cy={24} r={24} fill="#EAEAEA" />
    <Path
      d="M27.6667 14.8333H20.3333C17.125 14.8333 15.75 16.6667 15.75 19.4167V28.5833C15.75 31.3333 17.125 33.1667 20.3333 33.1667H27.6667C30.875 33.1667 32.25 31.3333 32.25 28.5833V19.4167C32.25 16.6667 30.875 14.8333 27.6667 14.8333ZM20.3333 24.2292H24C24.3758 24.2292 24.6875 24.5408 24.6875 24.9167C24.6875 25.2925 24.3758 25.6042 24 25.6042H20.3333C19.9575 25.6042 19.6458 25.2925 19.6458 24.9167C19.6458 24.5408 19.9575 24.2292 20.3333 24.2292ZM27.6667 29.2708H20.3333C19.9575 29.2708 19.6458 28.9592 19.6458 28.5833C19.6458 28.2075 19.9575 27.8958 20.3333 27.8958H27.6667C28.0425 27.8958 28.3542 28.2075 28.3542 28.5833C28.3542 28.9592 28.0425 29.2708 27.6667 29.2708ZM29.9583 21.4792H28.125C26.7317 21.4792 25.6042 20.3517 25.6042 18.9583V17.125C25.6042 16.7492 25.9158 16.4375 26.2917 16.4375C26.6675 16.4375 26.9792 16.7492 26.9792 17.125V18.9583C26.9792 19.5908 27.4925 20.1042 28.125 20.1042H29.9583C30.3342 20.1042 30.6458 20.4158 30.6458 20.7917C30.6458 21.1675 30.3342 21.4792 29.9583 21.4792Z"
      fill="black"
    />
  </Svg>
);
export default PsikologResultTestIcon;
