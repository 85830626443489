import { useNavigation } from "@react-navigation/native";
import { APP_SPECIAL_PACKAGE } from "@env";
import { Image, StyleSheet, View, Text, TouchableOpacity } from "react-native";

function CardTest(props) {
  const {data} = props;
  const navigation = useNavigation();

  return (
    <View style={styles.parentCard}>
      <Image
        source={{ uri: `https://fammi.ly/uploads/quiz/images/${data?.cover}` }}
        style={styles.cardImage}
      />
      <View style={styles.main}>
        <Text style={styles.txTitle} numberOfLines={2}>
          {data?.title}
        </Text>
        <Text style={styles.txSpan}>GRATIS</Text>
        <TouchableOpacity 
          style={{...styles.btn, backgroundColor: data.isAnswered === 1 ? "#DCF4CA" : "#DFD8FF" }}
          onPress={() => {
            if (data?.isAnswered === 1) {
              return navigation.navigate(
                data?.isScreening2 === "0" ? "SurveyResult" : "ScreeningResult",
                { quizId: data?.quizId, additionalContentId:APP_SPECIAL_PACKAGE, isScreening: data?.isScreening2, back: "Home"}
              );
            }

            navigation.navigate("SurveyDetail", {
              additionalContentId: APP_SPECIAL_PACKAGE,
              isScreening: data?.isScreening2,
              quizId: data?.quizId,
              back: "Home"
            });
          }}
        >
          <Text style={{
            ...styles.txBtn,
            color: data.isAnswered === 1 ? "#4EB826" : "#6724E2"
            }}>
            {
              data?.isAnswered === 1 ? "Lihat Hasil" : "Ikuti Test"
            }
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentCard: {
    width: 148,
    backgroundColor: "#fff",
    borderRadius: 10,
    marginRight: 16,
  },
  cardImage: {
    borderRadius: 10,
    height: 100,
    width: "100%",
  },
  main: {
    padding: 12,
  },
  txTitle: {
    color: "#000",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 14,
    height: 28,
  },
  txSpan: {
    color: "#999",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    marginTop: 8,
  },
  btn: {
    marginTop: 12,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#DFD8FF",
  },
  txBtn: {
    color: "#6724E2",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
  },
});
export default CardTest;
