import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ReceiptIcon = (props) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M11.835 1.5H6.165C3.33 1.5 2.625 2.2575 2.625 5.28V13.725C2.625 15.72 3.72 16.1925 5.0475 14.7675L5.055 14.76C5.67 14.1075 6.6075 14.16 7.14 14.8725L7.8975 15.885C8.505 16.6875 9.4875 16.6875 10.095 15.885L10.8525 14.8725C11.3925 14.1525 12.33 14.1 12.945 14.76C14.28 16.185 15.3675 15.7125 15.3675 13.7175V5.28C15.375 2.2575 14.67 1.5 11.835 1.5ZM11.25 8.8125H6.75C6.4425 8.8125 6.1875 8.5575 6.1875 8.25C6.1875 7.9425 6.4425 7.6875 6.75 7.6875H11.25C11.5575 7.6875 11.8125 7.9425 11.8125 8.25C11.8125 8.5575 11.5575 8.8125 11.25 8.8125ZM12 5.8125H6C5.6925 5.8125 5.4375 5.5575 5.4375 5.25C5.4375 4.9425 5.6925 4.6875 6 4.6875H12C12.3075 4.6875 12.5625 4.9425 12.5625 5.25C12.5625 5.5575 12.3075 5.8125 12 5.8125Z"
      fill="white"
    />
  </Svg>
);
export default ReceiptIcon;
