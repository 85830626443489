import React from "react";
import { StyleSheet, View, Text } from "react-native";

function TableDepartment(props) {
  const { lists } = props;
  return (
    <View style={styles.parentTable}>
      {lists.map((item, index) => (
        <View
          key={index}
          style={[
            styles.itemTable,
            index == 0 && {
              borderRightWidth: 1,
              borderRightColor: "#D9DADC",
              borderStyle: "solid",
            },
          ]}
        >
          <View
            style={[
              styles.headerTable,
              index == 0 && {
                borderTopLeftRadius: 14,
                borderStyle: "solid",
                borderBottomColor: "#D9DADC",
                borderBottomWidth: 1,
              },
              index == 1 && {
                borderTopRightRadius: 14,
                borderStyle: "solid",
                borderBottomColor: "#D9DADC",
                borderBottomWidth: 1,
              },
              {
                backgroundColor: item.color,
              },
            ]}
          >
            <Text style={styles.textHeaderTable}>
              Pilihan Jurusan {index + 1}
            </Text>
          </View>
          <View
            style={[
              styles.bodyTable,
              index == 0 && {
                borderBottomLeftRadius: 14,
              },
              index == 1 && {
                borderBottomRightRadius: 14,
              },
            ]}
          >
            <Text style={styles.textBodyTable}>{item.title}</Text>
          </View>
        </View>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  parentTable: {
    width: "100%",
    borderRadius: 14,
    borderColor: "#D9DADC",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 24,
    flexDirection: "row",
  },
  itemTable: {
    width: "50%",
  },
  headerTable: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  textHeaderTable: {
    color: "#0A0316",
    fontFamily: "Archivo-SemiBold",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "500",
    textAlign: "center",
  },
  textBodyTableTitle: {
    color: "#0A0316",
    fontFamily: "Archivo-SemiBold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "500",
  },
  bodyTable: {
    padding: 16,
    backgroundColor: "#FCFEFB",
  },
  textBodyTable: {
    color: "#0A0316",
    fontFamily: "Archivo-Bold",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "500",
    textAlign: "center",
  },
});
export default TableDepartment;
