import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native";

function DevelopSuggestion(props) {
  const { descriptions } = props;
  const [filteredList, setFilteredList] = useState([]);
  const [heights, setHeights] = React.useState([]);
  const tableSuggest = [
    {
      id: 1,
      title: "Kecerdasan Naturalis",
      key: "kecerdasan naturalis",
      description: "Kemampuan naturalistik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan naturalis"],
      color: "#4EB826",
      secondColor: "#DCF4CA",
    },
    {
      id: 2,
      title: "Kecerdasan Musikal",
      key: "kecerdasan musikal",
      description: "Kecerdasan musikal Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan musikal"],
      color: "#6724E2",
      secondColor: "#ECE4FB",
    },
    {
      id: 3,
      key: "kecerdasan kinestetik",
      title: "Kecerdasan Kinestetik",
      description: "Kemampuan kinestetik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan kinestetik"],
      color: "#305C92",
      secondColor: "#D1DFF0",
    },
    {
      id: 4,
      key: "kecerdasan verbal",
      title: "Kecerdasan Verbal",
      description: "Kemampuan kinestetik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan verbal"],
      color: "#034932",
      secondColor: "#A6D9C8",
    },
    {
      id: 5,
      key: "kecerdasan logika",
      title: "Kecerdasan Logika",
      description: "Kemampuan kinestetik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan logika"],
      color: "#FD681C",
      secondColor: "#FEDECD",
    },
    {
      id: 6,
      key: "kecerdasan spasial",
      title: "Kecerdasan Spasial",
      description: "Kemampuan kinestetik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan spasial"],
      color: "#AE1919",
      secondColor: "#F9D2D2",
    },
    {
      id: 7,
      key: "kecerdasan interpersonal",
      title: "Kecerdasan Interpersonal",
      description: "Kemampuan kinestetik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan interpersonal"],
      color: "#00314D",
      secondColor: "#B2E3FF",
    },
    {
      id: 8,
      key: "kecerdasan intrapersonal",
      title: "Kecerdasan Intrapersonal",
      description: "Kemampuan kinestetik Ananda bisa diasah dengan cara:",
      lists: descriptions["kecerdasan intrapersonal"],
      color: "#665600",
      secondColor: "#FFEB80",
    },
  ];
  const highest = Math.max(...heights);
  useEffect(() => {
    if (!descriptions) {
      return;
    }

    const filteredDescriptions = tableSuggest?.filter((d) => {
      return Object.keys(descriptions).indexOf(d.key) > -1;
    });
    console.log(filteredDescriptions);
    setFilteredList(filteredDescriptions);
  }, [descriptions]);
  return (
    <View style={styles.parentTable}>
      {filteredList.map((item, index) => (
        <View key={item.id} style={styles.itemTable}>
          <View
            style={[
              styles.headerTable,
              { backgroundColor: item.secondColor },
              index == 0 && {
                borderTopLeftRadius: 14,
                borderRightColor: "#D9DADC",
                borderStyle: "solid",
                borderRightWidth: 1,
                borderBottomColor: "#D9DADC",
                borderBottomWidth: 1,
              },
              index == 1 && {
                borderRightColor: "#D9DADC",
                borderStyle: "solid",
                borderRightWidth: 1,
                borderBottomColor: "#D9DADC",
                borderBottomWidth: 1,
              },
              index == 2 && {
                borderTopRightRadius: 14,
                borderStyle: "solid",
                borderBottomColor: "#D9DADC",
                borderBottomWidth: 1,
              },
            ]}
          >
            <Text style={[styles.textHeaderTable, { color: item.color }]}>
              {item.title}
            </Text>
          </View>
          <View
            style={[
              styles.bodyTable,
              index == 0 && {
                borderBottomLeftRadius: 14,
                borderRightWidth: 1,
                borderRightColor: "#D9DADC",
                borderStyle: "solid",
              },
              index == 1 && {
                borderRightWidth: 1,
                borderRightColor: "#D9DADC",
                borderStyle: "solid",
              },
              index == 2 && {
                borderBottomRightRadius: 14,
              },
              { height: highest || heights[index] },
            ]}
            onLayout={(event) => {
              const { x, y, width, height } = event.nativeEvent.layout;
              setHeights((prev) => [...prev, height]);
            }}
          >
            <Text style={styles.textBodyTableTitle}>{item.description}</Text>
            {item.lists.map((list, indexed) => {
              if (list.name === "saran_pengembangan") {
                return (
                  <View key={indexed} style={styles.wrapTextBodyTable}>
                    <Text>{"\u2022"}</Text>
                    <Text style={styles.textBodyTable}>{list.desc}</Text>
                  </View>
                );
              }

              return;
            })}
          </View>
        </View>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  parentTable: {
    width: "100%",
    borderRadius: 14,
    borderColor: "#D9DADC",
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 24,
    flexDirection: "row",
  },
  itemTable: {
    width: "33.35%",
    height: "auto",
  },
  headerTable: {
    backgroundColor: "#DCF4CA",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  textHeaderTable: {
    color: "#4EB826",
    fontFamily: "Archivo-SemiBold",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "500",
    textAlign: "center",
  },
  textBodyTableTitle: {
    color: "#0A0316",
    fontFamily: "Archivo-SemiBold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 20,
  },
  bodyTable: {
    padding: 16,
    backgroundColor: "#FCFEFB",
  },
  wrapTextBodyTable: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 8,
  },
  textBodyTable: {
    color: "#0A0316",
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "300",
    marginLeft: 8,
    lineHeight: 22,
  },
});
export default DevelopSuggestion;
