import React from "react";
import { StyleSheet, Text, View } from "react-native";

const ConditionText = (props) => {
  const {lang} = props;
  return (
    lang === "id" ? <IndonesianCondition/> : <EnglishCondition/>
  );
};

const IndonesianCondition = () => {
  return <View>
  <Text style={[termsAndConditionStyles.title]}>Kebijakan data</Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Kebijakan data ini mencakup kebijakan data untuk layanan Fammi
    sebagai Layanan Belajar, Bertanya dan Diskusi seputar masalah
    Keluarga. Kebijakan data ini merupakan komitmen Kami sebagai
    Penyedia Layanan untuk menjaga kerahasiaan, keamanan dan integritas
    pengguna terhadap perlindungan data pribadi pengguna. Kebijakan data
    ini berlaku untuk merek, produk, atau layanan baik melalui Aplikasi
    maupun Website.
    {"\n"}
    {"\n"}
    Dengan mengunjungi Layanan Kami, Anda menerima dan menyetujui
    pendekatan dan cara-cara yang digambarkan dalam Kebijakan Privasi
    ini.
    {"\n"}
    {"\n"}
    Merujuk pada peraturan perlindungan regulasi data yang berlaku,
    termasuk Undang-Undang No. 11 Tahun 2008 tentang Informasi dan
    Transaksi Elektronik, Peraturan Pemerintah No. 82 Tahun 2012 tentang
    Penyelenggaraan Sistem dan Transaksi Elektronik, serta Peraturan
    Menteri Komunikasi dan Informatika No. 20 Tahun 2016 tentang
    Perlindungan Data Pribadi dalam Sistem Elektronik (“Peraturan yang
    Berlaku”), penyelenggara sistem elektronik adalah PT Ruang Raya
    Indonesia dengan alamat Jalan Tanah Abang I Nomor 11F, Jakarta
    Pusat, DKI Jakarta, Indonesia.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>
    Data Pengguna apa yang kami kumpulkan?
  </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Informasi yang Anda berikan saat pendaftaran.{"\n"}
    Saat mendaftar Layanan Fammi, kami akan mengumpulkan data pribadi
    seperti: Nama, email, dan Nomor Telepon. Hal ini disebut dengan Data
    Registrasi bersifat wajib. Anda dapat memberikan informasi melalui
    formulir elektronik pada Platform Kami.
    {"\n"}
    {"\n"}
    Informasi yang Kami kumpulkan.{"\n"}
    Data perangkat. Kami mengumpulkan informasi dari perangkat yang
    pengguna gunakan untuk mengakses Layanan kami, Data perangkat ini
    mencakup data merek, manufaktur dan IP address dari perangkat yang
    digunakan. Kami mungkin akan mengaitkan informasi yang kami
    kumpulkan dengan perangkat pengguna yang akan membantu kami dalam
    memberi Layanan yang stabil ke perangkat yang digunakan.
    {"\n"}
    {"\n"}
    Data Profil. Kami mengumpulkan data pribadi seperti: Nama, usia,
    jenis kelamin dan profesi pada saat pertama kali Anda melengkapi
    profil di dalam aplikasi Fammi.
    {"\n"}
    {"\n"}
    Informasi yang Kami terima dari sumber lain{"\n"}
    Kami dapat menerima informasi jika Anda menggunakan situs lain yang
    Kami operasikan atau Layanan lain yang Kami sediakan. Kami juga
    bekerja sama dengan pihak ketiga (termasuk, namun tidak terbatas
    pada misalnya, mitra bisnis, pembayaran, dll) dan dapat menerima
    informasi dari mereka. Kami akan mengambil langkah-langkah untuk
    melakukan verifikasi terhadap informasi yang Kami dapatkan sesuai
    dengan peraturan yang berlaku.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana kami menggunakan data yang kami kumpulkan
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Secara umum. Kami bertanggung jawab untuk memproses Data Pendaftaran
    Anda. Data ini semata-mata digunakan untuk pengembangan kontrak dan
    komunikasi dengan Anda dan Layanan kami yang diberikan kepada Anda.
    Hal ini ditunjukkan untuk menyediakan, memelihara, melindungi dan
    memperbaiki Layanan yang Anda gunakan. Pengguna yang terdaftar juga
    mengirim email pemberitahuan tentang aktivasi Layanan.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana kami menjamin data Anda?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Anda, sebagai orang yang bertanggung jawab atas Data Pengguna yang
    kami proses atas nama Anda. Di sisi lain kami sebagai Layanan
    menjamin Anda bahwa
    {"\n"}
    {"\n"}
    1. Fammi tidak pernah menjual atau membagikan data kontak pengguna
    (email, no telepon) kepada siapapun.{"\n"}
    2. Narasumber atau Ahli berhak atas data pengguna di atas untuk
    kepentingan yang berkaitan dengan layanan yang diberikan di dalam
    aplikasi. Dalam hal ini Fammi bertanggung jawab penuh atas keamanan
    data pengguna.
    {"\n"}
    {"\n"}
    Jika terjadi penyalahgunaan data, pengguna berhak melaporkan
    penyalahgunaan data oleh narasumber kepada fammi dan pihak fammi
    akan melakukan:
    {"\n"}
    {"\n"}
    1. Konfirmasi atas kejadian tersebut.{"\n"}
    2. Memberikan teguran kepada fammi jika terbukti hal yg dilaporkan
    ternyata benar.{"\n"}
    3. Menggunakan jalur hukum jika terdapat kerugian di pihak pengguna
    atas penyalahgunaan yg dilakukan oleh fammi.{"\n"}
    {"\n"}
    {"\n"}
    Pengguna selama penggunaan dan penyediaan Layanan{"\n"}
    Kami percaya bahwa keamanan data pengguna sangatlah berharga. Semua
    data yang tersimpan dalam Layanan dan kami jaga kerahasiaan dan
    keamanannya. Namun, tidak ada sistem keamanan yang benar-benar
    terjamin 100% aman dari gangguan. Jika terjadi gangguan akibat
    pelanggaran keamanan, kami akan mengambil langkah untuk menyelidiki
    situasi tersebut. Selain itu, kami akan memberitahu pengguna Layanan
    dan mengambil langkah sesuai dengan hukum dan peraturan yang
    berlaku.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana Layanan Kami Melakukan Perlindungan?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Layanan Kami dapat mengumpulkan, mengungkapkan dan menyimpan
    informasi pengguna seperti yang dijelaskan dalam Kebijakan Data ini.
    Kami tidak dapat menggunakan atau berbagi informasi untuk
    kepentingan iklan ataupun komersial. Kami hanya akan mengizinkan
    pihak ketiga dalam berbagi informasi hanya untuk kepentingan
    perlindungan hukum jika dibutuhkan sesuai dengan peraturan yang
    berlaku sesuai dengan yang dijelaskan di Kebijakan Data ini.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana Anda memberikan Persetujuan?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Dengan melengkapi formulir yang disediakan Kami pada saat
    pendaftaran Akun, Anda menyatakan telah membaca dan menyetujui
    persyaratan Ketentuan Layanan dan Kebijakan Data ini. Tanpa
    mengurangi hal yang telah disebutkan diatas, Anda secara tegas telah
    menyetujui:
    {"\n"}
    {"\n"}
    1. Pengumpulan dan pengolahan data pribadi Anda oleh Layanan Kami
    yang telah dijelaskan pada Kebijakan Data ini.{"\n"}
    2. Persetujuan Anda terhadap segala pengumpulan dan pemprosesan data
    yang Layanan lakukan.
    {"\n"}
    {"\n"}
    Persetujuan dinyatakan sah ketika Anda mensubmit data diri Anda di
    pendaftaran.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>Hak Pengguna</Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Anda memiliki hak untuk mendaftar, menggunakan, memperbaiki,
    menghapus, memblokir dan mengolah data pribadi Anda. Selain itu,
    Anda dapat menarik kembali persetujuan Anda untuk memproses data dan
    informasi pribadi Anda. Hal ini tidak akan merusak Layanan kami
    hanya saja dengan begitu Anda telah mengundurkan diri dari
    penggunaan Layanan kami. Kami akan membantu Anda sesuai dengan
    fungsi Layanan dan Jangka waktu perjanjian untuk melakukan proses
    data pribadi. Anda juga dapat memilih untuk tidak memberikan data
    tertentu kepada Kami, tetapi mungkin tidak dapat menggunakan fitur
    tertentu dari layanan Kami. Silahkan hubungi Kami di alamat
    care@fammi.ly untuk melakukan penghapusan data
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana jika terdapat perubahan kebijakan data?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Kami dapat mengubah Kebijakan Data ini sewaktu-waktu dengan berbagai
    alasan, termasuk untuk meningkatkan keamanan data kami, perubahan
    pada Layanan kami dan mematuhi aturan hukum yang berlaku. Perubahan
    isi kebijakan ini akan diinformasikan kepada pengguna melalui email
    ataupun Layanan Fammi. Jika terdapat perubahan, kami mendorong
    pengguna untuk meninjau secara cermat, jika pengguna setuju dengan
    perubahan tersebut silahkan teruskan menggunakan Layanan kami. Namun
    jika pengguna tidak setuju dengan perubahan yang kami lakukan dan
    pengguna tidak ingin menggunakan Layanan kami, pengguna dapat
    memilih menutup Akun. Ketika melakukan perubahan Kebijakan Data,
    kami akan mengubah tanggal pembaharuan di halaman ini.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana cara menghubungi Kami ?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Jika ada pertanyaan apa pun terkait dengan Kebijakan Data ini,
    silahkan hubungi Kami dengan mengirimkan surat elektronik kepada
    Kami di care@fammi.ly
  </Text>
</View>;
}
const EnglishCondition = () => {
  return <View>
  <Text style={[termsAndConditionStyles.title]}>Data Policy</Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    This data policy covers the data policy for Fammi services as Learning, Inquiry, and Discussion services related to Family matters. This data policy is our commitment as a Service Provider to maintain the confidentiality, security, and integrity of user data protection. This data policy applies to our brand, products, or services both through the Application and Website.
    {"\n"}
    {"\n"}
    By visiting our Services, you accept and agree to the approaches and methods described in this Privacy Policy.
    {"\n"}
    {"\n"}
    Referring to the applicable data protection regulations, including Law No. 11 of 2008 concerning Electronic Information and Transactions, Government Regulation No. 82 of 2012 concerning the Implementation of Electronic Systems and Transactions, as well as Minister of Communication and Information Regulation No. 20 of 2016 concerning Personal Data Protection in Electronic Systems ("Applicable Regulations"), the electronic system provider is PT Ruang Raya Indonesia with address at Jalan Tanah Abang I No. 11F, Central Jakarta, DKI Jakarta, Indonesia.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>
  <Text style={[termsAndConditionStyles.title]}>
  What user data do we collect?
  </Text>
  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Information you provide during registration.{"\n"}
    When registering for Fammi services, we collect personal data such as: Name, email, and Telephone Number. This is called Registration Data and is mandatory. You can provide information through electronic forms on our Platform.
    {"\n"}
    {"\n"}
    Information We Collect.{"\n"}
    Device Data. We collect information from the device you use to access our Services. Device data includes brand, manufacturer, and IP address of the device used. We may associate the information we collect with the user's device to provide stable Service to the device.
    {"\n"}
    {"\n"}
    Profile Data. We collect personal data such as: Name, age, gender, and profession when you first complete your profile in the Fammi application.
    {"\n"}
    {"\n"}
    Information We Receive from Other Sources{"\n"}
    We may receive information if you use other sites that we operate or other services that we provide. We also collaborate with third parties (including, but not limited to, business partners, payments, etc.) and may receive information from them. We will take steps to verify the information we obtain in accordance with applicable regulations.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
  How do we use the data we collect
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    In general. We are responsible for processing your Registration Data. This data is solely used for contract development and communication with you and the services we provide to you. This is indicated to provide, maintain, protect, and improve the Services you use. Registered users also receive email notifications about Service activation.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
  How do we ensure your data?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    You, as the person responsible for the User Data we process on your behalf. On the other hand, we, as a Service, assure you that:
    {"\n"}
    {"\n"}
    1. Fammi never sells or shares user contact data (email, phone number) with anyone.{"\n"}
    1. Sources or Experts are entitled to user data above for purposes related to the services provided within the application. In this case, Fammi is fully responsible for the security of user data.
    {"\n"}
    {"\n"}
    If data misuse occurs, users have the right to report data misuse by sources to Fammi and Fammi will:
    {"\n"}
    {"\n"}
    1. Confirm the incident.{"\n"}
    2. Provide reprimands to Fammi if the reported matter is proven true.{"\n"}
    3. Use legal avenues if there are losses to users due to misuse committed by Fammi.{"\n"}
    {"\n"}
    {"\n"}
    User Responsibilities during Use and Provision of Services{"\n"}
    We believe that user data security is valuable. All data stored in the Service is kept confidential and secure. However, no security system is 100% guaranteed to be free from interference. If there is an interference due to a security breach, we will take steps to investigate the situation. Furthermore, we will notify users of the Service and take steps in accordance with applicable laws and regulations.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
  How Does Our Service Provide Protection?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Our Service may collect, disclose, and store user information as described in this Data Policy. We cannot use or share information for advertising or commercial purposes. We will only allow third parties to share information only for legal protection purposes if required according to applicable regulations as described in this Data Policy.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
  How Do You Give Consent?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    By completing the forms provided by us at the time of Account registration, you state that you have read and agreed to the terms of the Service Agreement and this Data Policy. Without prejudice to the above, you explicitly agree to:
    {"\n"}
    {"\n"}
    1. The collection and processing of your personal data by our Service as described in this Data Policy.{"\n"}
    2. Your consent to all data collection and processing carried out by the Service.
    {"\n"}
    {"\n"}
    Consent is deemed valid when you submit your personal data during registration.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>User Rights</Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    You have the right to register, use, correct, delete, block, and process your personal data. In addition, you can withdraw your consent to process your personal data and information. This will not harm our Service; it means that you have withdrawn from using our Service. We will assist you in accordance with the Service Function and Agreement Period to process personal data. You can also choose not to provide certain data to us, but may not be able to use certain features of our Service. Please contact us at care@fammi.ly to delete data.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
    Bagaimana jika terdapat perubahan kebijakan data?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    Kami dapat mengubah Kebijakan Data ini sewaktu-waktu dengan berbagai
    alasan, termasuk untuk meningkatkan keamanan data kami, perubahan
    pada Layanan kami dan mematuhi aturan hukum yang berlaku. Perubahan
    isi kebijakan ini akan diinformasikan kepada pengguna melalui email
    ataupun Layanan Fammi. Jika terdapat perubahan, kami mendorong
    pengguna untuk meninjau secara cermat, jika pengguna setuju dengan
    perubahan tersebut silahkan teruskan menggunakan Layanan kami. Namun
    jika pengguna tidak setuju dengan perubahan yang kami lakukan dan
    pengguna tidak ingin menggunakan Layanan kami, pengguna dapat
    memilih menutup Akun. Ketika melakukan perubahan Kebijakan Data,
    kami akan mengubah tanggal pembaharuan di halaman ini.
    {"\n"}
    {"\n"}
    {"\n"}
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
  What if there are changes to the data policy?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    We may change this Data Policy at any time for various reasons, including improving our data security, changes to our Services, and complying with applicable legal regulations. Changes to the content of this policy will be communicated to users via email or the Fammi Service. If there are changes, we encourage users to review them carefully. If users agree to the changes, please continue to use our Service. However, if users disagree with the changes we make and do not wish to use our Service, users can choose to close the Account. When making changes to the Data Policy, we will change the update date on this page.
  </Text>

  <Text style={[termsAndConditionStyles.title]}>
  How to contact us?
  </Text>

  <Text style={termsAndConditionStyles.text}>
    {"\n"}
    If you have any questions regarding this Data Policy, please contact us by sending an email to care@fammi.ly.
  </Text>
</View>;
}
export const termsAndConditionStyles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    padding: 16,
  },
  title: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 16,
  },
  text: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 16,
  },
  list: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 4,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 100,
    backgroundColor: "#000",
    marginTop: 6,
  },
});
export default ConditionText;
