import Svg, { Path } from "react-native-svg";
const DentIcon = ({ customColor }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <Path
      fill={customColor ?? "#000"}
      d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5Zm2.535 7.822 1.095.63a.99.99 0 0 1 .36 1.35.99.99 0 0 1-1.35.36l-1.095-.63a.377.377 0 0 0-.563.323v1.26a.99.99 0 1 1-1.98 0v-1.26c0-.285-.315-.473-.562-.322l-1.095.63a.99.99 0 0 1-1.35-.36.99.99 0 0 1 .36-1.35l1.095-.63a.38.38 0 0 0 0-.653l-1.095-.63a.99.99 0 0 1-.36-1.35.99.99 0 0 1 1.35-.36l1.095.63a.376.376 0 0 0 .562-.322v-1.26a.99.99 0 1 1 1.98 0v1.26c0 .285.316.472.563.322l1.095-.63a.99.99 0 0 1 1.35.36.99.99 0 0 1-.36 1.35l-1.095.63a.38.38 0 0 0 0 .652Z"
    />
  </Svg>
);
export default DentIcon;
