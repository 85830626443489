import { useEffect, useMemo, useState } from "react";
import {
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { AppBar, AspectDiffCard } from "../components";
import {
  getDiffAscpect,
  getUserDiffAspect,
  userRespondent
} from "../components/ScreeningReport/dummyScreeningReport";
import { screeningType } from "./ScreeningReport";

const ScreeningReportDetail = ({ route }) => {
  const { params } = route;

  const [filter, setFilter] = useState("");
  const [data, setData] = useState(params.data);
  const [filterData, setFilterData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  const kind = params?.kind;
  const isRespondent = kind === screeningType.respondent;
  const isUserDiffAspect = kind === screeningType.userAspect;

  useEffect(() => {
    if (kind === screeningType.respondent) {
      setData(params?.data);
    } else {
      setData(params?.data);
    }

    const tempData = [];
    params?.data.map((d) => {
      if (tempData.indexOf(d.summary) < 0) {
        tempData.push(d.summary);
      }
    });
    if (tempData.length > 0) {
      setFilter(tempData[0]);
    }
    setFilterData(tempData);
  }, [params?.kind, params?.data]);

  const renderHeader = useMemo(() => {
    const tempDisplayData = data?.filter((d) => (d.summary === filter))
    setDisplayedData(tempDisplayData);

    if (isRespondent) {
      return (
        <ScrollView
          horizontal
          style={styles.filter}
          contentContainerStyle={{ gap: 12, flexShrink: 0 }}
        >
          {filterData.map((f, id) => {
            const isActive = f === filter;
            return (
              <TouchableOpacity
                key={id}
                onPress={() => setFilter(f)}
                style={[
                  styles.filterBtn,
                  { backgroundColor: isActive ? "#6724E2" : "#fff" }
                ]}
              >
                <Text
                  style={[
                    styles.txFilterBtn,
                    { color: isActive ? "#fff" : "#000" }
                  ]}
                >
                  {f}
                </Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      );
    }

    if (isUserDiffAspect) {
      return (
        <View style={styles.flexRow}>
          <Text style={styles.txHeader}>Nama</Text>
          <Text style={styles.txHeader}>Perbedaan Aspek</Text>
        </View>
      );
    }

    return null;
  }, [isRespondent, params?.data, filter, data, filterData]);

  return (
    <>
      <AppBar title={params?.title} isBack />
      <FlatList
        data={displayedData}
        ListHeaderComponent={renderHeader}
        stickyHeaderHiddenOnScroll
        contentContainerStyle={styles.container}
        renderItem={({ item }) => {
          if (kind === screeningType.respondent) {
            if (!item.name) {
              return ;
            }

            if (filter !== "" && item.summary !== filter) {
              return <></>;
            }
            return <DataItem isRespondent item={item} filter={filter} />;
          // } 
          // else if (kind === screeningType.userAspect) {
          //   return <DataItem {...item} />;
          } else {
            return <AspectDiffCard isDetail {...item} />;
          }
        }}
        keyExtractor={(item) => item.id}
      />
    </>
  );
};

function DataItem({isRespondent, item, filter}) {
  return (
    <View style={[styles.dataItem, styles.flexRow]}>
      <Text style={styles.txDataItem}>{item.name}</Text>
      {!isRespondent && (
        <Text style={[styles.txDataItem, { color: "#6724E2" }]}>
          {parseInt(memberPoin ?? 0)} Aspek
        </Text>
      )}
    </View>
  );
}

export default ScreeningReportDetail;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#EEEAFF",
    gap: 10,
    padding: 16,
	minHeight: "92.5%"
  },
  filter: {
    flexGrow: 0,
    marginBottom: 4,
    backgroundColor: "#EEEAFF"
  },
  filterBtn: {
    paddingVertical: 11,
    paddingHorizontal: 12,
    borderRadius: 10
  },
  txFilterBtn: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12
  },
  txHeader: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12
  },
  dataItem: {
    padding: 14,
    backgroundColor: "#fff",
    borderRadius: 10,
    width: '100%'
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  txDataItem: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12
  }
});
