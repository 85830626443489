import { Text, View, StyleSheet, Pressable, Image } from "react-native";
import moment from "moment";
import CameraIcon from "../assets/icons/CameraIcon";
import ClockCircle from "../assets/icons/ClockCircle";

import { YoutubeIcon } from "../assets/icons";

function DetailZoomCard(props) {
  const {misc, data, speaker} = props;

  moment().locale("id");
  return (
    <View style={styles.cardParent}>
      <View style={styles.mainContent}>
        <Text style={{...styles.txName, textTransform: "capitalize"}}>
          {misc?.short_desc?.replace(/&lsquo;/g, "'").replace(/&ldquo;/g, "\"").replace(/&rdquo;/g, "'").replace(/&rdquo;/g, "\"").replace(/&Lsquo;/g, "'").replace(/&Ldquo;/g, "\"").replace(/&rsquo;/g, "'").replace(/&Rdquo;/g, "\"")}
        </Text>
      </View>
      <View style={styles.bottomSection}>
        <View style={styles.topSection}>
          <Image
            source={{
              uri: `http://fammi.ly/uploads/speakers/images/${speaker?.photo}`,
            }}
            style={styles.imgAva}
            height={30}
            width={30}
          />
          <View style={{...styles.parentItem, width: "90%"}}>
            <Text style={{...styles.txTitle}}>{speaker?.name}</Text>
            <Text style={styles.txSpan} ellipsizeMode="tail" numberOfLines={1}>{speaker?.description}</Text>
          </View>
        </View>
        <View style={styles.topSection}>
          <ClockCircle />
          <View style={styles.parentItem}>
            <Text style={styles.txTitle}>{moment(data?.scheduledDate).format("dddd, DD MMM YYYY")}</Text>
            <Text style={styles.txSpan}>Pukul {misc.start} - {misc.end} WIB</Text>
          </View>
        </View>
        {
          misc.type !== "diskusi" &&
          <View style={styles.topSection}>
            {
              data?.agendaType === "zoom" &&
              <CameraIcon fill="#EAEAEA" innerFill="#000" />
            }
            {
              data?.agendaType === "youtube" &&
              <YoutubeIcon fill="#EAEAEA" innerFill="#000" />
            }
            <View style={styles.parentItem}>
              <Text style={{...styles.txTitle}}>Melalui Aplikasi {data?.agendaType.replace("youtube", "YouTube").replace("zoome", "Zoom")}</Text>
              <Text style={styles.txSpan}>Pastikan kamu sudah instal</Text>
            </View>
          </View>
        }
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  txName: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: 18,
  },
  topSection: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  cardParent: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  mainContent: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#EAEAEA",
    borderStyle: "solid",
  },
  parentItem: {
    marginLeft: 10,
  },
  txTitle: {
    color: "#000",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
  },
  txDateTime: {
    color: "#4EB826",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
  },
  imgAva: {
    height: 30,
    width: 30,
    borderRadius: 30 / 2,
  },
  txSpan: {
    color: "#999",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
  },
  txMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 16,
  },
  bottomSection: {
    padding: 12,
	paddingBottom: 0,
  },
  buttonLeft: {
    width: "47.5%",
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderColor: "#EAEAEA",
    borderWidth: 1,
    borderRadius: 8,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRight: {
    width: "47.5%",
    backgroundColor: "#EEEAFF",
    borderRadius: 8,
    padding: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default DetailZoomCard;
