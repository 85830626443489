import React from "react";
import { TouchableOpacity } from "react-native";
import { View, Text, StyleSheet } from "react-native";
import { ChevronRight, SubscribeIcon } from "../assets/icons";

function MenuProfile(props) {
  const { list } = props;
  return (
    <View style={styles.parentMenu}>
      {list?.map((item, index) => (
        <TouchableOpacity
          key={index}
          style={[
            styles.listMenu,
            index + 1 < list?.length && {
              borderBottomWidth: 1,
              borderBottomColor: "#EAEAEA",
              borderStyle: "solid",
            },
          ]}
          onPress={() => {
            item.onPress();
          }}
        >
          <View style={styles.subMenu}>
            <item.icon />
            <Text style={styles.textMenu}>{item.title}</Text>
          </View>
          {!item?.hideRight && (item?.customRight ?? <ChevronRight />)}
        </TouchableOpacity>
      ))}
    </View>
  );
}
const styles = StyleSheet.create({
  parentMenu: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginTop: 16,
    flexDirection: "column",
    alignItems: "center",
  },
  listMenu: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 16,
  },
  subMenu: {
    flexDirection: "row",
    alignItems: "center",
  },
  textMenu: {
    marginLeft: 12,
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 14,
    color: "#000000",
  },
});

export default MenuProfile;
