import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { TicketStarIcon } from "../assets/icons";

const PromoCard = ({data, setIsKodeVisible, t}) => {
  const additionalNotes = data?.additionalNotes?.split(",");
  const desc = data?.additionalDescriptions?.split(",");
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.promoBadge}>
          <TicketStarIcon />
        </View>

        <View style={{
          width: "84%",
        }}>
          <Text style={styles.title}>{data?.productName}</Text>
          <Text style={styles.time} ellipsizeMode="tail" numberOfLines={1}>{data?.productDescription}</Text>
        </View>
      </View>

      <View style={styles.bottom}>
        <View style={styles.bottomBox}>
          <Text style={styles.detailText}>
            {
              desc?.map((d, index) => {
                return `${d.replace(" sesi", "")}x ${additionalNotes?.[index]
                  .replace("Share ke Expert", t("promo-card:consult"))
                  .replace("Share ke Tokoh", t("promo-card:sharing"))
                  .replace("Share ke Partner", t("promo-card:confide"))} `;
              })
            }
          </Text>
        </View>
        <TouchableOpacity style={{ ...styles.bottomBox, ...styles.button }} onPress={()=>{
          setIsKodeVisible(true);
        }}>
          <Text style={styles.buttonText}>{t("promo-card:try")}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    borderRadius: 10,
    backgroundColor: "#fff",
  },
  top: {
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 12,
    borderBottomColor: "#EAEAEA",
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  promoBadge: {
    width: 40,
    height: 40,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#4EB826",
    backgroundColor: "#ECF8E6",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 13,
  },
  time: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 12,
    lineHeight: 13,
    marginTop: 4,
    width: "100%",
  },
  bottom: {
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
    columnGap: 10,
  },
  bottomBox: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: "#eaeaea",
  },
  detailText: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 10,
    lineHeight: 11,
  },
  button: {
    borderColor: "#6724E2",
    flex: 1,
  },
  buttonText: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 10,
    lineHeight: 11,
    color: "#6724E2",
    textAlign: "center",
  },
  divider: {
    alignSelf: "center",
    backgroundColor: "#eaeaea",
    height: 22,
    width: 1,
  },
});

export default PromoCard;
