import { Fragment, useEffect, useState } from "react";
import { AppBar, EmptyState, ListEbook } from "../components";
import { ScrollView, StyleSheet, View } from "react-native";
import { getMaterials } from "../services/materials";
import { BookBlackIcon } from "../assets/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

function EbookList({route}) {
  const {params} = route;
  const [materials, setMaterials] = useState([]);
  const [memberId, setMemberId] = useState(undefined);
  useEffect(()=>{
    if (!params?.additionalContentId) {
      return ;
    }

    getMaterials(params?.additionalContentId)
    .then((res) => {
      setMaterials(res?.data);
    })

    async function getMember() {
      const token =  await AsyncStorage.getItem("token");
      setMemberId(token);
    }

    getMember();

  }, []);

  return (
    <Fragment>
      <AppBar title="Materi" isBack />
      {
        materials?.length === 0 &&
        <View style={{
          flexDirection:"row",
          alignItems: "center",
          padding: 16,
          backgroundColor: "#EEEAFF",
          flex: 1
        }}>
          <EmptyState
            Icon={BookBlackIcon}
            title="Materi Belum Tersedia"
            desc="Belum ada materi seputar program yang bisa kamu akses saat ini"
          />
        </View>
      }
      {
        materials?.length > 0 &&
        <ScrollView style={styles.container}>
          {materials.map((m, i) => (
            <ListEbook key={i} data={m} additionalContentId={params?.additionalContentId} memberId={memberId}/>
          ))}
        </ScrollView>
      }
    </Fragment>
  );
}
const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: "#EEEAFF",
  },
});

export default EbookList;
