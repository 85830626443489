import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { StarFillIcon } from "../assets/icons";

const JoinProgramCard = ({ onPressInput }) => {
  return (
    <TouchableOpacity onPress={onPressInput}>
    <View style={styles.container}>
      <View style={styles.iconBox}>
        <StarFillIcon fill="#fff" width={18} height={18} />
      </View>
      <View style={{flexDirection: "row", alignItems: "center"}}>
        <Text style={styles.text}>
          Mau gabung program?
        </Text>
        <View onPress={onPressInput}>
          <Text style={styles.link}>
          Input Kode Program
          </Text>
        </View>
      </View>
    </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 42,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
    backgroundColor: "#fff",
    borderRadius: 6,
    marginHorizontal: 16,
    marginBottom: 24,
  },
  iconBox: {
    width: 42,
    height: 42,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 18,
    backgroundColor: "#FDC52E",
    padding: 12,
  },
  text: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#000000",
    alignItems: "center"
  },
  link: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    color: "#6724E2",
    marginLeft: 4,
  },
});

export default JoinProgramCard;
