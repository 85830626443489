import { LinearGradient } from "expo-linear-gradient";
import { useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  ChevronRight,
  EmojiHappy2Icon,
  EmojiNormalIcon,
  EmojiSadIcon,
} from "../../assets/icons";

function SuggestionAspectCard(props) {
  const { aspect, condition } = props;

  const conditionStyle = useMemo(() => {
    switch (condition) {
      case "secure":
        return {
          label: "Aman",
          Icon: EmojiHappy2Icon,
          color: ["#3E951D", "#4EB826", "#DCF4CA", "#F1FBEA"],
        };
      case "warning":
        return {
          label: "Waspada",
          Icon: EmojiNormalIcon,
          color: ["#A48A00", "#B29600", "#FFEB80", "#FFF7CC"],
        };
      case "critical":
        return {
          label: "Kritis",
          Icon: EmojiSadIcon,
          color: ["#E54646", "#E54646", "#F9D2D2", "#FCE9E9"],
        };
    }
  }, [condition]);

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={["#fff", "#fff", conditionStyle.color[3]]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={styles.header}
      >
        <View style={[styles.icon, { borderColor: conditionStyle.color[1] }]}>
          <conditionStyle.Icon width={38} height={38} />
          <LinearGradient
            colors={[conditionStyle.color[1], "#D9DADC"]}
            start={{ x: 0, y: 0 }}
            end={{ x: 0, y: 1 }}
            style={styles.verticalLine}
          />
        </View>
        <View style={styles.labels}>
          <View
            style={[styles.label, { backgroundColor: conditionStyle.color[3] }]}
          >
            <Text style={styles.textLabelTop}>Aspek</Text>
            <Text style={styles.textLabelBottom}>{aspect}</Text>
          </View>
          <ChevronRight
            width={24}
            height={24}
            stroke={conditionStyle.color[1]}
          />
          <View
            style={[styles.label, { backgroundColor: conditionStyle.color[2] }]}
          >
            <Text style={styles.textLabelTop}>Kondisi</Text>
            <Text style={styles.textLabelBottom}>{conditionStyle.label}</Text>
          </View>
        </View>
      </LinearGradient>
      <View style={styles.content}>
        <Text style={styles.textContentHeader}>
          Berikut merupakan beberapa saran untuk mengatasi kondisi{" "}
          {aspect.toLowerCase()} ananda:
        </Text>
        {[
          "Ketika Ananda merasa memiliki masalah yang sulit diselesaikan dan memiliki perasaan yang tidak nyaman, maka ia bisa mulai bercerita pada orangtua atau guru.",
          `Ajarkan positive self-talk untuk mengelola rasa cemas yang Ananda miliki. Positive self talk adalah seni berbicara pada diri sendiri dengan menggunakan kalimat yang positif. Misalnya: "Aku takut ngomong di depan kelas" meniadi "Aku berani ngomong di depan kelas".`,
          "Latihan Relaksasi juga dapat menurunkan rasa cemas ananda. Berikut langkah-langkah melakukan relaksasi: Ambil posisi duduk bersila atau berbaring, Tarik nafas 4 hitungan, tahan 3 hitungan dan hembuskan 4 hitungan, Lakukan beberapa kali sampai tubuh dirasa lebih rileks",
          "Ketika Ananda merasa memiliki masalah yang sulit diselesaikan dan memiliki perasaan yang tidak nyaman, maka ia bisa mulai bercerita pada orangtua atau guru.",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed enim ut sem viverra aliquet eget sit amet. Id eu nisl nunc mi ipsum faucibus vitae aliquet nec. Nisl rhoncus mattis rhoncus urna neque viverra. Augue lacus viverra vitae congue. Nisi vitae suscipit tellus mauris a diam maecenas sed. Urna duis convallis convallis tellus id interdum velit laoreet id. Eget duis at tellus at.",
        ].map((item, id) => (
          <View key={id} style={styles.item}>
            <View
              style={[
                styles.dotItem,
                { backgroundColor: conditionStyle.color[1] },
              ]}
            />
            <Text style={styles.textItem}>{item}</Text>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 24,
    marginTop: 20,
  },
  header: {
    width: "100%",
    paddingVertical: 18,
    paddingHorizontal: 32,
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    gap: 32,
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderRadius: 14,
  },
  icon: {
    width: 64,
    height: 64,
    borderWidth: 3,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  verticalLine: {
    position: "absolute",
    top: "100%",
    width: 3,
    height: 46,
  },
  labels: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  label: {
    width: 154,
    padding: 12,
    borderRadius: 8,
    alignItems: "flex-start",
    gap: 8,
  },
  textLabelTop: {
    fontFamily: "Archivo-Regular",
    fontSize: 14,
    fontWeight: 400,
    fontStyle: "normal",
  },
  textLabelBottom: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
  },
  content: {
    padding: 32,
    gap: 12,
    alignItems: "flex-start",
    borderWidth: 1,
    borderColor: "#D9DADC",
    borderRadius: 14,
  },
  textContentHeader: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 600,
    color: "#0A0316",
  },
  item: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 10,
  },
  dotItem: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginTop: 6,
  },
  textItem: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 22,
    color: "#0A0316",
  },
});

export default SuggestionAspectCard;
