import * as React from "react";
import Svg, { Path } from "react-native-svg";
const StarMoveIcon = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.39 5.21101L16.8 8.03101C16.99 8.42101 17.5 8.79101 17.93 8.87101L20.48 9.29101C22.11 9.56101 22.49 10.741 21.32 11.921L19.33 13.911C19 14.241 18.81 14.891 18.92 15.361L19.49 17.821C19.94 19.761 18.9 20.521 17.19 19.501L14.8 18.081C14.37 17.821 13.65 17.821 13.22 18.081L10.83 19.501C9.12 20.511 8.08 19.761 8.53 17.821L9.1 15.361C9.19 14.881 9 14.231 8.67 13.901L6.68 11.911C5.51 10.741 5.89 9.56101 7.52 9.28101L10.07 8.86101C10.5 8.79101 11.01 8.41101 11.2 8.02101L12.61 5.20101C13.38 3.68101 14.62 3.68101 15.39 5.21101ZM8 5.75001H2C1.59 5.75001 1.25 5.41001 1.25 5.00001C1.25 4.59001 1.59 4.25001 2 4.25001H8C8.41 4.25001 8.75 4.59001 8.75 5.00001C8.75 5.41001 8.41 5.75001 8 5.75001ZM5 19.75H2C1.59 19.75 1.25 19.41 1.25 19C1.25 18.59 1.59 18.25 2 18.25H5C5.41 18.25 5.75 18.59 5.75 19C5.75 19.41 5.41 19.75 5 19.75ZM3 12.75H2C1.59 12.75 1.25 12.41 1.25 12C1.25 11.59 1.59 11.25 2 11.25H3C3.41 11.25 3.75 11.59 3.75 12C3.75 12.41 3.41 12.75 3 12.75Z"
      fill="#6724E2"
    />
  </Svg>
);
export default StarMoveIcon;
