import React from "react";
import { StyleSheet, Text, View } from "react-native";

const ScreeningResultCard = ({ Icon, title, value, aspectValue }) => {
  return (
    <View style={styles.container}>
      {
        !aspectValue &&
        <React.Fragment>
          <View style={styles.header}>
            <Icon />
            <Text style={styles.titleTx}>{title}</Text>
          </View>
          <Text style={styles.valueTx}>{value}</Text>
        </React.Fragment>
      }
      {
        aspectValue &&
        <React.Fragment>
            <View style={styles.header}>
              <Icon />
              <Text style={styles.titleTx}>Tindak Lanjut</Text>
            </View>
            {
              aspectValue?.map((v) => {
                return <React.Fragment>
                  {/* <View style={styles.header}> */}
                    <Text style={styles.titleTx}>{v.question}</Text>
                  {/* </View> */}
                  <Text style={{...styles.valueTx, marginBottom: 16}}>{v.aspect}</Text>
                </React.Fragment>
              })
            }
          </React.Fragment>
      }
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 10,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
    marginBottom: 12,
  },
  titleTx: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
  },
  valueTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 16,
  },
});

export default ScreeningResultCard;
