import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ChevronRight, ProfileIcon } from "../assets/icons";

const ScreeningCard = ({ data, quizId }) => {
  const { name, additionalContentId, respId, isAnswered, answeredDate } = data;
  const navigation = useNavigation();
  const theDate =  answeredDate ? answeredDate : "";
  return (
    <TouchableOpacity
      onPress={() => {
        if (isAnswered) {
          navigation.navigate("ScreeningResult", {
            quizId,
            additionalContentId,
            respId,
            isScreening: "1"
          });  
          return;
        }
        navigation.navigate("SurveyDetail", {
          quizId,
          additionalContentId,
          respId,
          isScreening: "1"
        });
      }}>
      <View style={styles.container}>
        <View style={[styles.header, styles.flexRow]}>
          <ProfileIcon width={18} height={18} />
          <Text style={styles.titleTx}>{name}</Text>
        </View>
        <View style={[styles.footer, styles.flexRow]}>
          <Text style={[styles.footerTx]}>
            {isAnswered ? `Sudah diisi ${theDate}` : "Belum diisi"}
          </Text>
          <TouchableOpacity
            style={[styles.flexRow, { gap: 10 }]}
            onPress={() => {
              if (isAnswered) {
                navigation.navigate("ScreeningResult", {
                  quizId,
                  additionalContentId,
                  respId,
                  isScreening: "1"
                });  
                return;
              }
              navigation.navigate("SurveyDetail", {
                quizId,
                additionalContentId,
                respId,
                isScreening: "1"
              });
            }}
          >
            <Text
              style={[
                styles.footerTx,
                {
                  fontFamily: "Archivo-SemiBold",
                  fontWeight: 600,
                  color: isAnswered ? "#4EB826" : "#6724E2"
                }
              ]}
            >
              {isAnswered ? "Lihat Hasil" : "Isi Sekarang"}
            </Text>
            <ChevronRight fill="#000" />
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 10
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center"
  },
  header: {
    gap: 6,
    padding: 12
  },
  titleTx: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12
  },
  footer: {
    paddingHorizontal: 12,
    paddingTop: 10,
    paddingBottom: 12,
    borderTopWidth: 1,
    borderColor: "#eaeaea",
    justifyContent: "space-between"
  },
  footerTx: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "#999999"
  }
});

export default ScreeningCard;
